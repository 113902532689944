export default {
  HEAD_OFFICE_ADMIN: "HEAD_OFFICE_ADMIN",
  OFFICE_ADMIN: "OFFICE_ADMIN",
  OFFICE_USER: "OFFICE_USER",
  CUSTOMER: "CUSTOMER",
  SPECIAL_CUSTOMER: "SPECIAL_CUSTOMER",
  1: "HEAD_OFFICE_ADMIN",
  2: "OFFICE_ADMIN",
  3: "OFFICE_USER",
  4: "CUSTOMER",
  5: "SPECIAL_CUSTOMER",
};

export const RoleHeadOffice = {
  "0":"権限無し",
  "1": "システム管理者",
  "3": "一般ユーザー",
  ADMIN:"1",
  USER:"3",
  NO_ROLE:"0"
  
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Empty,
  Input,
  Spin,
  Form,
  Checkbox,
} from "antd";
import { lang } from "../../constants/common.const";
import InfiniteScroll from "react-infinite-scroller";

const { Paragraph } = Typography;

function PopupAllUser({
  isPC,
  popup,
  _nameUser,
  set_nameUser,
  gridPopup,
  _listUser,
  grid_checkbox,
  gridLoadMore,
  form,
  loadSpin,
}) {
  useEffect(() => {
    // Event only mobile
    const debound = setTimeout(() => {
      !isPC && popup.search(true);
    }, 1000);
    return () => {
      if (debound) clearTimeout(debound);
    };
  }, [_nameUser]);

  return (
    <div>
      <Row className={isPC && "mb15 pl5 pr5"}>
        <Col span={24}>
          <Form
            form={form}
            name="horizontal_login"
            layout={isPC && "inline"}
            onFinish={() => {
              popup.search(true);
            }}
          >
            <Form.Item name="UserName">
              <Input
                width={!isPC ? "100%" : ""}
                placeholder={lang.SEARCH}
                value={_nameUser.value}
                onChange={async (e) => {
                  set_nameUser({
                    ..._nameUser,
                    value: e.target.value,
                  });
                }}
              />
            </Form.Item>
            {isPC && (
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {lang.SEARCH}
                </Button>
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
      {/* Grid */}
      <div className="scroll-grid pt5 pl5 pb5 pr5">
        <Spin spinning={loadSpin} className="content-center"></Spin>
        {/* <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={gridLoadMore}
          hasMore={!gridPopup.loading && gridPopup.hasMore}
          useWindow={false}
        > */}
        <Row gutter={[20, 20]}>
          {_listUser && _listUser.length > 0 ? (
            _listUser.map((e, index) => {
              return (
                <Col xs={24} sm={12} md={8} lg={6} key={e.userPrincipalName}>
                  <Row
                    align="middle"
                    style={{ padding: "13px 16px", height: "130px" }}
                    className="itemList itemList-row "
                    disabled={e.disabled}
                    onClick={() => {
                      !e.disabled && grid_checkbox(e.id, index, !e["checked"]);
                    }}
                  >
                    {/* Title */}
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col className="id">{e.userPrincipalName}</Col>
                        <Col className="created">
                          <Checkbox
                            disabled={e.disabled || (popup.listAdd.length >0 && popup.listAdd[0].userPrincipalName != e.userPrincipalName)}
                            checked={e.checked}
                            onChange={(e) => {
                              grid_checkbox(e.id, index, e.target.checked);
                            }}
                          ></Checkbox>
                        </Col>
                      </Row>
                    </Col>
                    {/* Body */}
                    <Col className="title mb0" span={24}>
                      <Paragraph
                        ellipsis={{ rows: 2 }}
                        className="text-weight-700"
                      >
                        {e.displayName}
                      </Paragraph>
                    </Col>
                    {/* Footer */}
                    <Col span={24}>
                      <Row>
                        <Col className="created">{e.mail}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              );
            })
          ) : (
            <div className="center mt20 w100">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Row>
        {/* </InfiniteScroll> */}
      </div>
    </div>
  );
}

export default PopupAllUser;

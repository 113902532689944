import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { injectionPump } from "../../../api/backend/injectionPump";
import Loading from "../../../component/Desktop/loading/loading";
import { KEY, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";

import useLoadTabDataEffect from "../../../hooks/useLoadTabData";
import { deboundClickModal, formatCurrencyJPY } from "../../../utils/helpers";
import AddFusionPump from "./AddFusionPump";
const { Panel } = Collapse;

const TableInFusionPump = ({isLoad}) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [loading, setLoading] = useState(false);

  const [dataChemicalInjection, setDataChemicalInjection] = useState([]);
  const [idItem, setIdItem] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [costManagement, setCostManagement] = useState([]);
  const onClose = (reset = true) => {
    setIdItem(null);
    setOpenModal(false);
    if (reset) {
      getListPump();
    }
  };

  const getListPump = async () => {
    try {
      setLoading(true);
      const optionsRes = await injectionPump.getInjectionPump();
      const optionData = optionsRes.data.data.map((item, index) => ({
        key: item.injectionPumpId,
        injectName: item.injectName,
        weight: item.weight,
        price: item.price,
        priceBasis: item.priceBasis,
        note: item.note,
      }));
      setDataChemicalInjection(optionData);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIdItem(null);
  };

  // GET DATA
  useLoadTabDataEffect(isLoad,getListPump);

  useEffect(() => {
    if (isError) {
      deboundClickModal.handleCloseModal();
    }
  }, [isError]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: "5%",
      render: (text, record, index) => {
        return <span>{++index}</span>;
      },
      align: "center",
    },
    {
      title: <div style={{ textAlign: "start" }}>{LANG.NAME_INJECTION}</div>,
      dataIndex: "injectName",
      key: "injectName",
      width: "20%",
      render: (text, record, index) => {
        return <span>{record.injectName}</span>;
      },
    },
    {
      title: <div style={{ textAlign: "start" }}>{LANG.WEIGHT}(kg)</div>,
      dataIndex: "weight",
      key: "weight",
      width: "10%",
      render: (text, record) => {
        return <span>{formatCurrencyJPY(record.weight)}</span>;
      },
    },
    {
      title: (
        <div style={{ textAlign: "start" }}>{LANG.AMOUT_OF_MONEY}(千円/基)</div>
      ),
      dataIndex: "price",
      key: "price",
      width: "10%",
      render: (text, record) => {
        return <span>{formatCurrencyJPY(record.price)}</span>;
      },
    },
    {
      title: <div style={{ textAlign: "start" }}>{LANG.PRICE_BASIS}</div>,
      dataIndex: "priceBasis",
      key: "priceBasis",
      width: "20%",
      render: (text, record, index) => {
        return <span>{record.priceBasis}</span>;
      },
    },
    {
      title: <div style={{ textAlign: "start" }}>{LANG.REMARK}</div>,
      dataIndex: "note",
      key: "note",
      width: "20%",
      render: (text, record, index) => {
        return <span>{record.note}</span>;
      },
    },
    {
      title: LANG.ACTION,
      dataIndex: "deleete",
      key: "delete",
      align: "center",
      width: "15%",
      render: (id, record, index) => {
        return (
          <Row gutter={!isPC && [4, 4]} justify={"center"}>
            <Col>
              {" "}
              <Tooltip title={lang.EDIT}>
              <Button
                onClick={() => {
                  setOpenModal(true);
                  setIdItem(record.key);
                }}
                className={"iconItem"}
                icon={<EditOutlined />}
              />
              </Tooltip>
            </Col>
           
          </Row>
        );
      },
    },
  ];

  return (
    <div>
      <Collapse
        className="mt15"
        defaultActiveKey={["1"]}
        expandIconPosition="right"
      >
        <Panel
          header={
            <div className={"wrapTitle"}>
              <span style={{ fontWeight: "bold" }}>{"注入ポンプ"}</span>
            </div>
          }
          key={"1"}
        >
          <div style={{
            marginTop:20
          }}>
            <Table
              dataSource={dataChemicalInjection}
              columns={columns}
              pagination={false}
              className="gridSearch"
              scroll={{ x: !isPC && "100%" }}
              bordered
            />
          </div>
        </Panel>
      </Collapse>

      <Loading show={loading} />
      {openModal && (
        <AddFusionPump open={openModal} onClose={onClose} id={idItem} />
      )}
    </div>
  );
};
export default TableInFusionPump;

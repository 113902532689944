class CheckListGroupsClass {
  constructor(data = {}) {
    this.id = data.id;
    this.title = data.checklistGroupName || data.title;
    this.groupId = data.checklistId;
    this.checklistMachines = data.checklistMachines;
    this.checklistItemPreChecks = data.checklistItemPreChecks;
    this.itemOrder = data.itemOrder;
    this.remark = data.remark;
    this.tabComplete = data.tabComplete;
  }
}
export default CheckListGroupsClass;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  title: null,
  typeConfirm: null,
  redirectUrl: null,
  typeCustom: null,
  data: {},
  openDesktop: false,
};

const confirmSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    openConfirm: (state, { payload }) => {
      state.redirectUrl = payload.redirectUrl;
      state.open = payload.open;
      state.title = payload.title;
      state.typeConfirm = payload.typeConfirm;
      state.typeCustom = false;
      state.data = payload.data;
    },
    closeModal: (state) => {
      state.open = false;
      state.openDesktop = false;
    },
    customGroup: (state, { payload }) => {
      state.open = payload.open;
      state.title = payload.title;
      state.data = payload.data;
      state.typeCustom = payload.typeCustom;
      state.typeConfirm = false;
    },
    openPopupDesktop: (state, { payload }) => {
      state.type = payload.type;
      state.title = payload.title;
      state.openDesktop = payload.open;
    },
  },
});
// the action
export const {
  openConfirm,
  closeModal,
  customGroup,
  openPopupDesktop,
} = confirmSlice.actions;

// the selector
export const confirmSelector = (state) => state.confirm;

//the reducer
export default confirmSlice.reducer;

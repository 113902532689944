import "../Estimate.scss";

import { RollbackOutlined, SendOutlined } from "@ant-design/icons";
import { Col, Collapse, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ApiMachine from "@src/api/backend/machineManagement";
import FooterPage from "@src/component/footer/footerPage";
import { ButtonModal } from "@src/page/Estimate/view/step2.jsx";
import _ from "lodash";
import { Redirect, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { EstimateApi } from "../../../api/backend/estimate";
import BreakLine from "../../../component/Desktop/breakLine";
import RowInput from "../../../component/Desktop/rowInput";
import TitlePage from "../../../component/Desktop/titlePage";
import { KEY } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import {
  openSave,
  setDataFromStep2ToStep1,
  setDataStep1,
  setDataStep2,
} from "../../../redux/estimate";
import {
  cloneObj,
  getPathEstimateWithStep,
  getStandardLv,
  roundNumber,
} from "../../../utils/helpers";
import DescriptionFiberLength from "../descriptionFiberLength";
import ButtonSave from "./buttonSave";
import PanelCustom from "../../../component/panel/PanelCustom";
import { injectionPump } from "../../../api/backend/injectionPump";
import { name } from "file-loader";
export const INJECT_NAME = [
  LANG.PAC_INJECTION,
  LANG.HYPOCHLOROUS_INJECTION,
  LANG.PH_INJECTION,
];
const AddStep2 = ({ dataStep1, basePath, isNewDataDetail }) => {
  const { Panel } = Collapse;
  const { dataStep2, dataStep3, fromToStep2, detailData, listLv } = useSelector(
    (state) => state.estimate
  );
  const { id } = useParams();
  const path = !id ? "/Estimate/Add" : "/Estimate/Edit/" + id;
  const history = useHistory();
  const dispatch = useDispatch();
  const isnotExistStep1Data = _.isEmpty(dataStep1);
  const [firberType, setFirberType] = useState("");
  const { formDataPostDefault } = useSelector((state) => state.estimate);
  const [actualLv, setActualLv] = useState("");
  const [dataCapacity, setDataCapacity] = useState([]);

  const [estimationAuxMachine, setEstimationAuxMachine] = useState({
    auxFiltrationPumpModel: "",
    auxBackwashPumpModel: "",
    auxAirWashingBlowerModel: "",
  });
  const [numberOfMachine, setNumberOfMachine] = useState("");
  const [machineSelected, setModelMachine] = useState("");
  const [netVolumeWaterRecovered, setNetVolumeWaterRecovered] = useState("");
  const volumnWater =
    dataStep1 && dataStep1.estimationConditionInput
      ? dataStep1.estimationConditionInput.inputRawWaterVolume ||
        dataStep1.estimationConditionInput.inputNetVolumeWaterRecovered
      : "";

  // CUrrenr LV
  let lv = null;
  if (dataStep1 && dataStep1.fiberLength) {
    lv = getStandardLv(listLv, dataStep1.fiberLength, dataStep1.rawWaterType);
  }

  // GET KBN
  const getKbn = async () => {
    try {
      const [resFiberType, resLv] = await Promise.all([
        EstimateApi.getFirberType(),
        EstimateApi.getLv(),
      ]);
      const dataFiber = resFiberType.data.data;
      const item = dataFiber.find(
        (item) => item.key === dataStep1.rawWaterType
      );
      if (item) {
        setFirberType(item.value);
      }
    } catch (error) {}
  };

  // GET CAPACITY
  const getCapacity = async () => {
    try {
      const bodyCapacity = cloneObj({
        ...cloneObj(formDataPostDefault),
        ...cloneObj(dataStep1),
        ...dataStep3,
      });
      const { h2so4DrugConc, h2so4ChemicalSpecificGravity, ...rest } =
        bodyCapacity;

      const body = {
        ...rest,
        estimationConst: {
          ...rest.estimationConst,
          h2so4DrugConc: h2so4DrugConc,
          h2so4ChemicalSpecificGravity: h2so4ChemicalSpecificGravity,
        },
      };
      delete body.rawWaterTypevalue;
      body.standardLv = lv || dataStep2.lv.current || null;
      body.options = body.options.filter((i) => i.checked);
      body.options.forEach((item) => {
        if (item.hasOwnProperty("isError")) {
          delete item.isError;
        }
        if (item.hasOwnProperty("checked")) {
          delete item.checked;
        }
        item.defaultValue = item.value;
      });
      body.waterDevices = body.waterDevices
        .filter((i) => i.checked)
        .map(({ checked, ...other }) => other);
      body.injectionPumps = body.injectionPumps
        .filter((i) => i.checked)
        .map(({ checked, ...other }) => other);
      body.eagleFiberDtos = [];

      const listPac = [];
      body.getPac = dataStep1.injectionPumps.forEach((item, index) => {
        if (item.checked) {
          listPac.push(index + 1 + "");
        }
      });
      body.getPac = listPac;
      delete body.id;
      const res = await injectionPump.getCapcityChemicalInjection(body);
      setDataCapacity(res.data);
    } catch (error) {}
  };

  // Get InfoCapacity By inject id;
  const getInfoCapacity = (injection) => {
    if (!injection.checked) {
      return LANG.NOT_HAVE;
    }
    const itemCapa = dataCapacity.find(
      (i) => i.injectID == injection.injectionPumpId
    );
    if (itemCapa) {
      return `${itemCapa.capacityInfo.name}`;
    }
  };

  // CACULATE waterVolumTranfervalue
  const waterVolumTranfervalue = dataStep1.estimationConditionInput
    ? Math.round(
        (volumnWater * 10) /
          dataStep1.estimationConditionInput.inputOperatingTime
      ) / 10
    : 0;

    const pushNewDataStep2 = () => {
      const newData = {
        ...dataStep2,
        lv: {
          current: lv,
        },
        capacity:dataCapacity
      };
      dispatch(setDataStep2(newData));
    }
  // NEXT STEP
  const handleNextStep = () => {
    history.push(getPathEstimateWithStep(basePath, 3));
    pushNewDataStep2()
  };

  // CAPACITY
  useEffect(() => {
    if (dataStep1 && dataStep1.injectionPumps) {
      getCapacity();
    }
  }, [dataStep1.injectionPumps,dataStep3,lv]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  useEffect(() => {
    getKbn();
  }, [fromToStep2]);
  useEffect(() => {
    if (!_.isEmpty(dataStep1)) {
      let lvPost = lv ||  String(dataStep2.lv.current);
      if (lvPost[lvPost.length - 1] === ".") {
        lvPost = lvPost.substring(0, lvPost.length);
      }
      EstimateApi.getDataStep3({
        ...dataStep1,
        standardLv: Number(lvPost),
      })
        .then((res) => {
          const data = res.data;
          setNumberOfMachine(data.numberOfMachines);
          setNetVolumeWaterRecovered(data.netVolumeWaterRecovered);
          setModelMachine(data.machineSelected);
          setActualLv(data.actualLv);
          setEstimationAuxMachine({
            ...estimationAuxMachine,
            auxFiltrationPumpModel: dataStep1.estimationAuxMachine
              .haveAuxFiltrationPump
              ? data.estimationAuxMachine.auxFiltrationPumpModel
              : "",
            auxBackwashPumpModel: dataStep1.estimationAuxMachine
              .haveAuxBackwashPump
              ? data.estimationAuxMachine.auxBackwashPumpModel
              : "",
            auxAirWashingBlowerModel: dataStep1.estimationAuxMachine
              .haveAuxAirWashingBlower
              ? data.estimationAuxMachine.auxAirWashingBlowerModel
              : "",
          });
          if (data.numberOfMachines) {
            dispatch(
              setDataStep1({
                ...dataStep1,
                estimationAuxMachine: {
                  ...dataStep1.estimationAuxMachine,
                  auxFiltrationPumpRegularNumber: data.numberOfMachines,
                },
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => {});
    }
  }, [dataStep2.lv.current,lv]);

  // open save
  const handleOpenSave = () => {
    dispatch(openSave());
  };

  const [machineData, setMachineData] = useState({});
  useEffect(() => {
    if (machineSelected) {
      ApiMachine.getOne(machineSelected, "")
        .then((res) => {
          if (res.status === 200) {
            res.data = {
              ...res.data,
              ...res.data.eagleFiber,
              filtrationPumpModelNumber: res.data.filtrationPump.modelNumber,
              backwashPumpModelNumber: res.data.backwashPump.modelNumber,
              airWashingBlowerModelNumber:
                res.data.airWashingBlower.modelNumber,
            };
            setMachineData(res.data);
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
    }
  }, [machineSelected, detailData]);


  return !isnotExistStep1Data ? (
    <>
      <div className="des-content wrapStep2 valueInputRight">
        <TitlePage name={"選定結果"}></TitlePage>
        <Collapse defaultActiveKey={["0"]} expandIconPosition="right">
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.PROJECT_INFOMATION_INPUT}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.WATER_TYPE}>
              {`${dataStep1.rawWaterTypevalue}`}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.FIBER_TYPE}
                  <br />
                  (型)
                </>
              }
              box
            >
              {`${firberType} ${firberType === "P" ? "(用水用)" : "(排水用)"}`}
            </RowInput>
            <BreakLine />

            <RowInput labelCol={8} name={LANG.FIBER_LENGTH} box>
              {dataStep1.fiberLength}㎜
              <DescriptionFiberLength filberLength={dataStep1.fiberLength} />
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.WATER_VOLUME}
                  <br />
                  (m³/d)
                </>
              }
              box
            >
              {volumnWater}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.OPERATING_TIME} box>
              {dataStep1.estimationConditionInput.inputOperatingTime}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {"処理水量"}
                  <br />
                  (m³/h)
                </>
              }
              box
            >
              {waterVolumTranfervalue}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.LV}
                  <br />
                  (m/h)
                </>
              }
              box
            >
              <Row>
                <Col
                  style={{ textAlign: "end", color: "gray", fontSize: 12 }}
                  span={24}
                ></Col>
                {lv}
              </Row>
            </RowInput>

            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {"実質LV"}
                  <br />
                  (m/h)
                </>
              }
              box
            >
              {actualLv}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.NESSCES_AREA}
                  <br />
                  (m²)
                </>
              }
              box
            >
              {lv
                ? roundNumber(waterVolumTranfervalue / lv, 3)
                : ""}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              box
              name={
                <>
                  {LANG.NET_VOLUMN_OF_WATER_RECOVERED}
                  <br />
                  (m³/d)
                </>
              }
            >
              {netVolumeWaterRecovered}
            </RowInput>
          </Panel>
        </Collapse>
        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.SELECTED_RESULT}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.MODEL}>
              {machineSelected && (
                <ButtonModal
                  name={machineSelected}
                  type="machine"
                  machineData={machineData}
                />
              )}{" "}
              {machineData &&
                machineData.eagleFiber &&
                machineData.eagleFiber.eagleFiberTowerDimension}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.QUANTITY}
                  <br />
                  (基)
                </>
              }
              box
            >
              {numberOfMachine}
            </RowInput>
          </Panel>
        </Collapse>
        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.AUXILIARI_MACHINE}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.FILTRATION_PUMP}>
              {(estimationAuxMachine.auxFiltrationPumpModel &&
                machineData.filtrationPump) ?  (
                  <ButtonModal
                    id={machineData.filtrationPump.modelNumber}
                    name={machineData.filtrationPump.filtrationPumpModel}
                    version={machineData.filtrationPump.recordVersion}
                    type="pump"
                  />
                ) :LANG.NOT_HAVE}{" "}
              {estimationAuxMachine.auxFiltrationPumpModel &&
                machineData &&
                machineData.filtrationPump &&
                machineData.filtrationPump.filtrationPumpCapacity}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.BACKWASH_PUMP} box>
              {(estimationAuxMachine.auxBackwashPumpModel &&
                machineData.backwashPump ) ?  (
                  <ButtonModal
                    id={machineData.backwashPump.modelNumber}
                    name={machineData.backwashPump.backwashPumpModel}
                    version={machineData.backwashPump.recordVersion}
                    type="pump"
                  />
                ):LANG.NOT_HAVE}{" "}
              {estimationAuxMachine.auxBackwashPumpModel &&
                machineData &&
                machineData.backwashPump &&
                machineData.backwashPump.backwashPumpCapacity}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.AIR_WASHING_BLOWER} box>
              {(estimationAuxMachine.auxAirWashingBlowerModel &&
                machineData.airWashingBlower) ? (
                  <ButtonModal
                    id={machineData.airWashingBlower.modelNumber}
                    name={machineData.airWashingBlower.airWashingBlowerModel}
                    version={machineData.airWashingBlower.recordVersion}
                    type="air"
                  />
                ):LANG.NOT_HAVE}{" "}
              {estimationAuxMachine.auxAirWashingBlowerModel &&
                machineData &&
                machineData.airWashingBlower &&
                machineData.airWashingBlower.airWashingBlowerCapacity}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.AIR_WASHING_BLOWER_SOUND} box>
              {(dataStep1 &&
                dataStep1.estimationAuxMachine &&
                dataStep1.estimationAuxMachine
                  .haveAuxAirWashingBlowerSndprfBox == 1 &&
                estimationAuxMachine.auxAirWashingBlowerModel &&
                machineData.airWashingBlower) ? (
                  <ButtonModal
                    id={machineData.airWashingBlower.modelNumber}
                    name={
                      machineData.airWashingBlower
                        .airWashingBlowerSndprfBoxModel
                    }
                    version={machineData.airWashingBlower.recordVersion}
                    type="box"
                  />
                ):LANG.NOT_HAVE}{" "}
            </RowInput>
          </Panel>
        </Collapse>

        {/* INJECTION */}
        <PanelCustom
          title={LANG.INJECTION_STEP2}
          listData={
            dataStep1 && dataStep1.injectionPumps
              ? dataStep1.injectionPumps.map((i, index) => ({
                  label: INJECT_NAME[index],
                  value: getInfoCapacity(i)
                    ? getInfoCapacity(i) == LANG.NOT_HAVE
                      ? LANG.NOT_HAVE
                      : `タンク　${
                          getInfoCapacity(i) || ""
                        }　注入ポンプ　${numberOfMachine}台 `
                    : "",
                }))
              : []
          }
        />
      </div>
      <FooterPage>
        <Row>
          <Col span={8}>
            <div
              className={"actionRight"}
              onClick={() => {
                dispatch(
                  setDataFromStep2ToStep1({
                    fiberLength: dataStep1.fiberLength,
                    waterType: dataStep1.rawWaterType,
                    current: dataStep2.lv.current,
                    upperLimit: dataStep2.lv.upperLimit,
                    lowerLimit: dataStep2.lv.lowerLimit,
                  })
                );
                pushNewDataStep2()
                history.push(getPathEstimateWithStep(basePath, 1));
              }}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>
          <Col span={8}>
            <ButtonSave
              body={{
                ...cloneObj(formDataPostDefault),
                ...cloneObj(dataStep1),
                ...dataStep3,
              }}
              handleOpenSave={handleOpenSave}
            />
          </Col>
          <Col span={8}>
            <div onClick={() => handleNextStep()} className={"actionRight"}>
              <SendOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              見積表の出力
            </div>
          </Col>
        </Row>
      </FooterPage>
    </>
  ) : (
    <Redirect to={path} />
  );
};
export default AddStep2;

import { RollbackOutlined, SendOutlined } from "@ant-design/icons";
import ApiAirWashing from "@src/api/backend/airWashing";
import ApiMachine from "@src/api/backend/machineManagement";
import ApiPump from "@src/api/backend/pump";
import FooterPage from "@src/component/footer/footerPage";
import { DetailMachine as AirInfo } from "@src/page/Machine_Management/air.jsx";
import { DetailMachine as BoxInfo } from "@src/page/Machine_Management/box.jsx";
import { DetailMachine as PumpInfo } from "@src/page/Machine_Management/pump.jsx";
import { DetailMachine as MachineInfo } from "@src/page/Machine_Management/view.jsx";
import { Col, Collapse, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { injectionPump } from "../../../api/backend/injectionPump";
import BreakLine from "../../../component/Desktop/breakLine";
import RowInput from "../../../component/Desktop/rowInput";
import PanelCustom from "../../../component/panel/PanelCustom";
import { KEY, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import {
  MSG_CATCH,
  getQuerySearch,
  showMessage,
} from "../../../constants/utils";
import { cloneObj, getPathEstimateWithStep } from "../../../utils/helpers";
import DescriptionFiberLength from "../descriptionFiberLength";
import "../Estimate.scss";
import ButtonDelete from "./buttonDelete";
import ButtonEdit from "./buttonEdit";
import ButtonMore from "./buttonMore";
import { INJECT_NAME } from "../add/step2";

const ViewStep2 = ({ basePath, dataDetail: data }) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { isLastest } = getQuerySearch();

  const { Panel } = Collapse;
  const history = useHistory();
  const volumnWater =
    data && data.estimationConditionInput
      ? data.estimationConditionInput.inputRawWaterVolume ||
        data.estimationConditionInput.inputNetVolumeWaterRecovered
      : "";

  const [machineData, setMachineData] = useState({});
  useEffect(() => {
    if (data) {
      ApiMachine.getOne(data.machineSelected, data.machineVersion)
        .then((res) => {
          if (res.status === 200) {
            res.data = {
              ...res.data,
              ...res.data.eagleFiber,
              filtrationPumpModelNumber: res.data.filtrationPump.modelNumber,
              backwashPumpModelNumber: res.data.backwashPump.modelNumber,
              airWashingBlowerModelNumber:
                res.data.airWashingBlower.modelNumber,
            };
            setMachineData(res.data);
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
    }
  }, [data]);


  // Get InfoCapacity By inject id;
  const getInfoCapacity = (injection) => {
    if(!data)return null;
    if (!injection.checked) {
      return LANG.NOT_HAVE;
    }

    const dataCapacity = [data.chemicalTankPac,data.chemicalTankNaclo,data.chemicalTankH2so4]
  
    const itemCapa = dataCapacity.find(
      (i) => i &&  i.injectID == injection.injectionPumpId
    );
    if (itemCapa) {
      return `${itemCapa.name}`;
    }
  };
  return (
    <>
      <div className="des-content wrapStep2">
        {/* <TitlePage name={"EFの選定"}></TitlePage> */}

        <Collapse defaultActiveKey={["0"]} expandIconPosition="right">
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.PROJECT_INFOMATION_INPUT}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.WATER_TYPE}>
              {data &&
                data.rawWaterTypeValue + " - " + data.rawWaterDetailTypeValue}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.FIBER_TYPE}
                  <br />
                  (型)
                </>
              }
              box
            >
              {data &&
                `${data.fiberType} ${
                  data.fiberType === "P" ? "(用水用)" : "(排水用)"
                }`}
            </RowInput>
            <BreakLine />

            <RowInput labelCol={8} name={LANG.FIBER_LENGTH} box>
              {data && data.fiberLength}㎜
              <DescriptionFiberLength filberLength={data && data.fiberLength} />
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.WATER_VOLUME}
                  <br />
                  (m³/d)
                </>
              }
              box
            >
              {volumnWater}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.OPERATING_TIME} box>
              {data && data.estimationConditionInput.inputOperatingTime}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {"処理水量"}
                  <br />
                  (m³/h)
                </>
              }
              box
            >
              {data &&
                Math.round(
                  (volumnWater /
                    data.estimationConditionInput.inputOperatingTime) *
                    10
                ) / 10}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.LV}
                  <br />
                  (m/h)
                </>
              }
              box
            >
              {data && data.standardLv}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {"実質LV"}
                  <br />
                  (m/h)
                </>
              }
              box
            >
              {data && data.actualLv}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.NESSCES_AREA}
                  <br />
                  (㎡)
                </>
              }
              box
            >
              {data &&
                parseFloat(
                  (
                    Math.round(
                      (data.estimationConditionInput.inputRawWaterVolume /
                        data.estimationConditionInput.inputOperatingTime) *
                        10
                    ) /
                    10 /
                    data.standardLv
                  ).toFixed(3)
                )}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              box
              name={
                <>
                  {LANG.NET_VOLUMN_OF_WATER_RECOVERED}
                  <br />
                  (m³/d)
                </>
              }
            >
              {data && data.netVolumeWaterRecovered}
            </RowInput>
          </Panel>
        </Collapse>
        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.SELECTED_RESULT}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.MODEL}>
              {data ?  (
                <>
                  {" "}
                  <ButtonModal
                    name={data.machineSelected}
                    type="machine"
                    version={data.machineVersion}
                    machineData={machineData}
                  />{" "}
                  {data.eagleFiberTowerDimension}
                </>
              ) : LANG.NOT_HAVE}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.QUANTITY}
                  <br />
                  (基)
                </>
              }
              box
            >
              {data && data.numberOfMachines}
            </RowInput>
          </Panel>
        </Collapse>
        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.AUXILIARI_MACHINE}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.FILTRATION_PUMP}>
              {(data &&
                data.estimationAuxMachine &&
                data.estimationAuxMachine.auxFiltrationPumpModel &&
                machineData.filtrationPump) ? (
                  <>
                    {" "}
                    <ButtonModal
                      id={machineData.filtrationPump.modelNumber}
                      name={machineData.filtrationPump.filtrationPumpModel}
                      version={machineData.filtrationPump.recordVersion}
                      type="pump"
                    />{" "}
                    {data.estimationAuxMachine.auxFiltrationPumpCapacity}
                  </>
                ) : LANG.NOT_HAVE}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.BACKWASH_PUMP} box>
              {(data &&
                data.estimationAuxMachine &&
                data.estimationAuxMachine.auxBackwashPumpModel &&
                machineData.backwashPump) ?  (
                  <>
                    {" "}
                    <ButtonModal
                      id={machineData.backwashPump.modelNumber}
                      name={machineData.backwashPump.backwashPumpModel}
                      version={machineData.backwashPump.recordVersion}
                      type="pump"
                    />{" "}
                    {data.estimationAuxMachine.auxBackwashPumpCapacity}
                  </>
                ):LANG.NOT_HAVE}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.AIR_WASHING_BLOWER} box>
              {(data &&
                data.estimationAuxMachine &&
                data.estimationAuxMachine.auxAirWashingBlowerModel &&
                machineData.airWashingBlower) ?  (
                  <>
                    {" "}
                    <ButtonModal
                      id={machineData.airWashingBlower.modelNumber}
                      name={machineData.airWashingBlower.airWashingBlowerModel}
                      version={machineData.airWashingBlower.recordVersion}
                      type="air"
                    />{" "}
                    {data.estimationAuxMachine.auxAirWashingBlowerCapacity}
                  </>
                ) :LANG.NOT_HAVE}
            </RowInput>
            <BreakLine />

            {/* BOX */}
            <RowInput labelCol={8} name={LANG.AIR_WASHING_BLOWER_SOUND} box>
              {(data &&
                data.estimationAuxMachine &&
                data.estimationAuxMachine.haveAuxAirWashingBlowerSndprfBox == 1 &&
                machineData.airWashingBlower) ? (
                  <>
                    {" "}
                    <ButtonModal
                      id={machineData.airWashingBlower.modelNumber}
                      name={machineData.airWashingBlower.airWashingBlowerSndprfBoxModel}
                      version={machineData.airWashingBlower.recordVersion}
                      type="box"
                    />{" "}
                  </>
                ):LANG.NOT_HAVE}
            </RowInput>
            <BreakLine />

            
          </Panel>
        </Collapse>
        {/* INJECTION */}
        <PanelCustom
          title={LANG.INJECTION_STEP2}
          listData={
            data && data.injectionPumps
              ? data.injectionPumps.map((i, index) => ({
                  label: INJECT_NAME[index],
                  value: getInfoCapacity(i)
                    ? getInfoCapacity(i) == LANG.NOT_HAVE
                      ? LANG.NOT_HAVE
                      : `タンク　${
                          getInfoCapacity(i) || ""
                        }　注入ポンプ　${data && data.numberOfMachines}台`
                    : "",
                }))
              : []
          }
        />
      </div>
      <FooterPage>
        <Row>
          <Col span={8}>
            <div
              onClick={() => history.push(getPathEstimateWithStep(basePath, 1))}
              className={"actionRight"}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>
          <Col span={8}>
            <ButtonMore
              machineSelected={data ? data.machineSelected : ""}
              isDraf={data && data.status === "0"}
            >
              <Col span={24}>
                <ButtonEdit status={data && data.status} />
              </Col>
              {isLastest && (
                <Col span={24}>
                  <ButtonEdit status={data && data.status} revup />
                </Col>
              )}
              <Col span={24}>
                <ButtonDelete />
              </Col>
            </ButtonMore>
          </Col>
          <Col span={8}>
            <div
              className={"actionRight"}
              onClick={() => history.push(getPathEstimateWithStep(basePath, 3))}
            >
              <SendOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              見積表の出力
            </div>
          </Col>
        </Row>
      </FooterPage>
    </>
  );
};
export default ViewStep2;

export const ButtonModal = ({
  id,
  name,
  version,
  type,
  machineData: machineDataProp,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [machineData, setMachineData] = useState(
    machineDataProp ? machineDataProp : {}
  );

  useEffect(() => {
    if (!machineDataProp) return;
    setMachineData(machineDataProp);
  }, [machineDataProp]);

  useEffect(() => {
    if (machineDataProp) return;
    let reqApi = ApiPump;
    if (type === "air" || type === "box") {
      reqApi = ApiAirWashing;
    }

    reqApi
      .getOne(id || name, version)
      .then((res) => {
        if (res.status === 200) {
          setMachineData(res.data);
        }
      })
      .catch((err) => {
        console.log(err)
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  }, []);

  return (
    <>
      <a
        style={{ minWidth: 92, display: "inline-block" }}
        href="#"
        onClick={() => setShowModal(1)}
      >
        {name}
      </a>
      {showModal && (
        <Modal
        centered={true}
          style={{ top: 20, marginBottom: 50 }}
          open={showModal}
          title={
            <>
              {(type == "machine" || type == "box") ? name : id}
              &nbsp;ver.{version || machineData.recordVersion}
            </>
          }
          onCancel={() => {
            setShowModal(null);
          }}
          footer={null}
        >
          {type === "pump" && (
            <PumpInfo
              viewMode={true}
              machineData={machineData}
              version={version}
            />
          )}
          {type === "air" && (
            <AirInfo
              viewMode={true}
              machineData={machineData}
              version={version}
            />
          )}
           {type === "box" && (
            <BoxInfo
              viewMode={true}
              machineData={machineData}
              version={version}
            />
          )}
          {type === "machine" && (
            <MachineInfo
              viewMode={true}
              machineData={machineData}
              version={version}
            />
          )}
        </Modal>
      )}
    </>
  );
};

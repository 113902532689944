import Api from "@src/api";
import { isEmpty } from "lodash";
export const WaterDeviceApi = {

  getListWaterDevice() {
    return Api().get(`/api/office/WaterDevice`)
  },
  getWaterDeviceById(id) {
    return Api().get(`/api/office/WaterDevice?id=${id}`)
  },
  updateWaterDevice(body){
    return Api().put(`/api/office/WaterDevice`,body)
  },
  createWaterDevice(body){
    return Api().post(`/api/office/WaterDevice`,body)
  },
  deleteWaterDevice(id){
    return Api().delete(`/api/office/WaterDevice?id=${id}`)
  },
  
}
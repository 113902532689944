import React from "react";
import "./popup.scss";
function LoadingInline(props) {
  return props.isLoadingInline ? (
    <div className="loading-inline">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : (
    <></>
  );
}

export default LoadingInline;

import React from "react";
export const IconNumber = ({
  number,
  color = "#5f8de5",
  size = 18,
  fontSize = 12,
  marginLeft,
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `2px solid ${color}`,
        color,
        fontSize,
        padding: 2,
        marginRight: 6,
        fontWeight: "bold",
        marginLeft: marginLeft ? marginLeft : 0,
      }}
    >
      {number}
    </div>
  );
};

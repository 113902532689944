import React from "react";
import "./footerPage.scss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../constants/common.const";

function FooterPage({ children }) {
  const { collapsed } = useSelector((state) => state.sideBar);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const styleFooter = {
    position: 'fixed',
    bottom: 0,
    left: isPC ? (collapsed ? "61px" : "220px") : '0px',
    zIndex: 3,
    height: '48px',
    // borderTop: '1px solid #dee6ec',
    width: isPC ? `calc(100vw - ${collapsed ? "61px" : "220px"})` : "100%",
  };

  return (
    <>
      <div className="footer-page-top"></div>
      <div
        className="footer-page"
        style={styleFooter}
      >
        {children}
      </div>
    </>
  );
}

export default FooterPage;

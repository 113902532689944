import { KEY, lang } from "@src/constants/common.const";
import { collapsedSideBar } from "@src/redux/sideBar";
import { Col, Drawer, Layout, Menu, Row } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./sideBar.scss";
// icon
import { openConfirm } from "@src/redux/confirm";
import Logo from "@src/styles/icon/logo.svg";
import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

import CONFIG_ROUTER, { ROUTER } from "../../routers/roles/configRoles";
import { isArray } from "lodash";

const { Sider } = Layout;

function SideBar() {
  const { collapsed } = useSelector((state) => state.sideBar);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, updateCurrentPage] = useState(null);
  const { isUpdated } = useSelector((state) => state.checklist);
  const { roleKey } = useSelector((state) => state.auth);
  const toggleButton = () => {
    dispatch(collapsedSideBar(!collapsed));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  // Current router
  const currentRouter = (route) => {
    if (isUpdated && route) {
      dispatch(
        openConfirm({
          open: true,
          data: { left: "OK", right: "cancel" },
          typeConfirm: "error",
          redirectUrl: route,
        })
      );
    } else {
      const routers = ROUTER;
      const routerPath = history.location.pathname;
      let keyCurrent = currentPage;
      let isUpdatedRouter = false;
      for (const [key, value] of Object.entries(routers)) {
        if (key !== keyCurrent && Array.isArray(value)) {
          let isUpdated1 = false;
          value.forEach((item) => {
            if (routerPath.includes(item)) {
              isUpdated1 = true;
            }
          });
          if (isUpdated1) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        } else {
          if (routerPath.includes(value) && key !== keyCurrent) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        }
      }
      if (isUpdatedRouter) {
        updateCurrentPage(keyCurrent);
      }
    }
  };

  // useEffect(() => {
  //   dispatch(updateTitleHeaderWithoutConst(""));
  //   const path = history.location.pathName;
  //   if (currentPage) {
  //   }
  // }, [history.location.pathName, currentPage]);

  useEffect(() => {
    currentRouter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const renderMenuItem = (menuItem, isSub) => {
    if (!menuItem.children && menuItem.show) {
      return !isSub ? (
        <Fragment key={menuItem.key}>
          <Menu.Item
            key={menuItem.key}
            icon={menuItem.icon}
            // className="customMenuItemMain"
            onClick={() => currentRouter(menuItem.path)}
          >
            {!isUpdated ? (
              <Link to={menuItem.path}>
                <span>{menuItem.menuName}</span>
              </Link>
            ) : (
              <span>{menuItem.menuName}</span>
            )}
          </Menu.Item>
        </Fragment>
      ) : (
        <Menu.Item
          key={menuItem.key}
          icon={menuItem.icon}
          // className="customMenuItemMain"
          onClick={() => currentRouter(menuItem.path)}
        >
          {!isUpdated ? (
            <Link to={menuItem.path}>
              <span>{menuItem.menuName}</span>
            </Link>
          ) : (
            <span>{menuItem.menuName}</span>
          )}
        </Menu.Item>
      );
    }
    if (menuItem.children && Array.isArray(menuItem.children)) {
      return (
        <Fragment key={menuItem.key}>
          <Menu.SubMenu
            key={menuItem.key}
            icon={menuItem.icon}
            title={menuItem.menuName}
            className={`${
              isDesktopOrLaptop ? "customSubMenu" : "customSubMenu--mobile"
            }`}
          >
            {menuItem.children.map((item) => renderMenuItem(item, true))}
          </Menu.SubMenu>
        </Fragment>
      );
    }
  };

  // filter
  const filterItem = (arr) =>
    arr.filter((i) => {
      return !Array.isArray(i.roleHides) || !i.roleHides.includes(roleKey);
    });
  const filterRoute = (routes) => {
    const finalRoutes = filterItem(routes).map((item) => {
      if (item.children) {
        item.children = filterItem(item.children);
      }
      return item;
    });
    return finalRoutes;
  };
  const renderMenu = useMemo(
    () =>
      filterRoute(CONFIG_ROUTER).map((item) => {
        if (roleKey) return renderMenuItem(item, false);
      }),
    [roleKey]
  );

  const styleSider = {
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    left: 0,
    background: "transparent",
    borderRight: "1px solid rgba(5, 5, 5, 0.06)",
  };
  return isDesktopOrLaptop ? (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      width={220}
      onCollapse={toggleButton}
      collapsedWidth={60}
      style={styleSider}
    >
      <div className="logo">
        <Row className="rowNotMargin" align="middle" justify="center">
          <Col style={{ marginTop: "20px" }}>
            {collapsed ? (
              <img style={{ height: "44px" }} src={Logo} alt="logo" />
            ) : (
              <img style={{ height: "60px" }} src={Logo} alt="logo" />
            )}
          </Col>
        </Row>
      </div>
      {!collapsed && (
        <Row
          justify="center"
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            paddingTop: "8px",
          }}
        >
          FSシステム
        </Row>
      )}
      <Menu
        mode="vertical"
        theme="light"
        selectedKeys={[currentPage]}
        style={{
          width: "100%",
          marginTop: collapsed ? "10px" : "20px",
        }}
      >
        {renderMenu}
      </Menu>
      {!collapsed && (
        <Row className="copy-right">
          <Col>{lang.COPYRIGHT}</Col>
        </Row>
      )}
    </Sider>
  ) : (
    <>
      <Drawer
        placement="left"
        className="custom-drawer"
        onClose={toggleButton}
        closable={false}
        open={collapsed}
        key={"left"}
        footer={
          <Row className="copy-right__mobile" justify="center">
            <Col className="text-center">{lang.COPYRIGHT}</Col>
          </Row>
        }
      >
        <div className="logo">
          <Row className="rowNotMargin" align="middle" justify="center">
            <Col
              span={20}
              style={{ marginTop: "20px" }}
              className="text-center"
            >
              <img
                style={{ height: "44px", paddingLeft: "30px" }}
                src={Logo}
                alt="logo"
              />
            </Col>
            <Col span={4}>
              <button className="hamburger opened" onClick={toggleButton}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </Col>
          </Row>
        </div>
        <Row
          justify="center"
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            paddingTop: "8px",
          }}
        >
          FSシステム
        </Row>
        <Menu
          mode="inline"
          theme="light"
          selectedKeys={[currentPage]}
          style={{ width: "100%", marginTop: collapsed ? "10px" : "20px" }}
          onClick={() => toggleButton()}
        >
          {renderMenu}
        </Menu>
      </Drawer>
    </>
  );
}

export default SideBar;

import { Collapse } from "antd";
import React from "react";
import RowInput from "../Desktop/rowInput";
import BreakLine from "../Desktop/breakLine";
const { Panel } = Collapse;
const PanelCustom = ({ listData = [], title }) => {
  return (
    <Collapse
      style={{ marginTop: 20 }}
      defaultActiveKey={["0"]}
      expandIconPosition="right"
    >
      <Panel
        header={
          <div className={"wrapTitle"}>
            <span style={{ fontWeight: "bold" }}>{title}</span>
          </div>
        }
        key={"0"}
      >
        {listData.map((item, index) => (
          <>
            <RowInput key={index} labelCol={8} box name={item.label}>
              {item.value}
            </RowInput>
            <BreakLine />
          </>
        ))}
      </Panel>
    </Collapse>
  );
};
export default PanelCustom;

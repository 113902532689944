import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { injectionPump } from "../../../api/backend/injectionPump";
import FormInput from "../../../component/FormInput";
import {
  COMMON_CONSTANT,
  KEY,
  KEY_RULE_INPUT,
  STYLE,
  lang,
} from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import classText from "../../../models/control/text";
import { LIST_RULE,ModalCustom, showErrorMessage } from "../../../utils/helpers";

const AddFusionPump = ({ open, onClose = () => {}, id }) => {
  let [showLoading, setShowLoading] = useState(false);
  const history = useHistory();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [data, setData] = useState({
    injectionPumpId: new classText(),
    injectName: new classText(),
    weight: new classText(),
    price: new classText(),
    priceBasis: new classText(),
    note: new classText(),
  });
  const inputRefs = useRef([]);
  // Change Input
  const handleChangeData = (key) => (e) => {
    const newValue = e.target.value;
    const dataItem = listDataModal.find((i) => i.name === key);
    if (dataItem && Array.isArray(dataItem.ruleInput)) {
      const isNotMatchInput = dataItem.ruleInput.some((ruleName) => {
        return !LIST_RULE[ruleName](newValue);
      });
      if (isNotMatchInput) {
        return;
      }
    }
   
    setData({ ...data, [key]: { ...data[key], value: newValue } });
  };

  const getIniData = async () => {
    try {
      // edit
      if (id) {
        const optionsRes = await injectionPump.getInjectionPumpById(id);
        setData({
          injectionPumpId: new classText(
            optionsRes.data.data[0].injectionPumpId
          ),
          injectName: new classText(optionsRes.data.data[0].injectName),
          weight: new classText(optionsRes.data.data[0].weight),
          price: new classText(optionsRes.data.data[0].price),
          priceBasis: new classText(optionsRes.data.data[0].priceBasis),
          note: new classText(optionsRes.data.data[0].note),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    getIniData();
  }, [id]);
  //List data
  const listDataModal = [
    {
      name: "injectName",
      title: LANG.NAME_INJECTION,
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK],
    },
    {
      name: "weight",
      title: LANG.WEIGHT + "(kg)",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK,KEY_RULE_INPUT.UNSIGN_NUMBER],
      ruleInput: [KEY_RULE_INPUT.UNSIGN_NUMBER,KEY_RULE_INPUT.MAX_LENGTH_NUMBER,KEY_RULE_INPUT.WEIGHT_NUMBER],
    },
    {
      name: "price",
      title: LANG.AMOUT_OF_MONEY + "(千円/基)",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK,KEY_RULE_INPUT.IS_UNSIGN_INTERGER,],
      ruleInput: [KEY_RULE_INPUT.IS_UNSIGN_INTERGER,KEY_RULE_INPUT.MAX_LENGTH_NUMBER],
    },
    {
      name: "priceBasis",
      title: LANG.PRICE_BASIS,
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK],
      type: "textarea",
    },

    {
      name: "note",
      title: lang.REMARK,
      required: false,
      type: "textarea",
    },
  ];

  // VALIDATE DATA

  const validate = () => {
    let isFlagErr = false;
    let fistNameErr = "";
    const listDataWithRule = listDataModal.filter((i) => Array.isArray(i.rule));
    if (listDataWithRule.length === 0) {
      return false;
    }
    // check rule
    for (let index = 0; index < listDataWithRule.length; index++) {
      const nameItem = listDataWithRule[index].name;
      const value = data[nameItem].value;
      const listRule = listDataWithRule[index].rule;
      const isErrItem = listRule.some((ruleName) => {
        return !LIST_RULE[ruleName](value);
      });
      if (isErrItem) {
        isFlagErr = true
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: true,
          },
        }));
        if (!fistNameErr) {
          fistNameErr = nameItem;
        }
      } else {
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: false,
          },
        }));
      }
    }

    // Focus Fist Item Error
    if (fistNameErr && inputRefs.current && inputRefs.current[fistNameErr]) {
      inputRefs.current[fistNameErr].focus();
    }
    return !isFlagErr;
  };

  const handleClose = () => {
    setData({
      injectionPumpId: new classText(),
      injectName: new classText(),
      weight: new classText(),
      price: new classText(),
      priceBasis: new classText(),
      note: new classText(),
    });
    onClose(false);
  };
  // Save Data
  const postData = async () => {
    try {
      setShowLoading(true);
      if (id) {
        const dataPost = {
          injectionPumpId: data.injectionPumpId.value,
          injectName: data.injectName.value,
          weight: data.weight.value,
          price: data.price.value,
          priceBasis: data.priceBasis.value,
          note: data.note.value,
        };
        await injectionPump.updateInjectionPump(dataPost);
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      } else {
        const dataPost = {
          injectName: data.injectName.value,
          weight: data.weight.value,
          price: data.price.value,
          priceBasis: data.priceBasis.value,
          note: data.note.value,
        };
        await injectionPump.createInjectionPump(dataPost);
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      }
    } catch (error) {
      showErrorMessage(error)
    } finally {
      setShowLoading(false);
      onClose();
    }
  };
  // SAVE DATA
  const onSaveData = async () => {
    if (!validate()) {
      return;
    } else {
      ModalCustom.confirm({
        centered: true,
        title: lang.CONFIRM,
        content: COMMON_CONSTANT.BOM_C008,
        okText: lang.OK,
        cancelText: lang.CANCEL,
        onOk: () => postData(),
      });
    }

  }
 


  return (
    <>
      <Modal
        title={!id ? "注入ポンプ作成" : "注入ポンプ編集"}
        centered
        width={isPC ? "40%" : "90%"}
        bodyStyle={{ height: "auto", overflow: "auto" }}
        closeIcon={<></>}
        className=""
        open={open}
        footer={[
          <Button
            onClick={() => handleClose()}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CANCEL}
          </Button>,
          <Button
            onClick={() => onSaveData()}
            className="buttonPC button--info wAuto"
          >
            {lang.OK}
          </Button>,
        ]}
      >
        <Row className="mb10 flex-start" style={{ gap: 10 }}></Row>

        <Row align={"middle"} gutter={[16, 16]}>
          {listDataModal.map((item) => (
            <Col key={item.name} span={24}>
              <div>
                <label className="bold">
                  {item.title}{" "}
                  {item.required && (
                    <label className="blod mark-required">*</label>
                  )}
                </label>
                <FormInput
                  valueObject={data[item.name]}
                  onChange={handleChangeData(item.name)}
                  refer={(ref) => (inputRefs.current[item.name] = ref)}
                  className={`${data[item.name].error ? STYLE.BORDER_RED : ""}`}
                  type={item.type}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};
export default AddFusionPump;

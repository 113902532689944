import Api from "@src/api";
import { isEmpty } from "lodash";
export const InternalFeeApi = {

  getInternalFee() {
    return Api().get(`/api/office/InternalFee`)
  },
  getInternalFeeById(id) {
    return Api().get(`/api/office/InternalFee?id=${id}`)
  },
  updateInternalFee(body){
    return Api().put(`/api/office/InternalFee`,body)
  },
  createInternalFee(body){
    return Api().post(`/api/office/InternalFee`,body)
  },
  deleteInternalFee(id){
    return Api().delete(`/api/office/InternalFee?id=${id}`)
  },
  
}
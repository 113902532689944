import { Col, Radio, Row } from "antd";
import React from "react";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import { HeaderText } from "../../components/common/headerText";
const PopupSelectRole = ({ roleSelect, setRoleSelect,handleAddUser = () => {} }) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}><HeaderText isLine={false} text={"ユーザーの権限を選択してください"} /></Col>
        <Col span={24}>
          {" "}
          <Radio
            checked={roleSelect ==RoleHeadOffice.ADMIN}
            onChange={() => setRoleSelect(RoleHeadOffice.ADMIN)}
          />{" "}
        システム管理者
        </Col>

        <Col span={24}>
          {" "}
          <Radio
            checked={roleSelect == RoleHeadOffice.USER}
            onChange={() => setRoleSelect(RoleHeadOffice.USER)}
          />{" "}
           {RoleHeadOffice[RoleHeadOffice.USER]}
        </Col>
      </Row>
    </>
  );
};
export default PopupSelectRole;

import {
  ClearOutlined,
  SearchOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { ReactComponent as IconAdd } from "@src/styles/icon/add.svg";
import {
  Button,
  Col,
  Collapse,
  Drawer,
  Empty,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EstimateApi } from "../../api/backend/estimate";
import TitlePage from "../../component/Desktop/titlePage";
import LoadingInline from "../../component/Popup/loading-inline";
import { AddIcon } from "../../components/common/addIcon";
import {
  COMMON_CONSTANT,
  ESTIMATE_STEP,
  ESTIMATE_VIEW_STEP,
  KEY,
  lang,
} from "../../constants/common.const";
import { LANG } from "../../constants/lang.const";
import { MSG_CATCH, renderOption, showMessage } from "../../constants/utils";
import {
  resetState,
  setEstimateViewStep,
  setInputSearch,
  setStepEstimate,
} from "../../redux/estimate";
import ItemSearch from "./itemSearch";
import { UserMangementApi } from "../../api/backend/userManagement";
function ListEstimate() {
  const PAGE_SIZE = 20;
  const [formSearch] = Form.useForm();
  const [show, setShow] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const { inputSearch } = useSelector((state) => state.estimate);
  const [dataInput, setDataInput] = useState(inputSearch);
  const [openListSort, setOpenListSort] = useState(false);
  const listSort = [
    {
      label: LANG.UPDATE_TIME,
      key: "updateTime",
    },
    {
      label: "担当者",
      key: "createUser",
    },
    {
      label: "見積番号",
      key: "estimationNumber",
    },
    {
      label: "客先名称",
      key: "customerName",
    },
    {
      label: "工事名称",
      key: "constructionName",
    },
    {
      label: "原水種類",
      key: "rawWaterType",
    },
  ];
  const [sort, setSort] = useState({
    sortKey: "updateTime",
    sortOrder: "desc",
  });
  let sortLabel = "";
  const sortItem = listSort.find((i) => i.key === sort.sortKey);
  if (sortItem) {
    sortLabel = sortItem.label;
  }

  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [optionWaterType, setOptionWaterType] = useState([]);
  const [optionUser, setOptionUser] = useState([]);
  // page
  const [page, setPage] = useState(0);
  const [dataSearch, setDataSearch] = useState([]);
  const [countItemSearch, setCountItemSearch] = useState(0);
  // vao man add
  const toAddScreen = () => {
    dispatch(setStepEstimate(ESTIMATE_STEP.STEP1));
    dispatch(resetState());
    history.push("/Estimate/Add");
  };
  // vao man Edit
  const toEditScreen = (id) => {
    dispatch(setStepEstimate(ESTIMATE_STEP.STEP1));
    history.push("/Estimate/Edit/" + id);
  };
  const toViewScreen = (item, isLastest) => {
    dispatch(setEstimateViewStep(ESTIMATE_VIEW_STEP.STEP1));
    history.push(
      "/Estimate/View/" +
        item.id +
        "?version=" +
        item.version +
        (isLastest ? "&isLastest=true" : "")
    );
  };

  // Clser
  const handleClear = () => {
    formSearch.resetFields();
    setDataInput({
      customerName: "",
      constructionName: "",
      estimationNumber: "",
      creatorId: "",
      rawWatterType: "",
    });
    setSort({
      sortKey: "updateTime",
      sortOrder: "desc",
    });
    // formSearch.submit();
    fetchMoreData(true, true);
  };
  const hanldeDeleteItem = (id, ver) => {
    const deleteEstimateItem = async () => {
      setLoading(true);

      try {
        Modal.destroyAll();
        await EstimateApi.deleteEstimate(id, ver);
        fetchMoreData(true);

        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        let msg;
        if (ex.response) {
          if (ex.response.status === 400) {
            msg = ex.response.data;
          }
        }
        showMessage(KEY.ERROR, msg || MSG_CATCH());
      } finally {
      }
    };

    Modal.confirm({
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: deleteEstimateItem,
      centered: true,
      zIndex: 1000000,
    });
  };

  const renderListSearch = dataSearch.map((item, index) => (
    <Col key={index} span={24}>
      <ItemSearch
        toEditScreen={toEditScreen}
        toViewScreen={toViewScreen}
        hanldeDeleteItem={hanldeDeleteItem}
        item={item}
      />
    </Col>
  ));
  // change input search
  const handleChangeDataInput = (key, type) => (e) => {
    const newValue = type === "select" ? e : e.target.value;
    setDataInput({
      ...dataInput,
      [key]: newValue,
    });
  };
  const dataInint = {
    customerName: "",
    constructionName: "",
    estimationNumber: "",
    creatorId: "",
    rawWatterType: "",
    // sortKey: "createUser",
    // sortOrder: "asc",
  };

  // fetch Option water Type
  useEffect(() => {
    Promise.all([
      UserMangementApi.searchUserByKeyWord({ user: "" }),
      EstimateApi.getWater(),
    ]).then((res) => {
      setOptionUser(
        res[0].data.data.map((i) => ({
          key: i.userName,
          value: i.displayName,
        }))
      );
      setOptionWaterType(res[1].data.data);
    });
  }, []);
  // fetch Data
  const fetchMoreData = async (isClickSearch = false, isReset = false) => {
    dispatch(setInputSearch(isReset ? dataInint : dataInput));
    setHasMoreItems(true);
    try {
      const filterParams = {
        // limit: PAGE_SIZE,
        // offset: isClickSearch ? 0 : page * PAGE_SIZE,
      };
      if (isClickSearch || isReset) {
        setLoadingInit(true);
      } else {
        setLoading(true);
      }
      const body = isReset ? dataInint : dataInput;
      const res = await EstimateApi.searchEstimate({
        ...sort,
        ...body,
        ...filterParams,
      });
      setCountItemSearch(res.data.count);
      const dataSearchRes = res.data.data;
      const prevDataSearch = isClickSearch ? [] : [...dataSearch];
      if (res.data.count === dataSearchRes.length + prevDataSearch.length) {
        setHasMoreItems(false);
      } else {
        if (!isClickSearch) {
          setPage(page + 1);
        } else {
          setPage(1);
        }
      }
      setDataSearch([...prevDataSearch, ...dataSearchRes]);
      setLoading(false);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoadingInit(false);
    }
  };

  // Change Sort Order
  const handleChangeSortOrder = () => {
    if (sort.sortOrder === "desc") {
      setSort({ ...sort, sortOrder: "asc" });
    } else {
      setSort({ ...sort, sortOrder: "desc" });
    }
  };
  //Change sort Key
  const handleChangeSortKey = ({ key }) => {
    setSort({
      ...sort,
      sortKey: key,
    });
    setOpenListSort(false);
  };
  useEffect(() => {
    formSearch.setFieldsValue(inputSearch);
    fetchMoreData(true);
  }, [sort]);
  useEffect(() => {
    const button = document.getElementById("buttonAddEstimate");

    const handleScroll = () => {
      if (!button) return;
      const rect = button.getBoundingClientRect();
      const isOffScreen =
        rect.bottom < 0 ||
        rect.right < 0 ||
        rect.left > window.innerWidth ||
        rect.top < 30;

      setShow(isOffScreen);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <TitlePage name={LANG.LIST_ESTIMATE} />
      <Content className="site-layout-background m24">
        <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
          <Panel
            header={
              <div className="wrapTitle">
                <SearchOutlined
                  style={{
                    color: "#0080CB",
                    marginRight: 6,
                    fontSize: 18,
                  }}
                />
                <span style={{ fontWeight: "bold" }}>{lang.SEARCH}</span>
              </div>
            }
            key={0}
          >
            <Form
              // onFinish={() => fetchMoreData(true)}
              layout="vertical"
              form={formSearch}
            >
              <Row style={{ padding: "16px 0" }} gutter={[10, 10]}>
                <Col span={24}>
                  <Form.Item
                    label={<span>{LANG.ESTIMATE_NUMBER}</span>}
                    className="rowNotMargin"
                    name="estimationNumber"
                  >
                    <Input
                      value={dataInput.estimationNumber}
                      onChange={handleChangeDataInput("estimationNumber")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={<span>{LANG.CLIENT_NAME}</span>}
                    className="rowNotMargin"
                    name="customerName"
                  >
                    <Input
                      value={dataInput.customerName}
                      onChange={handleChangeDataInput("customerName")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span className="bold">{LANG.CONSTRUCTION_NAME}</span>
                    }
                    className="rowNotMargin"
                    name="constructionName"
                  >
                    <Input
                      value={dataInput.constructionName}
                      onChange={handleChangeDataInput("constructionName")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={<span className="bold">{LANG.WATER_TYPE}</span>}
                    className="rowNotMargin"
                    name="rawWatterType"
                  >
                    <Select
                      value={dataInput.rawWatterType}
                      onChange={handleChangeDataInput(
                        "rawWatterType",
                        "select"
                      )}
                    >
                      {renderOption(optionWaterType)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={<span className="bold">{"担当者"}</span>}
                    className="rowNotMargin"
                    name="creatorId"
                  >
                    <Select
                      value={dataInput.creatorId}
                      onChange={handleChangeDataInput("creatorId", "select")}
                    >
                      {renderOption(optionUser)}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} className="right">
                  <Button
                    style={{ minWidth: 103, borderRadius: 4 }}
                    // htmlType="submit"
                    className="buttonPC button--info wAuto"
                    onClick={() => fetchMoreData(true)}
                  >
                    <SearchOutlined />
                    {lang.SEARCH}
                  </Button>
                  <Button
                    style={{ minWidth: 103, borderRadius: 4 }}
                    className="buttonPC button--outline --todo-- wAuto ml10"
                    onClick={() => handleClear()}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
      </Content>

      {/* Sort */}
      <Content
        style={{
          border: "1px solid #dfe6ec",
          borderLeft: "none",
          borderRight: "none",
          padding: "12px 0",
        }}
        className="site-layout-background mt20"
      >
        <Row gutter={[10, 10]}>
          <Col>
            {sort.sortOrder === "desc" ? (
              <ArrowDownOutlined
                onClick={() => handleChangeSortOrder()}
                style={{
                  cursor: "pointer",
                  color: "#5f8de5",
                }}
              />
            ) : (
              <ArrowUpOutlined
                onClick={() => handleChangeSortOrder()}
                style={{
                  cursor: "pointer",
                  color: "#5f8de5",
                }}
              />
            )}
          </Col>
          <Col>
            <span>並べ替え</span>
            <span
              onClick={() => setOpenListSort(true)}
              style={{
                color: "#5f8de5",
                fontWeight: "bold",
                marginLeft: 5,
                cursor: "pointer",
              }}
            >
              {sortLabel}
            </span>
          </Col>
        </Row>
      </Content>
      <Content
        className="site-layout-background"
        style={
          {
            // padding: 15,
          }
        }
      >
        <Row justify="space-between mt10 ">
          <Col id="buttonAddEstimate" span={12}>
            <Button
              type="link"
              onClick={() => {
                toAddScreen();
              }}
              className="btnAddRecord btnLink"
            >
              <IconAdd className="svg-default mr5" />
              {lang.ADD}
            </Button>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            span={12}
          >
            {/* Count */}
            該当件数
            <span className="ml5">{countItemSearch}件</span>
          </Col>
        </Row>
        <Row style={{ paddingBottom: 70 }}>
          {loadingInit ? (
            <div className="flex-center mt10">
              <LoadingInline isLoadingInline={loadingInit} />
            </div>
          ) : dataSearch.length ? (
            <Col span={24}>
              {/* <InfiniteScroll
                pageStart={0}
                loadMore={() => fetchMoreData(false)}
                hasMore={!loading && hasMoreItems}
                loader={
                  <div className="flex-center mt10">
                    <LoadingInline isLoadingInline />
                  </div>
                }
              > */}
              <Row>{renderListSearch}</Row>
              {/* </InfiniteScroll> */}
            </Col>
          ) : (
            <div className="flex-center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Row>
      </Content>
      {show && <AddIcon hanldeClick={toAddScreen} />}
      <Drawer
        className="drawerSort"
        height={350}
        zIndex={1000}
        closeIcon={null}
        title={
          <div
            style={{
              textAlign: "center",
            }}
          >
            {"並べ替え"}
          </div>
        }
        placement="bottom"
        open={openListSort}
        onClose={() => setOpenListSort(false)}
        style={{
          borderRadius: "10px 10px 0 0",
        }}
      >
        <List
          style={{ width: "100%" }}
          size="small"
          bordered={false}
          dataSource={listSort}
          renderItem={(item) => {
            const isMaping = item.label === sortLabel;
            return (
              <List.Item
                className="flex-start"
                style={{
                  padding: 15,
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => handleChangeSortKey(item)}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: isMaping ? "#5f8de5" : "",
                  }}
                >
                  {item.label}
                </span>
                {isMaping && (
                  <CheckOutlined
                    style={{
                      color: "#5f8de5",
                    }}
                  />
                )}
              </List.Item>
            );
          }}
        />
      </Drawer>
    </div>
  );
}

export default ListEstimate;

import Api from "@src/api";
export default {
  getFactories() {
    // return Api().get("SiteOfficeMaster").then(data => {
    //   console.log(data);
    //   return Promise.resolve(data);
    // });
    return Promise.resolve({
      "data": [
          {
              "siteOfficeMasterCode": "11040",
              "siteOfficeMasterName": "a",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1068",
              "siteOfficeMasterName": "abc",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "10018",
              "siteOfficeMasterName": "duc test create office with template",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "11038",
              "siteOfficeMasterName": "duc test create office with templates",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1075",
              "siteOfficeMasterName": "DucHM Higashi test",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "11039",
              "siteOfficeMasterName": "DucHM Iwanai test",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": true
          },
          {
              "siteOfficeMasterCode": "12006",
              "siteOfficeMasterName": "duchm nagare test",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "12005",
              "siteOfficeMasterName": "DucHM Nagareyama test",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "35005",
              "siteOfficeMasterName": "DucHM Tabuse test",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "10020",
              "siteOfficeMasterName": "DucHM Toyotakamo test1",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": true
          },
          {
              "siteOfficeMasterCode": "10019",
              "siteOfficeMasterName": "DucHM Yuni test",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": true
          },
          {
              "siteOfficeMasterCode": "10017",
              "siteOfficeMasterName": "gosho duchm test",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "9001",
              "siteOfficeMasterName": "Iwanai 2022",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1061",
              "siteOfficeMasterName": "Iwanai test",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1062",
              "siteOfficeMasterName": "Iwanai test 2",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "35002",
              "siteOfficeMasterName": "Site Demo",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": true
          },
          {
              "siteOfficeMasterCode": "1057",
              "siteOfficeMasterName": "Test 01",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1059",
              "siteOfficeMasterName": "Test 02",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1060",
              "siteOfficeMasterName": "Test 03",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1063",
              "siteOfficeMasterName": "Test 04",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1069",
              "siteOfficeMasterName": "test 60",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1058",
              "siteOfficeMasterName": "Test count check result",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "12004",
              "siteOfficeMasterName": "Test create 1",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1065",
              "siteOfficeMasterName": "Test JP 01",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1066",
              "siteOfficeMasterName": "Test JP 02",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1067",
              "siteOfficeMasterName": "Test JP 03",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1070",
              "siteOfficeMasterName": "test zipcode",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1073",
              "siteOfficeMasterName": "Toyama(hamakurosaki)",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1064",
              "siteOfficeMasterName": "Yuni test 01",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "14007",
              "siteOfficeMasterName": "デモ1の事業所",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "14006",
              "siteOfficeMasterName": "トンの事業所",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1076",
              "siteOfficeMasterName": "岩内・共和事業所・トン",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "35001",
              "siteOfficeMasterName": "長門事業所New2",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": true
          },
          {
              "siteOfficeMasterCode": "35003",
              "siteOfficeMasterName": "田布施事業所（Test）",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "35004",
              "siteOfficeMasterName": "田布施事業所テスト2",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          },
          {
              "siteOfficeMasterCode": "1074",
              "siteOfficeMasterName": "富山事業所(浜黒崎)",
              "status": null,
              "statusValue": null,
              "headquartersChargeName": null,
              "customerApprove": false
          }
      ],
      "status": 200,
      "statusText": "",
      "headers": {
          "content-type": "application/json; charset=utf-8"
      },
      "config": {
          "url": "SiteOfficeMaster",
          "method": "get",
          "headers": {
              "Accept": "application/json, text/plain, */*",
              "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IldnMWdIX0hWdEI0aGsxdlFyVVM4SlZhRzRWWSIsImtpZCI6IldnMWdIX0hWdEI0aGsxdlFyVVM4SlZhRzRWWSJ9.eyJhdWQiOiJjZTU5MDA2Ny1iOGE5LTRiMjUtOTg1YS00NDJiOWQ4MmJlNjUiLCJpc3MiOiJodHRwczovL3BvbW0tZGV2LWFkZnMuamFwYW53ZXN0LmNsb3VkYXBwLmF6dXJlLmNvbS9hZGZzIiwiaWF0IjoxNjkxNzI2NzA0LCJleHAiOjE2OTE3NTU1MDQsImF1dGhfdGltZSI6MTY5MTcyNjcwNCwibm9uY2UiOiI2NGYyMzk4MS00YzcwLTQ5ZGItYjRmMS1mZWEzODY1NGU3MmEiLCJzdWIiOiJjamgxUWRVdUIrVDR0Tm5id21ZcnlmVHlJRG9jUjZqOXoxbWlqMmx1YWFFPSIsInVwbiI6ImFkbWluMUBzaGlldi5sb2NhbCIsInVuaXF1ZV9uYW1lIjoiU0hJRVZcXGFkbWluMSIsInNpZCI6IlMtMS01LTIxLTMxNjk0NTM4MTYtMTMwMjE3Mzc2Ni02NzM5MjU0OTMtMTEwMyJ9.OO7UNdrAgszNtoHOxQR1JspGYzgDkva-Xtp387cnwWOqNyWQFfe5jbq3cfgMaW0lPLZ77u6PfuWOAHNj9ILL2YAmoaQP-QtOKCDo9730R9cOfwDI-5qb9fWmNo9-ua3iBThWzahSBEHPizw7PCRcxntyeedemhsSztNwz_nuSlAeDfwCQ8-9Tp8M8zV_OnGuqj6PorMVUvdliyu40a5-gErHl7_58r1uHq09XMUBXeXPgUUdUVdFjwO-MSrzy8w_7T7DDpk4R94eSMjq3tx5SXCczqH7pOGxmnrlXhIXLdqUkpBvF-gtCV0PEU_qjrPLOZRRkGHQJqJJljBHCBj9pA"
          },
          "baseURL": "https://pomm-dev-web.azurewebsites.net/api/office/10017/",
          "transformRequest": [
              null
          ],
          "transformResponse": [
              null
          ],
          "timeout": 600000,
          "xsrfCookieName": "XSRF-TOKEN",
          "xsrfHeaderName": "X-XSRF-TOKEN",
          "maxContentLength": -1,
          "maxBodyLength": -1
      },
      "request": {}
  })
  },
  getUserInfoWithSiteOffice(userName, siteOffice) {
    return Api().get(
      `UserInfo?userName=${userName}&siteOfficeMasterCode=${siteOffice}`
    );
  },
  getUserInfo() {
    return Api().get("/api/office/UserInfo");
  },
  getUserInfoBySideOfficeCode(){
    const api = `UserInfo`;
    // return Api().get(api);
    return Promise.resolve({
      "data": {
          "userName": "admin1",
          "firstName": "",
          "lastName": "",
          "displayName": "Admin1",
          "role": "1",
          "siteOfficeMasterCode": "10017",
          "siteOfficeMasterName": "gosho duchm test",
          "email": "pommdev03@gmail.com",
          "ip": "1.55.242.166",
          "avatarUrl": "https://pommdevstorage.blob.core.windows.net/image/admin1-c38dfd8b-6ed3-4fa9-9493-105bc1e48202",
          "avatarName": "images.png",
          "signatureUrl": "https://pommdevstorage.blob.core.windows.net/signature/admin1-886b496d-aa9f-4978-af6b-180d91299b1e?sv=2023-01-03&se=2023-08-11T05%3A03%3A01Z&sr=b&sp=r&rscd=filename*%3DUTF-8%27%27download.jpg&sig=HNiufwhWT9vHTyXEZMmo6ucsQpoR3q0zF00onH%2BH%2BFc%3D",
          "signatureName": "download.jpg",
          "inMultipleSites": true,
          "isGroupManager": false,
          "headOfficeRoleType": "11",
          "roleHistories": null
      },
      "status": 200,
      "statusText": "",
      "headers": {
          "content-type": "application/json; charset=utf-8"
      },
      "config": {
          "url": "UserInfo",
          "method": "get",
          "headers": {
              "Accept": "application/json, text/plain, */*",
              "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IldnMWdIX0hWdEI0aGsxdlFyVVM4SlZhRzRWWSIsImtpZCI6IldnMWdIX0hWdEI0aGsxdlFyVVM4SlZhRzRWWSJ9.eyJhdWQiOiJjZTU5MDA2Ny1iOGE5LTRiMjUtOTg1YS00NDJiOWQ4MmJlNjUiLCJpc3MiOiJodHRwczovL3BvbW0tZGV2LWFkZnMuamFwYW53ZXN0LmNsb3VkYXBwLmF6dXJlLmNvbS9hZGZzIiwiaWF0IjoxNjkxNzI2MzY0LCJleHAiOjE2OTE3NTUxNjQsImF1dGhfdGltZSI6MTY5MTcyNjM2NCwibm9uY2UiOiI0MGQwZjAyMi1jNWRhLTRlNjEtOTNkNS0xNWNkMzA4MjcxNWUiLCJzdWIiOiJjamgxUWRVdUIrVDR0Tm5id21ZcnlmVHlJRG9jUjZqOXoxbWlqMmx1YWFFPSIsInVwbiI6ImFkbWluMUBzaGlldi5sb2NhbCIsInVuaXF1ZV9uYW1lIjoiU0hJRVZcXGFkbWluMSIsInNpZCI6IlMtMS01LTIxLTMxNjk0NTM4MTYtMTMwMjE3Mzc2Ni02NzM5MjU0OTMtMTEwMyJ9.FCYxh64sRjGaHtvJPYlZcWMuHXEMUM4FaK_bw84GRbHfTSlhtFFZluvjC5PXrqZsB3GKGqUq_rFlEH64DIws6LPYk2zNf9_VwWXCjfy1dUUMEOyXNdrywuvujYW_A33U1D67pWWux_czt7rwktCILFMl6Uk_D9xuS2ppNRjqHyWvS_GlN6h0vwI_ts2Ua1qxY-8oHYBpjUVviuXtqnh6JUgddL_srkO_kMx5_U_4xKd0xoDoyPBXU0LnW9Ed7AxSK9MOrETkeJ_mRK6pMaRwTW7Z-uh5KNNIK8UsRaqsEKd051ZGiBi6AHNbgAG3874q3Vu1J5ftMo2HbTT-cjpYDA"
          },
          "baseURL": "https://pomm-dev-web.azurewebsites.net/api/office/",
          "transformRequest": [
              null
          ],
          "transformResponse": [
              null
          ],
          "timeout": 600000,
          "xsrfCookieName": "XSRF-TOKEN",
          "xsrfHeaderName": "X-XSRF-TOKEN",
          "maxContentLength": -1,
          "maxBodyLength": -1
      },
      "request": {}
  });
  }
};

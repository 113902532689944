import React from "react";
import { Layout } from "antd";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../constants/common.const";

const { Content } = Layout;

function BoxContent({ children, style, ...other }) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  return (
    <Content
      className={isPC && "site-layout-background"}
      style={{
        margin: "24px 16px",
        padding: isPC ? 20 : 0,
        ...style
      }}
    >
      {children}
    </Content>
  );
}

export default BoxContent;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  isLoadingInline: false,
  isLoadingDesktop: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    loadingPage: (state, { payload }) => {
      state.isLoading = payload;
    },
    loadingInline: (state, { payload }) => {
      state.isLoadingInline = payload;
    },
    loadingDesktop: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

// the action
export const {
  loadingPage,
  loadingInline,
  loadingDesktop,
} = loadingSlice.actions;

//the selector
export const loadingSelector = (state) => state.loading;

// the reducer
export default loadingSlice.reducer;

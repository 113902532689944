import { notification } from "antd";
function Notification({ type, text, description,time }) {
  const data = {
    placement: "topRight",
    top: 70,
    message: text,
    duration: time !== 0 ? time : 3,
    description: description ? description : null,
    rtl: true,
  };
  return notification[type](data);
}
export default Notification;

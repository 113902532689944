import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Table, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { EstimateApi } from "../../../api/backend/estimate";
import TitlePage from "../../../component/Desktop/titlePage";
import { HeaderText } from "../../../components/common/headerText";
import { COMMON_CONSTANT, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { handleCatchError } from "../../../constants/utils";
import { deboundClickModal, formatCurrencyJPY } from "../../../utils/helpers";
import Loading from "../../../component/Desktop/loading/loading";
import { EditOutlined } from "@ant-design/icons";
import EditLv from "./EditLv";
import { LvAPI } from "../../../api/backend/lv";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";
const LvManage = ({isLoad,activeMaster}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openModal,setOpenModal]= useState(false);
  const [currentItem,setCurrentItem]= useState(false);
  const [listWaterType,setListWaterType] = useState([])
  
  // GET WATER NAME
  const getWaterName = (key) => {
    const currentWater = listWaterType.find(i => i.key === key);
    if(currentWater){
      return currentWater.value
    }
  }
  // OPEN MODAL
  const onOpenModal  = (item) => {
    setOpenModal(true);
    setCurrentItem(item)

  }
 
  // CLOSE MODAL
  const onCLoseModal  = () => {
    setOpenModal(false);
    setCurrentItem(null)

  }
  // Set Data by firberLength
  const setDataSortByFirberLength = (data) => {
    data = data.sort((a,b) => a.fiberLength - b.fiberLength)
   const mapData = {};
   data.forEach(item => {
    const {fiberLength} = item;
    if(!mapData[fiberLength]){
      mapData[fiberLength] = [item];
    }
    else{
      mapData[fiberLength].push(item)
      mapData[fiberLength].sort((a,b) => a.rawWaterType - b.rawWaterType)
    }
   })
   console.log(mapData)
   const finalData  = Object.values(mapData).flatMap(i => i);
   setData(finalData)
  }
  // get Data LV
  const getLv = async () => {
    try {
      setLoading(true);
      const [resWater,resLv] = await Promise.all([EstimateApi.getWater(),LvAPI.getListLv()])
     setListWaterType(resWater.data.data)
      setDataSortByFirberLength(resLv.data.data);
    } catch (error) {
      console.log(error)
      handleCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  // GET DATA
  useLoadTabDataEffect([isLoad,activeMaster],getLv);

  const getRowClassName = (record, index) => {
    if (index < 2) {
      return ""
    } else {
      return 'row-color'; 
    }
  };
  const columns = [
    {
      title: <div>シリーズ</div>,
      dataIndex: "fiberlength",
      key: "fiberlength",
      render: (text, row, index) => ({
        children: <span>{row.fiberLength}mm</span>,
        props: {
          rowSpan: index % 2 === 0 ? 2 : 0,
        },
      }),
     
    },
    {
      title: <div>種類</div>,
      dataIndex: "rawWater",
      key: "rawWater",
      render: (text, record, index) => {
        return <span>{getWaterName(record.rawWaterType)}</span>;
      },
    },
    {
      title: <div>単位</div>,
      dataIndex: "unit",
      key: "unit",
      render: (text, record, index) => {
        return <span>{"m/H"}</span>;
      },
    },
    {
      title: <div >基準</div>,
      dataIndex: "rawWater",
      key: "rawWater",
      render: (text, record) => {
        return <span>{formatCurrencyJPY(record.standard)}</span>;
      },
    },
    {
      title: <div>範囲</div>,
      dataIndex: "range",
      key: "range",
      render: (text, record) => {
        return formatCurrencyJPY(record.lowerLimit) + " ~ " + formatCurrencyJPY(record.upperLimit);
      },
    },
    {
      title: <div>備考</div>,
      dataIndex: "remark",
      key: "remark",
      width:"30%",
       render: (text, record) => {
        return <span>{record.remark}</span>;
      },
    },
    {
      title: LANG.ACTION,
      dataIndex: "deleete",
      key: "delete",
      align: "center",
      render: (_, record) => {
        return (
          <div>
            <Tooltip title={lang.EDIT}>
            <Button
              onClick={() => onOpenModal({...record,
                rawWaterTypeName:getWaterName(record.rawWaterType)
              })}
              className={"iconItem"}
              icon={<EditOutlined />}
            />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt20">
      <div>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowClassName={getRowClassName}
          className="gridSearch2"
          bordered
        />
      </div>
      {openModal && <EditLv callback={getLv} isModalVisible={openModal} onClose={onCLoseModal} itemDetail={currentItem} />}
      <Loading show={loading} />
    </div>
  );
};
export default LvManage;

import Api from "@src/api";

export default {
  //search machine
  search(params) {
    return Api().get("api/office/AirWashingBlower", { params });
  },
  delete(code, version = '') {
    return Api().delete(`api/office/AirWashingBlower/${code}/${version}`, {})
  },
  getOne(code, version = '') {
    return Api().get(`api/office/AirWashingBlower/${code}/${version}`, {});
  },
  save(formData) {
    return Api().post(`api/office/AirWashingBlower`, formData, {});
  },
  update(formData) {
    return Api().put(`api/office/AirWashingBlower`, formData, {});
  },
  getSoundProofBox(params) {
    return Api().get("api/office/AirWashingBlower/SoundProofBoxOptions", { params });
  },
  getAirWashingBlower(params) {
    return Api().get("api/office/AirWashingBlower/GetAirWashingBlowerModelOptions", { params });
  },
  getElectricMotor(params) {
    return Api().get("api/office/AirWashingBlower/GetElectricMotorOptions", { params });
  },
};

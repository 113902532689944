import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  isLoadingInline: false,
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    registrationMaster: (state, { payload }) => {
      state.isLoading = payload;
    },
    registrationInline: (state, { payload }) => {
      state.isLoadingInline = payload;
    },
  },
});

// the action
export const {
  registrationPage,
  registrationInline,
} = registrationSlice.actions;

//the selector
export const registrationSelector = (state) => state.registration;

// the reducer
export default registrationSlice.reducer;

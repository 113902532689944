import { PlusOutlined } from "@ant-design/icons";
import React from "react";

export const AddIcon = ({ hanldeClick }) => {
  return (
    <div
      style={{
        background: "#0080cb",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 60,
        height: 60,
        borderRadius:60,
        position: "fixed",
        bottom: 10,
        right: "50%",
        transform:"translateX(50%)",
        padding: 5,
        boxShadow: '0 -4px 8px rgba(0,0,0,.1)'
      }}
      onClick={() => {
        if (hanldeClick) {
          hanldeClick();
        }
      }}
    >
      <PlusOutlined   style={{ color: "white",fontSize:30,fontWeight:"bold" }} />
    </div>
  );
};

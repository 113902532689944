import { Tabs } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import TitlePage from "../../component/Desktop/titlePage";
import { KEY } from "../../constants/common.const";
import { LANG } from "../../constants/lang.const";
import { getQuerySearch, pushObject2Query } from "../../constants/utils";



import TabDCManage from "./Tab_DC_Manage/TabDCManage";
import TabManageModel from "./Tab_Manage_Model/TabManageModel";
import TabMaxtrixManage from "./Tab_Maxtrix_Manage/TabMaxtrixManage";
import { useLocation } from "react-router-dom";

const MasterManage = () => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { tab_master } = getQuerySearch();
  const [activeKey, setActiveKey] = useState(tab_master || "0");
  const onChange = (key) => {
    setActiveKey(key);
    pushObject2Query({ tab_master: key });
  };

  const items = [
    {
        key: "0",
        label: (
          <>
           
            { <span>{"機種管理"}</span>}
          </>
        ),
        children: <>
        <TabManageModel key={`model-${activeKey==="0"}`} activeMaster={activeKey} /></>,
      },
    {
      key: "1",
      label: (
        <>
          {<span>{"マトリックス管理"}</span>}
        </>
      ),
      children: (
        <>
          <TabMaxtrixManage key={`maxtrix-${activeKey==="1"}`}  activeMaster={activeKey} />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          {<span>{"DC表管理"}</span>}
        </>
      ),
      children: <TabDCManage key={`dc-${activeKey==="2"}`}  activeMaster={activeKey} />,
    },
    
  ];

  return (
    <>
      <TitlePage name={LANG.MASTER_MANAGEMENT} />
      <Tabs
        hideAdd
        activeKey={activeKey}
        onChange={onChange}
        type="card"
        items={items}
        animated={{ inkBar: true, tabPane: true }}
        size={isPC ? "large" : "small"}
        tabPosition={"top"}
      />
    </>
  );
};
export default MasterManage;

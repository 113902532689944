import React from "react";
import { Modal, Select } from "antd";
import { KEY, KEY_RULE_INPUT, lang } from "../constants/common.const";
import { isInteger } from "lodash";
import { MSG_CATCH, showMessage } from "../constants/utils";
const { Option } = Select;

export const renderOption = (options, optionBlank = true, lengthText) => {
  if (options && options.length > 0) {
    return (
      <>
        {optionBlank && <Option value="" key="blank"></Option>}
        {options.map((e) => {
          let optionText = e.value || e.name;
          if (lengthText && typeof lengthText === "number") {
            if (optionText.length > lengthText) {
              optionText = optionText.substring(0, lengthText) + "...";
            }
          }
          return (
            <Option value={e.key || e.id} key={e.key || e.id}>
              {optionText}
            </Option>
          );
        })}
      </>
    );
  }
};
// get path with step
export const getPathEstimateWithStep = (basePath, step) => {
  const index = basePath.indexOf("step");
  const index2 = basePath.indexOf("&step");
  const startQuery = basePath.indexOf("?");
  if (startQuery < 0) {
    basePath = basePath + "?";
  }
  if (index < 0) {
    return (
      basePath + ((index2 < 0) & (startQuery > 0) ? "&" : "") + "step=" + step
    );
  } else {
    basePath =
      basePath.substring(0, index + 5) + step + basePath.substring(index + 6);
    return basePath;
  }
};

// Tinh so chu so sau dau phay
function countDecimalPlaces(number) {
  const stringNumber =
    number != null && number !== undefined && !isNaN(number)
      ? number.toString()
      : "0";
  const decimalIndex = stringNumber.indexOf(".");

  if (decimalIndex === -1) {
    return 0; // số nguyên
  } else {
    return stringNumber.length - decimalIndex - 1;
  }
}
// Nhan chinh xac
export const exactMultiplication = (...arg) => {
  if (arg.findIndex((i) => isNaN(i)) >= 0) {
    return 0;
  }
  if (arg.findIndex((i) => !i) >= 0) return 0;
  let count = 0;
  let arr = [...arg];
  arr = arr.map((item) => {
    const countDecimal = countDecimalPlaces(item);
    if (countDecimal > 0) {
      count += countDecimal;
      return item * Math.pow(10, countDecimal);
    }
    return item;
  });
  const multi = arr.reduce((result, item) => {
    return result * item;
  }, 1);
  return multi / Math.pow(10, count);
};
export function preciseMultiply(...numbers) {
  if (numbers.some((i) => !i)) {
    return 0;
  }
  // Chuyển tất cả các số thành chuỗi và loại bỏ dấu thập phân
  let factors = numbers.map((num) => {
    const parts = num.toString().split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] || "";
    return {
      factor: parseInt(integerPart + decimalPart, 10),
      decimalPlaces: decimalPart.length,
    };
  });

  // Tính tổng số chữ số thập phân
  const totalDecimalPlaces = factors.reduce(
    (sum, factor) => sum + factor.decimalPlaces,
    0
  );

  // Nhân tất cả các số đã chuyển đổi thành số nguyên
  const product = factors.reduce((result, factor) => result * factor.factor, 1);

  // Chuyển đổi lại thành số thập phân
  return product / Math.pow(10, totalDecimalPlaces);
}

// format money
export function formatCurrencyJPY(amount) {
  if (isNaN(amount)) {
    return amount;
  }
  const formattedAmount = amount
    ? Number(amount).toLocaleString("ja-JP", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 10,
      })
    : 0;

  return formattedAmount ? formattedAmount.replace("¥", "") : 0; // Loại bỏ ký hiệu tiền tệ
}

export const roundNumber = (number, n) => {
  return Math.round(number * Math.pow(10, n)) / Math.pow(10, n);
};

export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));
export const isEmptyData = (data) =>
  data == null || data === "" || data === "undefined";
// calcNetVolumeWaterRecovered
export function calcNetVolumeWaterRecovered(
  rawWaterVolume,
  eagleFiberArea,
  operatingTime,
  rawWaterSsConc,
  numberOfMachines
) {
  const C15 = 40;
  const C19 = 12;
  const C18 = 20;
  const C20 = 5;
  const I3 = 6;

  const C12 = (rawWaterVolume / numberOfMachines / operatingTime).toFixed(1);
  const I2 = (C12 * rawWaterSsConc) / 1000;
  const C14 = Math.round((I3 / I2) * 60);
  const C16 = (eagleFiberArea * C15).toFixed(1);
  const C17 = ((C16 * C19) / 60).toFixed(1);
  const C22 = C14 + C18 + C20;
  const C23 = ((operatingTime * 60) / C22).toFixed(1);
  const C25 = (C17 * C23).toFixed(1);
  const C21 = ((C12 * C20) / 60).toFixed(1);
  const C27 = (C21 * C23).toFixed(1);

  return rawWaterVolume - C25 - C27;
}

// get String QuerySearch

export const getStringQuery = (obj) => {
  let str = "";
  Object.keys(obj).forEach((key) => {
    if (obj[key]) str += `&${key}=${obj[key]}`;
  });
  return str;
};

// Round up

export const roundToDecimalPlaces = (number, decimalPlaces) => {
  if (isNaN(number) || isNaN(decimalPlaces)) {
    return 0;
  }
  number = Number(number);
  decimalPlaces = Number(decimalPlaces);
  const factor = Math.pow(
    10,
    decimalPlaces >= 0 ? decimalPlaces : -decimalPlaces
  );
  if (decimalPlaces >= 0) {
    return Math.ceil(number * factor) / factor;
  } else {
    const integerNumber = Number.parseInt(number);
    return (
      Number.parseInt(
        Number.isInteger(integerNumber / factor)
          ? integerNumber / factor
          : integerNumber / factor + 1
      ) * factor
    );
  }
};
export function roundToNearestThousand(num) {
  // Kiểm tra nếu num không phải là số hoặc là NaN
  if (typeof num !== "number" || isNaN(num)) {
    return 0;
  }

  // Sử dụng hàm Math.round để làm tròn đến bậc nghìn gần nhất
  return Math.round(num / 1000) * 1000;
}
// Tính tổng weight và money các table
export const caculatorSumMoneyandWeight = (listTable) => {
  const result = {
    weight: 0,
    money: 0,
  };

  listTable.forEach((table) => {
    table.forEach((i) => {
      const { content } = i;
      content.forEach((contentItem, index) => {
        if (contentItem.key && contentItem.key.toLowerCase() === "weight") {
          result.weight += contentItem.content;
        }
        if (contentItem.key && contentItem.key.toLowerCase() === "money") {
          result.money += contentItem.content;
        }
      });
    });
  });
  return { ...result };
};

///
const deboundClickModalFunc = () => {
  let isOpenModal = true;
  return {
    handleOpenModal(callback = () => {}) {
      if (isOpenModal) {
        callback();
        isOpenModal = false;
      }
    },
    handleCloseModal(callback = () => {}) {
      isOpenModal = true;
      callback();
    },
  };
};
export const deboundClickModal = deboundClickModalFunc();

const ModalCustomFunc = () => {
  let isOpenModal = true;
  return {
    confirm({ onOk: okFunc, onCancel: cancelFunc, ...rest }) {
      if (isOpenModal) {
        isOpenModal = false;
        Modal.confirm({
          ...rest,
          onOk: () => {
            if (okFunc) {
              okFunc();
            }
            isOpenModal = true;
          },
          onCancel: () => {
            if (cancelFunc) {
              cancelFunc();
            }
            isOpenModal = true;
          },
        });
      }
    },
  };
};
export const ModalCustom = ModalCustomFunc();

export const hanldeKeyDown = (rule) => (e) => {
  if (
    rule &&
    !e.key.match(rule) &&
    e.key !== "Backspace" &&
    e.key !== "ArrowUp" &&
    e.key !== "ArrowDown" &&
    e.key !== "ArrowRight" &&
    e.key !== "ArrowLeft" &&
    e.key !== "Tab"
  ) {
    e.preventDefault();
  }
};

// check blank input
const isNotBlank = (value) => {
  try {
    if (value === null || value === undefined) {
      return false;
    }
    if (!value.trim()) {
      return false;
    }
    return true;
  } catch (error) {
    return true;
  }
};

// is Number
const isNumber = (value) => {
  return !isNaN(value);
};

// Interger
const isNumberInteger = (value) => {
  if (!isNumber(value)) {
    return false;
  }
  return Number.isInteger(Number(value));
};

const isRateValue = (value) => {
  value = Number(value);
  if (!isUnsignInteger(value)) {
    return false;
  }
  return value >= 0 && value <= 100;
};

//
export const isUnsignNumber = (value) => {
  return isNumber(value) && Number(value) >= 0;
};

// Unsign Interger
export const isUnsignInteger = (value) => {
  return isNumberInteger(value) && Number(value) >= 0;
};

// check max length
const checkMaxNumber = (input) => {
  const str = input.toString();
  const strWithoutDot = str.replace(".", "");
  return strWithoutDot.length <= 9;
};

function createDecimalPlaceChecker(n) {
  // Trả về hàm nhận vào số cần kiểm tra
  return function (value) {
    console.log(value);
    // Chuyển đổi số sang chuỗi
    const numStr = value.toString();

    // Tìm vị trí của dấu chấm thập phân
    const decimalIndex = numStr.indexOf(".");

    // Nếu không có dấu chấm thập phân, hoặc không có chữ số nào sau dấu chấm, hoặc số chữ số sau dấu chấm <= n
    if (
      decimalIndex === -1 ||
      decimalIndex === numStr.length - 1 ||
      numStr.length - decimalIndex - 1 <= n
    ) {
      return true;
    }

    // Nếu có nhiều hơn n chữ số sau dấu chấm
    return false;
  };
}

// Get STANDAR
export const getStandardLv = (listLv, fiberLength, waterType) => {
  if (!listLv || listLv.length == 0) {
    return null;
  }
  const lvItem = listLv.find(
    (item) => item.rawWaterType == waterType && item.fiberLength == fiberLength
  );
  return lvItem ? lvItem.standard : null;
};
export const LIST_RULE = {
  [KEY_RULE_INPUT.NOT_BLANK]: (value) => {
    return isNotBlank(value);
  },
  [KEY_RULE_INPUT.IS_NUMBER]: (value) => {
    return isNumber(value);
  },
  [KEY_RULE_INPUT.UNSIGN_NUMBER]: (value) => {
    return isUnsignNumber(value);
  },
  [KEY_RULE_INPUT.IS_INTEGER]: (value) => {
    return isNumberInteger(value);
  },
  [KEY_RULE_INPUT.IS_UNSIGN_INTERGER]: (value) => {
    return isUnsignInteger(value);
  },
  [KEY_RULE_INPUT.IS_RATE]: (value) => {
    return isRateValue(value);
  },
  [KEY_RULE_INPUT.MAX_LENGTH_NUMBER]: checkMaxNumber,
  [KEY_RULE_INPUT.WEIGHT_NUMBER]: createDecimalPlaceChecker(1),
};

// Handle Show ERROR
export const showErrorMessage = (error) => {
  if (error && error.response && error.response.status == 400) {
    if (error.response.data) {
      if (Array.isArray(error.response.data)) {
        const message = (
          <>
            {error.response.data.map((msg, i) => (
              <div key={"x" + i}>{msg}</div>
            ))}
          </>
        );
        showMessage(KEY.ERROR, message);
      } else if (error.response.data && error.response.data.messages) {
        const message = (
          <>
            {Object.values(error.response.data.messages).map((msg, i) => (
              <div key={"x" + i}>
                {lang.NUMBER_ROW}
                {/* include headerline */}
                {Number(Object.keys(error.response.data.messages)[i]) + 1}:
                <br />
                {Array.isArray(msg) ? (
                  <>
                    {Object.values(msg).map((m, z) => (
                      <div key={"z" + z}>{m}</div>
                    ))}
                  </>
                ) : (
                  { msg }
                )}
              </div>
            ))}
          </>
        );
        Modal.error({
          width: 500,
          centered: true,
          title: lang.ERROR,
          content: message,
        });
      } else if (typeof error.response.data === "string") {
        showMessage(KEY.ERROR, error.response.data);
      }
    } else {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  } else {
    showMessage(KEY.ERROR, MSG_CATCH());
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Modal, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { CostManagementApi } from "../../../api/backend/costManagement";
import FormInput from "../../../component/FormInput";
import {
  COMMON_CONSTANT,
  KEY,
  KEY_RULE_INPUT,
  STYLE,
  lang,
} from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import classText from "../../../models/control/text";
import { LIST_RULE,ModalCustom, showErrorMessage } from "../../../utils/helpers";

const { Paragraph } = Typography;

function AddReflect({ open, onClose = () => {}, id,detail }) {

  let [showLoading, setShowLoading] = useState(false);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [data, setData] = useState({
    costID: new classText(detail && detail.key),
    projectName: new classText(detail && detail.projectName),
    unitPrice: new classText(detail && detail.unitPrice),
    unitPriceBasis: new classText(detail && detail.unitPriceBasis),
    remark: new classText(detail && detail.remark),
  });

  const inputRefs = useRef([]);
  // Change Input
// Change Input
const handleChangeData = (key) => (e) => {
  const newValue = e.target.value;
  const dataItem = listDataModal.find((i) => i.name === key);
  if (dataItem && Array.isArray(dataItem.ruleInput)) {
    const isNotMatchInput = dataItem.ruleInput.some((ruleName) => {
      return !LIST_RULE[ruleName](newValue);
    });
    if (isNotMatchInput) {
      return;
    }
  }
  setData({ ...data, [key]: { ...data[key], value: newValue } });
};
  
  const listDataModal = [

    {
      name: "projectName",
      title: "項目",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK],
    },
    {
      name: "unitPrice",
      title: "単価（千円/基）",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK,KEY_RULE_INPUT.IS_UNSIGN_INTERGER],
      ruleInput: [KEY_RULE_INPUT.IS_UNSIGN_INTERGER,KEY_RULE_INPUT.MAX_LENGTH_NUMBER],
    },
    {
      name: "unitPriceBasis",
      title: LANG.PRICE_BASIS,
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK],
      type: "textarea",
    },

    {
      name: "remark",
      title: lang.REMARK,
      required: false,
      type: "textarea",
    },
  ];

  // VALIDATE DATA

  const validate = () => {
    let isFlagErr = false;
    let fistNameErr = "";
    const listDataWithRule = listDataModal.filter((i) => Array.isArray(i.rule));
    if (listDataWithRule.length === 0) {
      return false;
    }
    // check rule
    for (let index = 0; index < listDataWithRule.length; index++) {
      const nameItem = listDataWithRule[index].name;
      const value = data[nameItem].value;
      const listRule = listDataWithRule[index].rule;
      const isErrItem = listRule.some((ruleName) => {
        return !LIST_RULE[ruleName](value);
      });
      if (isErrItem) {
        isFlagErr = true
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: true,
          },
        }));
        if (!fistNameErr) {
          fistNameErr = nameItem;
        }
      } else {
        fistNameErr = "";
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: false,
          },
        }));
      }
    }

    // Focus Fist Item Error
    if (fistNameErr && inputRefs.current && inputRefs.current[fistNameErr]) {
      inputRefs.current[fistNameErr].focus();
    }
    return !isFlagErr;
  };
  const handleClose = () => {
    setData({
      costID: new classText(),
    projectName: new classText(),
    unitPrice: new classText(),
    unitPriceBasis: new classText(),
    remark: new classText(),
    });
    onClose(false);
  };
  // SAVE DATA
  const postData = async () => {
    try {
      setShowLoading(true);
      const dataPost = {
        costID: data.costID.value,
        projectName: data.projectName.value,
        unitPrice: data.unitPrice.value,
        unitPriceBasis: data.unitPriceBasis.value,
        remark: data.remark.value,
      };
      await CostManagementApi.updateCostManagement(dataPost);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
    } catch (error) {
      showErrorMessage(error)
    } finally {
      setShowLoading(false);
      onClose();
    }
  };
   // SAVE DATA
  const onSaveData = async () => {
    if (!validate()) {
      return;
    } else {
      ModalCustom.confirm({
        centered: true,
        title: lang.CONFIRM,
        content: COMMON_CONSTANT.BOM_C008,
        okText: lang.OK,
        cancelText: lang.CANCEL,
        onOk: () => postData(),
      });
    }
  };
 
  return (
    <>
      <Modal
        title={"メーカー費用編集"}
        centered
        width={isPC ? "40%" : "90%"}
        bodyStyle={{ height: "auto", overflow: "auto" }}
        closeIcon={<></>}
        className=""
        open={open}
        footer={[
        
          <Button
            onClick={() => handleClose()}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CANCEL}
          </Button>,
            <Button
            onClick={() => onSaveData()}
            className="buttonPC button--info wAuto"
          >
            {lang.OK}
          </Button>,
        ]}
      >
        <Row className="mb10 flex-start" style={{ gap: 10 }}></Row>

        <Row align={"middle"} gutter={[16, 16]}>
          {listDataModal.map((item) => (
            <Col key={item.name} span={24}>
              <div>
                <label className="bold">
                  {item.title}{" "}
                  {item.required && (
                    <label className="blod mark-required">*</label>
                  )}
                </label>
                <FormInput
                  valueObject={data[item.name]}
                  onChange={handleChangeData(item.name)}
                  refer={(ref) => (inputRefs.current[item.name] = ref)}
                  className={`${data[item.name].error ? STYLE.BORDER_RED : ""}`}
                  type={item.type}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default AddReflect;

import Api from "@src/api";

export const LvAPI =  {
  //search machine
  getListLv(params = {}) {
    return Api().get("/api/office/StandardLv", { params });
  },
  update(data) {
    return Api().put(`/api/office/StandardLv`, data, {});
  }
};

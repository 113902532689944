import Api from "@src/api";

export const ControlPanelApi =  {
  //search machine
  getList(params = {}) {
    return Api().get("/api/office/ControlPanel", { params });
  },
  update(data) {
    return Api().put(`/api/office/ControlPanel`, data, {});
  }
};

import React, { useEffect } from "react";
import "./App.scss";
import "./styles/css/common.scss";
import "./styles/css/mobile.scss";
import "./styles/css/vars.scss";
import Router from "@src/routers/router";
import { ConfigProvider, Layout } from "antd";
import Loading from "@src/component/Popup/loading";
import Confirm from "@src/component/Popup/confirm";
import { useDispatch, useSelector } from "react-redux";
import { changeStatusNetWork, getUserInfo } from "@src/redux/auth";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useParams, useLocation } from "react-router-dom";
import { reSetInputSearch } from "./redux/estimate";
import { EstimateApi } from "./api/backend/estimate";
import { KEY } from "@src/constants/common.const";
import { useMediaQuery } from "react-responsive";

const { Content } = Layout;
// reset item page to select menu when reload page or destroy app
window.onbeforeunload = function () {
  localStorage.removeItem("page");
};

moment.updateLocale("ja", {
  week: {
    dow: 0, //set sunday as the first day of week
  },
});

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const { currentUser, currentRouter, changeRoute } = useSelector(
    (state) => state.checklist
  );
  const location = useLocation();
  // const { inMultipleSites } = useSelector((state) => state.auth);

  // reset textSeacch
  useEffect(() => {
    const route = history.location.pathname;
    if (route.indexOf("Estimate") < 0) {
      dispatch(reSetInputSearch());
    }
  }, [location]);
  const history = useHistory();
  const dispatch = useDispatch();
  const onOffline = () => {
    dispatch(changeStatusNetWork(false));
  };
  const onOnline = () => {
    dispatch(changeStatusNetWork(true));
  };

  useEffect(() => {
    dispatch(getUserInfo(currentUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (changeRoute !== null) {
      const route = history.location.pathname;
      if (changeRoute) {
        history.push("/");
      } else {
        // in case open new tab with new route below while using input checklist
        if (
          !(
            route.includes("/ViewReport") ||
            route.includes("/history-detail") ||
            route.includes("/ViewMachine")
          )
        ) {
          if (currentRouter.includes("/list")) {
            history.push(currentRouter);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeRoute]);
  
  const vh = window.innerHeight * 0.01;
  // set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const keySearchCheckList = sessionStorage.getItem("searchKey");
    if (!path.startsWith("/checklist") && !path.startsWith("/list")) {
      if (keySearchCheckList) {
        sessionStorage.removeItem("searchKey");
      }
    }
  }, [location]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
          colorPrimary: '#5f8de5',
        },
        components: {
          Layout: {
            colorBgHeader: '#2a354a',
            colorBgBody: '#dbe7ff',
            colorBgTrigger: '#000c25',
            headerColor: '#FFF'
          },
          Menu: {
            activeBarBorderWidth: 0,
            darkItemBg: 'transparent',
            itemBg: "transparent",
          },
          Collapse: {
            colorFillAlter: '#dbe7ff',
          },
          Button: {
            colorLinkHover: '#8ab2f2',
          },
          Card: {
            background: 'rgb(83, 130, 184)',
          },
          Meta: {
            color: '#fff'
          }
        },
      }}>
      <Layout className="layout" id="layout">
        <Content>
          <Router />
        </Content>
        <Loading />
        <Confirm />
      </Layout>
    </ConfigProvider>
  );
}
export default App;

import { combineReducers } from "redux";
import navBarReducer from "./navBar";
import loadingReducer from "./loading";
import confirmReducer from "./confirm";
import checkListReducer from "./checkList";
import settingReducer from "./setting";
import authReducer from "./auth";
import registrationReducer from "./registration";
import sideBarReducer from "./sideBar";
import homeBannerReducer from "./homeBanner";
import estimateReducer from "./estimate";
const rootReducer = combineReducers({
  auth: authReducer,
  navBar: navBarReducer,
  loading: loadingReducer,
  confirm: confirmReducer,
  checklist: checkListReducer,
  setting: settingReducer,
  registration: registrationReducer,
  sideBar: sideBarReducer,
  homeBanner: homeBannerReducer,
  estimate: estimateReducer
});

export default rootReducer;

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { EstimateApi } from "../../../api/backend/estimate";
import Loading from "../../../component/Desktop/loading/loading";
import TitlePage from "../../../component/Desktop/titlePage";
import { HeaderText } from "../../../components/common/headerText";
import { COMMON_CONSTANT, KEY, lang, } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import { ModalCustom, deboundClickModal, formatCurrencyJPY } from "../../../utils/helpers";
import AddMainUnit from "./AddMainUnitoption";
import { useLocation } from "react-router-dom";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";
const MainUnitOption = ({isLoad}) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [optionMonney, setOptionMonney] = useState([]);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  // Close Modal
  const onClose = (isRefetch = true) => {
    setDetail(null);
    setOpenModal(false);
    if(isRefetch){
      getListOption();
    }
  };

  // get list option
  const getListOption = async () => {
    try {
      setLoading(true);
      const optionsRes = await EstimateApi.getOptions();
      const optionData = optionsRes.data.data.map((item, index) => ({
        key: item.optionId,
        optionName: item.optionName,
        defaultValue: item.defaultValue,
        remark : item.remark,
        id: index,
        canDelete:item.canDelete
      }));
      setOptionMonney(optionData);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };
  useLoadTabDataEffect(isLoad,getListOption)
  useEffect(() => {
    if (isError) {
      deboundClickModal.handleCloseModal();
    }
  }, [isError]);
  const columnOptionMoney = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: "5%",
      render: (text, record, index) => {
        return <span>{++index}</span>;
      },
      align: "center",
    },
    {
      title: <div className="text-start">項目</div>,
      dataIndex: "optiontext",
      width: "30%",
      key: "optiontext",
      render: (text, record, index) => {
        return <span>{record.optionName}</span>;
      },
    },
    {
      title: <div className="text-start">金額(千円/基)</div>,
      width: "15%",
      dataIndex: "defaultValue",
      key: "defaultValue",
      render: (text, record, index) => {
        return <span>{formatCurrencyJPY(record.defaultValue)}</span>;
      },
    },
    {
      title: <div className="text-start">{lang.REMARK}</div>,
      dataIndex: "remark",
      key: "remark",
      width: "35%",
      render: (text, record) => {
        return <span>{record.remark}</span>;
      },
    },
    {
      title: LANG.ACTION,
      dataIndex: "deleete",
      key: "delete",
      align: "center",
      width: "15%",
      render: (id, record, index) => {
        return (
          <Row gutter={!isPC && [4, 4]} justify={"center"}>
            <Col>
              {" "}
             <Tooltip title={lang.EDIT}>
             <Button
                onClick={() => {
                  setOpenModal(true);
                  setDetail(record)
                }}
                className={"iconItem"}
                icon={<EditOutlined />}
              />
             </Tooltip>
            </Col>
            <Col>
              {" "}
              <Tooltip title={lang.DELETE}>
              <Button
                 disabled={record.canDelete}
                onClick={() => handleDeleteOptionMoney(record.key)}
                className={"iconItem"}
                icon={<DeleteOutlined />}
              />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  // Const delete OptionMoney
  const handleDeleteOptionMoney = (key) => {
    ModalCustom.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          await EstimateApi.deleteOptions(key);
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
          getListOption();
        } catch (error) {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      },
    });
   
  };
  // validate option
  const validateOptionMoney = () => {
    let isError = false;
    let idFocus;
    for (let i = 0; i < optionMonney.length; i++) {
      if (!optionMonney[i].optionName.trim()) {
        isError = true;
        idFocus = "addMoneyOption-" + optionMonney[i].id;
        break;
      }
    }
    const element = document.getElementById(idFocus);
    if (element) {
      element.focus();
    }
    return !isError;
  };

  const getContenDelete = ({ itemName, price }) => (
    <Row>
      <Col className="mb10" span={24}>本体オプションを削除してよろしいでしょうか？</Col>
      <Col span={24}>
        <Row>
          <Col>項目 :&nbsp; </Col>
          <Col>{itemName}</Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col>金額(千円/基) : &nbsp; </Col>
          <Col>{price}</Col>
        </Row>
      </Col>
    </Row>
  );
  // DELETE
  const handleDelete = (id,itemName,price) => {
    Modal.confirm({
      title: lang.DELETE,
      content: getContenDelete({itemName,price}),
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {},
      onCancel: () => {},
      centered: true,
      width:isPC &&500
    });
  };
  return (
    <div className="mt10">
      <div className="mb10">
      </div>
      <Row
        style={{
          marginBottom: 10,
        }}
        align={"middle"}
        justify="space-between "
      >
        <Col span={12}>
          <Button
          disabled={optionMonney.length >=8}
            style={{
              background: "#5f8de5",
              color: "#fff",
              border: "none",
              opacity:optionMonney.length >=8 ? 0.7 :1
            }}
            onClick={() => setOpenModal(true)}
            icon={<PlusOutlined />}
          >
            {lang.ADD}
          </Button>
        </Col>
        <Col span={12} className="right"></Col>
      </Row>
      <Table
        className="gridSearch"
        rowKey="id"
        dataSource={optionMonney}
        columns={columnOptionMoney}
        pagination={false}
        bordered
        
      />
      <Loading show={loading} />
     {openModal &&  <AddMainUnit open={openModal} onClose={onClose} detail={detail} />}
    </div>
  );
};
export default MainUnitOption;

import {
  EditOutlined,
  RollbackOutlined,
  CloudDownloadOutlined,
  FileImageOutlined,
  FileExcelOutlined,
  FileZipOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileOutlined,
  ToTopOutlined,
  DeleteOutlined,
  SaveOutlined,
  EyeOutlined
} from "@ant-design/icons";
import { Wind2, Wind, InfoCircle, FolderAdd } from "iconsax-react";
import {
  Input,
  Button,
  Col,
  Row,
  Descriptions,
  Collapse,
  Typography,
  Modal,
  Table,
  Select,
  Empty,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import { lang, COMMON_CONSTANT, KEY } from "../../constants/common.const";
import {
  isImage,
  MSG_CATCH,
  showMessage,
  getQuerySearch,
} from "../../constants/utils";
import ApiMachine from "../../api/backend/machineManagement";
import ApiPump from "../../api/backend/pump";
import ApiAirWashing from "../../api/backend/airWashing";
import BoxContent from "@src/component/Desktop/content";
import "./machine.scss";
import { isArray } from "lodash";
import { LANG } from "../../constants/lang.const";
import FooterPage from "@src/component/footer/footerPage";
import { isEmpty } from "lodash";
import { DetailMachine as PumpInfo } from "@src/page/Machine_Management/pump.jsx";
import { DetailMachine as AirInfo } from "@src/page/Machine_Management/air.jsx";
import { QuestionToolTip } from "./air.jsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { deboundClickModal } from "../../utils/helpers.js";

const { Panel } = Collapse;
const { Text } = Typography;
export const getIconByFileType = (name) => {
  if (isImage(name)) return <FileImageOutlined className="first-icon" />;
  const splitArray = name.split(".");
  const fileType = splitArray[splitArray.length - 1];

  switch (fileType) {
    case "zip":
    case "rar":
      return <FileZipOutlined className="first-icon" />;
    case "xlsx":
    case "xlsm":
    case "xls":
    case "xml":
      return <FileExcelOutlined className="first-icon" />;
    case "txt":
    case "csv":
      return <FileTextOutlined className="first-icon" />;
    case "pdf":
      return <FilePdfOutlined className="first-icon" />;
    case "docx":
    case "doc":
      return <FileWordOutlined className="first-icon" />;
    default:
      return <FileOutlined className="first-icon" />;
  }
};

export function FileAttachment({ item, index, noIcon = false, onClick }) {
  const name = item.fileName.split(".");
  const fileType = name.pop();
  return (
    <li key={index} className="machine-file" onClick={onClick}>
      {!noIcon && getIconByFileType(item.fileName)}
      <a
        // href={item.fileUrl}
        href="#"
        title={item.fileName}
        style={{ display: "flex", wordBreak: "break-all" }}
      >
        {name}.{fileType}
      </a>
    </li>
  );
}

const getEagleFiberArea = (diameter) => {
  return Math.round(((diameter * diameter * Math.PI) / 4) * 100) / 100;
};

const getEagleFiberRefWaterAmountMin = (
  diameter,
  eagleFiberRefWaterAmountBaseMin
) => {
  return (
    Math.round(
      eagleFiberRefWaterAmountBaseMin * getEagleFiberArea(diameter) * 100
    ) / 100
  );
};

const getEagleFiberRefWaterAmountMax = (
  diameter,
  eagleFiberRefWaterAmountBaseMax
) => {
  return (
    Math.round(
      eagleFiberRefWaterAmountBaseMax * getEagleFiberArea(diameter) * 100
    ) / 100
  );
};

const getEagleFiberFilterUnitPrice = (
  eagleFiberFilterStdUnitPrice,
  eagleFiberPartition
) => {
  return Math.ceil(eagleFiberFilterStdUnitPrice * eagleFiberPartition);
};

function ViewMachine({ isEndUser }) {
  const history = useHistory();
  const { id } = useParams();
  const { version } = getQuerySearch();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const isUpdate = id && history.location.pathname.includes("update");
  const isView = id && !history.location.pathname.includes("update");
  const isCreate = !id && history.location.pathname.includes("create");
  const { isLeader } = useSelector((state) => state.auth);
  const [listAttachMachine, setListAttachMachine] = useState([]);

  const [showLoading, setShowLoading] = useState(false);
  const [machineData, setMachineData] = useState({
    efModel: "",
    eagleFiberDiameter: "",
    eagleFiberArea: "",
    eagleFiberRefWaterAmountBaseMin: "",
    eagleFiberRefWaterAmountBaseMax: "",
    eagleFiberRefWaterAmountMin: "",
    eagleFiberRefWaterAmountMax: "",
    eagleFiberFilterUnitPrice: "",
    eagleFiberFilterStdUnitPrice: "",
    eagleFiberPartition: "",
    eagleFiberTowerDimension: "",
    eagleFiberOccupiedDimension: "",
    eagleFiberProductWeight: "",
    eagleFiberOperatingWeight: "",
    eagleFiberFilterMaterial: "",
    filtrationPumpModelNumber: "",
    backwashPumpModelNumber: "",
    airWashingBlowerModelNumber: "",
    attachFiles: [],
  });
  useEffect(() => {
    if (isView || isUpdate) {
      setShowLoading(true);
      ApiMachine.getOne(id, version)
        .then((res) => {
          if (res.status === 200) {
            res.data = {
              ...res.data,
              ...res.data.eagleFiber,
              filtrationPumpModelNumber: res.data.filtrationPump.modelNumber,
              backwashPumpModelNumber: res.data.backwashPump.modelNumber,
              airWashingBlowerModelNumber:
                res.data.airWashingBlower.modelNumber,
            };
            setMachineData(res.data);
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }, []);

  const save = async () => {
    const body = {
      id: machineData.id,
      efModel: machineData.efModel,
      eagleFiber: {
        eagleFiberDiameter: +machineData.eagleFiberDiameter,
        eagleFiberArea: getEagleFiberArea(+machineData.eagleFiberDiameter) || 0,
        eagleFiberRefWaterAmountBaseMin:
          +machineData.eagleFiberRefWaterAmountBaseMin,
        eagleFiberRefWaterAmountBaseMax:
          +machineData.eagleFiberRefWaterAmountBaseMax,
        eagleFiberRefWaterAmountMin:
          getEagleFiberRefWaterAmountMin(
            +machineData.eagleFiberDiameter,
            +machineData.eagleFiberRefWaterAmountBaseMin
          ) || 0,
        eagleFiberRefWaterAmountMax:
          getEagleFiberRefWaterAmountMax(
            +machineData.eagleFiberDiameter,
            +machineData.eagleFiberRefWaterAmountBaseMax
          ) || 0,
        eagleFiberFilterUnitPrice:
          getEagleFiberFilterUnitPrice(
            +machineData.eagleFiberFilterStdUnitPrice,
            +machineData.eagleFiberPartition
          ) || 0,
        eagleFiberFilterStdUnitPrice: machineData.eagleFiberFilterStdUnitPrice,
        eagleFiberPartition: machineData.eagleFiberPartition,
        eagleFiberTowerDimension: machineData.eagleFiberTowerDimension,
        eagleFiberOccupiedDimension: machineData.eagleFiberOccupiedDimension,
        eagleFiberProductWeight: machineData.eagleFiberProductWeight,
        eagleFiberOperatingWeight: machineData.eagleFiberOperatingWeight,
        eagleFiberFilterMaterial: machineData.eagleFiberFilterMaterial,
      },
      filtrationPumpModelNumber: machineData.filtrationPumpModelNumber,
      backwashPumpModelNumber: machineData.backwashPumpModelNumber,
      airWashingBlowerModelNumber: machineData.airWashingBlowerModelNumber,
      attachFiles: listAttachMachine.map((item, index) => {
        return {
          fileName: item.fileName,
          fileUrl: item.fileUrl,
          order: index,
          note: item.note,
          recordVersion: 0,
        };
      }),
    };

    const api = isCreate ? ApiMachine.save(body) : ApiMachine.update(body);
    try {
      setShowLoading(true);
      const res = await api;
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      history.push(`/machine/${machineData.efModel}`);
    } catch (e) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };

  const handleSaveData = () => {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        save();
        deboundClickModal.handleCloseModal()
      },
      onCancel:() => {
        deboundClickModal.handleCloseModal()
      }
    });
  };

  const [showModal, setShowModal] = useState(false);

  const column_machine_detail = [
    {
      title: <>ポンプ型式</>,
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: <>ろ過ポンプ</>,
      dataIndex: "model1",
      key: "model1",
      align: "center",
      render: (value, row, index) => {
        return (
          <>
            <Button
              className="blue p0 ml4"
              onClick={() => {
                value && setShowModal(1);
              }}
              type="link"
            >
              {value ? "●" : "x"}
            </Button>
          </>
        );
      },
    },
    {
      title: <>逆洗ポンプ</>,
      dataIndex: "model2",
      align: "center",
      key: "model2",
      render: (value, row, index) => {
        return (
          <>
            <Button
              className="blue p0 ml4"
              onClick={() => {
                value && setShowModal(2);
              }}
              type="link"
            >
              {value ? "●" : "x"}
            </Button>
          </>
        );
      },
    },
  ];

  const [pump, setPump] = useState([]);
  const [airWashingBlower, setAirWashingBlower] = useState([]);

  useEffect(() => {
    if (isCreate || isUpdate) {
      setShowLoading(true);
      Promise.all([ApiPump.search(), ApiAirWashing.search()])
        .then((res) => {
          if (res[0].status === 200) {
            setPump(res[0].data.data);
          }
          if (res[1].status === 200) {
            setAirWashingBlower(res[1].data.data);
          }
        })
        .catch((err) => {
          showMessage(
            KEY.ERROR,
            (err.response && err.response.data.msg) || MSG_CATCH()
          );
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }, []);

  const airWashingBlowerDetail = machineData.airWashingBlower || {};
  const filtrationPump = machineData.filtrationPump || {};
  const backwashPump = machineData.backwashPump || {};

  const getDataSource = () => {
    if (filtrationPump.filtrationPumpModel === backwashPump.backwashPumpModel) {
      return [
        {
          key: 1,
          name: filtrationPump.filtrationPumpModel,
          model1: true,
          model2: true,
        },
      ];
    }

    return [
      {
        key: 1,
        name: filtrationPump.filtrationPumpModel,
        model1: true,
        model2: false,
      },
      {
        key: 2,
        name: backwashPump.backwashPumpModel,
        model1: false,
        model2: true,
      },
    ];
  };

  const filtrationPumpDetail = {
    ...filtrationPump,
    modelNumber: filtrationPump.modelNumber,
    model: filtrationPump.filtrationPumpModel,
    type: "1",
    motorPower: filtrationPump.filtrationPumpMotorPower,
    flow: filtrationPump.filtrationPumpFlow,
    lift: filtrationPump.filtrationPumpLift,
    motorPoles: filtrationPump.filtrationPumpMotorPoles,
    materialCasing: filtrationPump.filtrationPumpMaterialCasing,
    materialImpeller: filtrationPump.filtrationPumpMaterialImpeller,
    materialShaft: filtrationPump.filtrationPumpMaterialShaft,
    shaftSeal: filtrationPump.filtrationPumpShaftSeal,
    waterInjection: filtrationPump.filtrationPumpWaterInjection,
    partition: filtrationPump.filtrationPumpPartition,
    weight: filtrationPump.filtrationPumpWeight,
    stdUnitPrice: filtrationPump.filtrationPumpStdUnitPrice,
  };

  const backwashPumpDetail = {
    ...backwashPump,
    modelNumber: backwashPump.modelNumber,
    model: backwashPump.backwashPumpModel,
    type: "2",
    motorPower: backwashPump.backwashPumpMotorPower,
    flow: backwashPump.backwashPumpFlow,
    lift: backwashPump.backwashPumpLift,
    motorPoles: backwashPump.backwashPumpMotorPoles,
    materialCasing: backwashPump.backwashPumpMaterialCasing,
    materialImpeller: backwashPump.backwashPumpMaterialImpeller,
    materialShaft: backwashPump.backwashPumpMaterialShaft,
    shaftSeal: backwashPump.backwashPumpShaftSeal,
    waterInjection: backwashPump.backwashPumpWaterInjection,
    partition: backwashPump.backwashPumpPartition,
    weight: backwashPump.backwashPumpWeight,
    stdUnitPrice: backwashPump.backwashPumpStdUnitPrice,
  };

  const displayPump = showModal == 1 ? filtrationPumpDetail : backwashPumpDetail;

  const downloadFile = async () => {
    try {
      setShowLoading(true);
      const thisType = 0;
      const resApi = ApiMachine.downloadFile(machineData.efModel || "", thisType, '', version);
      const res = await resApi;
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = `${machineData.efModel || 'file'}.zip`;
      link.click();
    } catch (e) {
      let msg = MSG_CATCH();
      if (e.response && e.response.status == 404) {
        msg = "ファイルが見つかりません";
      }
      showMessage(KEY.ERROR, msg);
    } finally {
      setShowLoading(false);
    };
  };
  return (
    <>
      <div className="des-content machine-content">
        <TitlePage
          name={
            isView
              ? LANG.VIEW_MACHINE
              : id
                ? LANG.UPDATE_MACHINE
                : LANG.ADD_MACHINE
          }
        />
        <Loading show={showLoading}></Loading>

        <Row gutter={0} style={{}}>
          <Col span={24} className="mt8">
            <DetailMachine
              item={{
                title: "イーグルファイバー機種表"
                  + " ver." + (machineData.recordVersion || '')
                ,
                icon: <InfoCircle className="first-icon" />,
                defaultExpand: true,
              }}
              machineData={machineData}
              setMachineData={setMachineData}
            />
          </Col>
          {isView && (
            <Col span={24} className="mt8">
              <Table
                columns={column_machine_detail}
                bordered={true}
                pagination={false}
                dataSource={getDataSource()}
              />
            </Col>
          )}
          {isView && (
            <Col span={24} className="mt8">
              <BoxContent style={{ margin: 0, padding: 0 }}>
                <Collapse
                  defaultActiveKey={["0"]}
                  expandIconPosition="right"
                  style={{ padding: 0 }}
                >
                  <Panel
                    header={
                      <>
                        
                        空洗ブロワ機種表
                        &nbsp;ver.{airWashingBlowerDetail.recordVersion}
                      </>
                    }
                    key="1"
                  >
                    <AirInfo
                      viewMode={true}
                      machineData={{
                        price:
                          airWashingBlowerDetail.airWashingBlowerSndprfBoxPrice,
                        airWashingBlowerPrice: {
                          model: airWashingBlowerDetail.airWashingBlowerModel,
                          airWashingBlowerModelPrice:
                            airWashingBlowerDetail.airWashingBlowerModelPrice,
                        },
                        soundProofBoxModel:
                          airWashingBlowerDetail.airWashingBlowerSndprfBoxModel,
                        soundProofBox: {
                          model:
                            airWashingBlowerDetail.airWashingBlowerSndprfBoxModel,
                          price:
                            airWashingBlowerDetail.price,
                          partition:
                            airWashingBlowerDetail.airWashingBlowerSndprfBoxPartition,
                          weight:
                            airWashingBlowerDetail.airWashingBlowerSndprfBoxWeight,
                        },
                        electricMotorPrice: {
                          electricMotorPower:
                            airWashingBlowerDetail.airWashingBlowerMotorPower,
                          v400OutdoorPrice:
                            airWashingBlowerDetail.airWashingBlowerElectricMotorPrice,
                        },
                        modelNumber: airWashingBlowerDetail.modelNumber,
                        model: airWashingBlowerDetail.airWashingBlowerModel,
                        airVolume:
                          airWashingBlowerDetail.airWashingBlowerAirVolume,
                        dischargePress:
                          airWashingBlowerDetail.airWashingBlowerDischargePress,
                        motorPower:
                          airWashingBlowerDetail.airWashingBlowerMotorPower,
                        materialCasing:
                          airWashingBlowerDetail.airWashingBlowerMaterialCasing,
                        materialRotor:
                          airWashingBlowerDetail.airWashingBlowerMaterialRotor,
                        partition:
                          airWashingBlowerDetail.airWashingBlowerPartition,
                        weight: airWashingBlowerDetail.airWashingBlowerWeight,
                      }}
                      version={airWashingBlowerDetail.recordVersion}
                    />
                  </Panel>
                </Collapse>
              </BoxContent>
            </Col>
          )}
          {!isView && (
            <>
              <Col span={24} className="mt8">
                <BoxContent style={{ margin: 0, padding: 0 }}>
                  <Collapse
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    style={{ padding: 0 }}
                  >
                    <Panel
                      header={
                        <>
                         
                          ポンプ機種表
                        </>
                      }
                      key="1"
                    >
                      <Descriptions size="small" column={1} bordered>
                        <Descriptions.Item
                          span={1}
                          label={
                            <>
                              ろ過ポンプ機種表{" "}
                              {!isView && (
                                <span className="mark-required">*</span>
                              )}
                            </>
                          }
                          labelStyle={{
                            width: "35%",
                            fontWeight: "semi-bold",
                          }}
                        >
                          <Select
                            className="w100"
                            options={pump
                              .filter((x) => x.type == "1")
                              .map((item) => ({
                                value: item.modelNumber,
                                label: item.modelNumber + " | " + item.model,
                              }))}
                            value={machineData.filtrationPumpModelNumber}
                            onChange={(value) => {
                              const newMachineData = { ...machineData };
                              newMachineData.filtrationPumpModelNumber = value;
                              setMachineData(newMachineData);
                            }}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={
                            <>
                              逆洗ポンプ機種表{" "}
                              {!isView && (
                                <span className="mark-required">*</span>
                              )}
                            </>
                          }
                          labelStyle={{
                            width: "35%",
                            fontWeight: "semi-bold",
                          }}
                        >
                          <Select
                            className="w100"
                            options={pump
                              .filter((x) => x.type == "2")
                              .map((item) => ({
                                value: item.modelNumber,
                                label: item.modelNumber + " | " + item.model,
                              }))}
                            value={machineData.backwashPumpModelNumber}
                            onChange={(value) => {
                              const newMachineData = { ...machineData };
                              newMachineData.backwashPumpModelNumber = value;
                              setMachineData(newMachineData);
                            }}
                          />
                        </Descriptions.Item>
                      </Descriptions>
                    </Panel>
                  </Collapse>
                </BoxContent>
              </Col>

              <Col span={24} className="mt8">
                <BoxContent style={{ margin: 0, padding: 0 }}>
                  <Collapse
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    style={{ padding: 0 }}
                  >
                    <Panel
                      header={
                        <>
                          空洗ブロワ機種表
                        </>
                      }
                      key="1"
                    >
                      <Descriptions size="small" column={1} bordered>
                        <Descriptions.Item
                          span={1}
                          label={
                            <>
                              空洗ブロワ機種表{" "}
                              {!isView && (
                                <span className="mark-required">*</span>
                              )}
                            </>
                          }
                          labelStyle={{
                            width: "35%",
                            fontWeight: "semi-bold",
                          }}
                        >
                          <Select
                            className="w100"
                            options={airWashingBlower.map((item) => ({
                              label:
                                item.modelNumber +
                                " | " +
                                item.model +
                                " | " +
                                item.soundProofBoxModelNumber,
                              value: item.modelNumber,
                            }))}
                            value={machineData.airWashingBlowerModelNumber}
                            onChange={(value) => {
                              const newMachineData = { ...machineData };
                              newMachineData.airWashingBlowerModelNumber =
                                value;
                              setMachineData(newMachineData);
                            }}
                          />
                        </Descriptions.Item>
                      </Descriptions>
                    </Panel>
                  </Collapse>
                </BoxContent>
              </Col>
            </>
          )}
          <Col span={24} className="mt8">
            <UploadFileSpace
              listAttachMachine={listAttachMachine}
              setListAttachMachine={setListAttachMachine}
              isView={isView}
              setShowLoading={setShowLoading}
              id={id}
              type={0}
              version={version}
            />
          </Col>
        </Row>
        <div style={{ height: "100px" }}></div>
        {showModal && (
          <Modal
            open={showModal}
            title={<>{displayPump.modelNumber
              + ' ver.' + displayPump.recordVersion
            }</>}
            onCancel={() => {
              setShowModal(null);
            }}
            footer={null}
            width={isPC ? '550px' : '100%'}
            style={{ top: 20, marginBottom: 50 }}
          >
            <PumpInfo viewMode={true} machineData={displayPump}
              version={displayPump.recordVersion}
            />
          </Modal>
        )}
      </div>
      <FooterPage>
        <Row style={{ cursor: "pointer" }}>
          {isView && (
            <Col span={isLeader ? 8 : 12}>
              <div
                className={isLeader ? "actionRight" : "actionLeft"}
                style={{ borderRight: "1px solid #e8e8e8" }}
                onClick={() => downloadFile()}
              >
                <CloudDownloadOutlined
                  className="mr4"
                  style={{ fontWeight: "bold", fontSize: 16 }}
                />{" "}
                {lang.DOWNLOAD_PDF}
              </div>
            </Col>
          )}
          <Col span={isView && isLeader ? 8 : 12}>
            <div
              className={"actionCancel"}
              style={{ borderRight: "1px solid #e8e8e8" }}
              onClick={() => history.goBack()}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>

          {isView && isLeader && (
            <Col span={8}>
              <div
                onClick={() => history.push(`/machine/${id}/update`)}
                className={"actionLeft"}
              >
                <EditOutlined
                  className="mr4"
                  style={{ fontWeight: "bold", fontSize: 16 }}
                />
                {lang.EDIT}
              </div>
            </Col>
          )}
          {!isView && (
            <Col span={12}>
              <div onClick={() => deboundClickModal.handleOpenModal(handleSaveData)}  className={"actionLeft"}>
                <SaveOutlined
                  className="mr4"
                  style={{ fontWeight: "bold", fontSize: 16 }}
                />
                {lang.SAVE}
              </div>
            </Col>
          )}
        </Row>
      </FooterPage>
    </>
  );
}

export default ViewMachine;

export const DetailMachine = ({ item, index, viewMode = false, machineData, setMachineData }) => {
  const history = useHistory();
  const { id } = useParams();
  const isUpdate = !viewMode && id && history.location.pathname.includes("update");
  const isView = viewMode || (id && !history.location.pathname.includes("update"));
  const isCreate = !viewMode && !id && history.location.pathname.includes("create");
  const onChangeInput = (e, info) => {
    let { value } = e.target;
    const newmMchineData = { ...machineData };

    // remove character not numeric when inputType is number
    if (info.inputType === "number") {
      value = value.replace(/[^0-9.]/g, "");
      if (value.split(".").length > 2) {
        value = value.substring(0, value.length - 1);
      }
    }

    newmMchineData[info.key] = info.isInteger ? Math.floor(value) : value;
    setMachineData(newmMchineData);
  };

  const onChangeSelect = (value, info) => {
    const newmMchineData = { ...machineData };
    newmMchineData[info.key] = value;
    setMachineData(newmMchineData);
  };

  const getNumber = (number) => {
    return number < 10 ? "0" + number : number;
  };

  const machine_information_display = [
    {
      key: "efModel",
      title: "EF型式",
      content: machineData.efModel,
      isRequired: true,
      auto: true,
    },
    {
      key: "eagleFiberDiameter",
      title: (
        <>
          直径
          <br />
          (φ,m)
        </>
      ),
      content: machineData.eagleFiberDiameter,
      isRequired: true,
      inputType: "number",
      auto: true,
    },
    {
      key: "eagleFiberArea",
      title: (
        <>
          <QuestionToolTip title="直径 x 直径 x PI /4" /> 面積
          <br />
          (㎡)
        </>
      ),
      content:
        (isView
          ? machineData.eagleFiberArea
          : getEagleFiberArea(machineData.eagleFiberDiameter)) || 0,
      inlineDisplay: true,
      auto: true,
    },
    {
      title: "参考水量",
      content: [
        {
          key: "eagleFiberRefWaterAmountBaseMin",
          title: "基準最小値",
          content: machineData.eagleFiberRefWaterAmountBaseMin,
          isRequired: true,
          inputType: "number",
        },
        {
          key: "eagleFiberRefWaterAmountBaseMax",
          title: "基準最大値",
          content: machineData.eagleFiberRefWaterAmountBaseMax,
          isRequired: true,
          inputType: "number",
        },
        {
          title: (
            <>
              <QuestionToolTip title="基準最小値 x 面積" /> 最小値
            </>
          ),
          content: isView
            ? machineData.eagleFiberRefWaterAmountMin
            : getEagleFiberRefWaterAmountMin(
              machineData.eagleFiberDiameter,
              machineData.eagleFiberRefWaterAmountBaseMin
            ),
          auto: true,
        },
        {
          title: (
            <>
              <QuestionToolTip title="基準最大値 x 面積" /> 最大値
            </>
          ),
          content: isView
            ? machineData.eagleFiberRefWaterAmountMax
            : getEagleFiberRefWaterAmountMax(
              machineData.eagleFiberDiameter,
              machineData.eagleFiberRefWaterAmountBaseMax
            ),
          auto: true,
        },
      ],
      inlineDisplay: true,
    },
    {
      key: "eagleFiberFilterStdUnitPrice",
      title: (
        <>
          ろ過器標準単価(P)(千円)
          <br />
          ろ材P(用水工業用水)
        </>
      ),
      content: machineData.eagleFiberFilterStdUnitPrice,
      inlineDisplay: true,
      isRequired: true,
      inputType: "number",
      isInteger: true,
    },
    {
      key: "eagleFiberPartition",
      title: "仕切",
      content: machineData.eagleFiberPartition,
      isRequired: true,
      inputType: "number",
    },
    {
      key: "eagleFiberFilterUnitPrice",
      title: (
        <>
          <QuestionToolTip title="ろ過器標準単価(P) x 仕切" /> 単価
          <br />
          (千円)
        </>
      ),
      content:
        getEagleFiberFilterUnitPrice(
          machineData.eagleFiberFilterStdUnitPrice,
          machineData.eagleFiberPartition
        ) || 0,
      inlineDisplay: true,
      auto: true,
    },
    {
      key: "eagleFiberTowerDimension",
      title: (
        <>
          ろ過塔寸法
          <br />
          (mm)
        </>
      ),
      content: machineData.eagleFiberTowerDimension,
      inlineDisplay: true,
      isRequired: true,
      render: (value) => {
        const isX = value.includes("X");
        const splitName = value.split(isX ? "X" : "x");
        const phi = splitName[0] ? +splitName[0].replace(/[^0-9.]/g, "") : 0;
        const H = splitName[1] ? +splitName[1].replace(/[^0-9.]/g, "") : 0;
        // replace all numeric character from splitName[1] to empty string
        const X = splitName[1] ? splitName[1].replace(/[0-9]/g, "") : 'H';

        return <>
          <Row className="mb4">
            <div style={{ width: 60 }}>
              <Select
                bordered={false}
                options={[
                  { label: "φ", value: "φ" },
                ]}
                value={'φ'}
              />
            </div>
            <div style={{ width: 'calc(100% - 60px)' }}>
              <Input
                type="number"
                // style={{ width: '100%' }}
                value={phi}
                onChange={(e) => {
                  const newmMchineData = { ...machineData };
                  newmMchineData['eagleFiberTowerDimension'] = "φ" + +e.target.value + "x" + H + X;
                  setMachineData(newmMchineData);
                }} />
            </div>
          </Row>
          <Row>
            <div style={{ width: 60 }}>
              <Select
                bordered={false}
                options={[
                  { label: "SH", value: "SH" },
                  { label: "H", value: "H" },
                ]}
                value={X}
                onChange={(e) => {
                  const newmMchineData = { ...machineData };
                  newmMchineData['eagleFiberTowerDimension'] = "φ" + +phi + "x" + +H + e;
                  setMachineData(newmMchineData);
                }}
              />
            </div>
            <div style={{ width: 'calc(100% - 60px)' }}>
              <Input
                type="number"
                style={{ width: '100%' }}
                value={H}
                onChange={(e) => {
                  const newmMchineData = { ...machineData };
                  newmMchineData['eagleFiberTowerDimension'] = "φ" + phi + "x" + +e.target.value + X;
                  setMachineData(newmMchineData);
                }} />
            </div>
          </Row>
        </>;
      },
    },
    {
      key: "eagleFiberOccupiedDimension",
      title: (
        <>
          占有寸法(mm)
          <br />W x D x H{" "}
        </>
      ),
      content: machineData.eagleFiberOccupiedDimension,
      inlineDisplay: true,
      isRequired: true,
      render: (value) => {
        const isX = value.includes("X");
        const splitName = value.split(isX ? "X" : "x");
        const W = splitName[0] ? +splitName[0].replace(/[^0-9.]/g, "") : 0;
        const D = splitName[1] ? +splitName[1].replace(/[^0-9.]/g, "") : 0;
        const H = splitName[2] ? +splitName[2].replace(/[^0-9.]/g, "") : 0;
        return <>
          <Input
            type="number"
            style={{ width: '33%' }}
            prefix="W"
            value={W}
            onChange={(e) => {
              const newmMchineData = { ...machineData };
              newmMchineData['eagleFiberOccupiedDimension'] = e.target.value + "x" + D + "x" + H;
              setMachineData(newmMchineData);
            }} />
          <Input
            type="number"
            style={{ width: '33%' }}
            prefix="D"
            value={D}
            onChange={(e) => {
              const newmMchineData = { ...machineData };
              newmMchineData['eagleFiberOccupiedDimension'] = W + "x" + e.target.value + "x" + H;
              setMachineData(newmMchineData);
            }} />
          <Input
            type="number"
            prefix="H"
            style={{ width: '33%' }}
            value={H}
            onChange={(e) => {
              const newmMchineData = { ...machineData };
              newmMchineData['eagleFiberOccupiedDimension'] = W + "x" + D + "x" + e.target.value;
              setMachineData(newmMchineData);
            }} />
        </>;
      },
    },
    {
      key: "eagleFiberProductWeight",
      title: (
        <>
          製品重量
          <br />
          (kg)
        </>
      ),
      content: machineData.eagleFiberProductWeight,
      inlineDisplay: true,
      isRequired: true,
      inputType: "number",
      isInteger: true,
    },
    {
      key: "eagleFiberOperatingWeight",
      title: (
        <>
          運転重量
          <br />
          (kg)
        </>
      ),
      content: machineData.eagleFiberOperatingWeight,
      inlineDisplay: true,
      isRequired: true,
      inputType: "number",
      isInteger: true,
    },
    {
      key: "eagleFiberFilterMaterial",
      title: "ろ過材",
      content: machineData.eagleFiberFilterMaterial,
      inlineDisplay: true,
      isRequired: true,
      maxLength: 4,
      auto: true,
    },
  ];

  const Content = <Descriptions size="small" column={1} bordered>
    {machine_information_display.map((info, index) => {
      return (
        <Descriptions.Item
          span={1}
          key={index}
          label={
            <>
              {info.title}{" "}
              {info.isRequired && !isView && (
                <span className="mark-required">*</span>
              )}
            </>
          }
          labelStyle={{
            width: "35%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: !isArray(info.content) ? "8px" : "0px",
            whiteSpace: "pre-wrap",
          }}
        >
          {
            !isArray(info.content) && <>
              {
                isView && (info.content || "-")
              }
              {
                !isView && <>
                  {
                    !info.render && <>
                      {!info.isSelect && !info.auto && (
                        <Input
                          // type={info.inputType || "text"}
                          maxLength={info.maxLength || 1000}
                          value={info.content}
                          onChange={(e) => {
                            onChangeInput(e, info);
                          }}
                        />
                      )}
                      {info.isSelect && (
                        <Select
                          className="w100"
                          options={info.options}
                          value={info.content}
                          onChange={(e) => onChangeSelect(e, info)}
                        />
                      )}
                      {info.auto && <Text>{info.content}</Text>}
                    </>
                  }
                  {
                    info.render && info.render(info.content)
                  }
                </>
              }
            </>
          }
          {isArray(info.content) && (
            <Descriptions
              key={index + "1"}
              size="small"
              column={1}
              bordered
            >
              {info.content.map((info, index) => {
                return (
                  <Descriptions.Item
                    key={index}
                    span={1}
                    label={
                      <>
                        {info.title}{" "}
                        {info.isRequired && !isView && (
                          <span className="mark-required">*</span>
                        )}
                      </>
                    }
                    labelStyle={{
                      width: "45%",
                      fontWeight: "semi-bold",
                    }}
                  >
                    {isView && (info.content || "-")}
                    {!isView && (
                      <>
                        {!info.isSelect && !info.auto && (
                          <Input
                            maxLength={info.maxLength || 1000}
                            value={info.content}
                            onChange={(e) => {
                              onChangeInput(e, info);
                            }}
                          // type={info.inputType || "text"}
                          />
                        )}
                        {info.isSelect && (
                          <Select
                            className="w100"
                            options={info.options}
                            value={info.content}
                            onChange={(e) => onChangeSelect(e, info)}
                          />
                        )}
                        {info.auto && <Text>{info.content}</Text>}
                      </>
                    )}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          )}
        </Descriptions.Item>
      );
    })}
  </Descriptions>;

  const [listAttachMachine, setListAttachMachine] = useState([]);
  if (viewMode) {
    return <>
      {Content}
      <div className="mt8 machine-content">
        <UploadFileSpace
          listAttachMachine={listAttachMachine}
          setListAttachMachine={setListAttachMachine}
          isView={isView}
          setShowLoading={() => { }}
          id={machineData.efModel}
          type={0}
          version={machineData.recordVersion}
          onlyTable={true}
        />
      </div>
    </>;
  }

  return (
    <BoxContent style={{ margin: 0, padding: 0 }}>
      <Collapse
        defaultActiveKey={item.defaultExpand ? ["1"] : ["0"]}
        expandIconPosition="right"
        style={{ padding: 0 }}
      >
        <Panel
          header={
            <>
             
              {item.title}
            </>
          }
          key="1"
        >
          {Content}
        </Panel>
      </Collapse>
    </BoxContent>
  );
};

export const UploadFileSpace = ({
  listAttachMachine,
  setListAttachMachine = () => { },
  isView,
  setShowLoading = () => { },
  id,
  hideIcon = false,
  type,
  version = 0,
  onlyTable = false,
}) => {
  const isPc = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const fileMachineRef = useRef(null);
  useEffect(() => {
    if (!id) return;
    const fetch = () => {
      setShowLoading(true);
      ApiMachine.getAttachFile(id, type, version)
        .then((res) => {
          if (res.status === 200) {
            setListAttachMachine(res.data.data);
          }
        })
        .catch((err) => {
          showMessage(
            KEY.ERROR,
            (err.response && err.response.data.msg) || MSG_CATCH()
          );
        })
        .finally(() => {
          setShowLoading(false);
        });
    };

    fetch();
  }, [id]);

  const uploadFile = async (file) => {
    const formData = new FormData();
    const body = {
      attachFileName: file.name,
    };
    formData.append("data", JSON.stringify(body));
    formData.append("file", file);

    try {
      setShowLoading(true);
      const res = await ApiMachine.uploadFile(formData);
      const resData = res.data || {};
      const newListAttachMachine = [...listAttachMachine];
      newListAttachMachine.push({
        fileName: resData.fileName,
        fileUrl: resData.url,
      });
      setListAttachMachine([...newListAttachMachine]);
    } catch (err) {
      showMessage(
        KEY.ERROR,
        (err.response && err.response.data.msg) || MSG_CATCH()
      );
    } finally {
      setShowLoading(false);
    }
  };

  const downloadFile = async (item) => {
    try {
      setShowLoading(true);
      const res = await ApiMachine.downloadFile(id, type, item.id, version);
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = item.fileName;
      link.click();
    } catch (err) {
      showMessage(
        KEY.ERROR,
        (err.response && err.response.data.msg) || MSG_CATCH()
      );
    } finally {
      setShowLoading(false);
    }
  };

  const [modalFile, setModalFile] = useState(false);
  const [src, setSrc] = useState(null);
  const viewFile = async (item) => {
    try {
      setShowLoading(true);
      const res = await ApiMachine.viewFile(item.id);
      const link = document.createElement("a");
      link.href = res.data;
      link.target = "_blank";
      link.click();
    } catch (err) {
      showMessage(
        KEY.ERROR,
        (err.response && err.response.data.msg) || MSG_CATCH()
      );
    } finally {
      setShowLoading(false);
    }
  };

  const handleChangeAttachMachine = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const exceedAmountFile =
      listAttachMachine.length + listAttachMachine.length >= 30;
    const exceedSizeFile = file.size > 100 * 1024 * 1024;
    const contentPopup = exceedAmountFile
      ? "ファイル数は 30 を超えないようにしてください"
      : "ファイルサイズは 100MB を超えないようにしてください";
    if (exceedAmountFile || exceedSizeFile) {
      e.preventDefault();
      Modal.destroyAll();
      Modal.confirm({
        centered: true,
        title: lang.WARMING,
        content: contentPopup,
        okText: lang.OK,
        cancelText: lang.CANCEL,
      });
      return;
    }

    uploadFile(file);
    fileMachineRef.current.value = null;
  };

  const handleChangeInputNote = (e, index) => {
    const newListAttachMachine = [...listAttachMachine];
    newListAttachMachine[index].note = e.target.value;
    setListAttachMachine([...newListAttachMachine]);
  };

  //list columns of table with 3 columns: file, note, delete
  const columns = [
    {
      title: "ファイル",
      dataIndex: "fileName",
      key: "fileName",
      width: "40%",
      render: (value, row, index) => {
        return (
          <ul
            className="mb0 mt0"
            style={{ listStyle: "none", paddingLeft: "24px" }}
          >
            <FileAttachment
              item={row}
              index={index}
              onClick={() => downloadFile(row)}
            />
          </ul>
        );
      },
    },
    {
      title: "履歴コメント",
      dataIndex: "note",
      key: "note",
      render: (value, row, index) => {
        return (
          <>
            {!isView && (
              <Input
                value={row.note}
                onChange={(e) => {
                  handleChangeInputNote(e, index);
                }}
              />
            )}
            {isView && <Text>{row.note}</Text>}
          </>
        );
      },
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: isPc ? '170px' : '50px',
      render: (value, row, index) => {
        const listCanOpen = ['pdf', 'PDF', 'png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'];
        const canOpen = listCanOpen.includes(row.fileName.split('.').pop());
        return (
          <>
            <Button
              onClick={() => downloadFile(row)}
              className="btn-deletefile"
              style={{ background: "#f7f9fc" }}
              icon={<CloudDownloadOutlined />}
            />
            {!isView && (
              <Button
                onClick={() => {
                  listAttachMachine.splice(index, 1);
                  setListAttachMachine([...listAttachMachine]);
                }}
                className="btn-deletefile"
                style={{ background: "#f7f9fc" }}
                icon={<DeleteOutlined />}
              />
            )}
            {
              canOpen && <Button
                onClick={() => {
                  viewFile(row);
                }}
                className="btn-deletefile"
                style={{ background: "#f7f9fc" }}
                icon={<EyeOutlined />}
              />
            }
            <Modal
              onCancel={() => setModalFile(false)}
              open={modalFile}
              width="95vw"
              style={{ top: 20, padding: 4 }}
              footer={<>
                <Button
                  onClick={() => downloadFile(row)}
                  className="btn-deletefile"
                  style={{ background: "#f7f9fc" }}
                  icon={<CloudDownloadOutlined />}
                >
                  Download
                </Button>
              </>}
            >
              {src && <iframe
                src={src}
                width="100%"
                height="1000px"
              />}
            </Modal>
          </>
        );
      },
    },
  ];

  if (onlyTable) {
    return (
      <Table
        columns={columns}
        dataSource={listAttachMachine}
        pagination={false}
        bordered
      />
    );
  }

  return (
    <BoxContent style={{ margin: 0, padding: 0 }}>
      <Collapse
        defaultActiveKey={[hideIcon ? "1" : "0"]}
        expandIconPosition="right"
        style={{ padding: 0 }}
        {...(hideIcon && { expandIcon: () => <></> })}
      >
        <Panel
          header={
            <>
              関連図面ファイル
            </>
          }
          key="1"
        >
          <>
            <input
              type="file"
              className="d-none"
              hidden={true}
              ref={fileMachineRef}
              onChange={handleChangeAttachMachine}
            />
            {!isView && (
              <Row className="mt8 pl8 pr8">
                <Col span={24}>
                  <Button
                    type="link"
                    className="buttonPC button--outline --todo-- wAuto"
                    onClick={(e) => fileMachineRef.current.click()}
                    style={{
                      backgroundColor: "#f7f9fc",
                      marginBottom: 10,
                      borderColor: "lightgray",
                    }}
                  >
                    <ToTopOutlined />
                    関連図面ファイル
                  </Button>
                </Col>
                <Col span={24}>
                  <Text type="danger" style={{ fontSize: "12px" }}>
                    ※ファイルサイズの上限は100MBです。
                  </Text>
                </Col>
              </Row>
            )}
            <Row className="">
              <Col
                span={24}
              // style={{ maxHeight: "190px", overflow: "auto" }}
              >
                <Table
                  columns={columns}
                  dataSource={listAttachMachine}
                  pagination={false}
                />
              </Col>
            </Row>
          </>
        </Panel>
      </Collapse>
    </BoxContent>
  );
};

import React from "react";
import { ActivityIndicator } from "antd-mobile";
import { useSelector } from "react-redux";
import { loadingSelector } from "@src/redux/loading";
import LoadingIcon from "@src/styles/icon/loading_animation.svg";
import { lang } from "@src/constants/common.const";
function Loading() {
  const { isLoading, isLoadingDesktop } = useSelector(loadingSelector);
  return (
    <>
      {isLoadingDesktop ? (
        <div className="fullScreen">
          <img className="loadingDesktop" src={LoadingIcon} alt="" />
        </div>
      ) : null}
      <ActivityIndicator toast text={lang.LOADING} animating={isLoading} />
    </>
  );
}
export default Loading;

import Api from "@src/api";

export const FeeRateApi =  {
  //search machine
  getListFeeRate(params = {}) {
    return Api().get("/api/office/FeeRate", { params });
  },
  update(data) {
    return Api().put(`/api/office/FeeRate`, data, {});
  }
};

import React from "react";
import { Col, Row } from "antd";

function RowInput({
  box,
  text,
  required,
  labelCol = 6,
  flexStart = true,
  multipleCol = false,
  ...props
}) {
  return (
    <div className={box ? "row-input-box" : "row-input"}>
      <Row>
      <Col
          span={labelCol}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          className={text ? "" : "row-input-col-first"}
        >
          <Row justify="space-between">
            <Col span={24} className="">
              <label className="row-input-label ">{props.name}</label>
              {required && <label className="blod ml5 mark-required">*</label>}
            </Col>
          </Row>
        </Col>

        <Col
          style={{
            borderLeft: "1px solid #e6e6e6",
          }}
          span={24 - labelCol}
          className={`${!multipleCol ? `row-input-col-2 ${flexStart ? "flex-start":""}` : ""} `}
        >
          <span style={{ textAlign: "start" }}>{props.children}</span>
        </Col>
      </Row>
    </div>
  );
}

export default RowInput;

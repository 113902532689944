import { createSlice } from "@reduxjs/toolkit";
import API from "@src/api/backend/homeBanner";
import { NOTI_CHECKLIST } from "@src/constants/status.const";
import StatusConst from "@src/constants/status.const";

import moment from 'moment';
const initialState = {
  dataHomeBanner: [],
  dataNotification: {},
  numberUnread: 0,
  listInventory: [],
  isShowBannerInvenrory: true,
};

const homeBanner = createSlice({
  name: "home-banner",
  initialState,
  reducers: {
    setDataHomeBanner: (state, { payload }) => {
      state.dataHomeBanner = payload;
    },
    setDataNotification: (state, { payload }) => {
      state.dataNotification = payload;
    },
    setNumberUnread: (state, { payload }) => {
      state.numberUnread = payload;
    },
    setListInvenBanner: (state, { payload }) => {
      state.listInventory = payload;
    },
    setShowBannerInventory: (state, { payload }) => {
      state.isShowBannerInvenrory = payload;
    },
  },
});

// the action
export const {
  setDataHomeBanner,
  setDataNotification,
  setNumberUnread,
  setListInvenBanner,
  setShowBannerInventory,
} = homeBanner.actions;

//the selector
export const selectorNotify = (state) => state.homeBanner.dataNotification;

export const getDataHomeBanner = (data) => (dispatch) => {
  API.getHomeBannerForNotification(data)
    .then((res) => {
      if (res && res.status === 200) {
        dispatch(setDataHomeBanner(res.data.listHomeBannerOutput));
      }
    })
    .catch((error) => console.log(error));
};

export const getDataNotification = (data) => (dispatch) => {
  API.getHomeBannerForNotification(data)
    .then((res) => {
      if (res && res.status === 200) {
        const mapCalendarReminds = res.data.calendarReminds.map(x => {
          const dateTime = moment.unix(x.time);
          const content = `${dateTime.format('YYYY年MM月DD日')} ${dateTime.format('HH')}時${dateTime.format('mm')}分に${x.taskName}の予定があります。`
          return {
            ...x,
            typeValue: '点検カレンダー',
            title: x.taskName + "のお知らせ",
            content: content,
            createDatetime: dateTime,
            createDatetimeDisplay: dateTime.format('YYYY/MM/DD'),
            type: "NOTI_CHECKLIST"
          }
        });

        const notifyList = res.data.listHomeBannerOutput.concat(mapCalendarReminds).sort((a,b) => moment(b.createDatetime).unix() - moment(a.createDatetime).unix());
        dispatch(
          setDataNotification({
            data: notifyList,
            isFetchMoreData: data.isFetchMoreData ? true : false,
          })
        );
        dispatch(setNumberUnread(res.data.numUnread));

        if (data.callback) {
          data.callback(res.data);
        }
      }
    })
    .catch((error) => console.log(error));
};

export const updateReadFlagHomeBanner = (data) => (dispatch) => {
  API.updateReadFlag(data.params)
    .then((res) => {
      if (res && res.status === 200) {
        if (data.callback) {
          data.callback(res.data);
        }
      }
    })
    .catch((error) => console.log(error));
};

export const updateReadFlagCalendar = (data) => (dispatch) => {
  API.updateReadFlagCalendar(data.params)
    .then((res) => {
      if (res && res.status === 200) {
        if (data.callback) {
          data.callback(res.data);
        }
      }
    })
    .catch((error) => console.log(error));
};

// the reducer
export default homeBanner.reducer;

import React from "react";
function BreakLine({ height = 1, ...other }) {
    return (
        <div style={{
            height
        }} className="break-line" {...other}></div>
    );
}


export default BreakLine;
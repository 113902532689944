import { ExportOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { EstimateApi } from "../../../api/backend/estimate";
import { KEY, lang } from "../../../constants/common.const";
import { closeLoading, openLoading } from "../../../redux/estimate";
import {
  MSG_CATCH,
  getQuerySearch,
  showMessage,
} from "../../../constants/utils";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Table, Checkbox } from "antd";
import { UploadFileSpace } from "@src/page/Machine_Management/view.jsx";
import { isEmpty } from "lodash";
import { getIconByFileType } from "@src/page/Machine_Management/view.jsx";
import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import ApiMachine from "@src/api/backend/machineManagement";

const { Title } = Typography;
function ButtonExport({ machineSelected }) {
  const NAME_FILE_EF = {
    flowSheet: `${machineSelected} フローシート`,
    outLine: `${machineSelected} 外形図`,
  };
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  // List Select pdf, excel
  const [listSelectTypeFileDesign, setListSelectTypeFileDesign] = useState([]);
  const [listSelectTypeFileDc, setListSelectTypeFileDc] = useState([]);
  const [listSelectTypeFileBalance, setListSelectTypeFileBalance] = useState(
    []
  );
  const [listSelectTypeFileEfModel, setListSelectTypeFileEfModel] = useState(
    []
  );
  const { id } = useParams();
  const [showActionExport, setShowActionExport] = useState(false);
  const { version } = getQuerySearch();
  const dispatch = useDispatch();
  const { detailData: data } = useSelector((state) => state.estimate);
  const code = data ? data.estimationCode : "";
  // Download

  const downloadFile = async (promise, nameFile, typeFile) => {
    try {
      dispatch(openLoading());
      const res = await promise;

      const blob = new Blob([res.data], {});
      let fileName = res.headers["content-disposition"].split("filename=")[1];
      fileName = fileName.substring(0, fileName.length - 1);
      fileName = decodeURI(fileName);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = fileName || nameFile + "_" + code + "." + typeFile;
      link.click();
    } catch (error) {
      console.log(error);
      let message = MSG_CATCH();
      if (error.response && error.response.status === 404) {
        message = "ファイルが見つかりません";
      }
      showMessage(KEY.ERROR, message);
    } finally {
      dispatch(closeLoading());
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [listAttachMachine, setListAttachMachine] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await EstimateApi.getListExportFiels(id, version);
        setListAttachMachine(res.data);
      } catch (e) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
    };
    fetch();
  }, []);
  const {
    machineAttachs,
    airWashingBlowerAttachs,
    backwashPumpAttachs,
    filtrationPumpAttachs,
  } = listAttachMachine;
  const listIds = [];
  const convertData = (item, index) => {
    listIds.push(item.id);
    return {
      key: index,
      name: item.fileName,
      comment: item.comment,
      ...item,
    };
  };
  const listAttach = [
    {
      key: "title1",
      name: "イーグルファイバー図面",
      isTitle: true,
      comment: "",
      children: (machineAttachs || []).map(convertData),
    },
    {
      key: "title2",
      name: "逆洗ポンプ図面",
      isTitle: true,
      comment: "",
      children: (backwashPumpAttachs || []).map(convertData),
    },
    {
      key: "title3",
      name: "ろ過ポンプ図面",
      isTitle: true,
      comment: "",
      children: (filtrationPumpAttachs || []).map(convertData),
    },
    {
      key: "title4",
      name: "空洗ブロワ図面",
      isTitle: true,
      comment: "",
      children: (airWashingBlowerAttachs || []).map(convertData),
    },
    {
      key: "design",
      name: "設計計算書",
      isTitle: true,
      comment: "",
      children: [
        {
          key: "design-pdf",
          name: "PDF設計計算書",
          isFileType: true,
          type: "pdf",
          reportName: "step4",
          listType: listSelectTypeFileDesign,
          setListType: setListSelectTypeFileDesign,
        },
        {
          key: "design-excel",
          name: "EXCEL設計計算書",
          isFileType: true,
          type: "excel",
          listType: listSelectTypeFileDesign,
          setListType: setListSelectTypeFileDesign,
        },
      ],
    },
    {
      key: "dc",
      name: "概算DC表",
      isTitle: true,
      comment: "",
      children: [
        {
          key: "dc-pdf",
          name: "PDF概算DC表",
          isFileType: true,
          type: "pdf",
          reportName: "step3",
          listType: listSelectTypeFileDc,
          setListType: setListSelectTypeFileDc,
        },
        {
          key: "dc-excel",
          name: "EXCEL概算DC表",
          isFileType: true,
          type: "excel",
          listType: listSelectTypeFileDc,
          setListType: setListSelectTypeFileDc,
        },
      ],
    },
    {
      key: "efmodel",
      name: "概算見積書",
      isTitle: true,
      comment: "",
      children: [
        {
          key: "ef-pdf",
          name: "PDF概算見積書",
          isFileType: true,
          type: "pdf",
          reportName: "fileEF",
          listType: listSelectTypeFileEfModel,
          setListType: setListSelectTypeFileEfModel,
        },
        {
          key: "ef-excel",
          name: "EXCEL概算見積書",
          isFileType: true,
          type: "excel",
          listType: listSelectTypeFileEfModel,
          setListType: setListSelectTypeFileEfModel,
        },
      ],
    },
    {
      key: "balance",
      name: "マテリアルバランスシート",
      isTitle: true,
      comment: "",
      children: [
        {
          key: "balance-pdf",
          name: "PDFマテリアルバランスシート",
          isFileType: true,
          type: "pdf",
          reportName: "step5",
          listType: listSelectTypeFileBalance,
          setListType: setListSelectTypeFileBalance,
        },
        {
          key: "balance-excel",
          name: "EXCELマテリアルバランスシート",
          isFileType: true,
          type: "excel",
          listType: listSelectTypeFileBalance,
          setListType: setListSelectTypeFileBalance,
        },
      ],
    },
  ];

  const isCheckAll =
    selectedIds.length === listIds.length &&
    isCheckedAllType(listSelectTypeFileDc) &&
    isCheckedAllType(listSelectTypeFileBalance) &&
    isCheckedAllType(listSelectTypeFileEfModel) &&
    isCheckedAllType(listSelectTypeFileDesign);
  const CheckboxAll = (
    <>
      <Checkbox
        checked={isCheckAll}
        className="mr5"
        onChange={() => {
          if (isCheckAll) {
            setSelectedIds([]);
            setListSelectTypeFileBalance([]);
            setListSelectTypeFileDc([]);
            setListSelectTypeFileEfModel([]);
            setListSelectTypeFileDesign([]);
          } else {
            setSelectedIds(listIds);
            setListSelectTypeFileBalance(["pdf", "excel"]);
            setListSelectTypeFileDc(["pdf", "excel"]);
            setListSelectTypeFileEfModel(["pdf", "excel"]);
            setListSelectTypeFileDesign(["pdf", "excel"]);
          }
        }}
      />
      ファイル
    </>
  );

  const getcheckboxTitleType = (text) => {
    const thisType = listAttach.find((item) => item.name === text);
    const thisChildren = thisType.children;
    // isAllChildrenSelected check all children of this type is in selectedIds or not
    const isAllChildrenSelected = thisChildren.every((item) =>
      selectedIds.includes(item.id)
    );
    const onChange = () => {
      if (isAllChildrenSelected) {
        // If all children of this type is in selectedIds, remove all children of this type from selectedIds
        setSelectedIds(
          selectedIds.filter(
            (item) => !thisChildren.map((item) => item.id).includes(item)
          )
        );
      } else {
        // If all children of this type is not in selectedIds, add all children of this type to selectedIds
        setSelectedIds([
          ...selectedIds,
          ...thisChildren.map((item) => item.id),
        ]);
      }
    };
    return (
      <>
        <Checkbox
          className="mr5"
          onChange={onChange}
          checked={!isEmpty(thisChildren) && isAllChildrenSelected}
        />
        <b>{text}</b>
      </>
    );
  };

  // Chane check box all pdf,excel
  function onChangeCheckBoxAllTypeFile(listType = [], setListType = () => {}) {
    if (listType.length < 2) {
      setListType(["pdf", "excel"]);
    } else if (
      listType.length === 2 &&
      listType.includes("pdf") &&
      listType.includes("excel")
    ) {
      setListType([]);
    }
  }

  function isCheckedAllType(list = []) {
    return list.length === 2 && list.includes("pdf") && list.includes("excel");
  }

  const CheckboxDesignCalculation = (
    <>
      <Checkbox
        className="mr5"
        onChange={() => {
          onChangeCheckBoxAllTypeFile(
            listSelectTypeFileDesign,
            setListSelectTypeFileDesign
          );
        }}
        checked={isCheckedAllType(listSelectTypeFileDesign)}
      />
      <b>設計計算書</b>
    </>
  );

  const CheckboxBalanceDiagram = (
    <>
      <Checkbox
        className="mr5"
        onChange={() => {
          onChangeCheckBoxAllTypeFile(
            listSelectTypeFileBalance,
            setListSelectTypeFileBalance
          );
        }}
        checked={isCheckedAllType(listSelectTypeFileBalance)}
      />
      <b>マテリアルバランスシート</b>
    </>
  );

  const CheckboxDC = (
    <>
      <Checkbox
        className="mr5"
        onChange={() => {
          onChangeCheckBoxAllTypeFile(
            listSelectTypeFileDc,
            setListSelectTypeFileDc
          );
        }}
        checked={isCheckedAllType(listSelectTypeFileDc)}
      />
      <b>概算DC表</b>
    </>
  );
  const CheckboxEfModel = (
    <>
      <Checkbox
        className="mr5"
        onChange={() => {
          onChangeCheckBoxAllTypeFile(
            listSelectTypeFileEfModel,
            setListSelectTypeFileEfModel
          );
        }}
        checked={isCheckedAllType(listSelectTypeFileEfModel)}
      />
      <b>概算見積書</b>
    </>
  );

  const attachFileColumns = [
    {
      //When check checkbox in header of table, all checkbox in table will be checked, include design calculation and balance diagram
      title: CheckboxAll,
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        if (text === "設計計算書") {
          return CheckboxDesignCalculation;
        }

        if (text === "概算DC表") {
          return CheckboxDC;
        }
        if (text === "概算見積書") {
          return CheckboxEfModel;
        }
        if (text === "マテリアルバランスシート") {
          return CheckboxBalanceDiagram;
        }
        if (record.isFileType) {
          return (
            <>
              <Checkbox
                className="mr5"
                onChange={() => {
                  if (record.listType.includes(record.type)) {
                    record.setListType(
                      record.listType.filter((item) => item !== record.type)
                    );
                  } else {
                    record.setListType([...record.listType, record.type]);
                  }
                }}
                checked={
                  record.listType &&
                  Array.isArray(record.listType) &&
                  record.listType.includes(record.type)
                }
              />
              {text}
              {record.type === "pdf" && (
                <ViewButton
                  isEstimate={true}
                  id={id}
                  version={version}
                  reportName={record.reportName}
                />
              )}
            </>
          );
        }

        if (record.isTitle) {
          return getcheckboxTitleType(text);
        }

        return (
          <>
            <Checkbox
              className="mr5"
              onChange={() => {
                if (selectedIds.includes(record.id)) {
                  setSelectedIds(
                    selectedIds.filter((item) => item !== record.id)
                  );
                } else {
                  setSelectedIds([...selectedIds, record.id]);
                }
              }}
              checked={selectedIds.includes(record.id)}
            />
            {text}
            <ViewButton id={record.id} />
          </>
        );
      },
    },
  ];

  const [listCheckedFile, setListCheckedFile] = useState([]);
  const changeListCheckFile = (currentkey) => {
    const newListChecked = [...listCheckedFile];
    const index = newListChecked.findIndex((key) => currentkey === key);
    if (index < 0) {
      setListCheckedFile([...newListChecked, currentkey]);
    } else {
      setListCheckedFile(newListChecked.filter((key) => key !== currentkey));
    }
  };

  // GET ID VIEW PDF
  const getIdViewPdf = (name) => {
    const item = (machineAttachs || []).find((i) => i.fileName.includes(name));
    return item ? item.id : null;
  };
  const listCheckFile = [
    {
      label: "概算見積書",
      key: "efModel",
      typeFile: "xlsx",
      api: (type) => EstimateApi.downloadEF(id, version, type),
      isEstimate: true,
      reportName: "fileEF",
      haveExcel: true,
    },
    {
      label: "フローシート",
      key: "flowsheet",
      disabled: !(machineAttachs || []).find((i) =>
        i.fileName.includes(NAME_FILE_EF.flowSheet)
      ),
      typeFile: "pdf",
      api: () => EstimateApi.downloadFlowSheet(id, version),
      disabled: !(machineAttachs || []).find((i) =>
        i.fileName.includes(NAME_FILE_EF.flowSheet)
      ),
      idView: getIdViewPdf(NAME_FILE_EF.flowSheet),
    },
    {
      label: "外形図",
      key: "outline",
      disabled: !(machineAttachs || []).find((i) =>
        i.fileName.includes(NAME_FILE_EF.outLine)
      ),
      typeFile: "pdf",
      api: () => EstimateApi.downLoadOutLine(id, version),
      disabled: !(machineAttachs || []).find((i) =>
        i.fileName.includes(NAME_FILE_EF.outLine)
      ),
      idView: getIdViewPdf(NAME_FILE_EF.outLine),
    },
    {
      label: "マテリアルバランスシート",
      key: "BalanceDiagram",
      typeFile: "pdf",
      api: (type) => EstimateApi.download5(id, version, type),
      isEstimate: true,
      reportName: "step5",
      haveExcel: true,
    },
  ];

  // DOWNLOAD FILE CHECKBOX
  const handleDownloadFileCheckbox = async () => {
    try {
      const listPromise = [];

      const listCurrentCheck = listCheckFile
        .filter((i) => listCheckedFile.find((key) => key === i.key))
        .forEach((checkedFile) => {
          listPromise.push(
            downloadFile(
              checkedFile.api("pdf"),
              lang.ATTACH_FILE,
              checkedFile.typeFile
            )
          );
        });

      await Promise.all(listPromise);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  };

  // get version machine
  const getVersionMachineById = (idMachine) => {
    for (let i = 0; i < listAttach.length; i++) {
      const listMachine = listAttach[i].children || [];
      const currentMachine = listMachine.find((i) => i.id == idMachine);
      if (currentMachine) {
        return currentMachine.recordVersion;
      }
    }
    return "";
  };

  // 
  const getType = (type) => type ==="excel" ? "xlsx" :type

  // Download other file
  const handleDownloadOther = async () => {
    try {
      const listPromise = [];
      selectedIds.forEach((id) => {
        const version = getVersionMachineById(id);
        listPromise.push(
          downloadFile(
            EstimateApi.downloadOtherFileById(id, version),
            lang.ATTACH,
            "pdf"
          )
        );
      });

      // Check design
      listSelectTypeFileDesign.forEach((type) =>
        listPromise.push(
          downloadFile(EstimateApi.download4(id, version, getType(type)), "", getType(type))
        )
      );

      // check DC
      listSelectTypeFileDc.forEach((type) =>
        listPromise.push(
          downloadFile(EstimateApi.download3New(id, version, getType(type)), "", getType(type))
        )
      );

      // Check Balan
      listSelectTypeFileBalance.forEach((type) =>
        listPromise.push(
          downloadFile(EstimateApi.download5(id, version, getType(type)), "", getType(type))
        )
      );

      // Check EF modal
      listSelectTypeFileEfModel.forEach((type) =>
        listPromise.push(
          downloadFile(EstimateApi.downloadEF(id, version, getType(type)), "", getType(type))
        )
      );

      await Promise.all(listPromise);
    } catch (error) {
      console.log(error);
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  };

  // View PDF
  const viewFilePdfCheckBox = async (isEstimate, reportName, idView) => {
    try {
      dispatch(openLoading());
      const api = isEstimate
        ? EstimateApi.viewFile(reportName, id, version)
        : ApiMachine.viewFile(idView);
      const res = await api;
      const link = document.createElement("a");
      link.href = res.data;
      link.target = "_blank";
      link.click();
    } catch (err) {
      showMessage(
        KEY.ERROR,
        (err.response && err.response.data.msg) || MSG_CATCH()
      );
    } finally {
      dispatch(closeLoading());
    }
  };
  return (
    <>
      <div onClick={() => setShowActionExport(true)} className={"actionLeft"}>
        <ExportOutlined
          className="mr4"
          style={{ fontWeight: "bold", fontSize: 16 }}
        />
        {lang.EXPORT}
      </div>

      <Drawer
        className="drawEstimate"
        height={550}
        zIndex={1000}
        open={showActionExport}
        title={<strong>{lang.EXPORT}</strong>}
        placement="bottom"
        onClose={() => setShowActionExport(false)}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Button
              // onClick={() => makeDownloadAction()}
              onClick={() => {
                viewFilePdfCheckBox(true, "step3");
              }}
              className="buttonExport"
            >
              概算DC表
            </Button>
          </Col>

          {listCheckFile.map((i) => (
            <Col span={24}>
              <Button
                style={{
                  position: "relative",
                }}
                className="buttonExport"
                disabled={i.disabled}
              >
                <Checkbox
                  disabled={i.disabled}
                  onChange={() => changeListCheckFile(i.key)}
                  key={i.key}
                  checked={
                    listCheckedFile.findIndex((key) => key == i.key) >= 0
                  }
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: 10,
                    transform: "translateY(-50%)",
                  }}
                />{" "}
                <span
                  onClick={() => {
                    viewFilePdfCheckBox(i.isEstimate, i.reportName, i.idView);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {i.label}
                </span>
              </Button>
            </Col>
          ))}

          <Col className="right" span={24}>
            <Button
              onClick={() => handleDownloadFileCheckbox()}
              disabled={listCheckedFile.length === 0}
              className={"buttonPC button--info"}
              icon={<CloudDownloadOutlined />}
              style={{ width: 137, marginLeft: 5, marginBottom: 10 }}
            >
              {lang.DOWNLOAD}
            </Button>
          </Col>
          <Col span={24}>
            <Button onClick={() => setModalOpen(true)} className="buttonExport">
              ファイル一覧
            </Button>
          </Col>
        </Row>
      </Drawer>
      <Modal
        open={modalOpen}
        centered={true}
        width={"90vw"}
        className={isPC ? "" : "machine-content"}
        style={{ top: 20 }}
        onCancel={() => setModalOpen(false)}
        title={<div className="text-center pt15 pr5">ファイル一覧</div>}
        classNames={{ headers: "text-center" }}
        footer={
          <>
            <Button
              className={"buttonPC button--info" + (isPC ? "" : " mb10 mr10")}
              onClick={() => handleDownloadOther()}
              icon={<CloudDownloadOutlined />}
              style={{ width: 137 }}
              disabled={
                isEmpty(selectedIds) &&
                isEmpty(listSelectTypeFileDesign) &&
                isEmpty(listSelectTypeFileBalance) &&
                isEmpty(listSelectTypeFileDc) &&
                isEmpty(listSelectTypeFileEfModel)
              }
            >
              {lang.DOWNLOAD}
            </Button>
          </>
        }
      >
        <Table
          className="table-header-auto"
          columns={attachFileColumns}
          bordered={true}
          pagination={false}
          dataSource={listAttach}
          expandable={{
            defaultExpandAllRows: true,
          }}
        />
      </Modal>
    </>
  );
}

export default ButtonExport;

const ViewButton = ({
  id,
  isEstimate = false,
  reportName,
  version,
  isButtonDefined = false,
  button,
}) => {
  const dispatch = useDispatch();
  const viewFile = async () => {
    try {
      dispatch(openLoading());
      const api = isEstimate
        ? EstimateApi.viewFile(reportName, id, version)
        : ApiMachine.viewFile(id);
      const res = await api;
      const link = document.createElement("a");
      link.href = res.data;
      link.target = "_blank";
      link.click();
    } catch (err) {
      showMessage(
        KEY.ERROR,
        (err.response && err.response.data.msg) || MSG_CATCH()
      );
    } finally {
      dispatch(closeLoading());
    }
  };

  return (
    <span
      onClick={() => {
        viewFile();
      }}
    >
      {isButtonDefined ? (
        button
      ) : (
        <EyeOutlined
          className="ml8"
          style={{ fontSize: 14, color: "#1394df" }}
        />
      )}
    </span>
  );
};

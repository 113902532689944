import {
  DownOutlined,
  RightOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import ApiMachine from "@src/api/backend/machineManagement";
import BoxContent from "@src/component/Desktop/content";
import FooterPage from "@src/component/footer/footerPage";
import { IconNumber } from "@src/components/common/iconNumber.jsx";
import { Button, Col, Collapse, Descriptions, Input, Modal, Row } from "antd";
import { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { HeaderText } from "../../../components/common/headerText";
import { KEY, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import {
  getQuerySearch,
  MSG_CATCH,
  showMessage,
} from "../../../constants/utils";
import {
  caculatorSumMoneyandWeight,
  cloneObj,
  exactMultiplication,
  formatCurrencyJPY,
  getPathEstimateWithStep,
  roundToDecimalPlaces,
  roundToNearestThousand,
} from "../../../utils/helpers";
import { INJECT_NAME } from "../add/step2";
import "./../../Estimate/Estimate.scss";
import "./../../Machine_Management/machine.scss";
import ButtonDelete from "./buttonDelete";
import ButtonEdit from "./buttonEdit";
import ButtonMore from "./buttonMore";
import { useMediaQuery } from "react-responsive";

const { Panel } = Collapse;

function ViewStep3({ basePath, dataDetail }) {
  const data = dataDetail || {};
  const history = useHistory();
  const { id } = useParams();
  const isUpdate = id && history.location.pathname.includes("update");
  const [visible, setVisible] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const dispatch = useDispatch();
  const [showActionExport, setShowActionExport] = useState(false);
  const { isLastest } = getQuerySearch();
  const [estimationAuxMachine, setEstimationAuxMachine] = useState({});
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  // FInal numberOfmachine
  const numberSpareMachine = data ? data.completeSpareMachineQuantity || 0 : 0;
  const currentNumberMachine = data && data.numberOfMachines;
  const finalNumberOfMachine =
    Number(numberSpareMachine) + Number(currentNumberMachine || 0);
  const infoProject = [
    {
      title: LANG.ESTIMATE_NUMBER,
      content: data.estimationNumber || "",
    },
    {
      title: "注文主",
      content: data.customerName,
    },
    {
      title: "納入先(会社)",
      content: data.companyDeliveryDestination,
    },
    {
      title: "納入先(工場)",
      content: data.factoryDeliveryDestination,
    },
    {
      title: "案件名",
      content: data.constructionName,
    },
    {
      title: "商社",
      content: data.tradingCompany,
    },
    {
      title: LANG.CREATED_DATE,
      content: data.createDate || "",
      inlineDisplay: true,
    },
  ];

  const conditionDesign = [
    {
      title: LANG.WATER_TYPE,
      content: data.rawWaterTypeValue + " - " + data.rawWaterDetailTypeValue,
    },
    {
      title: (
        <>
          {LANG.TREATED_WATER_VOLUMN}
          <br />
          (㎥/h)
        </>
      ),

      content: data.estimationConditionInput
        ? Math.round(
            (data.estimationConditionInput.inputRawWaterVolume /
              data.estimationConditionInput.inputOperatingTime) *
              10
          ) / 10
        : "",
    },
    {
      title: data.rawWaterType === "1" ? LANG.IN : LANG.WATER_SS,
      content:
        data.rawWaterType === "1"
          ? data.estimationConditionInput
            ? data.estimationConditionInput.inputRawWaterTurbidity
            : ""
          : data.estimationConditionInput
          ? data.estimationConditionInput.inputRawWaterSsConc
          : "",
    },
    {
      title: data.rawWaterType === "1" ? LANG.OUT : LANG.TREATED_WATER_SS,
      content:
        data.rawWaterType === "1"
          ? data.estimationConditionInput
            ? data.estimationConditionInput.inputTreatedWaterTurbidity
            : ""
          : data.estimationConditionInput
          ? data.estimationConditionInput.inputTreatedWaterSsConc
          : "",
    },
  ];

  const openModalInfoTableCost = (item) => {
    setVisible(true);
    setDataModal(item);
  };

  const closeModalInfoTableCost = () => {
    setVisible(false);
    setDataModal(null);
  };

  const renderItemTableCost = (tableCost, config = {}) => {
    const { isStart, isNotMoney } = config;
    return tableCost.map((info, index) => {
      const showField = info.content.find((i) => i.isShow);
      const text = showField ? showField.content : "-";
      const unit = showField.unit;
      const isSub = info.isSub;

      const title = unit ? (
        <>
          {info.title}
          <br />
          {`(${unit})`}
        </>
      ) : (
        info.title
      );
      return (
        <Descriptions.Item
          span={1}
          key={index}
          label={
            <div
              onClick={() => openModalInfoTableCost(info)}
              style={{
                cursor: "pointer",
                paddingLeft: isSub && (isPC ? 50 : 20),
              }}
            >
              {title}
            </div>
          }
          labelStyle={{
            width: "35%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: "8px",
            whiteSpace: "pre-wrap",
          }}
        >
          <div
            className="flex-center"
            style={{ justifyContent: isStart ? "flex-start" : "flex-end" }}
          >
            <span>{`${formatCurrencyJPY(text || 0)} ${
              isNotMoney ? "" : "(千円)"
            }`}</span>
          </div>
        </Descriptions.Item>
      );
    });
  };

  const infoProjectArr = [
    {
      title: LANG.PROJECT_INFOMATION,
      content: infoProject,
      defaultExpand: true,
    },
  ];
  const conditionArr = [
    {
      title: LANG.DESIGN_CONDITION,
      content: conditionDesign,
    },
  ];

  const renderInfo = (listInfo) =>
    listInfo.map((item, index) => {
      return (
        <Col span={24} className="mt8" key={index}>
          <BoxContent style={{ margin: 0, padding: 0 }}>
            <Collapse
              defaultActiveKey={"1"}
              expandIconPosition="right"
              style={{ padding: 0 }}
            >
              <Panel
                header={
                  <>
                    <div
                      className="flex-center"
                      style={{ justifyContent: "flex-start" }}
                    >
                      {item.icon}
                      {item.title}
                    </div>
                  </>
                }
                key="1"
              >
                <Descriptions size="small" column={1} bordered>
                  {item.content.map((info, index) => {
                    return (
                      <Descriptions.Item
                        span={1}
                        key={index}
                        label={
                          <span onClick={() => setVisible(true)}>
                            {info.title}
                          </span>
                        }
                        labelStyle={{
                          width: "35%",
                          fontWeight: "bold",
                          padding: "8px 8px",
                        }}
                        contentStyle={{
                          padding: !isArray(info.content) ? "8px" : "0px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {!isArray(info.content) &&
                          !info.isInput &&
                          info.content}
                        {!isArray(info.content) && info.isInput && (
                          <Input defaultValue={isUpdate ? info.content : ""} />
                        )}
                        {isArray(info.content) && (
                          <Descriptions
                            key={index}
                            size="small"
                            column={1}
                            bordered
                          >
                            {info.content.map((item, index) => {
                              return (
                                <Descriptions.Item
                                  span={1}
                                  label={item.title}
                                  labelStyle={{
                                    width: "45%",
                                    fontWeight: "semi-bold",
                                  }}
                                >
                                  {!item.isInput && item.content}
                                  {item.isInput && (
                                    <Input
                                      defaultValue={
                                        isUpdate ? item.content : ""
                                      }
                                    />
                                  )}
                                </Descriptions.Item>
                              );
                            })}
                          </Descriptions>
                        )}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
      );
    });
  const renderDesContent = (arr, { isOpenModal = true }) => {
    return arr.content.map((info, index) => {
      const { unit } = info;

      const title = unit ? (
        <>
          {info.title}
          <br />
          {`(${unit})`}
        </>
      ) : (
        info.title
      );
      return (
        <Descriptions.Item
          style={{ display: info.isHide && "none" }}
          span={1}
          key={index}
          label={
            <span onClick={() => (isOpenModal ? setVisible(true) : null)}>
              {title}
            </span>
          }
          labelStyle={{
            width: "35%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: !isArray(info.content) ? "8px" : "0px",
            whiteSpace: "pre-wrap",
          }}
        >
          {!isArray(info.content) &&
          !isNaN(info.content) &&
          info.content !== null &&
          info.content !== ""
            ? formatCurrencyJPY(info.content)
            : info.content}

          {isArray(info.content) && (
            <Descriptions key={index} size="small" column={1} bordered>
              {info.content.map((item, index) => {
                return (
                  <Descriptions.Item
                    span={1}
                    label={item.title}
                    labelStyle={{
                      width: "45%",
                      fontWeight: "semi-bold",
                    }}
                  >
                    {item.content}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          )}
        </Descriptions.Item>
      );
    });
  };

  // GET DATA MACHINE
  useEffect(() => {
    if (data && data.machineSelected) {
      ApiMachine.getOne(data.machineSelected, data.machineVersion)
        .then((res) => {
          if (res.status === 200) {
            const otherMachineData = res.data;

            const pumpData = otherMachineData.filtrationPump;
            const backwashData = otherMachineData.backwashPump;
            const airData = otherMachineData.airWashingBlower;
            const finalEstimationAuxMachine = {};

            // PUMP
            finalEstimationAuxMachine.auxFiltrationPumpModel =
              pumpData.filtrationPumpModel;
            finalEstimationAuxMachine.auxFiltrationPumpCapacity =
              pumpData.filtrationPumpCapacity;
            finalEstimationAuxMachine.auxFiltrationPumpWeight =
              pumpData.filtrationPumpWeight;
            finalEstimationAuxMachine.auxFiltrationPumpUnitPrice =
              pumpData.filtrationPumpUnitPrice;

            // Washing
            finalEstimationAuxMachine.auxBackwashPumpModel =
              backwashData.backwashPumpModel;
            finalEstimationAuxMachine.auxBackwashPumpCapacity =
              backwashData.backwashPumpCapacity;
            finalEstimationAuxMachine.auxBackwashPumpWeight =
              backwashData.backwashPumpWeight;
            finalEstimationAuxMachine.auxBackwashPumpUnitPrice =
              backwashData.backwashPumpUnitPrice;

            // AIR
            finalEstimationAuxMachine.auxAirWashingBlowerModel =
              airData.airWashingBlowerModel;
            finalEstimationAuxMachine.auxAirWashingBlowerCapacity =
              airData.airWashingBlowerCapacity;
            finalEstimationAuxMachine.auxAirWashingBlowerWeight =
              airData.airWashingBlowerWeight;
            finalEstimationAuxMachine.auxAirWashingBlowerUnitPrice =
              airData.airWashingBlowerPrice;

            // SndprfBoxModel

            finalEstimationAuxMachine.auxAirWashingBlowerSndprfBoxModel =
              airData.airWashingBlowerSndprfBoxModel;
            // finalEstimationAuxMachine.auxAirWashingBlowerCapacity =
            //   airData.airWashingBlowerCapacity;
            finalEstimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight =
              airData.airWashingBlowerSndprfBoxWeight;
            finalEstimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice =
              airData.airWashingBlowerSndprfBoxPrice;
            setEstimationAuxMachine(finalEstimationAuxMachine);
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
    }
  }, [data]);
  // List table Cost
  const tableCost1 = [
    {
      title: "イーグルファイバー",
      content: [
        {
          title: "型式",
          content: data.machineSelected || "",
        },
        {
          title: "仕様",
          content: data.eagleFiberTowerDimension || "",
        },
        {
          title: "数量(基)",
          content: finalNumberOfMachine || "",
        },
        {
          title: "単位質量(kg)",
          content: data.eagleFiberProductWeight || "",
        },
        {
          title: "単価(千円)",
          content: data.eagleFiberUnitPrice || "",
        },
        {
          title: "質量(kg)",
          content: data
            ? Number(data.eagleFiberProductWeight) *
              Number(finalNumberOfMachine)
            : "",
          key: "weight",
        },
        {
          title: "金額(千円)",
          content: data ? data.eagleFiberUnitPrice * finalNumberOfMachine : "",
          isShow: true,
          key: "money",
        },
      ],
    },

    {
      title: "計装作業",
      content: [
        {
          title: "数量(式)",
          content: finalNumberOfMachine || "",
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.instrumentationWorkFee,
          isEdit: true,
        },

        {
          title: "金額(千円",
          content:
            data.estimationConst &&
            data.estimationConst.instrumentationWorkFee * finalNumberOfMachine,
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.instrumentationWorkFeeComment,
        },
      ],
    },
    {
      title: "運搬費",
      content: [
        {
          title: "数量(式)",
          content: finalNumberOfMachine || "",
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.transportationFee,
          isEdit: true,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            data.estimationConst.transportationFee * finalNumberOfMachine,
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.transportationFeeComment,
          key: KEY.REMARK,
        },
      ],
    },
    {
      title: "試運転調整費・単価",
      content: [
        {
          title: "数量(日)",
          content: data ? 2 + (finalNumberOfMachine - 1) * 1 : "",
        },

        {
          isEdit: true,
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.trialRunAdjustmentFee,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? (2 + (finalNumberOfMachine - 1) * 1) *
              data.estimationConst.trialRunAdjustmentFee
            : "",
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.trialRunAdjustmentFeeComment,
        },
      ],
    },
    {
      title: "立会対応費",
      content: [
        {
          title: "数量(式)",
          content: finalNumberOfMachine || "",
        },

        {
          isEdit: true,
          title: "単価(千円)",
          content: data.estimationConst
            ? data.estimationConst.attendanceFee
            : "",
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? data.estimationConst.attendanceFee * finalNumberOfMachine
            : "",
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.attendanceFeeComment,
        },
      ],
    },
    {
      title: "設計図書作成費",
      content: [
        {
          title: "数量(式)",
          content: finalNumberOfMachine || "",
        },

        {
          isEdit: true,
          title: "単価(千円)",
          content: data.estimationConst
            ? data.estimationConst.designDocumentPreparationFee
            : "",
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? data.estimationConst.designDocumentPreparationFee *
              finalNumberOfMachine
            : "",
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.designDocumentPreparationFeeComment,
        },
      ],
    },
  ];
  const options =
    data && data.options && data.options.length ? data.options : [];
  const itemOptionOftableCost1Array = {
    title: LANG.OPTION,
    content: [],
  };
  /// chia 1000 vi don vị option là yên, còn đơn vị ở đây là nghìn yên

  options.forEach((itemOption) => {
    const value = itemOption.value ? Number(itemOption.value) : 0;
    itemOptionOftableCost1Array.content.push({
      title: (
        <>
          {" "}
          {itemOption.optionName} <br /> {"(千円)"}
        </>
      ),
      content: value,
    });
    // ADD SUB
    tableCost1.push({
      title: itemOption.optionName,
      isSub: true,

      content: [
        {
          title: itemOption.optionName,
          content: exactMultiplication(value, finalNumberOfMachine),
          isShow: true,
          isNotPlus: true,
        },
      ],
    });
  });

  const moneyOption = itemOptionOftableCost1Array.content.reduce(
    (money, item) => {
      return money + item.content;
    },
    0
  );

  itemOptionOftableCost1Array.content.push({
    title: "金額(千円)",
    content: exactMultiplication(moneyOption, finalNumberOfMachine),
    isShow: true,
    key: "money",
  });
  itemOptionOftableCost1Array.content.push({
    title: lang.REMARK,
    content: data.estimationConst && data.estimationConst.bodyOptionsComment,
  });
  tableCost1.splice(6, 0, itemOptionOftableCost1Array);
  const tableCost2 = [
    {
      title: "ろ過ポンプ",
      content: [
        {
          title: "型式",
          content:
            estimationAuxMachine && estimationAuxMachine.auxFiltrationPumpModel,
        },
        {
          title: "仕様",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxFiltrationPumpCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            Number(data.estimationAuxMachine.auxFiltrationPumpRegularNumber) +
              Number(data.estimationAuxMachine.auxFiltrationPumpBackupMachine),
        },
        {
          title: "単位質量(kg)",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxFiltrationPumpWeight,
        },
        {
          title: "単価(千円)",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxFiltrationPumpUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                estimationAuxMachine.auxFiltrationPumpWeight,
                Number(
                  data.estimationAuxMachine.auxFiltrationPumpRegularNumber
                ) +
                  Number(
                    data.estimationAuxMachine.auxFiltrationPumpBackupMachine
                  )
              )
            ),
          key: "weight",
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              estimationAuxMachine.auxFiltrationPumpUnitPrice,
              Number(data.estimationAuxMachine.auxFiltrationPumpRegularNumber) +
                Number(data.estimationAuxMachine.auxFiltrationPumpBackupMachine)
            ),
          isShow: true,
          key: "money",
        },
      ],
    },
    {
      title: "逆洗ポンプ",
      content: [
        {
          title: "型式",
          content:
            estimationAuxMachine && estimationAuxMachine.auxBackwashPumpModel,
        },
        {
          title: "仕様",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxBackwashPumpCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            Number(data.estimationAuxMachine.auxBackwashPumpRegularNumber) +
              Number(data.estimationAuxMachine.auxBackwashPumpBackupMachine),
        },
        {
          title: "単位質量(kg)",
          content:
            estimationAuxMachine && estimationAuxMachine.auxBackwashPumpWeight,
        },
        {
          title: "単価(千円)",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxBackwashPumpUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                estimationAuxMachine.auxBackwashPumpWeight,
                Number(data.estimationAuxMachine.auxBackwashPumpRegularNumber) +
                  Number(data.estimationAuxMachine.auxBackwashPumpBackupMachine)
              )
            ),
          key: "weight",
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              estimationAuxMachine.auxBackwashPumpUnitPrice,
              Number(data.estimationAuxMachine.auxBackwashPumpRegularNumber) +
                Number(data.estimationAuxMachine.auxBackwashPumpBackupMachine)
            ),
          isShow: true,
          key: "money",
        },
      ],
    },
    {
      title: "空洗ブロワ",
      content: [
        {
          title: "型式",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxAirWashingBlowerModel,
        },
        {
          title: "仕様",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxAirWashingBlowerCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            Number(data.estimationAuxMachine.auxAirWashingBlowerRegularNumber) +
              Number(
                data.estimationAuxMachine.auxAirWashingBlowerBackupMachine
              ),
        },
        {
          title: "単位質量(kg)",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxAirWashingBlowerWeight,
        },
        {
          title: "単価(千円)",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxAirWashingBlowerUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                estimationAuxMachine.auxAirWashingBlowerWeight,
                Number(
                  data.estimationAuxMachine.auxAirWashingBlowerRegularNumber
                ) +
                  Number(
                    data.estimationAuxMachine.auxAirWashingBlowerBackupMachine
                  )
              )
            ),
          key: "weight",
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              estimationAuxMachine.auxAirWashingBlowerUnitPrice,
              Number(
                data.estimationAuxMachine.auxAirWashingBlowerRegularNumber
              ) +
                Number(
                  data.estimationAuxMachine.auxAirWashingBlowerBackupMachine
                )
            ),
          isShow: true,
          key: "money",
        },
      ],
    },
    {
      title: "空洗ブロワ用防音ボックス",
      content: [
        {
          title: "型式",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxAirWashingBlowerSndprfBoxModel,
        },

        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            Number(data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxRegularNumber) +
              Number(
                data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxBackupMachine
              ),
        },
        {
          title: "単位質量(kg)",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight,
        },
        {
          title: "単価(千円)",
          content:
            estimationAuxMachine &&
            estimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                estimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight,
                Number(
                  data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxRegularNumber
                ) +
                  Number(
                    data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxBackupMachine
                  )
              )
            ),
          key: "weight",
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              estimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice,
              Number(
                data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxRegularNumber
              ) +
                Number(
                  data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxBackupMachine
                )
            ),
          isShow: true,
          key: "money",
        },
      ],
    },
  ];

  const tableCost3 = [];
  // getInfocapacity
  const getInfoCapacity = (injection) => {
    if (!data) return null;
    const dataCapacity = [
      data.chemicalTankPac,
      data.chemicalTankNaclo,
      data.chemicalTankH2so4,
    ];
    if (!injection.checked) {
      return null;
    }
    if (!dataCapacity) {
      return null;
    }
    const itemCapa = dataCapacity.find(
      (i) => i && i.injectID == injection.injectionPumpId
    );
    return itemCapa;
  };
  // Merge Injection Pupm
  let injectionPumps = cloneObj(
    data && data.injectionPumps ? data.injectionPumps : []
  );

  injectionPumps.forEach((injection, index) => {
    const infoCapacity = getInfoCapacity(injection);
    const priceCapa = infoCapacity ? infoCapacity.price : 0;
    const item = {
      title: INJECT_NAME[index],
      content: [
        // Info - TK
        {
          title: "薬品タンク",
          isTitle: true,
        },

        {
          title: "数量",
          content: infoCapacity && 1,
        },
        {
          title: "型式",
          content: infoCapacity && infoCapacity.name,
        },
        {
          title: "タンク容量(L)",
          content: infoCapacity && infoCapacity.capacity,
        },
        {
          title: "重量(kg)",
          content: infoCapacity && infoCapacity.weight,
        },
        {
          title: "金額(千円/基)",
          content: infoCapacity && infoCapacity.price,
        },
        {
          title: "材質",
          content: infoCapacity && infoCapacity.material,
        },
        {
          title: "単価根拠",
          content: infoCapacity && infoCapacity.priceBasis,
        },
        {
          title: "備考",
          content: infoCapacity && infoCapacity.note,
        },

        // Info - Inject
        {
          title: "注入ポンプ",
          isTitle: true,
        },
        {
          title: "数量",
          content: injection.checked ? data && finalNumberOfMachine : 0,
        },

        {
          title: "重量(kg)",
          content: injection.weight,
          key: "weight",
        },
        {
          title: "金額(千円/基)",
          content: injection.price,
        },
        {
          title: "金額(千円/基)",
          content: injection.checked
            ? exactMultiplication(
                data && finalNumberOfMachine,
                injection.price
              ) + priceCapa
            : 0,
          isShow: true,
          key: "money",
          isHide: true,
        },
        {
          title: "単価根拠",
          content: injection.priceBasis,
        },
        {
          title: "備考",
          content: injection.note,
        },
      ],
    };
    tableCost3.push(item);
  });
  // Table 4
  const tableCost4 = [
    {
      title: "制御盤",
      content: [
        {
          title: "数量(式)",
          content:
            data.estimationConst && data.estimationConst.controlPanelNumber,
          isEdit: true,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.controlPanelPrice,
          isEdit: true,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              data.estimationConst.controlPanelPrice,
              data.estimationConst.controlPanelNumber
            ),
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.controlPanelPriceComment,
        },
      ],
    },
  ];

  // Merge water device
  let waterDevices = cloneObj(data.waterDevices || []);

  const itemWaterDeviceTableCost4 = {
    title: "水質測定装置",
    content: [],
  };
  waterDevices.forEach((device) => {
    itemWaterDeviceTableCost4.content.push({
      title: device.amountTable,
      content: device.unitPrice,
    });
    //ADD SUB
    tableCost4.push({
      title: device.amountTable,
      isSub: true,

      content: [
        {
          title: device.amountTable,
          content: device.unitPrice,
          isShow: true,
          isNotPlus: true,
        },
      ],
    });
  });

  const moneyWaterDevice = itemWaterDeviceTableCost4.content.reduce(
    (money, item) => {
      return money + item.content;
    },
    0
  );

  itemWaterDeviceTableCost4.content.push({
    title: "金額(千円)",
    content: moneyWaterDevice,
    isShow: true,
    key: "money",
  });
  tableCost4.splice(1, 0, itemWaterDeviceTableCost4);
  const tableCost5 = [
    {
      title: "試運転SV費",
      content: [
        {
          title: "数量(日)",
          content: data ? 2 + (finalNumberOfMachine - 1) * 1 : "",
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.commissioningSvFees,
          isEdit: true,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              2 + (finalNumberOfMachine - 1) * 1,
              data.estimationConst.commissioningSvFees
            ),
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.commissioningSvFeeComment,
        },
      ],
    },
    {
      title: "設計費",
      content: [
        {
          title: "数量(式)",
          content: data.estimationConst && data.estimationConst.designNumber,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst && data.estimationConst.designFee,
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.designFeeComment,
        },
      ],
    },
    {
      title: "図書作成費",
      content: [
        {
          title: "数量(式)",
          content:
            data.estimationConst && data.estimationConst.bookCreationNumber,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst && data.estimationConst.bookCreationFee,
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.bookCreationFeeComment,
        },
      ],
    },
  ];
  ///Equipment cost
  const equipmentCost = caculatorSumMoneyandWeight([
    tableCost1,
    tableCost2,
    tableCost3,
    tableCost4,
  ]);

  // Attach cost
  const attachCost = caculatorSumMoneyandWeight([
    tableCost2,
    tableCost3,
    tableCost4,
  ]);
  // Tổng phụ kiện kèm theo
  const attachEquimentCost = [
    {
      title: "付属機器小計",
      content: [
        {
          title: "質量(kg)",
          content: attachCost.weight,
        },
        {
          title: "金額(千円)",
          content: attachCost.money,
          isShow: true,
        },
      ],
    },
  ];

  // tổng chi phí thiết bị
  const machineBill = [
    {
      title: "機器費計",
      content: [
        {
          title: "質量(kg)",
          content: equipmentCost.weight,
        },
        {
          title: "金額(千円)",
          content: equipmentCost.money,
          isShow: true,
        },
      ],
    },
  ];

  const totalCostAndWeight = caculatorSumMoneyandWeight([tableCost5]);
  const totalCost = totalCostAndWeight.money + equipmentCost.money;
  const total = [
    {
      title: "計",
      content: [
        {
          title: "金額(千円)",
          content: totalCost,
          isShow: true,
        },
      ],
    },
  ];
  const reverseFund = [
    {
      title: "予備費",
      content: [
        {
          title: "料率 (%)",
          content: data.estimationConst && data.estimationConst.reserveFund,
          isEdit: true,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round((data.estimationConst.reserveFund * totalCost) / 100),
          isShow: true,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.reserveFundComment,
        },
      ],
    },
  ];

  const dcCost =
    data.estimationConst &&
    Math.round(
      exactMultiplication(
        Number(data.estimationConst.reserveFund) + 100,
        totalCost
      ) / 100
    );
  // DC
  const dc = [
    {
      title: "DC計",
      content: [
        {
          title: "金額(千円)",
          content: dcCost,
          isShow: true,
          key: "money",
        },
      ],
    },
  ];

  // Profit and Lost per
  const profitAndLossper = data.estimationConst
    ? Number(data.estimationConst.profitLossPercent)
    : 0;
  // Chi phí quản lí
  const tableCost6 = [
    {
      title: "工事管理費",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst &&
            data.estimationConst.constructionManagementPercent,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round(
              (data.estimationConst.constructionManagementPercent * dcCost) /
                100
            ),
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.constructionManagementPercentComment,
        },
      ],
    },
    {
      title: "販売管理費",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst &&
            data.estimationConst.generalAdministrativePercent,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round(
              (data.estimationConst.generalAdministrativePercent * dcCost) / 100
            ),
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.generalAdministrativePercentComment,
        },
      ],
    },
    {
      title: "手数料",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst && data.estimationConst.commissionPercent,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round(
              (data.estimationConst.commissionPercent * getSellingPrice()) / 100
            ),
          isShow: true,
          key: "money",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.commissionPercentComment,
        },
      ],
    },
  ];

  // Cost of sale
  let costOfSale = caculatorSumMoneyandWeight([dc, tableCost6]);
  costOfSale.money = Math.round(costOfSale.money);
  const costOfSaleTable = [
    {
      title: "販売原価",
      content: [
        {
          title: "金額(千円)",
          content: Math.round(costOfSale.money),
          isShow: true,
          key: "money",
        },
      ],
    },
  ];

  const sellingPrice = getSellingPrice();

  // Prodit and lose
  const profitAndLoss = [
    {
      title: "損益 (P)",
      content: [
        {
          title: "損益率 (%)",
          content: profitAndLossper,
          isEdit: true,
        },

        {
          title: "金額(千円)",
          content: Math.round(
            exactMultiplication(sellingPrice, profitAndLossper) / 100
          ),

          isShow: true,
          fullText:
            "損益率 : " +
            profitAndLossper +
            "(%)" +
            "   金額 : " +
            formatCurrencyJPY(
              Math.round(
                exactMultiplication(sellingPrice, profitAndLossper) / 100
              )
            ) +
            "(千円)",
        },
      ],
    },
  ];

  // Price sell Table
  const priceSellTable = [
    {
      title: "販売価格",
      content: [
        {
          title: "金額(千円)",
          content: sellingPrice,
          isShow: true,
        },
      ],
    },
  ];

  // Render Table Cost Collapse
  const rederCollapsetableCost = (
    arrData,
    num = 1,
    title = "",
    Icon,
    config = {}
  ) => {
    const isSingle = arrData.length === 1;
    const textTitle = title || arrData[0].title;
    const { isOpen, isNotMoney, isStart } = config;
    const sigleEdit = isSingle;
    let fullText;
    const isNoICon = num == null && !Icon;

    const price = arrData.reduce((result, item) => {
      const showEl = item.content.find((i) => i.isShow && !i.isNotPlus);
      if (showEl && showEl.fullText) {
        fullText = showEl.fullText;
      }
      const number = showEl ? showEl.content : 0;
      return result + number;
    }, 0);
    return (
      <Row gutter={0}>
        <Col span={24} className="mt8">
          <div style={{ margin: 0, padding: 0 }}>
            <Collapse
              className={isNotMoney ? "" : "collapseCost"}
              collapsible={arrData.length === 1 && "icon"}
              expandIconPosition={!isNotMoney ? "left" : "right"}
              defaultActiveKey={["1"]}
              style={{ padding: 0 }}
              expandIcon={
                !isNotMoney
                  ? ({ isActive }) =>
                      arrData.length === 1 ? null : isActive ? (
                        <DownOutlined
                          style={{ left: 5, marginTop: sigleEdit ? 4 : 0 }}
                        />
                      ) : (
                        <RightOutlined
                          style={{ left: 5, marginTop: sigleEdit ? 4 : 0 }}
                        />
                      )
                  : null
              }
            >
              <Panel
                header={
                  <>
                    <div
                      onClick={
                        isSingle
                          ? () => openModalInfoTableCost(arrData[0])
                          : null
                      }
                      className="flex-center"
                      style={{
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: isNoICon ? 8 : 0,
                        }}
                        className="flex-start"
                      >
                        {!isNoICon ? (
                          Icon ? (
                            <Icon
                              style={{
                                fontSize: 20,
                                color: "#0080cb",
                                marginRight: 6,
                                marginLeft: isSingle ? 8 : 0,
                              }}
                            />
                          ) : (
                            <IconNumber
                              marginLeft={isSingle ? 8 : 0}
                              size={20}
                              number={num}
                            />
                          )
                        ) : (
                          ""
                        )}
                        <span>{textTitle}</span>
                      </div>
                      <div
                        className="flex-start"
                        style={{
                          justifyContent: isStart ? "flex-start" : "flex-end",
                        }}
                      >
                        {!isNotMoney &&
                          (fullText ? (
                            <span>{fullText}</span>
                          ) : (
                            <span>{`${
                              !isSingle ? "金額 : " : ""
                            }${formatCurrencyJPY(price || 0)} (千円) `}</span>
                          ))}
                      </div>
                    </div>
                  </>
                }
                key={isOpen ? "1" : 0}
              >
                {arrData.length > 1 && (
                  <Descriptions size="small" column={1} bordered>
                    {renderItemTableCost(arrData, { isStart, isNotMoney })}
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
          </div>
        </Col>
      </Row>
    );
  };

  // Get Selling pirice
  function getSellingPrice() {
    if (!data || !data.estimationConst) {
      return 0;
    }

    // F56
    const constructionManagementPercent =
      data.estimationConst.constructionManagementPercent;
    // F57
    const generalAdministrativePercent =
      data.estimationConst.generalAdministrativePercent;
    // F58
    const commissionPercent = data.estimationConst.commissionPercent;
    //=ROUNDUP((U54*(1+F56+F57)/(1-F60-F58)),-3)

    // U54 :DC cost
    // F60 : Profitand Lose

    const finaPrice =
      exactMultiplication(
        dcCost *
          (1 +
            constructionManagementPercent / 100 +
            generalAdministrativePercent / 100)
      ) /
      (1 - profitAndLossper / 100 - commissionPercent / 100);
    return roundToDecimalPlaces(finaPrice, -3);
  }

  // render modal info
  const renderDataModalInfo = () => {
    const listIndexTitle = [];
    const listInfoItem = [];
    if (!dataModal || (dataModal && !Array.isArray(dataModal.content)))
      return null;
    const content = dataModal.content;
    content.forEach((item, index) => {
      if (item.isTitle) {
        listIndexTitle.push(index);
      }
    });
    if (listIndexTitle.length === 0) {
      return (
        <Descriptions size="small" column={1} bordered>
          {renderDesContent(dataModal, { isOpenModal: false })}
        </Descriptions>
      );
    }

    // render Title
    for (let i = 0; i < listIndexTitle.length; i++) {
      const indexTitile = listIndexTitle[i];
      if (i < listIndexTitle.length - 1) {
        const indexTitileNext = listIndexTitle[i + 1];
        listInfoItem.push(content[indexTitile]);
        listInfoItem.push({
          content: content.slice(indexTitile + 1, indexTitileNext),
        });
      } else {
        listInfoItem.push(content[indexTitile]);
        listInfoItem.push({
          content: content.slice(indexTitile + 1),
        });
      }
    }
    return listInfoItem.map((item) => {
      if (item.isTitle) {
        return (
          <div
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBlock: 5,
            }}
          >
            {item.title}
          </div>
        );
      } else {
        return (
          <Descriptions size="small" column={1} bordered>
            {renderDesContent(item, { isOpenModal: false })}
          </Descriptions>
        );
      }
    });
  };

  return (
    <>
      <div
        style={{ marginTop: -20 }}
        className="des-content machine-content step3"
      >
        <HeaderText text={LANG.PROJECT_INFOMATION} />
        <Row gutter={0}>{renderInfo(infoProjectArr)}</Row>
        <HeaderText text={LANG.CONDITION_INPUT} />
        <Row gutter={0}>{renderInfo(conditionArr)}</Row>
        <HeaderText text={LANG.TABLE_COSST} />
        <div className="wrapTableCost">
          {rederCollapsetableCost(tableCost1, 1, "本体機器小計")}
          {rederCollapsetableCost(tableCost2, 2, "補機小計")}

          {rederCollapsetableCost(tableCost3, 3, "薬品注入装置")}

          {rederCollapsetableCost(tableCost4, 4, "電気計装関係")}

          {rederCollapsetableCost(
            attachEquimentCost,
            null,
            "付属機器小計",
            null
          )}
          {rederCollapsetableCost(machineBill, null, "機器費計", null)}

          {rederCollapsetableCost(tableCost5, 5, "人件費関係")}
          {rederCollapsetableCost(total, null, "計", null)}
          {rederCollapsetableCost(reverseFund, null, "予備費", null)}
          {rederCollapsetableCost(dc, null, "DC計", null)}
          {rederCollapsetableCost(tableCost6, 6, "管理費")}
          {rederCollapsetableCost(costOfSaleTable, null, "販売原価", null)}
          {rederCollapsetableCost(profitAndLoss, null, "損益 (P)", null)}
          {rederCollapsetableCost(priceSellTable, null, "販売価格", null)}
        </div>

        <div style={{ height: "100px" }}></div>
      </div>
      <Modal
        style={{ padding: 0 }}
        centered
        closeIcon={<></>}
        className=""
        title={dataModal ? dataModal.title : null}
        open={visible && dataModal}
        onCancel={() => closeModalInfoTableCost()}
        footer={
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                className="buttonPC --todo-- wAuto  button--outline"
                onClick={() => closeModalInfoTableCost()}
              >
                {lang.CLOSE}
              </Button>
            </Col>
          </Row>
        }
      >
        {renderDataModalInfo()}
        {/* <Descriptions size="small" column={1} bordered>
           {renderDataModalInfo()}
          {dataModal && renderDesContent(dataModal, { isOpenModal: false })}
        </Descriptions> */}
      </Modal>

      <FooterPage>
        <Row>
          <Col span={12}>
            <div
              className={"actionRight"}
              onClick={() => history.push(getPathEstimateWithStep(basePath, 2))}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>

          <Col span={12}>
            <ButtonMore
              machineSelected={data ? data.machineSelected : ""}
              isDraf={data && data.status === "0"}
            >
              <Col span={24}>
                <ButtonEdit status={data && data.status} />
              </Col>
              {isLastest && (
                <Col span={24}>
                  <ButtonEdit revup status={data && data.status} />
                </Col>
              )}
              <Col span={24}>
                <ButtonDelete />
              </Col>
            </ButtonMore>
          </Col>
        </Row>
      </FooterPage>
    </>
  );
}

export default ViewStep3;

import { useHistory, useParams, useLocation } from "react-router-dom";
import { QuestionToolTip } from "./air";
import React, { useEffect, useState } from "react";
import BoxContent from "@src/component/Desktop/content";

import {
    EditOutlined,
    RollbackOutlined,
    QuestionCircleFilled,
    SaveOutlined,
    CloudDownloadOutlined,
  } from "@ant-design/icons";
  import { Wind } from "iconsax-react";
  import {
    Input,
    Col,
    Row,
    Descriptions,
    Collapse,
    Typography,
    Modal,
    Select,
    Tooltip,
  } from "antd";
import { isArray, isEmpty } from "lodash";
import Loading from "../../component/Desktop/loading/loading";
import { preciseMultiply } from "../../utils/helpers";
import { UploadFileSpace } from "./view";
const { Panel } = Collapse;
const { Text } = Typography;
export const DetailMachine = ({
    id: idProp,
    viewMode = false,
    machineData,
    setMachineData,
    soundProofBox = [],
    airWashingBlower = [],
    electricMotor = [],
    version,
  }) => {
    const history = useHistory();
    let { id } = useParams();
    id = idProp || id;
    const isUpdate =
      !viewMode && id && history.location.pathname.includes("update");
    const isView =
      viewMode || (id && !history.location.pathname.includes("update"));
    const isCreate =
      !viewMode && !id && history.location.pathname.includes("create");
  
    const [showLoading, setShowLoading] = useState(false);
  
    const machine_information_display = [
        {
            key: "soundProofBoxModel",
            title: "型式",
            content: machineData.soundProofBoxModel,
            isSelect: true,
            options: soundProofBox.map((item) => {
              return {
                value: item.model,
                label: item.model,
              };
            }),
            isRequired: true,
          },
          {
            title: "重量(kg)",
            content: machineData.soundProofBox
              ? machineData.soundProofBox.weight
              : 0,
            auto: true,
          },
          {
            title: "仕切",
            content: machineData.soundProofBox
              ? machineData.soundProofBox.partition
              : 0,
            auto: true,
          },
          {
            title: "基準単価(千円)",
            content: machineData.soundProofBox
              ? machineData.soundProofBox.price
              : 0,
            auto: true,
          },
          {
            title: (
              <>
                <QuestionToolTip title={"仕切 x 基準単価"} /> 価格(千円)
              </>
            ),
            content: (
              <>
                {machineData.soundProofBox
                  ? Math.ceil(
                    preciseMultiply(+machineData.soundProofBox.price ,
                        +machineData.soundProofBox.partition)
                    )
                  : 0}
              </>
            ),
            auto: true,
          },
      
      
    ];
  
    useEffect(() => {
      if (isEmpty(electricMotor) || !machineData.motorPower) return;
      const selected = electricMotor.find(
        (item) => item.electricMotorPower === machineData.motorPower
      );
      const newMachineData = { ...machineData };
      newMachineData.electricMotorPrice = selected;
      setMachineData(newMachineData);
    }, [machineData.motorPower, electricMotor]);
  
    useEffect(() => {
      if (isEmpty(airWashingBlower) || !machineData.model) return;
      const selected = airWashingBlower.find(
        (item) => item.model === machineData.model
      );
      const newMachineData = { ...machineData };
      newMachineData.airWashingBlowerPrice = selected;
      setMachineData(newMachineData);
    }, [machineData.model, airWashingBlower]);
  
    useEffect(() => {
      if (isEmpty(soundProofBox) || !machineData.soundProofBoxModel) return;
      const selected = soundProofBox.find(
        (item) => item.model === machineData.soundProofBoxModel
      );
      const newMachineData = { ...machineData };
      newMachineData.soundProofBox = selected;
      newMachineData.soundProofBoxId = selected.id;
      setMachineData(newMachineData);
    }, [machineData.soundProofBoxModel, soundProofBox]);
  
    const onChangeInput = (e, info) => {
      let { value } = e.target;
      const newmMchineData = { ...machineData };
  
      // remove character not numeric when inputType is number
      if (info.inputType === "number") {
        value = value.replace(/[^0-9.]/g, "");
        if (value.split(".").length > 2) {
          value = value.substring(0, value.length - 1);
        }
      }
  
      newmMchineData[info.key] = value;
      setMachineData(newmMchineData);
    };
  
    const onChangeSelect = (value, info) => {
      const newmMchineData = { ...machineData };
      newmMchineData[info.key] = value;
      setMachineData(newmMchineData);
    };
  
    const Content = (
      <Descriptions size="small" column={1} bordered>
        {machine_information_display.map((info, index) => {
          return (
            <Descriptions.Item
              span={1}
              key={index}
              label={
                <>
                  {info.title}{" "}
                  {info.isRequired && !isView && (
                    <span className="mark-required">*</span>
                  )}
                </>
              }
              labelStyle={{
                width: "28%",
                fontWeight: "bold",
                padding: "8px 8px",
              }}
              contentStyle={{
                padding: !isArray(info.content) ? "8px" : "0px",
                whiteSpace: "pre-wrap",
              }}
            >
              {!isArray(info.content) && isView && (info.content || "-")}
              {!isArray(info.content) && !isView && (
                <>
                  {!info.isSelect && !info.auto && (
                    <Input
                      // type={info.inputType || 'text'}
                      value={info.content}
                      onChange={(e) => {
                        onChangeInput(e, info);
                      }}
                    />
                  )}
                  {info.isSelect && (
                    <Select
                      className="w100"
                      options={info.options}
                      value={info.content}
                      onChange={(e) => onChangeSelect(e, info)}
                      // type={info.inputType || 'text'}
                    />
                  )}
                  {info.auto && <Text>{info.content}</Text>}
                </>
              )}
              {isArray(info.content) && (
                <Descriptions key={index + "1"} size="small" column={1} bordered>
                  {info.content.map((info, index) => {
                    return (
                      <Descriptions.Item
                        key={index}
                        span={1}
                        label={
                          <>
                            {info.title}{" "}
                            {info.isRequired && !isView && (
                              <span className="mark-required">*</span>
                            )}
                          </>
                        }
                        labelStyle={{
                          width: "45%",
                          fontWeight: "semi-bold",
                        }}
                      >
                        {isView && (info.content || "-")}
                        {!isView && (
                          <>
                            {!info.isSelect && !info.auto && (
                              <Input
                                value={info.content}
                                onChange={(e) => {
                                  onChangeInput(e, info);
                                }}
                              />
                            )}
                            {info.isSelect && (
                              <Select
                                className="w100"
                                options={info.options}
                                value={info.content}
                                onChange={(e) => onChangeSelect(e, info)}
                              />
                            )}
                            {info.auto && <Text>{info.content}</Text>}
                          </>
                        )}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              )}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    );
  
    const [listAttachMachine, setListAttachMachine] = useState([]);
    if (viewMode) {
      return (
        <>
          <Loading show={showLoading} />
          {Content}
          {/* <div className="mt8 machine-content">
            <UploadFileSpace
              listAttachMachine={listAttachMachine}
              setListAttachMachine={setListAttachMachine}
              isView={isView}
              setShowLoading={() => {}}
              type={3}
              version={version || 0}
              id={machineData.modelNumber}
              onlyTable={true}
            />
          </div> */}
        </>
      );
    }
  
    return (
      <>
        <Loading show={showLoading} />
        <BoxContent style={{ margin: 0, padding: 0 }}>
          <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
            <Panel
              header={
                <>
                  空洗ブロワ機種表
                </>
              }
              key="1"
            >
              {Content}
            </Panel>
          </Collapse>
        </BoxContent>
      </>
    );
  };
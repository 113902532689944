/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import PopupAllUser from "./popupAllUser";
// import { ReactComponent as ListIcon } from "@src/styles/icon/list.svg";
import { useMediaQuery } from "react-responsive";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../constants/utils";

import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";

import {
  ClearOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Avatar, List } from "antd";
import { Record, User, UserOctagon } from "iconsax-react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserMangementApi } from "../../api/backend/userManagement";
import classPopup from "../../models/control/popup";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import { deboundClickModal } from "../../utils/helpers";
import "./inspection.scss";
import "./list.scss";
import PopupSelectRole from "./popupSelectRole";

const { Content } = Layout;
const { Panel } = Collapse;

function Role_list() {
  const history = useHistory();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const openLoadingSpinner = () => {
    setShowLoading(true);
  };
  const closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();
  const { roleKey, userName } = useSelector((state) => state.auth);
  const isAdmin = roleKey === RoleHeadOffice.ADMIN;
  //  Grid
  const [initPage, setInitPage] = useState(true);
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  const [txtUser, settxtUser] = useState(new classText());
  // POPUP
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [popupUser, setPopupUser] = useState(new classPopup());
  const [form] = Form.useForm();

  const [_listUser, set_listUser] = useState([]);
  const [_nameUser, set_nameUser] = useState(new classText());
  const [loadSpin, setLoadSpin] = useState(true);

  // Pop up select role
  const [roleSelect, setRoleSelect] = useState("");
  const [popupRole, setPopupRole] = useState(new classPopup());
  // List with title

  const listUserWithTitle = useMemo(() => {
    const dataAdmin = grid.data.filter((item) =>
      item.roles.find((i) => i.roleValue === "1")
    );
    const dataUser = grid.data.filter((item) =>
      item.roles.find((i) => i.roleValue === "3")
    );
    const dataNoRole = grid.data.filter((item) => item.roles.length === 0);
    return [
      {
        title: RoleHeadOffice["1"],
        quantity: dataAdmin.length,
        data: dataAdmin,
        role: RoleHeadOffice.ADMIN,
      },
      {
        title: RoleHeadOffice["3"],
        quantity: dataUser.length,
        data: dataUser,
        role: RoleHeadOffice.USER,
      },
    ];
  }, [grid.data]);

  // Search
  const search_onClick = async (init = false, emptyParam = false) => {
    openLoadingSpinner();
    const bodySearch = {
      user: emptyParam ? null : txtUser.value,
    };
    try {
      const resUsers = await UserMangementApi.searchUserByKeyWord(bodySearch);
      setGrid({
        ...grid,
        data: resUsers.data.data,
        count: resUsers.data.data.length,
      });
    } catch (error) {
      !init && showMessageError(MSG_CATCH());
    } finally {
      setInitPage(false);
      // Close loading
      closeLoadingSpinner();
    }
  };

  const showMessageDeleteConfirm = async (userName) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();
      try {
        Modal.destroyAll();
        await UserMangementApi.deleteUser(userName);
        showMessage(KEY.INFO, COMMON_CONSTANT.DELETED_USER);
        search_onClick();
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.DELETE_USER,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        funcDel();
        deboundClickModal.handleCloseModal();
      },
      onCancel: () => {
        deboundClickModal.handleCloseModal();
      },
    });
  };

  // POPUP
  const popup = {
    show: () => {
      setPopupUser({ ...popupUser, show: true });
    },
    hide: () => {
      setPopupUser({ ...popupUser, show: false });
    },
    showPopup: () => {
      // Clear
      form.resetFields();
      // Re-search
      popup.search();
      popup.show();
    },
    closePopup: () => {
      // Clear
      form.resetFields();
      set_listUser([]);
      set_nameUser(new classText());
      //popup.hide();
      popup.hide();
    },
    // Search list User
    search: (reSearch) => {
      // Loading
      setLoadSpin(true);

      UserMangementApi.searchUserToAdd({
        keyword: _nameUser.value,
      })
        .then((res) => {
          const data = res.data;
          set_listUser(popup.verifyData(data.data));
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => {
          // Unload
          setLoadSpin(false);
        });
    },
    verifyData: (dataNew) => {
      return dataNew.map((v) => {
        let checked = false;
        // Checkid
        grid.data.forEach((vOld) => {
          if (vOld.userName === v.userPrincipalName) {
            checked = true;
          }
        });
        v["disabled"] = checked;
        v["checked"] = v["checked"] || checked; // Keep checked - user
        return v;
      });
    },
    listAdd: _listUser
      .filter((v) => v.checked && !v.disabled)
      .map((i) => ({
        userPrincipalName: i.userPrincipalName,
        displayName: i.displayName,
        mai: i.mail,
      })),
    addToGroup: () => {
      openLoadingSpinner();

      // List add

      UserMangementApi.addUserWithRole({
        ...popup.listAdd[0],
        roles: 
          {
            role: RoleHeadOffice[roleSelect],
            roleValue: roleSelect,
          },
        
      })
        .then((res) => {
          //  show message
          showMessage(
            KEY.INFO,
            <>
              {popup.listAdd.map((v) => {
                return (
                  <>
                    {v.displayName} <br />
                  </>
                );
              })}
              {COMMON_CONSTANT.ADD_USER}
            </>,
            search_onClick // Re-search home
          );
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          closeLoadingSpinner();
          popup.closePopup();
          popupSelectRole.closePopup()
        });
    },
  };

  // POPUP SELECT ROLE
  const popupSelectRole = {
    show: () => {
      setPopupRole({ ...popupSelectRole, show: true });
    },
    hide: () => {
      setPopupRole({ ...popupSelectRole, show: false });
    },
    showPopup: () => {
      popupSelectRole.show();
      setRoleSelect("");
    },
    closePopup: () => {
      setRoleSelect("");
      popupSelectRole.hide();
    },
  };

  // Search for Mobile
  useEffect(() => {
    search_onClick(true);
  }, []);

  const grid_checkbox = (id, index, checked) => {
    const list = JSON.parse(JSON.stringify(_listUser));
    list[index]["checked"] = checked;
    set_listUser(list);
  };

  const clearInputSearch = () => {
    settxtUser({ ...txtUser, value: "" });
    formSearch.setFieldsValue({
      user: null,
    });
    search_onClick(false, true);
  };

  const onChangeRole =
    (roleValue, { userName: name, displayName }) =>
    async () => {
      const role = RoleHeadOffice[roleValue] || "";
      const body = [
        {
          userName: name,
          displayName,
          roles:
            roleValue === RoleHeadOffice.NO_ROLE
              ? []
              : [
                  {
                    role,
                    roleValue,
                  },
                ],
          updaterName: userName,
        },
      ];
      try {
        openLoadingSpinner();
        await UserMangementApi.updateRole(body);
        showMessage(KEY.INFO, COMMON_CONSTANT.UPDATED_USER);
        search_onClick();
      } catch (error) {
        showMessage(KEY.ERROR, MSG_CATCH());
      } finally {
        closeLoadingSpinner();
      }
    };
  // change Role
  const handleChangeRole = (roleValue, item) => {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.UPDATE_USER,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: onChangeRole(roleValue, item),
    });
  };

  // Render
  return (
    <div className="des-content role-setting">
      <TitlePage name={lang.ROLE_SETTING} />
      {!initPage && (
        <Content className="site-layout-background">
          <Form layout="vertical" form={formSearch} onFinish={search_onClick}>
            <Row style={{}} gutter={[10, 10]}>
              <Col span={24}>
                <Input
                  value={txtUser.value}
                  onChange={(e) =>
                    settxtUser({ ...txtUser, value: e.target.value })
                  }
                  placeholder="ユーザ"
                />
              </Col>

              <Col span={24} className="right">
                <Button
                  style={{ minWidth: 103, borderRadius: 4 }}
                  htmlType="submit"
                  className="buttonPC button--info wAuto mr8"
                >
                  <SearchOutlined />
                  {lang.SEARCH}
                </Button>
                <Button
                  className="buttonPC button--outline --todo-- wAuto"
                  onClick={() => clearInputSearch()}
                >
                  <ClearOutlined />
                  {lang.CLEAR}
                </Button>
              </Col>
            </Row>
          </Form>
          <Divider className="ml0 mr0 mt20 mb20" />
          <Row justify="space-between mb15">
            <Col span={12}>
              {/* Add */}
              {isAdmin && (
                <>
                  <Button
                    type="link"
                    className="pl0 pr0 m-wAuto btnLink"
                    onClick={() => {
                      popup.showPopup();
                    }}
                  >
                    <AddIcon className="svg-default mr5" />
                    <span style={{ fontWeight: 600 }}>
                      {COMMON_CONSTANT.ADD}
                    </span>
                  </Button>
                </>
              )}
            </Col>
            <Col span={12} className="right">
              {/* Count */}
              <Space size={10} align="center ">
                該当件数 <span>{grid.count}件</span>
              </Space>
            </Col>
          </Row>

          {/* Grid  */}
          {/* {isEmpty(grid.data) && (
            <div className="text-center">
              <Empty />
            </div>
          )} */}
          {listUserWithTitle.map((itemTitle, index) => {
            const title = (
              <h3 level={4} className="mb0" style={{ fontSize: 18 }}>
                {itemTitle.title}{" "}
                <span style={{ color: "#65676B" }}>
                  · {itemTitle.quantity}件
                </span>
              </h3>
            );
            return (
              <div className="listUser">
                <Collapse defaultActiveKey={["1"]} ghost>
                  <Panel showArrow={false} header={title} key="1">
                    <List
                      locale={{
                        emptyText: (
                          <p
                            style={{
                              textAlign: "start",
                              padding: 0,
                              margin: 0,
                              marginLeft: -16,
                            }}
                          >
                            {COMMON_CONSTANT.EMPTY_DATA}
                          </p>
                        ),
                      }}
                      itemLayout="horizontal"
                      dataSource={itemTitle.data}
                      renderItem={(item, index1) => {
                        const title = (
                          <div
                            className="cursor-pointer"
                            style={{ fontSize: 16, fontWeight: 500 }}
                          >
                            <span>
                              {item.displayName} ({item.userName})
                            </span>
                          </div>
                        );
                        return (
                          <List.Item className="pb0" key={index1}>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  src={item.avatarUrl}
                                  size={48}
                                  style={{
                                    backgroundColor: "#fde3cf",
                                    color: "#f56a00",
                                  }}
                                >
                                  {!item.avatarUrl &&
                                    (item.displayName ||
                                      item.userName)[0].toUpperCase()}
                                </Avatar>
                              }
                              title={title}
                              description={<AccountDescription item={item} />}
                              className="mb0"
                            />
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {/* Delete */}
                              <Select
                                disabled={!isAdmin}
                                optionLabelProp="label"
                                value={itemTitle.role}
                                style={{
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                  marginRight: 10,
                                }}
                                dropdownStyle={{ minWidth: 150 }}
                                bordered={false}
                                onChange={(e) => handleChangeRole(e, item)}
                              >
                                <Select.Option
                                  value="1"
                                  label={
                                    <UserOctagon
                                      size="24"
                                      color="#3b4873"
                                      variant="Bold"
                                    />
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <UserOctagon
                                      size="24"
                                      color="#3b4873"
                                      variant="Bold"
                                    />
                                    <span className="ml4">
                                      {RoleHeadOffice["1"]}
                                    </span>
                                  </div>
                                </Select.Option>
                                <Select.Option
                                  value="3"
                                  label={
                                    <User
                                      size="24"
                                      color="#3b4873"
                                      variant="Bold"
                                    />
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <User
                                      size="24"
                                      color="#3b4873"
                                      variant="Bold"
                                    />
                                    <span className="ml4">
                                      {RoleHeadOffice["3"]}
                                    </span>
                                  </div>
                                </Select.Option>
                               
                              </Select>
                              <Tooltip title={lang.DELETE}>
                                {/* Delete */}
                                <Button
                                  disabled={!item.canDelete}
                                  className="mr5 btnDelete btn-action"
                                  onClick={() => {
                                    deboundClickModal.handleOpenModal(() => {
                                      showMessageDeleteConfirm(item.userName);
                                    });
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </Tooltip>
                            </div>
                          </List.Item>
                        );
                      }}
                    />
                  </Panel>
                </Collapse>
                <Divider className="mb20 mt10 ml0 mr0" />
              </div>
            );
          })}
        </Content>
      )}

      <Modal
        className={"popupP2"}
        centered
        title={popupUser.title}
        open={popupUser.show}
        // onOk={handleOk}
        onCancel={popup.closePopup}
        width={1000}
        closeIcon={<></>}
        footer={[
          <Button
            disabled={!popup.listAdd.length}
            key="submit"
            type="primary"
            // onClick={popup.addToGroup}
            onClick={popupSelectRole.showPopup}
          >
            <PlusOutlined />
            {COMMON_CONSTANT.ADD}
          </Button>,
          <Button
            className="buttonPC button--outline --todo-- wAuto"
            key="back"
            onClick={popup.closePopup}
          >
            <CloseOutlined />
            {lang.CANCEL}
          </Button>,
        ]}
      >
        <PopupAllUser
          isPC={isPC}
          loadSpin={loadSpin}
          popup={popup}
          _nameUser={_nameUser}
          set_nameUser={set_nameUser}
          _listUser={_listUser}
          form={form}
          grid_checkbox={grid_checkbox}
        />
      </Modal>

      {/* MODAL SELECT ROLE */}
      <Modal
        className={"popupSelectRole"}
        centered
        open={popupRole.show}
        // onOk={handleOk}
        onCancel={popupRole.closePopup}
        width={isPC ? "40%" : "90%"}
        closeIcon={<></>}
        footer={[
          <Button
            disabled={!roleSelect}
            key="submit"
            type="primary"
            onClick={popup.addToGroup}
          >
            <PlusOutlined />
            {COMMON_CONSTANT.ADD}
          </Button>,
          <Button
            className="buttonPC button--outline --todo-- wAuto"
            key="back"
            onClick={popupSelectRole.closePopup}
          >
            <CloseOutlined />
            {lang.CANCEL}
          </Button>,
        ]}
      >
        <PopupSelectRole
          handleAddUser={popup.addUserWithRole}
          roleSelect={roleSelect}
          setRoleSelect={setRoleSelect}
        />
      </Modal>
      <Loading show={showLoading} />
    </div>
  );
}
export default Role_list;

export function AccountDescription({ item }) {
  return (
    <>
      <span>最終更新日: {item.updateDateTime}</span>
    </>
  );
}

const showMessageError = (msg) => {
  showMessage(KEY.ERROR, msg);
};

import { CopyOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { lang } from "../../../constants/common.const";
import { getQuerySearch } from "../../../constants/utils";

function ButtonClone(props) {
  const history = useHistory();
  const { id } = useParams();
  const { version } = getQuerySearch();
  return (
    <div
      onClick={() => {
        history.push(`/Estimate/Add?CloneId=${id}&version=${version}`);
      }}
      className={"actionLeft"}
    >
      <CopyOutlined
        className="mr4"
        style={{ fontWeight: "bold", fontSize: 16 }}
      />
      {lang.CLONE}
    </div>
  );
}

export default ButtonClone;

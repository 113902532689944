import React from "react";
import { HeaderText } from "../../../components/common/headerText";
import FeeRate from "./FeeRate";
import WaterDevice from "../Water_Device/WaterDevice";
import ReflectManagement from "./ReflectManagement";
import InternalFee from "./InternalFee";

const OtherFee = ({isLoad}) => {
  
  return (
    <div>
     <ReflectManagement isLoad={isLoad} />
     <InternalFee isLoad={isLoad} />
    </div>
  );
};
export default OtherFee;

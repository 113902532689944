import Api from "@src/api";
import { isEmpty } from "lodash";
export const CostManagementApi = {

  getCostManagement() {
    return Api().get(`/api/office/CostManagement`)
  },
  getCostManagementById(id) {
    return Api().get(`/api/office/CostManagement?id=${id}`)
  },
  updateCostManagement(body){
    return Api().put(`/api/office/CostManagement`,body)
  },
  createCostManagement(body){
    return Api().post(`/api/office/CostManagement`,body)
  },
  deleteCostManagement(id){
    return Api().delete(`/api/office/CostManagement?id=${id}`)
  },
  
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { EstimateApi } from "../../../api/backend/estimate";
import { injectionPump } from "../../../api/backend/injectionPump";
import Loading from "../../../component/Desktop/loading/loading";
import { KEY } from "../../../constants/common.const";
import {
  MSG_CATCH,
  getQuerySearch,
  showMessage,
} from "../../../constants/utils";
import {
  closeLoading,
  openLoading,
  setDetailData,
} from "../../../redux/estimate";
import { updateTitleHeaderWithoutConst } from "../../../redux/sideBar";
import ViewStep1 from "./step1";
import ViewStep2 from "./step2";
import ViewStep3 from "./step3";

const ViewEstimate = () => {
  const { step } = getQuerySearch();
  const location = useLocation();
  const { version } = getQuerySearch();
  const [dataDetail, setDataDetail] = useState(null);
  const basePath = location.pathname + location.search;
  const { id } = useParams();
  const { loading } = useSelector((state) => state.estimate);
  const dispatch = useDispatch();
  let ComponentView;
  let titlepage;
  const TITLE = {
    STEP1: "入力情報",
    STEP2: "選定結果",
    STEP3: "見積表の出力",
  };
  switch (step) {
    case "1": {
      ComponentView = ViewStep1;
      titlepage = TITLE.STEP1;
      break;
    }
    case "2": {
      ComponentView = ViewStep2;
      titlepage = TITLE.STEP2;
      break;
    }
    case "3": {
      ComponentView = ViewStep3;
      titlepage = TITLE.STEP3;
      break;
    }
    default: {
      titlepage = TITLE.STEP1;
      ComponentView = ViewStep1;
    }
  }
  const fetchDataDetails = async () => {
    try {
      let netVolumeWaterRecovered = "";
      dispatch(openLoading());
      const [res, pumpRes, waterDeviceRes] = await Promise.all([
        EstimateApi.getDetailEstimate(id, version),
        injectionPump.getInjectionPump(),
      ]);
      const dataDetail = res.data;

      // GET netVolumeWaterRecovered
      await EstimateApi.getDataStep3(res.data)
        .then((res) => {
          const data = res.data;

          netVolumeWaterRecovered = data.netVolumeWaterRecovered;
        })

        .catch((error) => {
          console.log(error);
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => {});

      // PUMP
      const listPumpDetail = dataDetail.injectionPumps;
      const listAllInjectPump = pumpRes.data.data;
      listAllInjectPump.forEach((inject, pumpIndex) => {
        const index = listPumpDetail.findIndex(
          (i) => i.injectionPumpId === inject.injectionPumpId
        );
        if (index >= 0) {
          listAllInjectPump[pumpIndex] = {
            ...listPumpDetail[index],
            checked: true,
          };
        }
      });

      const finalDetail = {
        ...dataDetail,
        injectionPumps: listAllInjectPump,
        netVolumeWaterRecovered,
      };
      setDataDetail(finalDetail);
      dispatch(setDetailData(finalDetail));
    } catch (error) {
      console.log(error);
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      dispatch(closeLoading());
    }
  };

  useEffect(() => {
    fetchDataDetails();
  }, []);
  useEffect(() => {
    dispatch(updateTitleHeaderWithoutConst(titlepage + " rev." + version));
  }, [titlepage, dispatch, step, version]);

  return (
    <div>
      <ComponentView dataDetail={dataDetail} basePath={basePath} />
      <Loading show={loading} />
    </div>
  );
};
export default ViewEstimate;

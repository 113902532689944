import Api from "@src/api";
import { isEmpty } from "lodash";
export const injectionPump = {

  getInjectionPump() {
    return Api().get(`/api/office/InjectionPump`)
  },
  getInjectionPumpById(id) {
    return Api().get(`/api/office/InjectionPump?id=${id}`)
  },
  updateInjectionPump(body){
    return Api().put(`/api/office/InjectionPump`,body)
  },
  createInjectionPump(body){
    return Api().post(`/api/office/InjectionPump`,body)
  },
  deleteInjectionPump(id){
    return Api().delete(`/api/office/InjectionPump?id=${id}`)
  },
 getCapcityChemicalInjection(body){
  return Api().post("/api/office/Estimation/GetChemicalTank",body)
 }
  
}
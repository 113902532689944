import Api from "@src/api";

export const UserMangementApi = {
  searchUserToAdd(params) {
    return Api().get("/api/office/UserRole/SearchUsers", { params });
  },
  searchUserByKeyWord(params) {
    return Api().get("/api/office/UserRole", { params });
  },
  addUser(body) {
    return Api().post("/api/office/UserRole/AddUsers", body);
  },
  addUserWithRole(body) {
    return Api().post("/api/office/UserRole/CreateUserWithRole", body);
  },
  deleteUser(userName) {
    return Api().delete(`/api/office/UserRole/Users/${userName}`);
  },
  updateRole(body) {
    return Api().post("/api/office/UserRole", body);
  },
  getInforUserByUserName(params) {
    return Api().get("/UserInfo/GetUserByUserName", { params });
  },
  getOwnInformation() {
    return Api().get("/UserInfo");
  },
  uploadAvatar(formData) {
    return Api().post("FileUpload/Image", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  uploadSignature(formData) {
    return Api().post("FileUpload/Signature", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateUser(data) {
    return Api().put("UserInfo/UpdateUser", data);
  },
};

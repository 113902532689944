import Api from "@src/api";

export default {
  //search machine
  search(params) {
    return Api().get("api/office/Pump", { params });
  },
  delete(code, version = '') {
    return Api().delete(`api/office/Pump/${code}/${version}`, {})
  },
  getOne(code, version = 0) {
    return Api().get(`api/office/Pump/${code}/${version}`, {});
  },
  save(formData) {
    return Api().post(`api/office/Pump`, formData, {});
  },
  update(formData) {
    return Api().put(`api/office/Pump`, formData, {});
  }
};

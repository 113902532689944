import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CostManagementApi } from "../../../api/backend/costManagement";
import Loading from "../../../component/Desktop/loading/loading";
import TitlePage from "../../../component/Desktop/titlePage";
import { HeaderText } from "../../../components/common/headerText";
import { KEY, lang, COMMON_CONSTANT } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import { deboundClickModal, formatCurrencyJPY } from "../../../utils/helpers";
import AddReflect from "./AddReflect";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";
const { Panel } = Collapse;

const ReflectManagement = ({isLoad}) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const isAndroid = true;
  const isMobile = !isPC;
  const [idItem, setIdItem] = useState(null);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [costManagement, setCostManagement] = useState([]);

  const [detail, setDetail] = useState(null);
  // Close Modal
  const onClose = (isRefetch = true) => {
    setDetail(null);
    setOpenModal(false);
    if (isRefetch) {
      getListCostManagement();
    }
  };

  const getListCostManagement = async () => {
    try {
      setLoading(true);
      const optionsRes = await CostManagementApi.getCostManagement();
      const optionData = optionsRes.data.data.map((item, index) => ({
        key: item.costID,
        projectName: item.projectName,
        unitPrice: item.unitPrice,
        unitPriceBasis: item.unitPriceBasis,
        remark: item.remark,
      }));
      setCostManagement(optionData);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIdItem(null);
  };

  // GET DATA
  useLoadTabDataEffect(isLoad,getListCostManagement);

  useEffect(() => {
    if (isError) {
      deboundClickModal.handleCloseModal();
    }
  }, [isError]);

  const columnprice = [
    {
      title: <div className="text-start">項目</div>,
      dataIndex: "projectName",
      key: "projectName",
      width: "20%",
      render: (text, record, index) => {
        return <span>{record.projectName}</span>;
      },
    },
    {
      title: <div className="text-start">単価（千円/基）</div>,
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: "20%",
      render: (text, record, index) => {
        return <span>{formatCurrencyJPY(record.unitPrice)}</span>;
      },
    },

    {
      title: <div className="text-start">単価根拠</div>,
      dataIndex: "unitPriceBasis",
      width: "20%",
    },

    {
      title: <div className="text-start">{"備考"}</div>,
      dataIndex: "remark",
      key: "remark",
      width: "30%",
      render: (text, record) => {
        return <span>{record.remark}</span>;
      },
    },

    {
      title: LANG.ACTION,
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: "10%",
      render: (id, record, index) => {
        return (
          <Tooltip title={lang.EDIT}>
            <Button
            onClick={() => {
              setOpenModal(true);
              setDetail(record);
            }}
            icon={<EditOutlined />}
          />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      <Collapse
        className="mt15"
        defaultActiveKey={["1"]}
        expandIconPosition="right"
      >
        <Panel
          header={
            <div className={"wrapTitle"}>
              <span style={{ fontWeight: "bold" }}>{"メーカー費用"}</span>
            </div>
          }
          key={"1"}
        >
          <div
            style={{
              marginTop: 20,
              paddingBottom:10

            }}
          >
            <Table
              className="gridSearch"
              rowKey="id"
              dataSource={costManagement}
              columns={columnprice}
              pagination={false}
              bordered
            />
          </div>
        </Panel>
      </Collapse>

      <Loading show={loading} />
      {openModal && (
        <AddReflect open={openModal} onClose={onClose} detail={detail} />
      )}
    </div>
  );
};
export default ReflectManagement;

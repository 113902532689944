/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  CloseCircleOutlined,
  FormOutlined,
  PlusOutlined,
  SendOutlined,
} from "@ant-design/icons";
import FooterPage from "@src/component/footer/footerPage";
import { IconNumber } from "@src/components/common/iconNumber.jsx";
import { KEY } from "@src/constants/key.const";
import { LANG } from "@src/constants/lang.const";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BreakLine from "../../../component/Desktop/breakLine";
import Loading from "../../../component/Desktop/loading/loading";
import TitlePage from "../../../component/Desktop/titlePage";
import {
  KEY_RULE_INPUT,
  REGEX_INPUT,
  STYLE,
  lang,
} from "../../../constants/common.const";
import {
  openSave,
  setDataStep1,
  setDataStep2,
  setFromToStep2,
  setInitEdit,
} from "../../../redux/estimate";
import {
  cloneObj,
  deboundClickModal,
  getPathEstimateWithStep,
  getStandardLv,
  isEmptyData,
  LIST_RULE,
} from "../../../utils/helpers";
import { QuestionToolTip } from "../../Machine_Management/air";
import "../Estimate.scss";
import DescriptionFiberLength from "../descriptionFiberLength";
import { EstimateApi } from "./../../../api/backend/estimate";
import ButtonSave from "./buttonSave";
import WaterDevice from "./waterDevice";
import { HeaderText } from "../../../components/common/headerText";
import InjectionPump from "./injectionPump";
const { Panel } = Collapse;
export function RowInput({
  box,
  text,
  required,
  borderBottom = true,
  labelCol = 3,
  contentCol = 12,
  labelBottom,
  mb,
  unit = "",
  isHaveComment,
  marginTopUnit,
  titleRight = false,
  ...props
}) {
  return (
    <div
      style={{
        padding: "10px 0px",
        borderBottom: borderBottom ? "1px solid #dfe6ec" : "",
        // background: "lightGray",
      }}
      {...props}
    >
      <Row gutter={[8, 8]} align={isHaveComment ? "start" : "middle"}>
        <Col span={labelCol} className={text ? "" : "row-input-col-first"}>
          <Row align={isHaveComment ? "start" : "middle"}>
            <Col
              style={{ marginBottom: mb ? -20 : 0 }}
              span={24}
              className={`pr20 ${titleRight ? "right" : ""}`}
            >
              <span
                style={{
                  fontWeight: "bold",
                  marginTop: isHaveComment ? 15 : 0,
                  display: "inline-block",
                  marginBottom: labelBottom,
                  whiteSpace: "pre",
                }}
              >
                {props.name}
              </span>
              {required && <label className="blod ml5 mark-required">*</label>}
            </Col>
          </Row>
        </Col>
        <Col
          style={
            unit
              ? {
                  display: "flex",
                  alignItems: isHaveComment ? "start" : "center",
                  marginTop: 10,
                }
              : {}
          }
          span={contentCol}
          className="row-input-col-2"
        >
          {props.children}
          {unit && (
            <span
              style={{ marginLeft: "7px", marginTop: isHaveComment ? 10 : 0 }}
            >
              {unit}
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
}
const AddStep1 = ({ dataStep1, stringQuery, basePath }) => {
  const history = useHistory();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const isAndroid = true;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const isMobile = !isPC;
  const [numberOfMachine, setNumberOfMachine] = useState();
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const countRender = useRef(0);
  const { isLeader } = useSelector((state) => state.auth);

  const [fiberLengthOption] = useState([
    {
      key: 1000,
      value: "1000㎜",
    },
    {
      key: 1500,
      value: "1500㎜",
    },
  ]);
  const [waterTypeOption, setWaterTypeOption] = useState([]);
  const [waterTypeDetailOption, setWaterTypeOptionDetail] = useState([
    {
      key: "1",
      value: "河川水",
    },
    {
      key: "2",
      value: "工業用水",
    },
    {
      key: "3",
      value: "地下水",
    },
    {
      key: "4",
      value: "循環ろ過（除くスケール分散剤）",
    },
    {
      key: "5",
      value: "一般排水",
    },
  ]);

  const [optionMonney, setOptionMonney] = useState([]);
  const optionMoneyOriginnal = useRef();
  const [saveModal, setSaveModal] = useState(false);
  const {
    formDataPostDefault,
    dataStep2,
    dataStep3,
    optionsDefault,
    listLv,
    dataFromStep2ToStep1,
    isInitEdit,
    listWaterDeviceInit,
    injectionPumpsInit,
    h2So4Init,
    optionsEdit,
  } = useSelector((state) => state.estimate);
  const [formErrors, setFormErrors] = useState({});
  const [messErr, setMessErr] = useState({});
  const refFocus = useRef(null);
  // Option monney
  // data input
  const [data, setData] = useState({
    // panel 0
    estimationNumber: "",
    customerName: "",
    constructionName: "",
    ordererName: "",
    companyDeliveryDestination: "",
    factoryDeliveryDestination: "",
    caseName: "",
    tradingCompany: "",

    // panel 1
    rawWaterType: "",
    rawWaterDetailType: "",

    // panel 2
    estimationConditionInput: {
      inputRawWaterVolume: "",
      inputRawWaterPh: "",
      inputRawWaterTurbidity: "",
      inputRawWaterSsConc: "",
      inputRawWaterIronConc: "",
      inputRawWaterMgConc: "",
      inputNetVolumeWaterRecovered: "",
      inputTreatedWaterTurbidity: "",
      inputOperatingTime: "",
      inputTreatedWaterSsConc: "",
      otherWaterQualityRequirements: "",
    },

    // panel 3
    fiberLength: "",
    // Panel 4
    completeSpareMachineQuantity: 0,

    // Panel 5

    estimationAuxMachine: {
      haveAuxFiltrationPump: "0",
      auxFiltrationPumpRegularNumber: 3,
      auxFiltrationPumpBackupMachine: 0,
      haveAuxBackwashPump: "0",
      auxBackwashPumpRegularNumber: 1,
      auxBackwashPumpBackupMachine: 0,
      haveAuxAirWashingBlower: "0",
      auxAirWashingBlowerRegularNumber: 1,
      auxAirWashingBlowerBackupMachine: 0,
      haveAuxAirWashingBlowerSndprfBox: "0",
      auxAirWashingBlowerSndprfBoxRegularNumber: 1,
      auxAirWashingBlowerSndprfBoxBackupMachine: 0,
    },
    havePacInjectionDevice: "0",
    haveHypotoniaInjectionDevice: "0",
    havePhAdjusterInjectionDevice: "0",
    haveControlPanel: "0",

    // Panel 6
    options: [],
    waterDevices: [],
    injectionPumps: [],

    // H2So4
    h2so4DrugConc: "",
    h2so4ChemicalSpecificGravity: "",
  });

  //estimatedAmountOfWaterRecovered
  const [estimatedAmountOfWaterRecovered, setEstimatedAmountOfWaterRecovered] =
    useState("");

  const getDataPost = () => {
    // Map Data Post
    const dataPost = cloneObj(data);
    dataPost.estimationAuxMachine.auxAirWashingBlowerSndprfBoxRegularNumber =
      data.estimationAuxMachine.auxAirWashingBlowerRegularNumber;
    dataPost.estimationAuxMachine.auxAirWashingBlowerSndprfBoxBackupMachine =
      data.estimationAuxMachine.auxAirWashingBlowerBackupMachine;
    dataPost.estimationAuxMachine.haveAuxAirWashingBlowerSndprfBox =
      data.fiberLength == 1000
        ? " 0"
        : data.estimationAuxMachine.haveAuxAirWashingBlower == "0"
        ? data.estimationAuxMachine.haveAuxAirWashingBlower
        : data.estimationAuxMachine.haveAuxAirWashingBlowerSndprfBox;

    dataPost.estimationAuxMachine.auxFiltrationPumpRegularNumber =
      numberOfMachine;
    if (!dataPost.estimationConditionInput.inputRawWaterSsConc) {
      dataPost.estimationConditionInput.inputRawWaterSsConc =
        dataPost.estimationConditionInput.inputRawWaterTurbidity;
    }

    return dataPost;
  };

  //

  // set Option Money
  const handleChangeMoneyOption = (key, filedName, newVal, rule) => {
    if (rule && !newVal.match(rule) && newVal) {
      return;
    }
    if (
      String(newVal).indexOf("-") >= 0 ||
      newVal < 0 ||
      (newVal && isNaN(newVal))
    )
      return;
    const index = data.options.findIndex((i) => i.key === key);
    if (index >= 0) {
      const newData = cloneObj(data);
      newData["options"][index][filedName] = newVal;
      if (filedName === "optionId") {
        const itemOp = optionMonney.find((i) => i.key == newVal);
        if (itemOp) {
          newData["options"][index]["optionName"] = itemOp.value;
          newData["options"][index]["value"] = itemOp.defaultValue;
        }
      }
      if (filedName === "checked" && newVal === false) {
        newData["options"][index]["isError"] = false;
      }

      setData(newData);
    }
  };

  // change select add
  const handleChangeSelectApllOption = (checked) => {
    const newData = cloneObj(data);
    if (checked === true) {
      newData.options = newData.options.map((item) => ({
        ...item,
        checked: true,
      }));
    } else {
      newData.options = newData.options.map((item) => ({
        ...item,
        checked: false,
      }));
    }
    setData(newData);
  };
  // handle key down
  const hanldeKeyDown = (rule) => (e) => {
    if (
      rule &&
      !e.key.match(rule) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowUp" &&
      e.key !== "ArrowDown" &&
      e.key !== "ArrowRight" &&
      e.key !== "ArrowLeft" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };
  // Next Step
  const handleNextStep = () => {
    if (!validateForm()) return;
    const dataPost = getDataPost();
    const dataStep1 = cloneObj(dataPost);
    const warterTypeValue = waterTypeOption.find(
      (item) => item.key === data.rawWaterType
    );
    const warterTypeValueDetail = waterTypeDetailOption.find(
      (item) => item.key === data.rawWaterDetailType
    );
    dataStep1.rawWaterTypevalue =
      warterTypeValue && warterTypeValueDetail
        ? warterTypeValue.value + " - " + warterTypeValueDetail.value
        : "";
    dataStep1.options.forEach((item) => {
      if (item.hasOwnProperty("isError")) {
        delete item.isError;
      }
    });

    dispatch(setDataStep1(dataStep1));
    dispatch(setFromToStep2("step1"));
    dispatch(setInitEdit(false));
    history.push(getPathEstimateWithStep(basePath, 2));
  };

  // check disable add money
  let listKey = data && data.options ? data.options : [];
  listKey = listKey.map((i) => i.optionId);
  let firstOption = null;
  for (let index = 0; index < optionMonney.length; index++) {
    if (!listKey.includes(optionMonney[index].key)) {
      firstOption = optionMonney[index];
      break;
    }
  }

  // ADD MONEY ITEM
  const handleAddItemMoney = () => {
    setData({
      ...data,
      options: [
        ...data.options,
        {
          optionId: firstOption && firstOption.key,
          optionName: firstOption ? firstOption.value : "",
          value: firstOption ? firstOption.defaultValue : "",
          key: Date.now(),
        },
      ],
    });
  };

  const shortText = (value) => {
    if (!isPC) {
      if (value.length > 15) {
        return value.slice(0, 15) + " ...";
      }
    }
    return value;
  };

  // Const render listOptionMoney
  const renderListOptionMoney = data.options.map((item, index) => {
    return (
      <div
        style={{
          paddingLeft: 50,
        }}
      >
        <RowInput
          labelCol={isMobile ? 24 : 6}
          contentCol={isMobile ? 24 : 12}
          borderBottom={index < 2}
          key={index}
          name={
            <div
              style={{
                padding: 0,
                marginTop: !isPC ? 10 : 0,
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Checkbox
                checked={item.checked}
                onChange={(e) =>
                  handleChangeMoneyOption(item.key, "checked", e.target.checked)
                }
              ></Checkbox>
              <p
                style={{
                  width: !isPC && 300,
                }}
              >
                {item.optionName}
              </p>{" "}
            </div>
          }
          required={item.required}
        ></RowInput>
        <BreakLine />
      </div>
    );
  });

  // Chemical injection device

  const listInjectionDevice = [
    {
      title: "PAC注入装置",
      value: data.havePacInjectionDevice === "1" ? true : false,
      key: "havePacInjectionDevice",
      hint: "必須（客先供給ある場合は不要)",
    },

    {
      title: "次亜注入装置",
      value: data.haveHypotoniaInjectionDevice === "1" ? true : false,
      key: "haveHypotoniaInjectionDevice",
      hint: "必須（客先供給ある場合は不要)",
    },

    {
      title: "pH調整剤注入装置",
      value: data.havePhAdjusterInjectionDevice === "1" ? true : false,
      key: "havePhAdjusterInjectionDevice",
      hint: "pHが範囲を超える場合、又は色度とアルミの処理が必要となる場合は必要",
    },
  ];

  // Reder
  const renderListInjectionDevice = listInjectionDevice.map((item, index) => {
    return (
      <RowInput
        labelCol={isMobile ? 24 : 6}
        contentCol={isMobile ? 24 : 12}
        borderBottom={index < 2}
        key={index}
        name={
          <div
            style={{
              padding: isPC ? "10px 0" : "5px 0",
              marginTop: !isPC ? 10 : 0,
            }}
          >
            <Checkbox
              style={{ marginRight: 3 }}
              checked={item.value}
              onChange={(e) =>
                handleChangeInputData(
                  item.key,
                  e.target.checked,
                  null,
                  "checkbox"
                )
              }
            ></Checkbox>
            {item.title} <QuestionToolTip title={item.hint} />
          </div>
        }
        required={item.required}
      ></RowInput>
    );
  });
  /// List data With Or WithOut Machine
  const listDatWithOrWithOutMachine = [
    {
      title: LANG.FILTRATION_PUMP,
      value: data.estimationAuxMachine.haveAuxFiltrationPump,
      key: ["estimationAuxMachine", "haveAuxFiltrationPump"],
      keyRegular: ["estimationAuxMachine", "auxFiltrationPumpRegularNumber"],
      keyBackup: ["estimationAuxMachine", "auxFiltrationPumpBackupMachine"],
      regularvalue: numberOfMachine,
      backupMachine: data.estimationAuxMachine.auxFiltrationPumpBackupMachine,
      // require: true,
      hint: "本体塔数と同じ数量が自動入力されます",
      regularDes: "整数",
      backupDes: "整数",
    },
    {
      title: LANG.BACKWASH_PUMP,
      value: data.estimationAuxMachine.haveAuxBackwashPump,
      key: ["estimationAuxMachine", "haveAuxBackwashPump"],
      keyRegular: ["estimationAuxMachine", "auxBackwashPumpRegularNumber"],
      keyBackup: ["estimationAuxMachine", "auxBackwashPumpBackupMachine"],
      regularvalue: data.estimationAuxMachine.auxBackwashPumpRegularNumber,
      backupMachine: data.estimationAuxMachine.auxBackwashPumpBackupMachine,
      // require: true,
      regularDes: "整数・参考数値 : 1",
      backupDes: "整数",
    },
    {
      title: LANG.AIR_WASHING_BLOWER,
      value: data.estimationAuxMachine.haveAuxAirWashingBlower,
      key: ["estimationAuxMachine", "haveAuxAirWashingBlower"],
      keyRegular: ["estimationAuxMachine", "auxAirWashingBlowerRegularNumber"],
      keyBackup: ["estimationAuxMachine", "auxAirWashingBlowerBackupMachine"],
      regularvalue: data.estimationAuxMachine.auxAirWashingBlowerRegularNumber,
      backupMachine: data.estimationAuxMachine.auxAirWashingBlowerBackupMachine,
      // require: true,
      regularDes: "整数・参考数値 : 1",
      hintCheckbox:
        "1000㎜シリーズのブロワは原則としてオンザスキッドとなります。1000㎜シリーズ選択時は必ず選択してください。",
      backupDes: "整数",
    },
    {
      title: LANG.AIR_WASHING_BLOWER_SOUND,

      value:
        data.fiberLength == 1000
          ? "0"
          : data.estimationAuxMachine.haveAuxAirWashingBlower == "0"
          ? data.estimationAuxMachine.haveAuxAirWashingBlower
          : data.estimationAuxMachine.haveAuxAirWashingBlowerSndprfBox,
      key: ["estimationAuxMachine", "haveAuxAirWashingBlowerSndprfBox"],
      keyRegular: [
        "estimationAuxMachine",
        "auxAirWashingBlowerSndprfBoxRegularNumber",
      ],
      keyBackup: [
        "estimationAuxMachine",
        "auxAirWashingBlowerSndprfBoxBackupMachine",
      ],
      regularvalue: data.estimationAuxMachine.auxAirWashingBlowerRegularNumber,
      backupMachine: data.estimationAuxMachine.auxAirWashingBlowerBackupMachine,
      // disabled: data.estimationAuxMachine.haveAuxAirWashingBlower == "0",
      disabledNumber: true,
      regularDes: "整数・参考数値 : 1",
      hintCheckbox: (
        <>
          1000㎜シリーズは防音ボックスの選択はできません。なお、EF-□15-12以降用のブロワが85dBを上回ります。
        </>
      ),
      backupDes: "整数",
      space: true,
      disabled: data.fiberLength == 1000,
      get isHide() {
        return this.value == "0";
      },
    },
  ];
  // render List data with or without machine
  const renderListDataWithOrwithoutMachine = useMemo(() => {
    return listDatWithOrWithOutMachine.map((item, index) => {
      const isCommentMobile =
        item.keyRegular[1] === "auxFiltrationPumpRegularNumber" && !isPC;
      const isCommentPC =
        item.keyRegular[1] === "auxFiltrationPumpRegularNumber" && isPC;
      return (
        <RowInput
          key={index}
          labelCol={isMobile ? 24 : 0}
          contentCol={isMobile ? 24 : 24}
          isHaveComment={isCommentPC}
        >
          <Row gutter={[10, 10]}>
            <Col
              className={isPC ? (isCommentPC ? "mt10" : "mt5") : ""}
              span={isMobile ? 24 : 4}
            >
              <Checkbox
                style={{
                  marginTop: isPC && 10,
                  marginRight: 3,
                }}
                onChange={(e) =>
                  handleChangeInputData(
                    item.key,
                    e.target.checked,
                    null,
                    "checkbox"
                  )
                }
                checked={item.value == "1" ? true : false}
                disabled={item.disabled}
              >
                {}
              </Checkbox>
              <>
                <span className="bold">
                  {item.space && <span>&nbsp;&nbsp;</span>} {item.title + " "}
                </span>
                {item.require && (
                  <label className="blod ml5 mark-required">* </label>
                )}
              </>
              {item.hintCheckbox && (
                <QuestionToolTip title={item.hintCheckbox} />
              )}
            </Col>
            <>
              <Col span={isMobile ? 24 : 10}>
                <RowInput
                  titleRight={isPC}
                  isHaveComment={isCommentPC}
                  unit="基"
                  name={
                    <>
                      {isCommentMobile ? "常用台数" : "常用台数"}{" "}
                      {item.hint && <QuestionToolTip title={item.hint} />}
                    </>
                  }
                  borderBottom={false}
                  labelCol={isMobile ? 24 : 8}
                  contentCol={isMobile ? 24 : 16}
                  className="pb0 pt0"
                >
                  <Row style={{ width: "100%", marginTop: isPC && -15 }}>
                    <Col
                      style={{
                        textAlign: "start",
                        color: "gray",
                        fontSize: 12,
                        visibility: index === 3 && "hidden",
                      }}
                    >
                      {item.regularDes || ""}
                    </Col>
                    <Col span={24}>
                      <Input
                        placeholder={LANG.REGULAR_USE_NUMBER}
                        style={{
                          width: "100%",
                          color: item.isHide && "transparent",
                        }}
                        value={item.regularvalue}
                        disabled
                      />
                    </Col>
                  </Row>
                </RowInput>
              </Col>
              <Col span={isMobile ? 24 : 10}>
                <RowInput
                  titleRight={isPC}
                  isHaveComment={isCommentPC}
                  unit="基"
                  name={<>予備台数</>}
                  borderBottom={false}
                  labelCol={isMobile ? 24 : 8}
                  contentCol={isMobile ? 24 : 16}
                  className="pb0 pt0"
                >
                  <Row
                    style={{
                      width: "100%",
                      marginTop: isPC && -17,
                    }}
                  >
                    <Col
                      style={{
                        textAlign: "start",
                        color: "gray",
                        fontSize: 12,
                        visibility: index === 3 && "hidden",
                      }}
                      span={24}
                    >
                      {item.backupDes}
                    </Col>
                    <Col span={24}>
                      <Input
                        type={isAndroid ? "number" : "text"}
                        min={0}
                        className={
                          isErrorForm(item.keyBackup[1]) ? STYLE.BORDER_RED : ""
                        }
                        ref={getRef(item.keyBackup[1])}
                        disabled={item.disabledNumber}
                        // placeholder={"0～　整数"}
                        style={{
                          width: "100%",
                          color: item.isHide && "transparent",
                        }}
                        value={item.backupMachine}
                        onChange={(e) =>
                          handleChangeInputData(
                            item.keyBackup,
                            e.target.value,
                            REGEX_INPUT.interger
                          )
                        }
                        onKeyDown={hanldeKeyDown(REGEX_INPUT.interger)}
                      />
                    </Col>
                  </Row>
                </RowInput>
              </Col>
            </>
          </Row>
        </RowInput>
      );
    });
  }, [listDatWithOrWithOutMachine, data.estimationAuxMachine]);

  // Data table condition input
  const dataTableCondition = [
    {
      id: "waterVolumn",
      label: LANG.WATER_VOLUME_STEP1,
      unit: "m³/d",
      value: data.estimationConditionInput.inputRawWaterVolume,
      key: ["estimationConditionInput", "inputRawWaterVolume"],
      rule: REGEX_INPUT.interger,
      required: true,
      description: "整数",
      // otherErr: Number(data.estimationConditionInput.inputRawWaterVolume) < 44,
      hint: (
        <>
          <div>
            {"「処理水量」ではなく「原水量」を記載してください。処理水量が要求される場合は、次項の想定回収量の自動計算数値を参照して必要量を入力し直してください。".trim()}
          </div>
        </>
      ),

      // min: 43.2,
    },
    {
      label: "想定回収水量",
      unit: "m³/d",
      value: estimatedAmountOfWaterRecovered,
      key: ["estimationConditionInput", "estimatedAmountOfWaterRecovered"],
      autoFill: true,

      description: "自動入力",
    },
    {
      hint: "制限値を下回る又は上回る場合は技術部門に相談してください。",
      label: LANG.PH,
      value: data.estimationConditionInput.inputRawWaterPh,
      key: ["estimationConditionInput", "inputRawWaterPh"],
      rule: REGEX_INPUT.generateDecimalRegex(2),
      defaultValue: 8,
      description: "小数点第2位",
      ruleKeyDown: REGEX_INPUT.number,
      rangeText: "5.80~8.60",

      otherErr: !(
        Number(data.estimationConditionInput.inputRawWaterPh) <= 8.6 &&
        Number(data.estimationConditionInput.inputRawWaterPh) >= 5.8
      ),
    },
    {
      hint: "参考数値を上回る場合は技術部門に相談してください。",
      label: LANG.WATER_TURBIDITY,
      unit: "度",
      value: data.estimationConditionInput.inputRawWaterTurbidity,
      // min: 1,
      // max: 1000,
      rangeText: "≦100",
      required: data.rawWaterType == "1",
      disabled: data.rawWaterType == "2",
      key: ["estimationConditionInput", "inputRawWaterTurbidity"],
      rule: REGEX_INPUT.intergerFrom1To100,
      ruleKeyDown: REGEX_INPUT.interger,
      defaultValue: 20,
      description: "整数",
    },
    {
      hint: "条件が無い場合は「濁度＝SS濃度」を入力してください。参考数値を上回る場合は技術部門に相談してください。",
      label: LANG.WATER_SS,
      unit: "mg/L",
      rangeText: "≦100",
      value: data.estimationConditionInput.inputRawWaterSsConc,
      min: 1,
      max: 1000,
      key: ["estimationConditionInput", "inputRawWaterSsConc"],
      rule: REGEX_INPUT.intergerFrom1To100,
      ruleKeyDown: REGEX_INPUT.interger,
      defaultValue: 20,
      description: "整数",
      required: true,
    },
    {
      hint: "参考数値を上回る場合は技術部門に相談してください。",
      label: LANG.WATER_FE,
      rangeText: "≦20",
      unit: "mg/L",
      value: data.estimationConditionInput.inputRawWaterIronConc,
      key: ["estimationConditionInput", "inputRawWaterIronConc"],
      rule: REGEX_INPUT.generateDecimalRegex(3),
      limitText: "20",
      ruleKeyDown: REGEX_INPUT.number,
      description: "小数点第3位",
      disabled: data.rawWaterType == "2",
      defaultValue: 3,
      otherErr:
        Number(data.estimationConditionInput.inputRawWaterIronConc) > 20,
    },
    {
      hint: "参考数値を上回る場合は技術部門に相談してください。",
      label: LANG.WATER_MN,
      unit: "mg/L",
      remark: "",
      value: data.estimationConditionInput.inputRawWaterMgConc,
      key: ["estimationConditionInput", "inputRawWaterMgConc"],
      rule: REGEX_INPUT.generateDecimalRegex(3),
      ruleKeyDown: REGEX_INPUT.number,
      limitText: "0.05",
      description: "小数点第3位",
      defaultValue: 0.05,
      rangeText: "≦0.05",
      disabled: data.rawWaterType == "2",
      otherErr:
        Number(data.estimationConditionInput.inputRawWaterMgConc) > 0.05,
    },

    {
      hint: (
        <>
          条件が無い場合は「上水基準≦2度」、「膜ろ過原水基準≦5度」を参考として入力してください。2度を下回る要求がある場合は技術部門に相談してください。
        </>
      ),

      label: LANG.TREATED_WATER_TURBIDITY,
      reffrenceValue: 2,
      unit: "度",
      value: data.estimationConditionInput.inputTreatedWaterTurbidity,
      key: ["estimationConditionInput", "inputTreatedWaterTurbidity"],
      required: data.rawWaterType == "1",
      defaultValue: 2,
      description: "整数",
      disabled: data.rawWaterType == "2",
      min: 1,
      max: 1000,
      rangeText: "≦10",
      rule: REGEX_INPUT.intergerFrom1To10,

      ruleKeyDown: REGEX_INPUT.interger,
    },
    {
      hint: "条件が無い場合は「濁度＝SS濃度」を入力してください。",
      label: LANG.TREATED_WATER_SS,
      reffrenceValue: 2,
      min: 1,
      max: 1000,
      unit: "mg/L",
      value: data.estimationConditionInput.inputTreatedWaterSsConc,
      key: ["estimationConditionInput", "inputTreatedWaterSsConc"],
      rule: REGEX_INPUT.intergerFrom1To10,
      ruleKeyDown: REGEX_INPUT.interger,
      defaultValue: 2,
      description: "整数",
      required: data.rawWaterType == "2",
      disabled: data.rawWaterType == "1",
      rangeText: "≦10",
    },
    {
      label: "その他要求水質",
      value: data.estimationConditionInput.otherWaterQualityRequirements,
      key: ["estimationConditionInput", "otherWaterQualityRequirements"],
      description: "自由記述",
      typeInput: "text",
    },
    {
      label: LANG.OPERATING_TIME,
      unit: "h/d",
      value: data.estimationConditionInput.inputOperatingTime,
      key: ["estimationConditionInput", "inputOperatingTime"],
      rule: REGEX_INPUT.interger,
      required: true,
      defaultValue: 24,
      description: "整数",
    },
  ];

  // Render Condition Input
  const renderConditionInput = useMemo(() => {
    return dataTableCondition.map((item, index) => {
      const limitTxt = item.limitText
        ? item.limitText
        : `${item.min || ""}~${item.max || ""}`;
      const minMax =
        item.min != null || item.max != null || item.limitText
          ? `制限: ${limitTxt}`
          : "";
      return (
        <>
          <RowInput
            mb={-8}
            labelBottom={!isPC && 15}
            labelCol={isMobile ? 24 : 3}
            contentCol={isMobile ? 24 : 12}
            borderBottom={index < 8}
            key={index}
            name={
              item.hint ? (
                <>
                  <span>{item.label}</span>{" "}
                  <QuestionToolTip title={<>{item.hint}</>} />
                </>
              ) : (
                item.label
              )
            }
            required={item.required}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Row
                style={{
                  flex: 1,
                }}
              >
                <Col
                  style={{ textAlign: "start", color: "gray", fontSize: 12 }}
                  span={12}
                >
                  {item.description}

                  {item.defaultValue && `・参考数値 : ${item.defaultValue}`}
                </Col>
                <Col
                  style={{ textAlign: "end", color: "gray", fontSize: 12 }}
                  span={12}
                >
                  {!item.rangeText
                    ? `${minMax || ""} `
                    : "制限: " + item.rangeText}
                </Col>
                <Col span={24}>
                  <Input
                    style={{
                      color: item.disabled && "transparent",
                    }}
                    id={item.id}
                    disabled={item.disabled || item.autoFill || false}
                    type={
                      isAndroid && item.typeInput !== "text" ? "number" : "text"
                    }
                    min={0}
                    className={
                      isErrorForm(
                        Array.isArray(item.key) ? item.key[1] : item.key
                      )
                        ? STYLE.BORDER_RED
                        : ""
                    }
                    ref={getRef(
                      Array.isArray(item.key) ? item.key[1] : item.key
                    )}
                    value={item.value}
                    onChange={(e) =>
                      handleChangeInputData(item.key, e.target.value, item.rule)
                    }
                    onKeyDown={hanldeKeyDown(item.ruleKeyDown || item.rule)}
                  />
                  {messErr[
                    Array.isArray(item.key) ? item.key[1] : item.key
                  ] && (
                    <Row justify={"end"}>
                      <Col
                        style={{
                          textAlign: "end",
                          color: "red",
                          fontSize: 12,
                          marginTop: 5,
                        }}
                        span={24}
                      >
                        {
                          messErr[
                            Array.isArray(item.key) ? item.key[1] : item.key
                          ]
                        }
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <p
                style={{
                  marginTop: 30,
                  width: 38,
                  fontSize: 12,
                }}
              >
                {item.unit}
              </p>
            </div>
          </RowInput>
        </>
      );
    });
  }, [dataTableCondition, messErr, data.estimationConditionInput]);
  const listProjectInfoInput = [
    {
      title: LANG.ESTIMATE_NUMBER,
      value: data.estimationNumber,
      key: "estimationNumber",
      required: false,
      rule: /^.{1,30}$/,
      hint: "見積番号が未取得の段階でもFS実施可能です。見積番号が決定したらバックナンバーで記載・上書き保存をしてください。",
    },
    {
      title: "注文主",
      value: data.customerName,
      key: "customerName",
      required: true,
      rule: /^.{1,55}$/,
    },
    {
      title: "納入先(会社)",
      value: data.companyDeliveryDestination,
      key: "companyDeliveryDestination",
      required: true,
      rule: /^.{1,55}$/,
    },
    {
      title: "納入先(工場)",
      value: data.factoryDeliveryDestination,
      key: "factoryDeliveryDestination",
      required: false,
      rule: /^.{1,55}$/,
    },
    {
      title: "案件名",
      value: data.constructionName,
      key: "constructionName",
      required: true,
      rule: /^.{1,55}$/,
    },
    {
      title: "商社",
      value: data.tradingCompany,
      key: "tradingCompany",
      required: false,
      rule: /^.{1,55}$/,
    },
  ];

  // handle change inpiut data
  const handleChangeInputData = (
    key,
    newValue,
    rule = null,
    type,
    ruleFunc
  ) => {
    if (rule && !newValue.match(rule) && newValue) {
      return;
    }
    if (ruleFunc && !ruleFunc(newValue)) {
      return;
    }
    if (type === "checkbox") {
      newValue = newValue === true ? "1" : "0";
    }
    if (Array.isArray(key)) {
      if (
        key[1] === "inputOperatingTime" &&
        (newValue === "0" || newValue > 24)
      ) {
        return;
      }

      const [parentKey, childrenKey] = key;
      const newData = { ...data };
      newData[parentKey][childrenKey] = newValue;
      setData(newData);
    } else {
      if (key === "rawWaterDetailType") {
        const rawWaterType = waterTypeDetailOption
          .slice(0, 4)
          .find((i) => i.key === newValue)
          ? "1"
          : "2";
        setData({
          ...data,
          [key]: newValue,
          rawWaterType,
        });
      } else setData({ ...data, [key]: newValue });
    }
  };
  const renderProjectInfoInput = useMemo(() => {
    return listProjectInfoInput.map((item, index) => {
      return (
        <RowInput
          labelCol={isMobile ? 24 : 3}
          contentCol={isMobile ? 24 : 12}
          borderBottom={index < 5}
          key={index}
          name={
            <>
              {item.title + " "}
              {item.hint && <QuestionToolTip title={<>{item.hint}</>} />}
            </>
          }
          required={item.required}
        >
          <Input
            className={isErrorForm(item.key) ? STYLE.BORDER_RED : ""}
            ref={getRef(item.key)}
            value={item.value}
            onChange={(e) =>
              handleChangeInputData(item.key, e.target.value, item.rule)
            }
          />
        </RowInput>
      );
    });
  }, [listProjectInfoInput]);
  // Add Option Money
  const handleAddOptionMoney = () => {
    setOptionMonney([
      ...optionMonney,
      {
        key: null,
        value: "",
        id: Date.now(),
        defaultValue: "",
      },
    ]);
  };
  // Check disabled delete or change option
  const isDisabledDeleteOrChnageOptionMoney = (key) => {
    return optionsDefault.find((i) => i.optionId === key);
  };

  // cancel changeOptionnMoney
  const onCancelChangeOptionMoney = () => {
    if (optionMoneyOriginnal.current) {
      setOptionMonney(JSON.parse(JSON.stringify(optionMoneyOriginnal.current)));
    }

    setVisible(false);
    setSaveModal(false);
  };

  // handle Focus input err
  useEffect(() => {
    const idFocus =
      optionMonney.length > 0
        ? "addMoneyOption-" + optionMonney[optionMonney.length - 1].id
        : null;
    if (idFocus) {
      const element = document.getElementById(idFocus);
      if (element) {
        element.focus();
      }
    }
  }, [optionMonney.length]);

  // INIT DATA FROM REDUX
  useEffect(() => {
    if (!_.isEmpty(dataStep1)) {
      setData(cloneObj(dataStep1));
      setNumberOfMachine(
        dataStep1.estimationAuxMachine.auxFiltrationPumpRegularNumber
      );
    } else {
      if (!id) {
        setData({
          ...cloneObj(data),
          options: cloneObj(optionsDefault),
          waterDevices: cloneObj(listWaterDeviceInit),
          injectionPumps: cloneObj(injectionPumpsInit),
          h2so4DrugConc: h2So4Init.h2so4DrugConc,
          h2so4ChemicalSpecificGravity: h2So4Init.h2so4ChemicalSpecificGravity,
        });
      }
    }
  }, [
    dataStep1,
    optionsDefault,
    listWaterDeviceInit,
    injectionPumpsInit,
    h2So4Init,
  ]);

  // // get Kbn
  const getKbn = async () => {
    const [optionsRes, waterTypeRes, waterTypeDetaiRes] = await Promise.all([
      EstimateApi.getOptions(),
      EstimateApi.getWater(),
      EstimateApi.getWaterDetail(),
    ]);
    const optionData = optionsRes.data.data
      .map((item) => {
        const editOption = optionsEdit.find((i) => i.optionId == item.optionId);
        return editOption || item;
      })
      .map((item, index) => ({
        key: item.optionId,
        value: item.optionName,
        defaultValue: item.defaultValue,
        id: index,
      }));
    setOptionMonney(optionData);

    // water type
    setWaterTypeOption(waterTypeRes.data.data);
    setWaterTypeOptionDetail(waterTypeDetaiRes.data.data);
    optionMoneyOriginnal.current = JSON.parse(JSON.stringify(optionData));
  };
  useEffect(() => {
    if (optionsEdit) {
      getKbn();
    }
  }, [optionsEdit]);

  //Get So Thap va estimated amount
  useEffect(() => {
    const timer = setTimeout(() => {
      const currentLv = getStandardLv(
        listLv,
        data.fiberLength,
        data.rawWaterType
      );
      // Hàm gọi API
      EstimateApi.getDataStep3({
        ...data,
        standardLv: currentLv,
      }).then((res) => {
        setNumberOfMachine(res.data.numberOfMachines);
        setEstimatedAmountOfWaterRecovered(res.data.netVolumeWaterRecovered);
      });
    }, 1000);

    // Hủy timeout cũ nếu giá trị của inputValue thay đổi
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [data, listLv]);

  // Handle Focus Input Error
  useEffect(() => {
    if (refFocus.current) {
      if (
        data &&
        data.estimationConditionInput &&
        data.estimationConditionInput.inputRawWaterVolume > 1
      ) {
        refFocus.current.focus();
      }
    }
  }, [formErrors]);

  // Get Ref => get current reff to focus
  function getRef(key) {
    if (!_.isEmpty(formErrors)) {
      const keys = Object.keys(formErrors);
      if (keys[0] === key) {
        return refFocus;
      }
    }
    return null;
  }
  function isErrorForm(key) {
    return Object.keys(formErrors).findIndex((item) => item === key) >= 0;
  }
  // Submit Form
  function validateForm() {
    const formErrors = {};
    const messErr = {};
    formErrors.customerName = isEmptyData(data.customerName);
    formErrors.companyDeliveryDestination = isEmptyData(
      data.companyDeliveryDestination
    );
    formErrors.constructionName = isEmptyData(data.constructionName);
    formErrors.rawWaterType = isEmptyData(data.rawWaterType);
    formErrors.rawWaterDetailType = isEmptyData(data.rawWaterDetailType);

    // H2So4
    formErrors.h2so4DrugConc = isEmptyData(data.h2so4DrugConc);
    formErrors.h2so4ChemicalSpecificGravity = isEmptyData(
      data.h2so4ChemicalSpecificGravity
    );

    // Validate condtionInput
    for (let i = 0; i < dataTableCondition.length; i++) {
      const conditionItem = dataTableCondition[i];
      const key = conditionItem.key[1];
      if (conditionItem.disabled) {
        continue;
      }
      if (conditionItem.hasOwnProperty("otherErr")) {
        if (
          (conditionItem.otherErr && conditionItem.required) ||
          (conditionItem.otherErr &&
            !conditionItem.required &&
            !isEmptyData(conditionItem.value))
        ) {
          formErrors[key] = true;
          messErr[key] = "見積不能";
        }
      } else if (conditionItem.required) {
        formErrors[key] = isEmptyData(conditionItem.value);
      }
    }

    // check validate firberLength
    formErrors.fiberLength = isEmptyData(data.fiberLength);

    // check Validate machine
    formErrors.auxFiltrationPumpBackupMachine =
      data.estimationAuxMachine.haveAuxFiltrationPump === "1" &&
      isEmptyData(data.estimationAuxMachine.auxFiltrationPumpBackupMachine);
    formErrors.auxBackwashPumpBackupMachine =
      data.estimationAuxMachine.haveAuxBackwashPump === "1" &&
      isEmptyData(data.estimationAuxMachine.auxBackwashPumpBackupMachine);
    formErrors.auxAirWashingBlowerBackupMachine =
      data.estimationAuxMachine.haveAuxAirWashingBlower === "1" &&
      isEmptyData(data.estimationAuxMachine.auxAirWashingBlowerBackupMachine);

    const filteredObj = Object.fromEntries(
      Object.entries(formErrors).filter(([key, value]) => value === true)
    );

    setFormErrors(filteredObj);
    setMessErr(messErr);
    if (
      data.estimationConditionInput.inputRawWaterVolume &&
      data.estimationConditionInput.inputRawWaterVolume <= 1
    ) {
      Modal.confirm({
        title: lang.CONFIRM,
        content: "原水量の値を見直してください。",
        onOk: () => {
          const element = document.getElementById("waterVolumn");
          if (element) {
            element.focus();
          }
        },
        centered: true,
        cancelButtonProps: { style: { display: "none" } },
      });
      return false;
    }
    return _.isEmpty(filteredObj);
  }
  const handleOpenSave = () => {
    if (!validateForm()) return;
    dispatch(openSave());
  };

  const onCanCelAddEdit = () => {
    Modal.confirm({
      title: lang.CONFIRM,
      centered: true,
      content:
        "入力内容を保存されていないですがキャンセルしてもよろしいですか？",
      onOk: () => {
        history.push("/Estimate/list");
        deboundClickModal.handleCloseModal();
      },
      onCancel: () => {
        deboundClickModal.handleCloseModal();
      },
    });
  };

  // Set Water Device
  const setListWaterDevice = (list = []) => {
    setData({
      ...data,
      waterDevices: list,
    });
  };
  // Set List InjectPump
  const setListInjectionPump = (list = []) => {
    setData({
      ...data,
      injectionPumps: list,
    });
  };
  return (
    <>
      <TitlePage name={"入力情報"}></TitlePage>

      <div className="des-content valueInputRight">
        <p style={{ padding: 0, margin: 0, marginBottom: 10 }} className="bold">
          {LANG.EXPLAIN}
        </p>
        <Collapse defaultActiveKey={["0"]} expandIconPosition="right">
          <Panel
            header={
              <div className={"wrapTitle"}>
                <IconNumber number={1} />
                <span style={{ fontWeight: "bold" }}>
                  {`${LANG.PROJECT_INFOMATION_INPUT_STEP1}`}
                </span>
              </div>
            }
            key={"0"}
          >
            {renderProjectInfoInput}
          </Panel>
        </Collapse>

        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <>
                <div className={"wrapTitle"}>
                  <IconNumber number={2} />
                  <span style={{ fontWeight: "bold" }}>
                    {LANG.CONDITION_INPUT_STEP1}
                  </span>
                </div>
              </>
            }
            key={"0"}
          >
            <RowInput
              labelCol={isMobile ? 24 : 3}
              contentCol={isMobile ? 24 : 12}
              borderBottom={false}
              name={LANG.WATER_TYPE}
              required
            >
              <Row gutter={[10, 10]}>
                <Col style={{ marginBottom: 10 }} span={isMobile ? 24 : 24}>
                  <Row>
                    <Col className="bold" span={24}>
                      <Row>
                        <Col span={12}> 用水</Col>
                        <Col span={12}> 排水</Col>
                      </Row>
                    </Col>
                    <Radio.Group
                      className={
                        isErrorForm("rawWaterDetailType")
                          ? STYLE.BORDER_RED
                          : ""
                      }
                      ref={getRef("rawWaterDetailType")}
                      value={data.rawWaterDetailType}
                      onChange={(e) =>
                        handleChangeInputData(
                          "rawWaterDetailType",
                          e.target.value
                        )
                      }
                      style={{ width: "100%" }}
                      vertical
                    >
                      <Row>
                        <Col span={12}>
                          {waterTypeDetailOption.slice(0, 4).map((i) => (
                            <div>
                              <Radio
                                style={{
                                  borderColor: "red",
                                }}
                                key={i.key}
                                value={i.key}
                              >
                                {i.value}
                              </Radio>
                            </div>
                          ))}
                        </Col>
                        <Col span={12}>
                          {waterTypeDetailOption.slice(4).map((i) => (
                            <div>
                              <Radio key={i.key} value={i.key}>
                                {i.value}
                              </Radio>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Row>
                </Col>
              </Row>
            </RowInput>
            <BreakLine />
            {renderConditionInput}
          </Panel>
        </Collapse>

        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <>
                <div className={"wrapTitle"}>
                  <IconNumber number={3} />
                  <span style={{ fontWeight: "bold" }}>
                    {LANG.FIBER_LENGTH_STEP1}
                  </span>
                </div>
              </>
            }
            key={"0"}
          >
            <RowInput
              labelCol={isMobile ? 24 : 3}
              contentCol={isMobile ? 24 : 21}
              borderBottom={false}
              name={
                <>
                  {LANG.FIBER_LENGTH_STEP1}{" "}
                  <QuestionToolTip
                    title={
                      <>
                        <div>
                          原水量が参考水量閾値近辺の場合は2パターンのFS実施を推奨します。
                        </div>
                      </>
                    }
                  />
                </>
              }
              required
            >
              <Row align={"middle"}>
                <Col span={isPC ? 3 : 24}>
                  <Radio.Group
                    className={
                      isErrorForm("fiberLength") ? STYLE.BORDER_RED : ""
                    }
                    ref={getRef("fiberLength")}
                    onChange={(e) =>
                      handleChangeInputData("fiberLength", e.target.value)
                    }
                    value={data.fiberLength}
                    // style={{ width: "100%" }}
                  >
                    {fiberLengthOption.map((i) => (
                      <div>
                        <Radio key={i.key} value={i.key}>
                          {i.value}
                        </Radio>
                      </div>
                    ))}
                  </Radio.Group>
                </Col>
                {data.fiberLength && (
                  <Col
                    style={{
                      textAlign: "start",
                      marginTop: !isPC ? 10 : 0,
                      marginLeft: isPC ? 0 : -10,
                    }}
                    span={isPC ? 3 : 24}
                  >
                    <DescriptionFiberLength filberLength={data.fiberLength} />
                  </Col>
                )}
                <Col
                  style={{
                    marginTop: !isPC ? 5 : 0,
                    textAlign: "start",
                    marginLeft: isPC ? 8 : 0,
                  }}
                  span={isPC ? 12 : 24}
                >
                  <>
                    {LANG.CONFIRM_FIBER_LENGTH}
                    <br />
                    {LANG.CONFIRM_FIBER_LENGTH2}
                  </>
                </Col>
              </Row>
            </RowInput>
          </Panel>
        </Collapse>

        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <>
                <div className={"wrapTitle"}>
                  <IconNumber number={4} />
                  <span style={{ fontWeight: "bold" }}>
                    {LANG.COMPLETE_SPARE_MACHINE_QUANTITY}
                  </span>
                </div>
              </>
            }
            key={"0"}
          >
            <RowInput
              type="number"
              labelCol={isMobile ? 24 : 3}
              contentCol={isMobile ? 24 : 8}
              borderBottom={false}
              // name={"完全予備数量"}
              name={
                <>
                  {LANG.COMPLETE_SPARE_MACHINE_QUANTITY}{" "}
                  <QuestionToolTip
                    title={
                      <>完全休止待機基の要求がある場合は入力してください。</>
                    }
                  />
                </>
              }
              // unit="基"
              // marginTopUnit={5}
            >
              <Row
                style={{
                  width: "100%",
                }}
              >
                <Col span={24}>整数・参考数値 : 1</Col>
                <Col span={20}>
                  <Input
                    type={isAndroid ? "number" : "text"}
                    onChange={(e) =>
                      handleChangeInputData(
                        "completeSpareMachineQuantity",
                        e.target.value,
                        REGEX_INPUT.interger
                      )
                    }
                    value={data.completeSpareMachineQuantity}
                    onKeyDown={hanldeKeyDown(REGEX_INPUT.interger)}
                    min={0}
                  />
                </Col>
                <Col
                  span={3}
                  style={{
                    marginLeft: 3,
                    marginTop: 5,
                  }}
                >
                  基
                </Col>
              </Row>
            </RowInput>
          </Panel>
        </Collapse>

        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <>
                <div className={"wrapTitle"}>
                  <IconNumber number={5} />
                  <span style={{ fontWeight: "bold" }}>{LANG.OPTION}</span>
                </div>
              </>
            }
            key={"0"}
          >
            <Row
              style={{
                marginTop: 10,
                paddingBlock: 15,
                // marginBottom: data.options.length > 0 && isPC ? -10 : 0,
                borderBottom: "1px solid rgb(223, 230, 236)",
              }}
              align={"middle"}
            >
              <Col span={12}>
                {data && data.options && data.options.length ? (
                  <Checkbox
                    checked={
                      data.options.filter((i) => i.checked).length ===
                      data.options.length
                    }
                    onChange={(e) =>
                      handleChangeSelectApllOption(e.target.checked)
                    }
                  >
                    <span>
                      <b>すべて選択</b>
                    </span>
                  </Checkbox>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            {/*check all*/}
            {renderListOptionMoney}
          </Panel>
        </Collapse>

        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <>
                <div className={"wrapTitle"}>
                  <IconNumber number={6} />
                  <span style={{ fontWeight: "bold" }}>
                    {LANG.WITH_OR_WITHOUT_ACCESSORIES}
                  </span>
                </div>
              </>
            }
            key={"0"}
          >
            <div>
              <HeaderText isLine={true} text={"回転機器類"} />
            </div>
            <BreakLine height={1} />
            {renderListDataWithOrwithoutMachine}
            <BreakLine height={2} />
            <div>
              <HeaderText isLine={true} text={"薬品注入装置類"} />
            </div>
            <BreakLine height={1} />
            <InjectionPump
              listInjectionPump={data.injectionPumps}
              setListInjectionPump={setListInjectionPump}
            />
            <RowInput
              type="number"
              labelCol={isMobile ? 24 : 3}
              contentCol={isMobile ? 24 : 8}
              borderBottom={false}
              name={"硫酸濃度"}
            >
              <Row
                style={{
                  width: "100%",
                  padding: isPC ? "10px 0" : "5px 0",
                }}
              >
                <Col span={20}>
                  <Input
                    className={
                      isErrorForm("h2so4DrugConc") ? STYLE.BORDER_RED : ""
                    }
                    ref={getRef("h2so4DrugConc")}
                    type={isAndroid ? "number" : "text"}
                    onChange={(e) => {
                      handleChangeInputData(
                        "h2so4DrugConc",
                        e.target.value,
                        REGEX_INPUT.intergerFrom1To100
                      );
                    }}
                    value={data.h2so4DrugConc}
                    onKeyDown={hanldeKeyDown(REGEX_INPUT.interger)}
                  />
                </Col>
              </Row>
            </RowInput>
            <BreakLine />
            <RowInput
              type="number"
              labelCol={isMobile ? 24 : 3}
              contentCol={isMobile ? 24 : 8}
              borderBottom={false}
              name={"硫酸比重"}
            >
              <Row
                style={{
                  width: "100%",
                  padding: isPC ? "10px 0" : "5px 0",
                }}
              >
                <Col span={20}>
                  <Input
                    className={
                      isErrorForm("h2so4ChemicalSpecificGravity")
                        ? STYLE.BORDER_RED
                        : ""
                    }
                    ref={getRef("h2so4ChemicalSpecificGravity")}
                    type={isAndroid ? "number" : "text"}
                    onChange={(e) =>
                      handleChangeInputData(
                        "h2so4ChemicalSpecificGravity",
                        e.target.value,
                        REGEX_INPUT.generateDecimalRegex(2),
                        "",
                        LIST_RULE[KEY_RULE_INPUT.MAX_LENGTH_NUMBER]
                      )
                    }
                    value={data.h2so4ChemicalSpecificGravity}
                    onKeyDown={hanldeKeyDown(REGEX_INPUT.number)}
                  />
                </Col>
              </Row>
            </RowInput>
            <BreakLine />

            <BreakLine height={2} />
            <div>
              <HeaderText isLine={true} text={"電気計装類"} />
            </div>
            <BreakLine height={1} />

            {/*電気計装類 */}

            <RowInput
              labelCol={isMobile ? 24 : 6}
              contentCol={isMobile ? 24 : 12}
              borderBottom={false}
              name={
                <div
                  style={{
                    padding: isPC ? "10px 0" : "5px 0",
                    marginTop: !isPC ? 10 : 0,
                  }}
                >
                  <Checkbox
                    checked={data.haveControlPanel === "1" ? true : false}
                    onChange={(e) =>
                      handleChangeInputData(
                        "haveControlPanel",
                        e.target.checked,
                        null,
                        "checkbox"
                      )
                    }
                  >
                    制御盤{" "}
                  </Checkbox>
                  <QuestionToolTip
                    title={
                      "電磁弁盤は標準搭載です。ここは単独で「制御」が必要な場合に選択してください。"
                    }
                  />
                </div>
              }
            ></RowInput>
            <BreakLine height={1} />
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Checkbox
                  style={{
                    marginTop: 9,
                  }}
                  checked={
                    data.waterDevices.filter((i) => i.checked).length ===
                    data.waterDevices.length
                  }
                  onChange={(e) => {
                    const prevAllState =
                      data.waterDevices.filter((i) => i.checked).length ===
                      data.waterDevices.length;
                    setListWaterDevice(
                      data.waterDevices.map((i) => ({
                        ...i,
                        checked: prevAllState ? false : true,
                      }))
                    );
                  }}
                ></Checkbox>{" "}
                <HeaderText
                  fontSize={14}
                  isLine={false}
                  text={"水質測定装置"}
                />
              </div>
            </div>
            <BreakLine />

            <WaterDevice
              listWaterDevice={data.waterDevices}
              setListWaterDevice={setListWaterDevice}
            />
          </Panel>
        </Collapse>
      </div>
      <FooterPage>
        <Row>
          <Col span={8}>
            <div
              className={"actionCancel"}
              onClick={() => {
                deboundClickModal.handleOpenModal(() => onCanCelAddEdit());
              }}
            >
              <CloseCircleOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.CANCEL}
            </div>
          </Col>
          <Col span={8}>
            <ButtonSave
              validateForm={validateForm}
              isValidate={false}
              handleOpenSave={handleOpenSave}
              body={{
                ...cloneObj(formDataPostDefault),
                ...cloneObj(getDataPost()),
                ...dataStep3,
              }}
            />
          </Col>
          <Col span={8}>
            <div onClick={() => handleNextStep()} className={"actionRight"}>
              <SendOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              選定
            </div>
          </Col>
        </Row>
      </FooterPage>

      <Loading show={loading} />
    </>
  );
};

export default AddStep1;

import { EditOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { HeaderText } from "../../../components/common/headerText";
import { KEY, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { deboundClickModal, formatCurrencyJPY } from "../../../utils/helpers";
import EditDrugInjection from "./EditDrugInjection";
import classText from "../../../models/control/text";
import { error } from "highcharts";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import { ChemicalInjectionAPI } from "../../../api/backend/chemicalInjection";
import { render } from "@fullcalendar/core/preact.js";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";

const DrugInjection = ({ isLoad }) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [detail, setDetail] = useState(null);
  const showModal = (row) => {
    setDetail(row);
    setIsModalVisible(true);
  };

  const handleCancel = (reset = true) => {
    setIsModalVisible(false);
    setDetail(null);
    if (reset) {
      getListChemicalInjection();
    }
  };

  useEffect(() => {
    if (isError) {
      deboundClickModal.handleCloseModal();
    }
  }, [isError]);

  const getrangeText = (listRange, listRangevalue, index) => {
    if (index === listRangevalue.length - 1) {
      return "≦ " + formatCurrencyJPY(listRange[0].value);
    } else if (index === 0) {
      return "＞" + formatCurrencyJPY(listRange[listRange.length - 1].value);
    } else {
      if (listRange.length >= 2) {
        return `＞${formatCurrencyJPY(
          listRange[listRange.length - 1 - index].value
        )}、≦${formatCurrencyJPY(listRange[listRange.length - index].value)}`;
      }
    }
  };
  const handleSetData = (data) => {
    const lisData = [];
    data.forEach((item, indexRow) => {
      let { listRange, listRangevalue, id, name, remark, unit } = item;
      listRange = listRange.sort((r1, r2) => r1.orderNumber - r2.orderNumber);
      listRangevalue = listRangevalue.sort(
        (r1, r2) => r1.orderNumber - r2.orderNumber
      );
      listRangevalue.forEach(({ value, order }, index) => {
        const newRow = {
          id,
          name,
          unit,
          remark,
          listRange,
          listRangevalue,
          rangeValue: value,
          rowNumber: listRangevalue.length,
          range: getrangeText(listRange, listRangevalue, index),
          isFisrtRow: index === 0 ? true : false,
          rowColor: indexRow % 2 === 1,
        };
        lisData.push(newRow);
      });
    });
    setDataTable(lisData);
  };
  // GET LIST CHEMICAL
  const getListChemicalInjection = async () => {
    try {
      const res = await ChemicalInjectionAPI.getListChemicalInjection();
      const data = res.data.data;
      handleSetData(data);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  };

  // GET DATA
  useLoadTabDataEffect(isLoad, getListChemicalInjection);

  const columns = [
    {
      title: <div style={{ textAlign: "start" }}>項目</div>,
      dataIndex: "name",
      key: "name",
      render: (text, row, index) => ({
        children: text,
        props: {
          rowSpan: row.isFisrtRow ? row.rowNumber : 0,
        },
      }),
    },
    {
      title: <div style={{ textAlign: "start" }}>原水濁度条件</div>,
      dataIndex: "range",
      key: "range",
      render: (text, row) => <span>{formatCurrencyJPY(text)}</span>,
    },
    {
      title: <div style={{ textAlign: "start" }}>注入率</div>,
      dataIndex: "rangeValue",
      key: "rangeValue",
      render: (text) => <span>{formatCurrencyJPY(text)}</span>,
    },
    {
      title: <div style={{ textAlign: "start" }}>単位</div>,
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: <div style={{ textAlign: "start" }}>備考</div>,
      dataIndex: "remark",
      key: "remark",
      render: (text, row, index) => ({
        children: text,
        props: {
          rowSpan: row.isFisrtRow ? row.rowNumber : 0,
        },
      }),
    },
    {
      title: LANG.ACTION,
      dataIndex: "deleete",
      key: "delete",
      align: "center",
      render: (text, row, index) => ({
        children: (
          <div>
           <Tooltip title={lang.EDIT}>
           <Button
              onClick={() => showModal(row)}
              className={"iconItem"}
              icon={<EditOutlined />}
            />
           </Tooltip>
          </div>
        ),
        props: {
          rowSpan: row.isFisrtRow ? row.rowNumber : 0,
        },
      }),
    },
  ];

  // ROW CLASS NAME
  const getRowClassName = (record, index) => {
    const rowNumber = record.rowNumber;
    if (record.rowColor) {
      return "row-color";
    } else {
      return "";
    }
  };
  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <div className="mb10"></div>
      <Table
        rowClassName={getRowClassName}
        columns={columns}
        dataSource={dataTable}
        pagination={false}
        bordered
        className="gridSearch2"
      />
      {isModalVisible && (
        <EditDrugInjection
          detail={detail}
          isModalVisible={isModalVisible}
          onClose={handleCancel}
        />
      )}
    </div>
  );
};

export default DrugInjection;

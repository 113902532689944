import { EditOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ESTIMATE_STEP, lang } from "../../../constants/common.const";
import {
    getQuerySearch
} from "../../../constants/utils";
import { setStepEstimate } from "../../../redux/estimate";
function ButtonEdit({ revup,status }) {
    const dispatch = useDispatch()
  const history = useHistory();
  const { id } = useParams();
  const { version } = getQuerySearch();
  return (
    <div
      onClick={() => {
        dispatch(setStepEstimate(ESTIMATE_STEP.STEP1));
        history.push("/Estimate/Edit/" + id + `?version=${version}` + `&status=${status}`+ (revup ? `&isRevup=true`:""));
      }}
      className={"actionLeft"}
    >
      <EditOutlined
        className="mr4"
        style={{ fontWeight: "bold", fontSize: 16 }}
      />
      {revup  ? "REVUP" : lang.EDIT}
    </div>
  );
}

export default ButtonEdit;

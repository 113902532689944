import Api from "@src/api";

export const ChemicalInjectionAPI =  {
  //search machine
  getListChemicalInjection(params = {}) {
    return Api().get("/api/office/ChemicalInjection", { params });
  },
  update(data) {
    return Api().put(`/api/office/ChemicalInjection`, data,);
  }
};

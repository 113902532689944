import { useEffect, useRef } from "react";

const useLoadTabDataEffect = (
  depend,
  callback = () => {},
  isFirstTab = false
) => {
  const listDepend = Array.isArray(depend) ? [...depend] : [depend];
  const isFirstRender = useRef(true);

  useEffect(() => {
   
      const isLoad = listDepend[0];
      if (isFirstRender.current) {
        callback();
        isFirstRender.current = false;
      } else if (isLoad) {
        callback();
      }
    
  }, listDepend);
};

export default useLoadTabDataEffect;

import React from "react";
import { HeaderText } from "../../../components/common/headerText";
import TableChemicalTank from "./TableChemicalTank";
import TableInFusionPump from "./TableInFusionPump";
const ChemicalInjection = ({isLoad}) => {
  
  return (
    <div>
     <TableChemicalTank isLoad={isLoad} />
     <TableInFusionPump isLoad={isLoad} />
    </div>
  );
};
export default ChemicalInjection;

import { Tabs } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../../constants/common.const";
import {
    getQuerySearch,
    pushObject2QueryReplace,
} from "../../../constants/utils";
import DrugInjection from "../Drug_Injection/DrugInjection";
import LvManage from "../Lv_Manage/LvManage";

const TabMaxtrixManage = (activeMaster) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { tab } = getQuerySearch();
  const [activeKey, setActiveKey] = useState(tab || "0");
  const onChange = (key) => {
    setActiveKey(key);
    pushObject2QueryReplace({ tab: key });
  };

  const items = [
    {
      key: "0",
      label: <>{<span>{"基準LV"}</span>}</>,
      children: <LvManage activeMaster={activeMaster} isLoad={activeKey === "0"} />,
    },
    {
      key: "1",
      label: <>{<span>{"薬品注入率"}</span>}</>,
      children: (
        <>
          <DrugInjection  isLoad={activeKey === "1"} />
        </>
      ),
    },
  ];

  return (
    <div
      style={{
        marginTop: -10,
      }}
    >
      <Tabs
        hideAdd
        activeKey={activeKey}
        onChange={onChange}
        type="card"
        items={items}
        animated={{ inkBar: true, tabPane: true }}
        size={isPC ? "large" : "small"}
        tabPosition={"top"}
      />
    </div>
  );
};
export default TabMaxtrixManage;

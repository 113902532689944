import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ChemicalTankApi } from "../../../api/backend/chemicalTank";
import Loading from "../../../component/Desktop/loading/loading";
import { HeaderText } from "../../../components/common/headerText";
import { COMMON_CONSTANT, KEY, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import { InternalFeeApi } from "../../../api/backend/internalFee";
import { deboundClickModal, formatCurrencyJPY } from "../../../utils/helpers";
import AddFeeRate from "./AddFeeRate";
import EditInternalFee from "./EditInternalFee";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";
const { Panel } = Collapse;
const InternalFee = ({isLoad}) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [loading, setLoading] = useState(false);
  const [dataFee, setDataFee] = useState([]);

  const [idItem, setIdItem] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [costManagement, setCostManagement] = useState([]);
  const [detail, setDetail] = useState(null);
  // Close Modal
  const onClose = (isRefetch = true) => {
    setDetail(null);
    setOpenModal(false);
    if (isRefetch) {
      getListInternalFee();
    }
  };

  const getListInternalFee = async () => {
    try {
      setLoading(true);
      const optionsRes = await InternalFeeApi.getInternalFee();
      const optionData = optionsRes.data.data.map((item, index) => ({
        key: item.internalFeeId,
        projectName: item.projectName,
        amount: item.amount,
        remark: item.remark,
      }));
      setDataFee(optionData);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  // GET DATA
  useLoadTabDataEffect(isLoad,getListInternalFee);

  useEffect(() => {
    if (isError) {
      deboundClickModal.handleCloseModal();
    }
  }, [isError]);

  const columns = [
    {
      title: <div className="text-start">項目</div>,
      dataIndex: "projectName",
      key: "projectName",
      align: "start",
      width: "20%",
      render: (text, record, index) => {
        return <div className="text-start">{record.projectName}</div>;
      },
    },
    {
      title: <div className="text-start">金額(千円)</div>,
      dataIndex: "amount",
      key: "amount",
      width: "20%",
      render: (text, record) => {
        return <span>{formatCurrencyJPY(record.amount)}</span>;
      },
    },
    {
      title: <div className="text-start">{lang.REMARK}</div>,
      dataIndex: "remark",
      key: "remark",
      align: "start",
      width: "50%",
      render: (text, record, index) => {
        return <span>{record.remark}</span>;
      },
    },
    {
      title: LANG.ACTION,
      dataIndex: "edit",
      key: "edit",
      align: "center",
      width: "10%",
      render: (id, record, index) => {
        return (
          <Row gutter={!isPC && [4, 4]} justify={"center"}>
            <Col>
              {" "}
              <Tooltip title={lang.EDIT}>
              <Button
                onClick={() => {
                  setOpenModal(true);
                  setDetail(record);
                }}
                className={"iconItem"}
                icon={<EditOutlined />}
              />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <div>
      <Collapse
        className="mt15"
        defaultActiveKey={["1"]}
        expandIconPosition="right"
      >
        <Panel
          header={
            <div className={"wrapTitle"}>
              <span style={{ fontWeight: "bold" }}>{"社内費用"}</span>
            </div>
          }
          key={"1"}
        >
          <div style={{
            marginTop:20,
            paddingBottom:10
          }}>
            <Table
              dataSource={dataFee}
              columns={columns}
              pagination={false}
              className="gridSearch"
              scroll={{ x: !isPC && "100%" }}
              bordered
            />
          </div>
        </Panel>
      </Collapse>

      <Loading show={loading} />
      {openModal && (
        <EditInternalFee onClose={onClose} detail={detail} open={openModal} />
      )}
    </div>
  );
};
export default InternalFee;

import React, { useState } from "react";
import { Button, Col, Drawer, Input, Modal, Row } from "antd";
import { KEY, lang } from "../../constants/common.const";
import img1000 from "./../../assets/1000.PNG";
import img1500 from "./../../assets/1500.PNG";
import './../../page/Estimate/Estimate.scss'
import { useMediaQuery } from "react-responsive";
function DescriptionFiberLength({ filberLength }) {
  const [open, setOpen] = useState(false);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const comment =
    filberLength 
      ? "ラインナップ"
      : "";
  const image =
    filberLength == 1000 ? img1000 : filberLength == 1500 ? img1500 : "";
  return (
    <>
      <span
        style={{ cursor: "pointer", marginLeft: 10, color: "#0080cb" }}
        onClick={() => setOpen(true)}
      >
        {comment}
      </span>
      <Modal
        centered
        width={isPC ? "60%" : "99%"}
        bodyStyle={{ height: "auto", overflow: "auto"}}
        closeIcon={<></>}
        className="modalImage"
        open={open}
        footer={[
          <Button
            onClick={() => setOpen(false)}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CLOSE}
          </Button>,
        ]}
      >
        <Row className="mb10 " >
          <img
            alt="description for fiber length"
            src={img1000}
            style={{ width: "100%", height: "auto" }}
          />
            <img
            alt="description for fiber length"
            src={img1500}
            style={{ width: "100%", height: "auto",marginTop:20 }}
          />
        </Row>
      </Modal>
    </>
  );
}

export default DescriptionFiberLength;

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { WaterDeviceApi } from "../../../api/backend/waterDevice";
import Loading from "../../../component/Desktop/loading/loading";
import { COMMON_CONSTANT, KEY, lang, } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import { deboundClickModal, formatCurrencyJPY } from "../../../utils/helpers";
import AddWaterDevice from "./AddWaterDevice";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";

const WaterDevice = ({isLoad}) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [amountManagement, setAmountManagement] = useState([]);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idItem, setIdItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  // Close Modal
  const onClose = (reset = true) => {
    setIdItem(null);
    setOpenModal(false);
    if(reset){
      getListAmountManagement();
    }
  };
  // get list option
  const getListAmountManagement = async () => {
    try {
      setLoading(true);
      const optionsRes = await WaterDeviceApi.getListWaterDevice();
      const optionData = optionsRes.data.data.map((item, index) => ({
        key: item.amountId,
        amountTable: item.amountTable,
        unitPrice: item.unitPrice,
        priceBasis: item.priceBasis,
        remark: item.remark,
        canDelete:item.canDelete
      }));
      setAmountManagement(optionData);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  // GET DATA
  useLoadTabDataEffect(isLoad,getListAmountManagement);

  useEffect(() => {
    if (isError) {
      deboundClickModal.handleCloseModal();
    }
  }, [isError]);
  const columnOptionMoney = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: "5%",
      render: (text, record, index) => {
        return <span>{++index}</span>;
      },
      align: "center",
    },
    {
      title: <div className="text-start">金額表</div>,
      dataIndex: "amountTable",
      width: "20%",
      key: "amountTable",
      render: (text, record, index) => {
        return <span>{record.amountTable}</span>;
      },
    },
    {
      title: <div className="text-start">単価(千円/基)</div>,
      width: "10%",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text, record, index) => {
        return <span>{formatCurrencyJPY(record.unitPrice)}</span>;
      },
    },
    {
      title: <div className="text-start">{"単価根拠"}</div>,
      dataIndex: "priceBasis",
      key: "priceBasis",
      width: "20%",
      render: (text, record) => {
        return <span>{record.priceBasis}</span>;
      },
    },
    {
      title: <div className="text-start">{lang.REMARK}</div>,
      dataIndex: "remark",
      key: "remark",
      width: "35%",
      render: (text, record) => {
        return <span>{record.remark}</span>;
      },
    },
    {
      title: LANG.ACTION,
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: "15%",
      render: (id, record, index) => {
        return (
          <Row gutter={!isPC && [4, 4]} justify={"center"}>
            <Col>
              {" "}
              <Tooltip title={lang.EDIT}>
              <Button
                onClick={() => {
                  setOpenModal(true);
                  setIdItem(record.key)
                }}
                className={"iconItem"}
                icon={<EditOutlined />}
              />
              </Tooltip>
            </Col>
            <Col>
              {" "}
              <Tooltip title={lang.DELETE}>
              <Button
              disabled={record.canDelete}
                onClick={() => handleDeleteAmountManagement(record.key)}
                className={"iconItem"}
                icon={<DeleteOutlined />}
              />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  // Const delete OptionMoney
  const handleDeleteAmountManagement = (key) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          await WaterDeviceApi.deleteWaterDevice(key);
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
          getListAmountManagement();
        } catch (error) {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      },
    });
   
  };

  const getContenDelete = ({ itemName, price }) => (
    <Row>
      <Col className="mb10" span={24}>本体オプションを削除してよろしいでしょうか？</Col>
      <Col span={24}>
        <Row>
          <Col>項目 :&nbsp; </Col>
          <Col>{itemName}</Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col>金額(千円/基) : &nbsp; </Col>
          <Col>{price}</Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <div>
      <div className="mb10">
      </div>
      <Row
        style={{
          marginBottom: 5,
        }}
        align={"middle"}
        justify="space-between "
      >
        <Col span={12}>
          <Button
            style={{
              background: "#5f8de5",
              color: "#fff",
              border: "none",
              marginBottom:5
            }}
            onClick={() => setOpenModal(true)}
            icon={<PlusOutlined />}
          >
            {lang.ADD}
          </Button>
        </Col>
        <Col span={12} className="right"></Col>
      </Row>
      <Table
        className="gridSearch"
        rowKey="id"
        dataSource={amountManagement}
        columns={columnOptionMoney}
        pagination={false}
        bordered
      />
      <Loading show={loading} />
     {openModal &&  <AddWaterDevice open={openModal} onClose={onClose} id={idItem} />}
    </div>
  );
};
export default WaterDevice;

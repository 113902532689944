import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import E404 from "@src/page/Error/404";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../constants/common.const";
import { updateAllowRoute } from "@src/redux/auth";
import { useDispatch } from "react-redux";
import { checkCoincidePath } from "../utils/roles";
import CONFIG_ROUTER from "./roles/configRoles";

function PrivateRouter() {
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { listMenuCode } = useSelector((state) => state.sideBar);
  const { roleKey } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [allowedRoutes, updateAllowedRoutes] = React.useState([]);

  React.useEffect(() => {
    const array = [];
    CONFIG_ROUTER.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (
            !Array.isArray(child.roleHides) ||
            !child.roleHides.includes(roleKey)
          )
            array.push(child);
        });
      } else {
        if (!Array.isArray(item.roleHides) || !item.roleHides.includes(roleKey))
          array.push(item);
      }
    });
    updateAllowedRoutes(array);
    dispatch(updateAllowRoute(array));
    if (array.length) {
      let isRedirect = true;
      array.forEach((item) => {
        if (checkCoincidePath(item.path, location.pathname)) {
          isRedirect = false;
        }
      });
      if (isRedirect) {
        // history.push(array[0].path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMenuCode, roleKey]);
  return allowedRoutes.length > 0 ? (
    <Switch>
      <Route exact path="/">
        <Redirect to={"/home"} />
      </Route>
      {allowedRoutes.map((route) => {
        const { exact, path, component: Component } = route;
        return (
          <Route
            exact={exact}
            key={path}
            path={path}
            render={(props) => <Component {...props} />}
          ></Route>
        );
      })}
      {/* Error */}
      <Route exact path="*">
        <Redirect to={"/home"} />
      </Route>
    </Switch>
  ) : (
    <Switch></Switch>
  );
}

export default PrivateRouter;

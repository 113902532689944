import {
  CalculatorOutlined,
  ControlOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  UsergroupAddOutlined,MenuUnfoldOutlined,
  ProfileOutlined
} from "@ant-design/icons";
import { ROUTER_PAGE } from "@src/constants/router.const";
import OfficeDetail from "@src/page/Office/detail";
import { Profile2User } from "iconsax-react";
import React from "react";
import { LANG } from "../../constants/lang.const.js";
import AddEstimate from "../../page/Estimate/add/index.jsx";
import ListEstimate from "../../page/Estimate/list";
import ViewEstimate from "../../page/Estimate/view/index.jsx";
import AirView from "../../page/Machine_Management/air.jsx";
import ModelManagement from "../../page/Model_Management/list.jsx";
import MachineList from "../../page/Machine_Management/list";
import PumpView from "../../page/Machine_Management/pump.jsx";
import MachineView from "../../page/Machine_Management/view.jsx";
import MasterManage from "../../page/MasterManagement/MasterManage.jsx";
import UserList from "../../page/user_management/list.jsx";
import { RoleHeadOffice } from "./roles.const.js";

const CONFIG_ROUTER = [
  // dashboard (homepage)
  {
    show: true,
    component: OfficeDetail,
    icon: <HomeOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.DASHBOARD,
    menuName: "ホームページ",
    exact: true,
    key: "DASHBOARD",
  },
  {
    key: "ESTIMATE_LIST",
    icon: <CalculatorOutlined style={{ fontSize: "20px" }} />,
    menuName: "見積管理",
    show: true,
    component: ListEstimate,
    path: "/Estimate/list",
    exact: true,
  },
  {
    show: true,
    key: "MASTER",
    component: MasterManage,
    path: "/master/management",
    exact: true,
    icon: <ProfileOutlined style={{ fontSize: "20px" }} />,
    roleHides: [RoleHeadOffice.USER],
    menuName: LANG.MASTER_MANAGEMENT,
  },
  {
    show: false,
    key: "ESTIMATE_LIST",
    component: AddEstimate,
    path: "/Estimate/Add",
    exact: true,

    menuName: "追加",
    icon: <PlusCircleOutlined style={{ fontSize: "20px" }} />,
  },
  {
    show: false,
    key: "ESTIMATE_LIST",
    component: ViewEstimate,
    path: "/Estimate/View/:id",
    exact: true,
  },
  {
    show: false,
    key: "ESTIMATE_LIST",
    component: AddEstimate,
    path: "/Estimate/Edit/:id",
    exact: true,
  },
  {
     key: "MASTER",
    menuCode: "M060",
    icon: <ControlOutlined style={{ fontSize: "20px" }} />,
    menuName: "機種管理",
    path: "/machine/list",
    component: MachineList,
    show: false,
  },
  {
    menuName: "追加",
     key: "MASTER",
    component: MachineView,
    path: "/machine/create/new",
    exact: true,
    menuCode: "M061",
    show: false,
    icon: <PlusCircleOutlined style={{ fontSize: "20px" }} />,
    roleHides: [RoleHeadOffice.USER],
  },
  {
    show: false,
     key: "MASTER",
    component: MachineView,
    path: "/machine/:id",
    exact: true,
  },
  {
    show: false,
     key: "MASTER",
    component: MachineView,
    path: "/machine/:id/update",
    exact: true,
    roleHides: [RoleHeadOffice.USER],
  },
  {
    show: false,
     key: "MASTER",
    component: AirView,
    path: "/air/create/new",
    exact: true,
    roleHides: [RoleHeadOffice.USER],
  },
  {
    show: false,
     key: "MASTER",
    component: AirView,
    path: "/air/:id",
    exact: true,

    // roleHides:[RoleHeadOffice.USER]
  },
  {
    show: false,
     key: "MASTER",
    component: AirView,
    path: "/air/:id/update",
    exact: true,
    roleHides: [RoleHeadOffice.USER],
  },
  {
    show: false,
     key: "MASTER",
    component: PumpView,
    path: "/pump/create/new",
    exact: true,
    roleHides: [RoleHeadOffice.USER],
  },
  {
    show: false,
     key: "MASTER",
    component: PumpView,
    path: "/pump/:id",
    exact: false,
  },
  {
    show: false,
     key: "MASTER",
    component: PumpView,
    path: "/pump/:id/update",
    exact: true,
    roleHides: [RoleHeadOffice.USER],
  },
  {
    show: true,
    component: UserList,
    icon: <Profile2User style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.USER_INFO,
    menuName: "ユーザ設定",
    exact: true,
    key: "USER_INFO",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.USER],
  },
];

const splicePath = (path) => {
  const index = path.indexOf(":");
  return index >= 0 ? path.substring(0, index - 1) : path;
};
const addRouter = (routerItem, routerObj) => {
  if (routerItem.hasOwnProperty("key") && routerItem.hasOwnProperty("path")) {
    // có nhiều route cùng key thì chuyển thành mảng
    if (routerObj.hasOwnProperty(routerItem.key)) {
      if (typeof routerObj[routerItem.key] === "string") {
        routerObj[routerItem.key] = [
          routerObj[routerItem.key],
          splicePath(routerItem.path),
        ];
      } else {
        routerObj[routerItem.key] = routerObj[routerItem.key].concat([
          splicePath(routerItem.path),
        ]);
      }
    } else {
      // Chỉ có 1 key 1 route thì cho về dạng string
      routerObj[routerItem.key] = splicePath(routerItem.path);
    }
  }
};
const getRouterPage = (router, routerObj) => {
  router.forEach((routerItem) => {
    addRouter(routerItem, routerObj);
    if (
      routerItem.hasOwnProperty("children") &&
      Array.isArray(routerItem.children)
    ) {
      getRouterPage(routerItem.children, routerObj);
    }
  });
};

const finalRouter = () => {
  const routerObj = {};
  getRouterPage(CONFIG_ROUTER, routerObj);
  return routerObj;
};
export const ROUTER = finalRouter();
export default CONFIG_ROUTER;

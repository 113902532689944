import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import {
  getQuerySearch,
  pushObject2QueryReplace,
} from "../../../constants/utils";
import { Machine_list } from "../../Machine_Management/list";
import MainUnitOption from "../Main_Unit_Option/MainUnitOption";
import ApiMachine from "./../../../api/backend/machineManagement";
import ApiPump from "./../../../api/backend/pump";
import ApiAirWashing from "./../../../api/backend/airWashing";
import TabControlPanel from "../Tab_Control_Panel/TabControlPanel";
import WaterDevice from "../Water_Device/WaterDevice";
import ChemicalInjection from "../Chemical_Injection/ChemicalInjecttion";

const TabManageModel = ({ activeMaster }) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { tab } = getQuerySearch();
  const [activeKey, setActiveKey] = useState(tab || "0");
  const onChange = (key) => {
    setActiveKey(key);
    pushObject2QueryReplace({ tab: key });
  };

  const items = [
    {
      key: "0",
      label: <>{<span>{"イーグルファイバー本体"}</span>}</>,
      children: (
        <Machine_list
          activeMaster={activeMaster}
          isLoad={activeKey === "0"}
          isFirstTab={true}
          placeholder={"EF型式"}
          apiSearch={ApiMachine.search}
          apiDelete={ApiMachine.delete}
          modelName="efModel"
          type="1"
        />
      ),
    },
    {
      key: "1",
      label: <>{<span>{LANG.TAB_MAIN_UNIT}</span>}</>,
      children: (
        <>
          <MainUnitOption
            isLoad={activeKey === "1"}
            location={window.location}
          />
        </>
      ),
    },
    {
      key: "2",
      label: <>{<span>{"ポンプ"}</span>}</>,
      children: (
        <Machine_list
          isLoad={activeKey === "2"}
          placeholder={"機種番号"}
          apiSearch={ApiPump.search}
          apiDelete={ApiPump.delete}
          modelName="modelNumber"
          type="2"
        />
      ),
    },
    {
      key: "3",
      label: (
        <>
          <span>{"空洗ブロワ"}</span>
        </>
      ),
      children: (
        <>
          <Machine_list
            isLoad={activeKey === "3"}
            placeholder={"機種番号"}
            apiSearch={ApiAirWashing.search}
            apiDelete={ApiAirWashing.delete}
            modelName="modelNumber"
            type="3"
          />
        </>
      ),
    },
    {
      key: "4",
      label: (
        <>
          <span>{"薬品注入装置"}</span>
        </>
      ),
      children: (
        <>
          <ChemicalInjection isLoad={activeKey === "4"} />
        </>
      ),
    },
    {
      key: "5",
      label: (
        <>
          <span>{"水質測定装置"}</span>
        </>
      ),
      children: (
        <>
          <WaterDevice isLoad={activeKey === "5"} />
        </>
      ),
    },
    {
      key: "6",
      label: (
        <>
          <span>{"制御盤"}</span>
        </>
      ),
      children: (
        <>
          <TabControlPanel isLoad={activeKey === "6"} />
        </>
      ),
    },
  ];

  return (
    <div
      style={{
        marginTop: -10,
      }}
    >
      <Tabs
        hideAdd
        activeKey={activeKey}
        onChange={onChange}
        type="card"
        items={items}
        animated={{ inkBar: true, tabPane: true }}
        size={isPC ? "large" : "small"}
        tabPosition={"top"}
      />
    </div>
  );
};
export default TabManageModel;

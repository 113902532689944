class classPopup {
    constructor(title = '', customTitle, ok, show = false, footer, width, body) {
        this.title = title;
        this.customTitle = customTitle;
        this.show = show;
        this.footer = footer;
        this.width = width;
        this.body = body;
        this.ok = ok;
    }
};

export default classPopup;
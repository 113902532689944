import Api from "@src/api";
export default {
  getCLBookMark(factoryCode, offset, limit) {
    const endpoint =
      "Bookmark?siteOfficeMasterCode=" +
      factoryCode +
      "&offset=" +
      offset +
      "&limit=" +
      (limit ? limit : 4);
    return Api().get(endpoint);
  },
  getCLResult(factoryCode, offset, limit) {
    const endpoint =
      "CheckResult?siteOfficeMasterCode=" +
      factoryCode +
      "&offset=" +
      offset +
      "&limit=" +
      (limit ? limit : 6) +
      "&status=1,2,3,4,5";
    return Api().get(endpoint);
  },
  getChecklistFilter(params, factoryCode, offset) {
    const { name, type } = params;
    if (type === "first") {
      offset = 0;
    }
    let endpoint =
      "Checklists/Mobile?siteOfficeMasterCode=" +
      factoryCode +
      "&offset=" +
      offset +
      "&limit=10";
    if (!name) {
      return Api().get(endpoint);
    } else {
      // let params = { checklistName: name };
      // return Api(config).get(endpoint, { params: params });
      endpoint += `&checklistName=${encodeURIComponent(name)}`;
      return Api().get(endpoint);
    }
  },
  getChecklistName(factoryCode) {
    const endpoint = `Kbn/checklistList?siteOfficeMasterCode=${factoryCode}`;
    return Api().get(endpoint);
  },
  getCheckResultStatus() {
    return Api().get("Kbn/checkResultStatus");
  },
  getCheckListResultHistory(params, offsetHistory) {
    let endpoint = "check-results";
    if (params) {
      const { checklistId, inspectorName, status, fromDate, toDate, type } =
        params;

      if (type && (type === "search" || type === "first")) {
        offsetHistory = 0;
      }

      if (type && type === "first") {
        endpoint = endpoint + "?offset=" + offsetHistory + "&limit=10";
      } else {
        endpoint =
          "check-results/?inspectorName=" +
          inspectorName +
          "&checklistId=" +
          checklistId.toString() +
          "&status=" +
          status +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&offset=" +
          offsetHistory +
          "&limit=10";
      }
    } else {
      endpoint = endpoint + "?offset=" + offsetHistory + "&limit=10";
    }
    return Api().get(endpoint);
  },
  setBookmarkInChecklist(id, factoryCode) {
    const endpoint = `Bookmark?siteOfficeMasterCode=${factoryCode}&checklistId=${id}`;
    return Api().post(endpoint);
  },
  removeBookmarkInChecklist(id) {
    const endpoint = "Bookmark/" + id;
    return Api().delete(endpoint);
  },
  getAllChecklistHistory(factoryCode, offset, params) {
    const endpoint =
      "CheckResult?siteOfficeMasterCode=" +
      factoryCode +
      "&offset=" +
      offset +
      "&limit=18";
    if (params) {
      return Api().get(endpoint, { params: params });
    } else {
      return Api().get(endpoint);
    }
  },
  getAllGroup(params) {
    const { factoryCode } = params;
    const endpoint = `Checklists/Mobile/Group?siteOfficeMasterCode=${factoryCode}&limit=5`;
    return Api().get(endpoint);
  },
  getInputJudgment() {
    return Api().get("/Kbn/inputJudgment");
  },
  getWorkingStatus() {
    return Api().get("/Kbn/machineWorkingStatus");
  },
  getWeekendChecklist() {
    return Api().get("/Kbn/WeekendChecklists");
  },
  getDataGroup(params) {
    const { offset, groupId } = params;
    const endpoint = `Checklists/Mobile/Group/${groupId}?offset=${offset}&limit=5`;
    return Api().get(endpoint);
  },
  getCheckListCalendar(params) {
    const { checklistId, calendarRemindId } = params;
    const endpoint = `Checklists/Calendar?checklistId=${checklistId}&calendarRemindId=${calendarRemindId}`;
    return Api().get(endpoint);
  },
  getTypeGroup(params) {
    const { offset, type } = params;
    const endpoint = `/Checklists/TypeGroup/${type}?offset=${offset}&limit=5`;
    return Api().get(endpoint);
  },
  updateCheckListBookmark(data) {
    return Api().post("CheckResult", data);
  },
  updateCheckListResult(data) {
    return Api().put("CheckResult", data);
  },
  importTemplateChecklist(checklist) {
    const endpoint = `CheckResult/ImportCheckResult/start?checkListId=${checklist.id}`;
    return Api().post(endpoint, {
      fileName: checklist.fileName,
      fileData: checklist.fileData,
    });
  },
  downloadTemplate(data) {
    return Api().get("Checklists/DownloadTemplate", {
      params: data,
      responseType: "blob",
    });
  },
  uploadImageData(data) {
    return Api().post("CheckResult/Attachments", data);
  },
  getDataItemChart(stringData, checklistId, type, isPreUseChecklist) {
    let endpoint = "";
    if (type) {
      endpoint = `DynamicReport/show?checkItemCodes=${stringData}&checklistId=${checklistId}&type=${type}&isPreUseChecklist=${isPreUseChecklist}`;
    } else {
      endpoint = `DynamicReport/show?checkItemCodes=${stringData}&checklistId=${checklistId}&isPreUseChecklist=${isPreUseChecklist}`;
    }
    return Api().get(endpoint);
  },
  bookMarkItem(itemCode, checklistId) {
    const endpoint = `DynamicReport/${itemCode}?checklistId=${checklistId}`;
    return Api().post(endpoint);
  },
};

import Api from "@src/api";

export default {
  //search machine
  search(params) {
    return Api().get("api/office/Machine", { params });
  },
  delete(id, version) {
    return Api().delete(`api/office/Machine/${id}/${version}`, {})
  },
  getOne(code, version = '') {
    return Api().get(`api/office/Machine/${code}/${version}`, {});
  },
  save(formData) {
    return Api().post(`api/office/Machine`, formData, {
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      // }
    });
  },
  update(formData) {
    return Api().put(`api/office/Machine`, formData, {
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      // }
    });
  },
  uploadFile(formData) {
    return Api().post(`api/office/FileUpload/UploadAttachFile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getAttachFile(id, type, version = 0) {
    return Api().get(`api/office/Attachment/GetAttachFiles/${id}/${type}/${version}`, {});
  },
  viewFile(id) {
    return Api().get(`api/office/Attachment/View/${encodeURI(id)}`, {});
  },
  downloadFile(id, type, url = "", version = 0) {
    return Api().get(`api/office/Attachment/Download/${id}/${type}/${encodeURI(url)}/${version}`, {
      responseType: "blob"
    });
  },
  downloadMultiFile(listFile, type) {
    const urlParam = listFile.map((item) => `Models=${item}`).join("&");
    return Api().get(`api/office/Machine/DownloadMachineInfo/${type}?${urlParam}`, {
      responseType: "blob"
    });
  },
  downloadTemplateImportMachine() {
    return Api().get(`/api/office/Machine/DownloadImportTemplate`, {
      responseType:"blob"
    });
  },
  importMachine(formData) {
    return Api().post(`/api/office/Machine/ImportMachines`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
};

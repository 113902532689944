import {
  DownOutlined,
  EditOutlined,
  RightOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import ApiMachine from "@src/api/backend/machineManagement";
import BoxContent from "@src/component/Desktop/content";
import FooterPage from "@src/component/footer/footerPage";
import { IconNumber } from "@src/components/common/iconNumber.jsx";

import { KEY as KEY1 } from "@src/constants/common.const";
import { Button, Col, Collapse, Descriptions, Input, Modal, Row } from "antd";
import _, { isArray } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import TitlePage from "../../../component/Desktop/titlePage";
import { HeaderText } from "../../../components/common/headerText";
import { REGEX_INPUT, STYLE, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";

import TextArea from "antd/es/input/TextArea";
import { useMediaQuery } from "react-responsive";
import { EstimateApi } from "../../../api/backend/estimate";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import {
  openSave,
  setDataStep1,
  setDataStep3,
  setFromToStep2,
} from "../../../redux/estimate";
import {
  caculatorSumMoneyandWeight,
  cloneObj,
  exactMultiplication,
  formatCurrencyJPY,
  getPathEstimateWithStep,
  getStandardLv,
  isEmptyData,
  roundToDecimalPlaces,
  roundToNearestThousand,
} from "../../../utils/helpers";
import "./../../Estimate/Estimate.scss";
import "./../../Machine_Management/machine.scss";
import ButtonSave from "./buttonSave";
import { INJECT_NAME } from "./step2";
import { injectionPump } from "../../../api/backend/injectionPump";
const { Panel } = Collapse;

function AddStep3({ dataStep1, basePath, isNewDataDetail }) {
  const { isLeader } = useSelector((state) => state.auth);
  const [dataCapacity, setDataCapacity] = useState([]);
  const KEY = {
    MONNEY: "MONEY",
    WEIGHT: "WEIGHT",
    WEIGHT_UNIT: "WEIGHT_UNIT",
    PRICE_UNIT: "PRICE_UNIT",
    NUMBER_MACHINE: "NUMBER_MACHINE",
    PRICE_UNIT_PERCEN: "PRICE_UNIT_PERCEN",
    MANAGE_PERCEN: "MANAGE_PERCEN",
    BENEFIT_LOSS_PERCEN: "BENEFIT_LOSS_PERCEN",
    REMARK: "REMARK",
    OPTION_MONEY: "OPTION_MONEY",
  };
  const isPC = useMediaQuery({
    query: KEY1.LAPTOP_WIDTH,
  });
  const isAndroid = true;
  const history = useHistory();
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [showModalEditPrice, setShowModalEditPrice] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const dispatch = useDispatch();
  const currentDate = new Date();
  const { dataStep2, dataStep3, formDataPostDefault, detailData, listLv } =
    useSelector((state) => state.estimate);

  // LV
  // CUrrenr LV
  let lv = null;
  if (dataStep1 && dataStep1.fiberLength) {
    lv = getStandardLv(listLv, dataStep1.fiberLength, dataStep1.rawWaterType);
  }

  const inputRef = useRef();
  const [loading, setLoading] = useState(true);
  const [formErr, setFormErr] = useState({});
  const [data, setData] = useState({
    estimationConst: {
      otherFees: 0,
      instrumentationWorkFee: 250,
      transportationFee: 160,
      trialRunAdjustmentFee: 100,
      attendanceFee: 150,
      designDocumentPreparationFee: 150,
      pacInjectionDeviceNumber: 1,
      pacInjectionDevicePrice: 0,
      hypotoniaInjectionDeviceNumber: 1,
      hypotoniaInjectionDevicePrice: 0,
      phAdjusterInjectionDeviceNumber: 1,
      phAdjusterInjectionDevicePrice: 0,
      controlPanelNumber: 1,
      controlPanelPrice: 0,
      commissioningSvFees: 150,
      reserveFund: 10,
      designNumber: 1,
      designFee: 1000,
      bookCreationNumber: 1,
      bookCreationFee: 500,
    },
  });

  let day = currentDate.getDate();
  if (day < 10) day = "0" + day;
  let month = currentDate.getMonth() + 1;
  if (month < 10) month = "0" + month;
  const year = currentDate.getFullYear();
  const path = !id ? "/Estimate/Add" : "/Estimate/Edit/" + id;
  // Định dạng ngày tháng năm
  const formattedDate = year + "/" + month + "/" + day;

  // FInal numberOfmachine
  const numberSpareMachine = dataStep1
    ? dataStep1.completeSpareMachineQuantity || 0
    : 0;
  const currentNumberMachine = data && data.numberOfMachines;
  const finalNumberOfMachine =
    Number(numberSpareMachine) + Number(currentNumberMachine || 0);

  const infoProject = [
    {
      title: LANG.ESTIMATE_NUMBER,
      content: dataStep1.estimationNumber,
    },
    {
      title: "注文主",
      content: dataStep1.customerName,
    },
    {
      title: "納入先(会社)",
      content: dataStep1.companyDeliveryDestination,
    },
    {
      title: "納入先(工場)",
      content: dataStep1.factoryDeliveryDestination,
    },
    {
      title: "案件名",
      content: dataStep1.constructionName,
    },
    {
      title: "商社",
      content: dataStep1.tradingCompany,
    },

    {
      title: LANG.CREATED_DATE,
      content: formattedDate,
      inlineDisplay: true,
    },
  ];

  const conditionDesign = [
    {
      title: LANG.WATER_TYPE,
      content: dataStep1.rawWaterTypevalue || "",
    },
    {
      title: (
        <>
          {LANG.TREATED_WATER_VOLUMN}
          <br />
          (㎥/h)
        </>
      ),

      content: dataStep1.estimationConditionInput
        ? Math.round(
            (dataStep1.estimationConditionInput.inputRawWaterVolume /
              dataStep1.estimationConditionInput.inputOperatingTime) *
              10
          ) / 10
        : "",
    },
    {
      title: dataStep1.rawWaterType === "1" ? LANG.IN : LANG.WATER_SS,
      content:
        dataStep1.rawWaterType === "1"
          ? dataStep1.estimationConditionInput
            ? dataStep1.estimationConditionInput.inputRawWaterTurbidity
            : ""
          : dataStep1.estimationConditionInput
          ? dataStep1.estimationConditionInput.inputRawWaterSsConc
          : "",
    },
    {
      title: dataStep1.rawWaterType === "1" ? LANG.OUT : LANG.TREATED_WATER_SS,
      content:
        dataStep1.rawWaterType === "1"
          ? dataStep1.estimationConditionInput
            ? dataStep1.estimationConditionInput.inputTreatedWaterTurbidity
            : ""
          : dataStep1.estimationConditionInput
          ? dataStep1.estimationConditionInput.inputTreatedWaterSsConc
          : "",
    },
  ];

  // Show Modal Info
  const openModalInfoTableCost = (item) => {
    setVisible(true);
    setDataModal({
      ...item,
      content: item.content.map((i) => {
        i.isEdit = false;
        return i;
      }),
    });
  };

  // Show Data Modal Edit
  const openModalEditTableCost = (item) => {
    setShowModalEditPrice(true);
    setDataModal(item);
  };
  const closeModalInfoTableCost = () => {
    setVisible(false);
    setDataModal(null);
  };

  //close modal edit
  const closeModalEditTableCost = () => {
    setShowModalEditPrice(false);
    setDataModal(null);
    setFormErr({});
  };

  const getErr = (keyInput) => {
    if (!keyInput) return false;
    let key;
    if (Array.isArray(keyInput)) {
      key = keyInput[keyInput.length - 1];
    } else {
      key = keyInput;
    }
    if (!_.isEmpty(formErr)) {
      const keys = Object.keys(formErr);
      if (keys.includes(key)) {
        return true;
      }
    }
    return false;
  };
  // get ref
  const getRef = (keyInput) => {
    if (!keyInput) return null;
    let key;
    if (Array.isArray(keyInput)) {
      key = keyInput[keyInput.length - 1];
    } else {
      key = keyInput;
    }
    if (!_.isEmpty(formErr)) {
      const keys = Object.keys(formErr);
      if (keys[0] === key) {
        return inputRef;
      }
    }
    return null;
  };

  // focus err
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [formErr]);

  // check validate
  const checkValidateInput = () => {
    const objErr = {};
    if (!dataModal) return false;
    let contentArr = dataModal.content || [];
    contentArr = contentArr.filter((i) => i.isEdit && i.key !== KEY.REMARK);
    contentArr.forEach((item) => {
      if (item.content === "" || item.content === null) {
        const keyArr = item.keyInput;
        if (Array.isArray(keyArr)) {
          objErr[keyArr[keyArr.length - 1]] = true;
        } else {
          objErr[keyArr] = true;
        }
      }
    });
    setFormErr(objErr);

    return _.isEmpty(objErr);
  };
  // Ok modal edit
  const handleChangeValueCost = () => {
    if (!checkValidateInput()) return;
    const data =
      dataModal && dataModal.content ? cloneObj(dataModal).content : [];
    const valueUpdate = {};
    data.forEach((item) => {
      if (item.isEdit) {
        const value = item.content;
        const key = item.keyInput;
        if (Array.isArray(key)) {
          valueUpdate[key[0]] = valueUpdate[key[0]]
            ? { ...valueUpdate[key[0]], [[key[1]]]: value }
            : { [[key[1]]]: value };
        }
      }
    });
    handleChangeDataInputByValue(valueUpdate);

    setDataModal(null);
    setShowModalEditPrice(false);
    setFormErr({});
  };
  const renderItemTableCost = (tableCost, { isNotMoney }) => {
    return tableCost.map((info, index) => {
      const showField = info.content.find((i) => i.isShow);
      const text = showField ? (showField.content ? showField.content : 0) : 0;
      const isEdit = info.content.find((i) => i.isEdit);
      const unit = showField.unit;
      const isSub = info.isSub;
      const title = unit ? (
        <>
          {info.title}
          <br />
          {`(${unit})`}
        </>
      ) : (
        info.title
      );
      return (
        <Descriptions.Item
          span={1}
          key={index}
          label={
            <div
              style={{
                cursor: "pointer",
                paddingLeft: isSub && (isPC ? 50 : 20),
              }}
              onClick={() => openModalInfoTableCost(info)}
            >
              {title}
            </div>
          }
          labelStyle={{
            width: "35%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: "8px",
            whiteSpace: "pre-wrap",
          }}
        >
          {showField.subFullText ? (
            showField.subFullText
          ) : (
            <div
              className="flex-center"
              style={{ justifyContent: "space-between" }}
            >
              {isEdit ? (
                <EditOutlined
                  onClick={() => openModalEditTableCost(info)}
                  style={{
                    width: 30,
                    height: 30,
                    color: "#0080cb",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <span></span>
              )}
              <span>{`${formatCurrencyJPY(text || 0)} ${
                isNotMoney ? "" : "(千円)"
              }`}</span>
            </div>
          )}
        </Descriptions.Item>
      );
    });
  };
  // change Data
  const handleChangeDataInputByValue = (newValue) => {
    let newData = cloneObj(data);
    for (let key in newValue) {
      newData = { ...newData, [key]: { ...newData[key], ...newValue[key] } };
    }
    setData(newData);
  };
  // handle change inpiut modal
  const handleChangeInputData = (key, newValue, rule, keyInput) => {
    if (rule && !newValue.match(rule) && newValue) {
      return;
    }
    if (rule === REGEX_INPUT.interger) {
      newValue = String(newValue);
      if (newValue.length > 9) {
        return;
      }
      if (newValue.length > 1 && newValue[0] == "0") {
        newValue = newValue.slice(1);
      }
    }

    const arrContent = dataModal ? dataModal.content : [];
    const indexMoney = arrContent.findIndex((i) => i.key === KEY.MONNEY);
    const indexRemark = arrContent.findIndex((i) => i.key === KEY.REMARK);
    const indexOptionMoney = arrContent.findIndex(
      (i) => i.key === KEY.OPTION_MONEY
    );

    const indexNumberMachine = arrContent.findIndex(
      (i) => i.key === KEY.NUMBER_MACHINE
    );
    const indexPriceUnitPerCen = arrContent.findIndex(
      (i) => i.key === KEY.PRICE_UNIT_PERCEN
    );
    const indexManagePercen = arrContent.findIndex(
      (i) => i.key === KEY.MANAGE_PERCEN
    );
    const indexPriceUnit = arrContent.findIndex(
      (i) => i.key === KEY.PRICE_UNIT
    );
    const indexBenefitLossPercen = arrContent.findIndex(
      (i) => i.key === KEY.BENEFIT_LOSS_PERCEN
    );
    if (
      key === KEY.PRICE_UNIT &&
      indexMoney >= 0 &&
      indexPriceUnit >= 0 &&
      indexNumberMachine >= 0
    ) {
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexPriceUnit].content = newValue;
      newDataModal.content[indexMoney].content = exactMultiplication(
        newDataModal.content[indexNumberMachine].content,
        newValue
      );

      setDataModal(newDataModal);
    }
    if (
      key === KEY.NUMBER_MACHINE &&
      indexNumberMachine >= 0 &&
      indexPriceUnit >= 0
    ) {
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexNumberMachine].content = newValue;
      newDataModal.content[indexMoney].content = exactMultiplication(
        newDataModal.content[indexPriceUnit].content,
        newValue
      );

      setDataModal(newDataModal);
    }

    // REFUND PERCENT
    if (
      (key === KEY.PRICE_UNIT_PERCEN && indexMoney >= 0,
      indexPriceUnitPerCen >= 0)
    ) {
      if (Number(newValue) < 0 || Number(newValue) > 100) {
        return;
      }
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexPriceUnitPerCen].content = newValue;
      newDataModal.content[indexMoney].content = (newValue * totalCost) / 100;

      setDataModal(newDataModal);
    }
    // MANAGE PERCEN
    if (
      (key === KEY.MANAGE_PERCEN && indexMoney >= 0, indexManagePercen >= 0)
    ) {
      if (Number(newValue) < 0 || Number(newValue) > 100) {
        return;
      }
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexManagePercen].content = newValue;
      newDataModal.content[indexMoney].content = (newValue * dcCost) / 100;

      setDataModal(newDataModal);
    }

    // BENEFIT_LOSS_PERCENT
    if (
      (key === KEY.BENEFIT_LOSS_PERCEN && indexMoney >= 0,
      indexBenefitLossPercen >= 0)
    ) {
      if (Number(newValue) < 0 || Number(newValue) > 99) {
        return;
      }
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexBenefitLossPercen].content = newValue;
      const sellingPrice = roundToDecimalPlaces(
        (costOfSale.money * 100) / (100 - newValue),
        -3
      );
      newDataModal.content[indexMoney].content =
        sellingPrice - costOfSale.money;

      setDataModal(newDataModal);
    }
    // key money
    if (key === KEY.MONNEY && indexMoney >= 0) {
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexMoney].content =
        rule && !isEmptyData(newValue) ? Number(newValue) : newValue;
      console.log(newDataModal);
      setDataModal(newDataModal);
    }

    // key remark
    if (key === KEY.REMARK && indexRemark >= 0) {
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexRemark].content = newValue;
      setDataModal(newDataModal);
    }
    // KEY OPTION MONEY
    if (key === KEY.OPTION_MONEY && indexOptionMoney >= 0 && indexMoney) {
      const keyIn = keyInput[1];
      const exactlyIndex = arrContent.findIndex(
        (i) => (i.key === KEY.OPTION_MONEY) & (i.keyInput[1] === keyIn)
      );
      const newDataModal = cloneObj(dataModal);
      const oldMoneyItem = newDataModal.content[exactlyIndex].content;
      newDataModal.content[exactlyIndex].content = Number(newValue);
      const oldTotalMonety = newDataModal.content[indexMoney].content;
      newDataModal.content[indexMoney].content =
        Number(oldTotalMonety) +
        exactMultiplication(
          Number(newValue) - Number(oldMoneyItem),
          finalNumberOfMachine
        );
      setDataModal(newDataModal);
    }
  };

  // key down input
  const hanldeKeyDown = (rule) => (e) => {
    if (
      rule &&
      !e.key.match(rule) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowUp" &&
      e.key !== "ArrowDown" &&
      e.key !== "ArrowRight" &&
      e.key !== "ArrowLeft" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };
  // Get Data Step3
  useEffect(() => {
    setLoading(true);
    EstimateApi.getDataStep3({
      ...dataStep1,
      standardLv: lv,
    })
      .then(async (res) => {
        setLoading(false);
        // Current machine - EF-model
        const machineSeleted = res.data.machineSelected;

        // Pump,Air,Washing
        const otherMachineRes = await ApiMachine.getOne(machineSeleted);
        const otherMachineData = otherMachineRes.data;

        const pumpData = otherMachineData.filtrationPump;
        const backwashData = otherMachineData.backwashPump;
        const airData = otherMachineData.airWashingBlower;
        const finalEstimationAuxMachine = {
          ...res.data.estimationAuxMachine,
        };

        // PUMP
        finalEstimationAuxMachine.auxFiltrationPumpModel =
          pumpData.filtrationPumpModel;
        finalEstimationAuxMachine.auxFiltrationPumpCapacity =
          pumpData.filtrationPumpCapacity;
        finalEstimationAuxMachine.auxFiltrationPumpWeight =
          pumpData.filtrationPumpWeight;
        finalEstimationAuxMachine.auxFiltrationPumpUnitPrice =
          pumpData.filtrationPumpUnitPrice;

        // Washing
        finalEstimationAuxMachine.auxBackwashPumpModel =
          backwashData.backwashPumpModel;
        finalEstimationAuxMachine.auxBackwashPumpCapacity =
          backwashData.backwashPumpCapacity;
        finalEstimationAuxMachine.auxBackwashPumpWeight =
          backwashData.backwashPumpWeight;
        finalEstimationAuxMachine.auxBackwashPumpUnitPrice =
          backwashData.backwashPumpUnitPrice;

        // AIR
        finalEstimationAuxMachine.auxAirWashingBlowerModel =
          airData.airWashingBlowerModel;
        finalEstimationAuxMachine.auxAirWashingBlowerCapacity =
          airData.airWashingBlowerCapacity;
        finalEstimationAuxMachine.auxAirWashingBlowerWeight =
          airData.airWashingBlowerWeight;
        finalEstimationAuxMachine.auxAirWashingBlowerUnitPrice =
          airData.airWashingBlowerPrice;

        // SndprfBoxModel

        finalEstimationAuxMachine.auxAirWashingBlowerSndprfBoxModel =
          airData.airWashingBlowerSndprfBoxModel;
        // finalEstimationAuxMachine.auxAirWashingBlowerCapacity =
        //   airData.airWashingBlowerCapacity;
        finalEstimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight =
          airData.airWashingBlowerSndprfBoxWeight;
        finalEstimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice =
          airData.airWashingBlowerSndprfBoxPrice;

        // OPTION
        let objOption = {};
        if (dataStep1.options) {
          dataStep1.options
            .filter((i) => i.checked)
            .forEach((i) => {
              objOption[i.optionId] = i.value;
            });
        }

        const currData = {
          ...res.data,
          estimationConst: {
            ...res.data.estimationConst,
            ...dataStep3.estimationConst,
          },
          estimationAuxMachine: finalEstimationAuxMachine,

          options: objOption,
        };
        if (!_.isEmpty(dataStep1)) {
          const newData = cloneObj(currData);
          const newEstimationConst = cloneObj(newData.estimationConst);
          if (dataStep1.havePacInjectionDevice === "0") {
            newEstimationConst.pacInjectionDeviceNumber = 0;
          }
          if (dataStep1.haveHypotoniaInjectionDevice === "0")
            newEstimationConst.hypotoniaInjectionDeviceNumber = 0;
          if (dataStep1.havePhAdjusterInjectionDevice === "0")
            newEstimationConst.phAdjusterInjectionDeviceNumber = 0;
          if (dataStep1.haveControlPanel === "0")
            newEstimationConst.controlPanelNumber = 0;
          else {
            newEstimationConst.controlPanelNumber = 1;
          }
          newData.estimationConst = newEstimationConst;
          setData(newData);
        }
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  }, [dataStep1, dataStep2.lv, dataStep3, detailData, isNewDataDetail]);

  // GET CAPACITY
  const getCapacity = async () => {
    try {
      const bodyCapacity = cloneObj({
        ...cloneObj(formDataPostDefault),
        ...cloneObj(dataStep1),
        ...dataStep3,
      });
      const { h2so4DrugConc, h2so4ChemicalSpecificGravity, ...rest } =
        bodyCapacity;

      const body = {
        ...rest,
        estimationConst: {
          ...rest.estimationConst,
          h2so4DrugConc: h2so4DrugConc,
          h2so4ChemicalSpecificGravity: h2so4ChemicalSpecificGravity,
        },
      };
      delete body.rawWaterTypevalue;
      body.standardLv = lv || dataStep2.lv.current || null;
      body.options = body.options.filter((i) => i.checked);
      body.options.forEach((item) => {
        if (item.hasOwnProperty("isError")) {
          delete item.isError;
        }
        if (item.hasOwnProperty("checked")) {
          delete item.checked;
        }
        item.defaultValue = item.value;
      });
      body.waterDevices = body.waterDevices
        .filter((i) => i.checked)
        .map(({ checked, ...other }) => other);
      body.injectionPumps = body.injectionPumps
        .filter((i) => i.checked)
        .map(({ checked, ...other }) => other);
      body.eagleFiberDtos = [];

      const listPac = [];
      body.getPac = dataStep1.injectionPumps.forEach((item, index) => {
        if (item.checked) {
          listPac.push(index + 1 + "");
        }
      });
      body.getPac = listPac;
      delete body.id;
      const res = await injectionPump.getCapcityChemicalInjection(body);
      setDataCapacity(res.data);
    } catch (error) {}
  };

  // CAPACITY
  useEffect(() => {
    if (dataStep1 && dataStep1.injectionPumps) {
      getCapacity();
    }
  }, [dataStep1, dataStep3, lv]);

  // INFO
  const infoProjectArr = [
    {
      title: LANG.PROJECT_INFOMATION,
      content: infoProject,
      defaultExpand: true,
    },
  ];
  const conditionArr = [
    {
      title: LANG.DESIGN_CONDITION,
      content: conditionDesign,
    },
  ];

  const renderInfo = (listInfo, active = true) =>
    listInfo.map((item, index) => {
      return (
        <Col span={24} className="mt8" key={index}>
          <BoxContent style={{ margin: 0, padding: 0 }}>
            <Collapse
              defaultActiveKey={"1"}
              expandIconPosition="right"
              style={{ padding: 0 }}
            >
              <Panel
                header={
                  <>
                    <div
                      className="flex-center"
                      style={{ justifyContent: "flex-start" }}
                    >
                      {item.icon}
                      {item.title}
                    </div>
                  </>
                }
                key="1"
              >
                <Descriptions size="small" column={1} bordered>
                  {item.content.map((info, index) => {
                    const { rule, ruleKeyDown } = info;
                    const inputRule =
                      REGEX_INPUT[rule] != "undefined"
                        ? REGEX_INPUT[rule]
                        : REGEX_INPUT.interger;
                    const inputRuleKey =
                      REGEX_INPUT[ruleKeyDown] != "undefined"
                        ? REGEX_INPUT[ruleKeyDown]
                        : REGEX_INPUT.interger;

                    const type =
                      isAndroid && rule !== "all" ? "number" : "text";
                    return (
                      <Descriptions.Item
                        span={1}
                        key={index}
                        label={
                          <span onClick={() => active && setVisible(true)}>
                            {info.title}
                          </span>
                        }
                        labelStyle={{
                          width: "35%",
                          fontWeight: "bold",
                          padding: "8px 8px",
                        }}
                        contentStyle={{
                          padding: !isArray(info.content) ? "8px" : "0px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {!isArray(info.content) && !info.isEdit && info.content}
                        {!isArray(info.content) && info.isEdit && (
                          <Input
                            className={
                              getErr(info.keyInput) ? STYLE.BORDER_RED : ""
                            }
                            ref={getRef(info.keyInput)}
                            type={type}
                            onKeyDown={hanldeKeyDown(inputRuleKey)}
                            min={0}
                            value={info.content}
                            onChange={(e) =>
                              handleChangeInputData(
                                info.key,
                                e.target.value,
                                inputRule,
                                info.keyInput
                              )
                            }
                          />
                        )}
                        {isArray(info.content) && (
                          <Descriptions
                            key={index}
                            size="small"
                            column={1}
                            bordered
                          >
                            {info.content.map((item, index) => {
                              const { rule, ruleKeyDown, key } = item;
                              const inputRule =
                                REGEX_INPUT[rule] != "undefined"
                                  ? REGEX_INPUT[rule]
                                  : REGEX_INPUT.interger;
                              const inputRuleKey =
                                REGEX_INPUT[ruleKeyDown] != "undefined"
                                  ? REGEX_INPUT[ruleKeyDown]
                                  : REGEX_INPUT.interger;
                              const type =
                                isAndroid && rule !== "all" ? "number" : "text";

                              return (
                                <Descriptions.Item
                                  span={1}
                                  label={item.title}
                                  labelStyle={{
                                    width: "45%",
                                    fontWeight: "semi-bold",
                                  }}
                                >
                                  {!item.isEdit && item.content}
                                  {item.isEdit && (
                                    <Input
                                      className={
                                        getErr(item.keyInput)
                                          ? STYLE.BORDER_RED
                                          : ""
                                      }
                                      ref={getRef(item.keyInput)}
                                      type={type}
                                      onKeyDown={hanldeKeyDown(inputRuleKey)}
                                      min={0}
                                      value={info.content}
                                      onChange={(e) =>
                                        handleChangeInputData(
                                          info.key,
                                          e.target.value,
                                          inputRule,
                                          info.keyInput
                                        )
                                      }
                                    />
                                  )}
                                </Descriptions.Item>
                              );
                            })}
                          </Descriptions>
                        )}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
      );
    });
  const renderDesContent = (arr, { isOpenModal = true }) => {
    return arr.content.map((info, index) => {
      const { rule, ruleKeyDown, unit, key } = info;
      const title = unit ? (
        <>
          {info.title}
          <br />
          {`(${unit})`}
        </>
      ) : (
        info.title
      );

      const inputRule =
        REGEX_INPUT[rule] !== undefined
          ? REGEX_INPUT[rule]
          : REGEX_INPUT.interger;
      const inputRuleKey =
        REGEX_INPUT[ruleKeyDown] !== undefined
          ? REGEX_INPUT[ruleKeyDown]
          : REGEX_INPUT.interger;
      const type = isAndroid && rule !== "all" ? "number" : "text";
      return (
        <Descriptions.Item
          style={{ display: info.isHide && "none" }}
          span={1}
          key={index}
          label={
            <span onClick={() => (isOpenModal ? setVisible(true) : null)}>
              {title}
            </span>
          }
          labelStyle={{
            width: "35%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: !isArray(info.content) ? "8px" : "0px",
            whiteSpace: "pre-wrap",
          }}
        >
          {!isArray(info.content) && !info.isEdit
            ? !isNaN(info.content) &&
              info.content !== null &&
              info.content !== ""
              ? formatCurrencyJPY(info.content)
              : info.content
            : null}
          {!isArray(info.content) &&
            info.isEdit &&
            (key !== KEY.REMARK ? (
              <Input
                className={getErr(info.keyInput) ? STYLE.BORDER_RED : ""}
                ref={getRef(info.keyInput)}
                type={type}
                onKeyDown={hanldeKeyDown(inputRuleKey)}
                min={0}
                value={info.content}
                onChange={(e) =>
                  handleChangeInputData(
                    info.key,
                    e.target.value,
                    inputRule,
                    info.keyInput
                  )
                }
              />
            ) : (
              <TextArea
                // className={getErr(info.keyInput) ? STYLE.BORDER_RED : ""}
                // ref={getRef(info.keyInput)}
                type={type}
                style={{
                  background: "white",
                }}
                onKeyDown={hanldeKeyDown(inputRuleKey)}
                min={0}
                value={info.content}
                onChange={(e) =>
                  handleChangeInputData(
                    info.key,
                    e.target.value,
                    inputRule,
                    info.keyInput
                  )
                }
                className="textNote"
                rows={2}
              />
            ))}
          {isArray(info.content) && (
            <Descriptions key={index} size="small" column={1} bordered>
              {info.content.map((item, index) => {
                const { rule, ruleKeyDown } = item;
                const inputRule =
                  REGEX_INPUT[rule] != "undefined"
                    ? REGEX_INPUT[rule]
                    : REGEX_INPUT.interger;
                const inputRuleKey =
                  REGEX_INPUT[ruleKeyDown] != "undefined"
                    ? REGEX_INPUT[ruleKeyDown]
                    : REGEX_INPUT.interger;
                const type = isAndroid && rule !== "all" ? "number" : "text";

                return (
                  <Descriptions.Item
                    span={1}
                    label={item.title}
                    labelStyle={{
                      width: "45%",
                      fontWeight: "semi-bold",
                    }}
                  >
                    {!item.isEdit && item.content}
                    {item.isEdit && (
                      <Input
                        className={
                          getErr(item.keyInput) ? STYLE.BORDER_RED : ""
                        }
                        ref={getRef(item.keyInput)}
                        type={type}
                        onKeyDown={hanldeKeyDown(inputRuleKey)}
                        min={0}
                        value={info.content}
                        onChange={(e) =>
                          handleChangeInputData(
                            info.key,
                            e.target.value,
                            inputRule,
                            info.keyInput
                          )
                        }
                      />
                    )}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          )}
        </Descriptions.Item>
      );
    });
  };

  //push data step3
  const pushDataStep3 = () => {
    dispatch(
      setDataStep3({
        estimationConst: data.estimationConst,
      })
    );
    dispatch(
      setDataStep1({
        ...cloneObj(dataStep1),
        options: optionPost,
      })
    );
    dispatch(setFromToStep2("step3"));
  };

  // GET INDEX SHOW
  const getIndexShowItemTable = (arrCost = []) => {
    if (!Array.isArray(arrCost)) {
      return -1;
    }
    arrCost.forEach((info, index) => {
      const subArray = [];
      const showField = info.content.find((i) => i.isShow);
    });
  };
  // Table 1
  const tableCost1 = [
    {
      title: "イーグルファイバー",
      content: [
        {
          title: "型式",
          content: data.machineSelected || "",
        },
        {
          title: "仕様",
          content: data.eagleFiberTowerDimension || "",
        },
        {
          title: "数量(基)",
          content: finalNumberOfMachine || "",
        },
        {
          title: "単位質量(kg)",
          content: data.eagleFiberProductWeight || 0,
        },
        {
          title: "単価(千円)",
          content: Math.round(data.eagleFiberUnitPrice) || "",
        },
        {
          title: "質量(kg)",
          content: data
            ? Math.round(
                Number(data.eagleFiberProductWeight) *
                  Number(finalNumberOfMachine)
              )
            : "",
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content: data
            ? exactMultiplication(
                data.eagleFiberUnitPrice,
                finalNumberOfMachine
              )
            : "",
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },

    {
      title: "計装作業",
      content: [
        {
          title: "数量(式)",
          content: finalNumberOfMachine || "",
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst &&
            Math.round(data.estimationConst.instrumentationWorkFee),
          isEdit: false,
          keyInput: ["estimationConst", "instrumentationWorkFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              data.estimationConst.instrumentationWorkFee * finalNumberOfMachine
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.instrumentationWorkFeeComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "instrumentationWorkFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "運搬費",
      content: [
        {
          title: "数量(式)",
          content: finalNumberOfMachine || "",
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst &&
            Math.round(data.estimationConst.transportationFee),
          isEdit: false,
          keyInput: ["estimationConst", "transportationFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            data.estimationConst.transportationFee * finalNumberOfMachine,
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.transportationFeeComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "transportationFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "試運転調整費・単価",
      content: [
        {
          title: "数量(日)",
          content: data ? 2 + (finalNumberOfMachine - 1) * 1 : "",
          key: KEY.NUMBER_MACHINE,
        },

        {
          isEdit: false,
          title: "単価(千円)",
          content:
            data.estimationConst &&
            Math.round(data.estimationConst.trialRunAdjustmentFee),
          keyInput: ["estimationConst", "trialRunAdjustmentFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? (2 + (finalNumberOfMachine - 1) * 1) *
              data.estimationConst.trialRunAdjustmentFee
            : "",
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.trialRunAdjustmentFeeComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "trialRunAdjustmentFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "立会対応費",
      content: [
        {
          title: "数量(式)",
          content: finalNumberOfMachine || 0,
          key: KEY.NUMBER_MACHINE,
        },

        {
          isEdit: false,
          title: "単価(千円)",
          content: data.estimationConst
            ? Math.round(data.estimationConst.attendanceFee)
            : 0,
          keyInput: ["estimationConst", "attendanceFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? data.estimationConst.attendanceFee * finalNumberOfMachine
            : "",
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.attendanceFeeComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "attendanceFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "設計図書作成費",
      content: [
        {
          title: "数量(式)",
          content: finalNumberOfMachine || 0,
          key: KEY.NUMBER_MACHINE,
        },

        {
          isEdit: false,
          title: "単価(千円)",
          content: data.estimationConst
            ? Math.round(data.estimationConst.designDocumentPreparationFee)
            : 0,
          keyInput: ["estimationConst", "designDocumentPreparationFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? data.estimationConst.designDocumentPreparationFee *
              finalNumberOfMachine
            : "",
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.designDocumentPreparationFeeComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "designDocumentPreparationFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];

  let optionPost = cloneObj(
    dataStep1 && dataStep1.options ? dataStep1.options : []
  );

  // Merge Option
  let options = cloneObj(dataStep1.options || []);
  if (Array.isArray(options)) {
    options = options
      .filter((i) => i.checked)
      .map((i) => ({
        ...i,
        value: exactMultiplication(i.value, finalNumberOfMachine),
        defaultValue: exactMultiplication(i.value, finalNumberOfMachine),
      }));
  }
  const itemOptionTableCost1 = {
    title: "本体オプション",
    content: [],
  };
  options.forEach((option) => {
    itemOptionTableCost1.content.push({
      title: option.optionName,
      content: option.value,
    });

    tableCost1.push({
      title: option.optionName,
      isSub: true,

      content: [
        {
          title: option.optionName,
          content: option.value,
          isShow: true,
          isNotPlus: true,
        },
      ],
    });
  });

  const moneyOption = itemOptionTableCost1.content.reduce((money, item) => {
    return money + item.content;
  }, 0);

  itemOptionTableCost1.content.push({
    title: "金額(千円)",
    content: moneyOption,
    isShow: true,
    key: KEY.MONNEY,
  });
  tableCost1.splice(6, 0, itemOptionTableCost1);

  // const indexShowTable1  = tableCost1.findIndex(i => )
  // Table 2
  const tableCost2 = [
    {
      title: "ろ過ポンプ",
      content: [
        {
          title: "型式",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpModel,
        },
        {
          title: "仕様",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            Number(data.estimationAuxMachine.auxFiltrationPumpRegularNumber) +
              Number(data.estimationAuxMachine.auxFiltrationPumpBackupMachine),
        },
        {
          title: "単位質量(kg)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpWeight,
        },
        {
          title: "単価(千円)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                data.estimationAuxMachine.auxFiltrationPumpWeight,
                Number(
                  data.estimationAuxMachine.auxFiltrationPumpRegularNumber
                ) +
                  Number(
                    data.estimationAuxMachine.auxFiltrationPumpBackupMachine
                  )
              )
            ),
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              data.estimationAuxMachine.auxFiltrationPumpUnitPrice,
              Number(data.estimationAuxMachine.auxFiltrationPumpRegularNumber) +
                Number(data.estimationAuxMachine.auxFiltrationPumpBackupMachine)
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
    {
      title: "逆洗ポンプ",
      content: [
        {
          title: "型式",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpModel,
        },
        {
          title: "仕様",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            Number(data.estimationAuxMachine.auxBackwashPumpRegularNumber) +
              Number(data.estimationAuxMachine.auxBackwashPumpBackupMachine),
        },
        {
          title: "単位質量(kg)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpWeight,
        },
        {
          title: "単価(千円)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                data.estimationAuxMachine.auxBackwashPumpWeight,
                Number(data.estimationAuxMachine.auxBackwashPumpRegularNumber) +
                  Number(data.estimationAuxMachine.auxBackwashPumpBackupMachine)
              )
            ),
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              data.estimationAuxMachine.auxBackwashPumpUnitPrice,
              Number(data.estimationAuxMachine.auxBackwashPumpRegularNumber) +
                Number(data.estimationAuxMachine.auxBackwashPumpBackupMachine)
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
    {
      title: "空洗ブロワ",
      content: [
        {
          title: "型式",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerModel,
        },
        {
          title: "仕様",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            Number(data.estimationAuxMachine.auxAirWashingBlowerRegularNumber) +
              Number(
                data.estimationAuxMachine.auxAirWashingBlowerBackupMachine
              ),
        },
        {
          title: "単位質量(kg)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerWeight,
        },
        {
          title: "単価(千円)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                data.estimationAuxMachine.auxAirWashingBlowerWeight,
                Number(
                  data.estimationAuxMachine.auxAirWashingBlowerRegularNumber
                ) +
                  Number(
                    data.estimationAuxMachine.auxAirWashingBlowerBackupMachine
                  )
              )
            ),
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              data.estimationAuxMachine.auxAirWashingBlowerUnitPrice,
              Number(
                data.estimationAuxMachine.auxAirWashingBlowerRegularNumber
              ) +
                Number(
                  data.estimationAuxMachine.auxAirWashingBlowerBackupMachine
                )
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
    {
      title: "空洗ブロワ用防音ボックス",
      content: [
        {
          title: "型式",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxModel,
        },

        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            Number(
              data.estimationAuxMachine
                .auxAirWashingBlowerSndprfBoxRegularNumber
            ) +
              Number(
                data.estimationAuxMachine
                  .auxAirWashingBlowerSndprfBoxBackupMachine
              ),
        },
        {
          title: "単位質量(kg)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight,
        },
        {
          title: "単価(千円)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight,
                Number(
                  data.estimationAuxMachine
                    .auxAirWashingBlowerSndprfBoxRegularNumber
                ) +
                  Number(
                    data.estimationAuxMachine
                      .auxAirWashingBlowerSndprfBoxBackupMachine
                  )
              )
            ),
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice,
              Number(
                data.estimationAuxMachine
                  .auxAirWashingBlowerSndprfBoxRegularNumber
              ) +
                Number(
                  data.estimationAuxMachine
                    .auxAirWashingBlowerSndprfBoxBackupMachine
                )
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
  ];

  // Table 3
  const tableCost3 = [];

  // Merge Injection Pupm

  // getInfocapacity
  const getInfoCapacity = (injection) => {
    if (!injection.checked) {
      return null;
    }
    if (!dataCapacity) {
      return null;
    }
    const itemCapa = dataCapacity.find(
      (i) => i.injectID == injection.injectionPumpId
    );
    return itemCapa ? itemCapa.capacityInfo : null;
  };
  let injectionPumps = cloneObj(
    dataStep1 && dataStep1.injectionPumps ? dataStep1.injectionPumps : []
  );
  injectionPumps.forEach((injection, index) => {
    const infoCapacity = getInfoCapacity(injection);
    const priceCapa = infoCapacity ? infoCapacity.price : 0;
    const item = {
      title: INJECT_NAME[index],
      content: [
        // Info - TK
        {
          title: "薬品タンク",
          isTitle: true,
        },

        {
          title: "数量",
          content: infoCapacity && 1,
        },
        {
          title: "型式",
          content: infoCapacity && infoCapacity.name,
        },
        {
          title: "タンク容量(L)",
          content: infoCapacity && infoCapacity.capacity,
        },
        {
          title: "重量(kg)",
          content: infoCapacity && infoCapacity.weight,
        },
        {
          title: "金額(千円/基)",
          content: infoCapacity ? infoCapacity.price : "",
        },
        {
          title: "材質",
          content: infoCapacity && infoCapacity.material,
        },
        {
          title: "単価根拠",
          content: infoCapacity && infoCapacity.priceBasis,
        },
        {
          title: "備考",
          content: infoCapacity && infoCapacity.note,
        },

        // Info - Inject
        {
          title: "注入ポンプ",
          isTitle: true,
        },
        {
          title: "数量",
          content: injection.checked ? data && finalNumberOfMachine : 0,
        },

        {
          title: "重量(kg)",
          content: injection.weight,
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円/基)",
          content: injection.price,
        },
        {
          title: "金額(千円/基)",
          content: injection.checked
            ? exactMultiplication(
                data && finalNumberOfMachine,
                injection.price
              ) + priceCapa
            : 0,
          isShow: true,
          key: KEY.MONNEY,
          isHide: true,
        },
        {
          title: "単価根拠",
          content: injection.priceBasis,
        },
        {
          title: "備考",
          content: injection.note,
        },
      ],
    };
    tableCost3.push(item);
  });

  const tableCost4 = [
    {
      title: "制御盤",
      content: [
        {
          title: "数量(式)",
          content:
            data.estimationConst && data.estimationConst.controlPanelNumber,
          isEdit: false,
          key: KEY.NUMBER_MACHINE,
          keyInput: ["estimationConst", "controlPanelNumber"],
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.controlPanelPrice,
          isEdit: false,
          keyInput: ["estimationConst", "controlPanelPrice"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              data.estimationConst.controlPanelPrice,
              data.estimationConst.controlPanelNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.controlPanelPriceComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "controlPanelPriceComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];

  // Merge water device
  let waterDevices = cloneObj(dataStep1.waterDevices || []);
  if (Array.isArray(waterDevices)) {
    waterDevices = waterDevices.filter((i) => i.checked);
  }
  const itemWaterDeviceTableCost4 = {
    title: "水質測定装置",
    content: [],
  };
  waterDevices.forEach((device) => {
    itemWaterDeviceTableCost4.content.push({
      title: device.amountTable,
      content: device.unitPrice,
    });

    tableCost4.push({
      title: device.amountTable,
      isSub: true,

      content: [
        {
          title: device.amountTable,
          content: device.unitPrice,
          isShow: true,
          isNotPlus: true,
        },
      ],
    });
  });

  const moneyWaterDevice = itemWaterDeviceTableCost4.content.reduce(
    (money, item) => {
      return money + item.content;
    },
    0
  );

  itemWaterDeviceTableCost4.content.push({
    title: "金額(千円)",
    content: moneyWaterDevice,
    isShow: true,
    key: KEY.MONNEY,
  });
  tableCost4.splice(1, 0, itemWaterDeviceTableCost4);

  // Table cost 5
  const tableCost5 = [
    {
      title: "試運転SV費",
      content: [
        {
          title: "数量(日)",
          content: data ? 2 + (finalNumberOfMachine - 1) * 1 : "",
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.commissioningSvFees,
          isEdit: false,
          keyInput: ["estimationConst", "commissioningSvFees"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              2 + (finalNumberOfMachine - 1) * 1,
              data.estimationConst.commissioningSvFees
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.commissioningSvFeeComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "commissioningSvFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "設計費",
      content: [
        {
          title: "数量(式)",
          content: data.estimationConst && data.estimationConst.designNumber,
          key: KEY.NUMBER_MACHINE,
          keyInput: ["estimationConst", "designNumber"],
        },

        {
          title: "金額(千円)",
          content: data.estimationConst && data.estimationConst.designFee,
          isShow: true,
          key: KEY.MONNEY,
          isEdit: false,
          keyInput: ["estimationConst", "designFee"],
        },

        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.designFeeComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "designFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "図書作成費",
      content: [
        {
          title: "数量(式)",
          content:
            data.estimationConst && data.estimationConst.bookCreationNumber,
          key: KEY.NUMBER_MACHINE,
          keyInput: ["estimationConst", "bookCreationNumber"],
        },

        {
          title: "金額(千円)",
          content: data.estimationConst && data.estimationConst.bookCreationFee,
          isShow: true,
          key: KEY.MONNEY,
          isEdit: false,
          keyInput: ["estimationConst", "bookCreationFee"],
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.bookCreationFeeComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "bookCreationFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];

  // Equipment cost
  const equipmentCost = caculatorSumMoneyandWeight([
    tableCost1,
    tableCost2,
    tableCost3,
    tableCost4,
  ]);
  // Attach cost
  const attachCost = caculatorSumMoneyandWeight([
    tableCost2,
    tableCost3,
    tableCost4,
  ]);
  // Tổng phụ kiện kèm theo
  const attachEquimentCost = [
    {
      title: "付属機器小計",
      content: [
        {
          title: "質量(kg)",
          content: attachCost.weight,
        },
        {
          title: "金額(千円)",
          content: attachCost.money,
          isShow: true,
        },
      ],
    },
  ];
  // Machine bill
  const machineBill = [
    {
      title: "機器費計",
      content: [
        {
          title: "質量(kg)",
          content: equipmentCost.weight,
        },
        {
          title: "金額(千円)",
          content: equipmentCost.money,
          isShow: true,
        },
      ],
    },
  ];

  const totalCostAndWeight = caculatorSumMoneyandWeight([tableCost5]);
  const totalCost = totalCostAndWeight.money + equipmentCost.money;
  const total = [
    {
      title: "計",
      content: [
        {
          title: "金額(千円)",
          content: totalCost,
          isShow: true,
        },
      ],
    },
  ];
  const reverseFund = [
    {
      title: "予備費",
      content: [
        {
          title: "料率(%)",
          content: data.estimationConst && data.estimationConst.reserveFund,
          isEdit: false,
          keyInput: ["estimationConst", "reserveFund"],
          key: KEY.PRICE_UNIT_PERCEN,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round((data.estimationConst.reserveFund * totalCost) / 100),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.reserveFundComment,
          key: KEY.REMARK,
          isEdit: false,
          keyInput: ["estimationConst", "reserveFundComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];

  const dcCost =
    data.estimationConst &&
    Math.round(
      exactMultiplication(
        Number(data.estimationConst.reserveFund) + 100,
        totalCost
      ) / 100
    );
  // DC
  const dc = [
    {
      title: "DC計",
      content: [
        {
          title: "金額(千円)",
          content: dcCost,
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
  ];

  // Prodit per

  const profitAndLossper = data.estimationConst
    ? Number(data.estimationConst.profitLossPercent)
    : 0;

  // Chi phí quản lí
  const manageCost = [
    {
      title: "工事管理費",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst &&
            data.estimationConst.constructionManagementPercent,
          keyInput: ["estimationConst", "constructionManagementPercent"],
          isEdit: false,
          key: KEY.MANAGE_PERCEN,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round(
              (data.estimationConst.constructionManagementPercent * dcCost) /
                100
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.constructionManagementPercentComment,
          keyInput: ["estimationConst", "constructionManagementPercentComment"],
          isEdit: false,
          rule: "all",
          ruleKeyDown: "all",
          key: KEY.REMARK,
        },
      ],
    },
    {
      title: "販売管理費",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst &&
            data.estimationConst.generalAdministrativePercent,
          keyInput: ["estimationConst", "generalAdministrativePercent"],
          isEdit: false,
          key: KEY.MANAGE_PERCEN,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round(
              (data.estimationConst.generalAdministrativePercent * dcCost) / 100
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.generalAdministrativePercentComment,
          keyInput: ["estimationConst", "generalAdministrativePercentComment"],
          isEdit: false,
          rule: "all",
          ruleKeyDown: "all",
          key: KEY.REMARK,
        },
      ],
    },
    {
      title: "手数料",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst && data.estimationConst.commissionPercent,
          keyInput: ["estimationConst", "commissionPercent"],
          key: KEY.MANAGE_PERCEN,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round(
              (data.estimationConst.commissionPercent * getSellingPrice()) / 100
            ),
          isShow: true,
          key: KEY.MONNEY,
          subFullText: (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span className="bold">料率 :</span>
              <Input
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue && !REGEX_INPUT.interger.test(newValue)) {
                    return;
                  }
                  if (Number(newValue) < 0 || Number(newValue) > 100) {
                    return;
                  }
                  const newData = cloneObj(data);
                  setData({
                    ...newData,
                    estimationConst: {
                      ...newData.estimationConst,
                      commissionPercent: Number(newValue),
                    },
                  });
                }}
                style={{
                  width: 80,
                  marginLeft: 5,
                  marginRight: 5,
                }}
                value={
                  data.estimationConst
                    ? data.estimationConst.commissionPercent || 0
                    : 0
                }
              />
              <span className="bold">
                (%) 金額 :{" "}
                {formatCurrencyJPY(
                  data.estimationConst
                    ? Math.round(
                        (data.estimationConst.commissionPercent *
                          getSellingPrice()) /
                          100
                      )
                    : 0
                )}
                (千円)
              </span>
            </div>
          ),
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.commissionPercentComment,
          keyInput: ["estimationConst", "commissionPercentComment"],
          rule: "all",
          ruleKeyDown: "all",
          key: KEY.REMARK,
        },
      ],
    },
  ];
  // Cost of sale
  const costOfSale = caculatorSumMoneyandWeight([dc, manageCost]);
  costOfSale.money = Math.round(costOfSale.money);

  const costOfSaleTable = [
    {
      title: "販売原価",
      content: [
        {
          title: "金額(千円)",
          content: costOfSale.money,
          isShow: true,
          key: "money",
        },
      ],
    },
  ];

  // const sellingPrice = roundToDecimalPlaces(
  //   (costOfSale.money * 100) / (100 - profitAndLossper),
  //   -3
  // );
  const sellingPrice = getSellingPrice();

  //損益率：％（％）　金額：tiền（千円）
  const profitAndLoss = [
    {
      title: "損益 (P)",
      content: [
        {
          title: "損益率 (%)",
          content: profitAndLossper,
          key: KEY.BENEFIT_LOSS_PERCEN,
          keyInput: ["estimationConst", "profitLossPercent"],
        },

        {
          title: "金額(千円)",
          content: Math.round(
            exactMultiplication(sellingPrice, profitAndLossper) / 100
          ),
          isShow: true,
          key: KEY.MONNEY,
          fullText: (
            <>
              損益率 :
              <Input
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue && !REGEX_INPUT.interger.test(newValue)) {
                    return;
                  }
                  if (Number(newValue) < 0 || Number(newValue) > 100) {
                    return;
                  }
                  const newData = cloneObj(data);
                  setData({
                    ...newData,
                    estimationConst: {
                      ...newData.estimationConst,
                      profitLossPercent: newValue,
                    },
                  });
                }}
                style={{
                  width: 80,
                  marginLeft: 5,
                  marginRight: 5,
                }}
                value={profitAndLossper}
              />
              (%) 金額 :
              {formatCurrencyJPY(
                Math.round(
                  exactMultiplication(sellingPrice, profitAndLossper) / 100
                )
              )}
              (千円)
            </>
          ),
        },
      ],
    },
  ];

  // Price sell Table
  const priceSellTable = [
    {
      title: "販売価格",
      content: [
        {
          title: "金額(千円)",
          content: sellingPrice,
          isShow: true,
        },
      ],
    },
  ];

  // Render Table Cost Collapse
  const rederCollapsetableCost = (
    arrData,
    num = 1,
    title = "",
    Icon,
    config = {}
  ) => {
    const isSingle = arrData.length === 1;
    const textTitle = title || arrData[0].title;
    const sigleEdit = isSingle && arrData[0].content.find((i) => i.isEdit);
    const { isOpen, isNotMoney } = config;
    let fullText;
    const isNoICon = num == null && !Icon;

    const price = arrData.reduce((result, item) => {
      const showEl = item.content.find((i) => i.isShow && !i.isNotPlus);
      if (showEl && showEl.fullText) {
        fullText = showEl.fullText;
      }
      const number = showEl ? (showEl.content ? showEl.content : 0) : 0;
      return result + number;
    }, 0);

    return (
      <Row gutter={0}>
        <Col span={24} className="mt8">
          <div style={{ margin: 0, padding: 0 }}>
            <Collapse
              defaultActiveKey={["1"]}
              className={isNotMoney ? "" : "collapseCost"}
              collapsible={arrData.length === 1 && "icon"}
              expandIconPosition={!isNotMoney ? "left" : "right"}
              style={{ padding: 0 }}
              expandIcon={
                !isNotMoney
                  ? ({ isActive }) =>
                      arrData.length === 1 ? null : isActive ? (
                        <DownOutlined
                          style={{ left: 5, marginTop: sigleEdit ? 4 : 0 }}
                        />
                      ) : (
                        <RightOutlined
                          style={{ left: 5, marginTop: sigleEdit ? 4 : 0 }}
                        />
                      )
                  : null
              }
            >
              <Panel
                header={
                  <>
                    <div
                      className="flex-center"
                      style={{
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        onClick={
                          isSingle
                            ? () => openModalInfoTableCost(arrData[0])
                            : null
                        }
                        className="flex-start"
                        style={{
                          paddingLeft: isNoICon ? 8 : 0,
                        }}
                      >
                        {!isNoICon ? (
                          Icon ? (
                            <Icon
                              style={{
                                fontSize: 20,
                                color: "#0080cb",
                                marginRight: 6,
                                marginLeft: isSingle ? 8 : 0,
                              }}
                            />
                          ) : (
                            <IconNumber
                              marginLeft={isSingle ? 8 : 0}
                              size={20}
                              number={num}
                            />
                          )
                        ) : (
                          ""
                        )}
                        <span>{textTitle}</span>
                      </div>
                      <div
                        className="flex-start"
                        style={{ justifyContent: "flex-end" }}
                      >
                        {sigleEdit && (
                          <EditOutlined
                            onClick={() => openModalEditTableCost(arrData[0])}
                            style={{
                              width: 30,
                              height: 30,
                              color: "#0080cb",
                              cursor: "pointer",
                            }}
                          />
                        )}
                        {!isNotMoney &&
                          (fullText ? (
                            <span>{fullText}</span>
                          ) : (
                            <span>{`${
                              !isSingle ? "金額 : " : ""
                            }${formatCurrencyJPY(price || 0)} (千円) `}</span>
                          ))}
                      </div>
                    </div>
                  </>
                }
                key={isOpen ? "1" : 0}
              >
                {arrData.length > 1 && (
                  <Descriptions size="small" column={1} bordered>
                    {renderItemTableCost(arrData, { isNotMoney })}
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
          </div>
        </Col>
      </Row>
    );
  };

  // Get Selling pirice
  function getSellingPrice() {
    if (!data || !data.estimationConst) {
      return 0;
    }

    // F56
    const constructionManagementPercent =
      data.estimationConst.constructionManagementPercent;
    // F57
    const generalAdministrativePercent =
      data.estimationConst.generalAdministrativePercent;
    // F58
    const commissionPercent = data.estimationConst.commissionPercent;
    //=U54*(1+F56+F57)/(1-F60-F58)
    // U54 :DC cost
    // F60 : Profitand Lose

    const finaPrice =
      exactMultiplication(
        dcCost *
          (1 +
            constructionManagementPercent / 100 +
            generalAdministrativePercent / 100)
      ) /
      (1 - profitAndLossper / 100 - commissionPercent / 100);
    return roundToDecimalPlaces(finaPrice, -3);
  }

  // render modal info
  const renderDataModalInfo = () => {
    const listIndexTitle = [];
    const listInfoItem = [];
    if (!dataModal || (dataModal && !Array.isArray(dataModal.content)))
      return null;
    const content = dataModal.content;
    content.forEach((item, index) => {
      if (item.isTitle) {
        listIndexTitle.push(index);
      }
    });
    if (listIndexTitle.length === 0) {
      return (
        <Descriptions size="small" column={1} bordered>
          {renderDesContent(dataModal, { isOpenModal: false })}
        </Descriptions>
      );
    }

    // render Title
    for (let i = 0; i < listIndexTitle.length; i++) {
      const indexTitile = listIndexTitle[i];
      if (i < listIndexTitle.length - 1) {
        const indexTitileNext = listIndexTitle[i + 1];
        listInfoItem.push(content[indexTitile]);
        listInfoItem.push({
          content: content.slice(indexTitile + 1, indexTitileNext),
        });
      } else {
        listInfoItem.push(content[indexTitile]);
        listInfoItem.push({
          content: content.slice(indexTitile + 1),
        });
      }
    }
    return listInfoItem.map((item) => {
      if (item.isTitle) {
        return (
          <div
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBlock: 5,
            }}
          >
            {item.title}
          </div>
        );
      } else {
        return (
          <Descriptions size="small" column={1} bordered>
            {renderDesContent(item, { isOpenModal: false })}
          </Descriptions>
        );
      }
    });
  };

  if (_.isEmpty(dataStep1) || _.isEmpty(dataStep2))
    return <Redirect to={path} />;
  return (
    <>
      <div
        style={{ marginTop: -20 }}
        className="des-content machine-content step3 valueInputRight"
      >
        <TitlePage name={"見積表の出力"}></TitlePage>

        <HeaderText text={LANG.PROJECT_INFOMATION} />
        <Row gutter={0}>{renderInfo(infoProjectArr, false)}</Row>
        <HeaderText text={LANG.CONDITION_INPUT} />
        <Row gutter={0}>{renderInfo(conditionArr, false)}</Row>
        <HeaderText text={LANG.TABLE_COSST} />
        <div className="wrapTableCost">
          {rederCollapsetableCost(tableCost1, 1, "本体機器小計")}
          {rederCollapsetableCost(tableCost2, 2, "補機小計")}
          {rederCollapsetableCost(tableCost3, 3, "薬品注入装置")}

          {rederCollapsetableCost(tableCost4, 4, "電気計装関係")}
          {rederCollapsetableCost(
            attachEquimentCost,
            null,
            "付属機器小計",
            null
          )}
          {rederCollapsetableCost(machineBill, null, "機器費計", null)}

          {rederCollapsetableCost(tableCost5, 5, "人件費関係")}
          {rederCollapsetableCost(total, null, "計", null)}
          {rederCollapsetableCost(reverseFund, null, "予備費", null)}

          {rederCollapsetableCost(dc, null, "DC計", null)}
          {rederCollapsetableCost(manageCost, 6, "管理費")}
          {rederCollapsetableCost(costOfSaleTable, null, "販売原価", null)}
          {rederCollapsetableCost(profitAndLoss, null, "損益 (P)", null)}
          {rederCollapsetableCost(priceSellTable, null, "販売価格", null)}
        </div>
      </div>
      <Modal
        style={{ padding: 0 }}
        centered
        closeIcon={<></>}
        className=""
        title={dataModal ? dataModal.title : null}
        open={visible}
        onCancel={() => closeModalInfoTableCost()}
        footer={
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                className="buttonPC --todo-- wAuto  button--outline"
                onClick={() => closeModalInfoTableCost()}
              >
                {lang.CLOSE}
              </Button>
            </Col>
          </Row>
        }
      >
        {renderDataModalInfo()}
      </Modal>
      <Modal
        style={{ padding: 0 }}
        centered
        closeIcon={<></>}
        title={dataModal ? dataModal.title : ""}
        open={showModalEditPrice}
        onCancel={() => closeModalEditTableCost()}
        footer={
          <Row>
            <Col span={20} style={{ textAlign: "right" }}>
              <Button
                onClick={() => handleChangeValueCost()}
                className={"buttonOk"}
              >
                {LANG.OK}
              </Button>
              <Button
                className="buttonPC --todo-- wAuto  button--outline"
                onClick={() => closeModalEditTableCost()}
              >
                {lang.CANCEL}
              </Button>
            </Col>
          </Row>
        }
      >
        <Descriptions size="small" column={1} bordered>
          {dataModal && renderDesContent(dataModal, { isOpenModal: false })}
        </Descriptions>
      </Modal>

      <FooterPage>
        <Row>
          <Col span={12}>
            <div
              className={"actionRight"}
              onClick={() => {
                pushDataStep3();
                history.push(getPathEstimateWithStep(basePath, 2));
              }}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>
          <Col span={12}>
            <ButtonSave
              haveDraft={false}
              body={{
                ...cloneObj(formDataPostDefault),
                ...{
                  ...cloneObj(dataStep1),
                  options: optionPost,
                  waterDevices:
                    dataStep1 &&
                    Array.isArray(dataStep1.waterDevices) &&
                    dataStep1.waterDevices,
                },
                estimationConst: data.estimationConst,
              }}
              handleOpenSave={() => dispatch(openSave())}
            />
          </Col>
        </Row>
      </FooterPage>
    </>
  );
}

export default AddStep3;

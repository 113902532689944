import { Button, Col, Collapse } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ControlPanelApi } from "../../../api/controlPanel";
import BreakLine from "../../../component/Desktop/breakLine";
import Loading from "../../../component/Desktop/loading/loading";
import RowInput from "../../../component/Desktop/rowInput";
import FormInput from "../../../component/FormInput";
import {
  COMMON_CONSTANT,
  KEY,
  KEY_RULE_INPUT,
  STYLE,
  lang,
} from "../../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import classText from "../../../models/control/text";
import { LIST_RULE, ModalCustom } from "../../../utils/helpers";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";

const { Panel } = Collapse;
const TabControlPanel = ({isLoad}) => {
  const [data, setData] = useState({
    id: new classText(),
    amount: new classText(),
    remark: new classText(),
  });
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const initLoad = useRef(0);
  const inputRefs = useRef([]);
  // Change Input
  const handleChangeData = (key) => (e) => {
    const newValue = e.target.value;
    const dataItem = listDataModal.find((i) => i.name === key);
    if (dataItem && Array.isArray(dataItem.ruleInput)) {
      const isNotMatchInput = dataItem.ruleInput.some((ruleName) => {
        return !LIST_RULE[ruleName](newValue);
      });
      if (isNotMatchInput) {
        return;
      }
    }
    setData({ ...data, [key]: { ...data[key], value: newValue } });
  };

  //List data
  const listDataModal = [
    {
      name: "amount",
      title: "金額(千円)",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK, KEY_RULE_INPUT.IS_UNSIGN_INTERGER],
      ruleInput: [KEY_RULE_INPUT.IS_UNSIGN_INTERGER,KEY_RULE_INPUT.MAX_LENGTH_NUMBER],
    },

    {
      name: "remark",
      title: lang.REMARK,
      required: false,
      type: "textarea",
    },
  ];

  // VALIDATE DATA

  const validate = () => {
    let isFlagErr = false;
    let fistNameErr = "";
    const listDataWithRule = listDataModal.filter((i) => Array.isArray(i.rule));
    if (listDataWithRule.length === 0) {
      return false;
    }
    // check rule
    for (let index = 0; index < listDataWithRule.length; index++) {
      const nameItem = listDataWithRule[index].name;
      const value = data[nameItem].value;
      const listRule = listDataWithRule[index].rule;
      const isErrItem = listRule.some((ruleName) => {
        return !LIST_RULE[ruleName](value);
      });
      if (isErrItem) {
        isFlagErr = true;
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: true,
          },
        }));
        if (!fistNameErr) {
          fistNameErr = nameItem;
        }
      } else {
        fistNameErr = "";
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: false,
          },
        }));
      }
    }

    // Focus Fist Item Error
    if (fistNameErr && inputRefs.current && inputRefs.current[fistNameErr]) {
      inputRefs.current[fistNameErr].focus();
    }
    return !isFlagErr;
  };

  // GET CONTROL PANEL
  const getControlPanel = async () => {
    try {
      setLoading(true);
      const res = await ControlPanelApi.getList();
      const dataRes = res.data.data[0];
      setData({
        ...data,
        id: {
          ...data.id,
          value: dataRes.id,
        },
        remark: {
          ...data.remark,
          value: dataRes.remark,
        },
        amount: {
          ...data.amount,
          value: dataRes.amount,
        },
      });
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  //GET DATA
  useLoadTabDataEffect(isLoad,getControlPanel);


  useEffect(() => {
    if (initLoad.current > 1 && !activeButton) {
      setActiveButton(true);
    }
    if (!activeButton) {
      initLoad.current++;
    }
  }, [data]);

  const postData = async () => {
    try {
      setLoading(true);
      const body = {
        id: data.id.value,
        amount: data.amount.value,
        remark: data.remark.value,
      };
      await ControlPanelApi.update(body);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };
  // SAVE
  const onSaveData = async () => {
    if(!activeButton){
     return;
    }
    
    if (!validate()) {
      return;
    } else {
      ModalCustom.confirm({
        centered: true,
        title: lang.CONFIRM,
        content: COMMON_CONSTANT.BOM_C008,
        okText: lang.OK,
        cancelText: lang.CANCEL,
        onOk: () => postData(),
      });
    }
  };
  return (
    <div>
      <div className="mb10"></div>
      <div>
        {listDataModal.map((item, index) => (
          <div key={item.name}>
            {index === 0 && <BreakLine />}
            <RowInput
              flexStart={false}
              required={item.required}
              labelCol={8}
              box
              name={item.title}
            >
              <FormInput
                valueObject={data[item.name]}
                onChange={handleChangeData(item.name)}
                refer={(ref) => (inputRefs.current[item.name] = ref)}
                className={`${data[item.name].error ? STYLE.BORDER_RED : ""}`}
                type={item.type}
              />
            </RowInput>
            <BreakLine />
          </div>
        ))}
      </div>

      <Col span={24} style={{ marginTop: "10px" }}>
        <Button
          onClick={() => onSaveData()}
          type="primary"
          style={{ minWidth: 103, borderRadius: 4, opacity: !activeButton && 0.6,cursor:!activeButton && "not-allowed" }}
          className="wAuto mr8"
        >
          保存
        </Button>
        <Button
          disabled={!activeButton}
          onClick={() =>{
            if(activeButton){
              getControlPanel()
            }
          }}
          type="link"
          style={{ minWidth: 103, borderRadius: 4 }}
          className="buttonPC button--outline --todo-- wAuto"
        >
          キャンセル
        </Button>
      </Col>
      <Loading show={loading} />
    </div>
  );
};
export default TabControlPanel;

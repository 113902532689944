import React from "react";
import { Col, Row } from "antd";

function VerticalInput({ box, text, required, label, ...props }) {
  return (
    <Row>
      <Col
        span={24}
        className="row-input-col-first flex-center"
        style={{
          padding: 8,
          border: "1px solid #e6e6e6",
          borderTop: "none",
          borderLeft: "none",
          fontWeight: "bold",
        }}
      >
        {label}
      </Col>
      <Col
        span={24}
        className="flex-center"
        style={{
          padding: 8,
          borderRight: "1px solid #e6e6e6",
          borderBottom: "none",
          minHeight: 40,
        }}
      >
        {props.children}
      </Col>
    </Row>
  );
}

export default VerticalInput;

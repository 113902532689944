import Api from "@src/api";
import { isEmpty } from "lodash";
export const ChemicalTankApi = {

  getChemicalTank() {
    return Api().get(`/api/office/ChemicalTank`)
  },
  getChemicalTankById(id) {
    return Api().get(`/api/office/ChemicalTank?id=${id}`)
  },
  updateChemicalTank(body){
    return Api().put(`/api/office/ChemicalTank`,body)
  },
  createChemicalTank(body){
    return Api().post(`/api/office/ChemicalTank`,body)
  },
  deleteChemicalTank(id){
    return Api().delete(`/api/office/ChemicalTank?id=${id}`)
  },
  
}
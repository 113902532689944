/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Modal, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { CostManagementApi } from "../../../api/backend/costManagement";
import { FeeRateApi } from "../../../api/backend/feeRate";
import FormInput from "../../../component/FormInput";
import {
  COMMON_CONSTANT,
  KEY,
  KEY_RULE_INPUT,
  lang
} from "../../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import classText from "../../../models/control/text";
import { LIST_RULE, ModalCustom, showErrorMessage } from "../../../utils/helpers";

const { Paragraph } = Typography;

function AddFeeRate({ open, onClose = () => {}, id,detail }) {
  let [showLoading, setShowLoading] = useState(false);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [data, setData] = useState({
    project: new classText(detail && detail.project),
    rate: new classText(detail && detail.rate),
    remark: new classText(detail && detail.remark),
  });

  const inputRefs = useRef([]);
  // Change Input
  const handleChangeData = (key) => (e) => {
    const newValue = e.target.value;
    const dataItem = listDataModal.find((i) => i.name === key);
    if (dataItem && Array.isArray(dataItem.ruleInput)) {
      const isNotMatchInput = dataItem.ruleInput.some((ruleName) => {
        return !LIST_RULE[ruleName](newValue);
      });
      if (isNotMatchInput) {
        return;
      }
    }
    setData({ ...data, [key]: { ...data[key], value: newValue } });
  };

  const getIniData = async () => {
    try {
      // edit
      if (id) {
        const optionsRes = await CostManagementApi.getCostManagementById(id);
        setData({
          costID: new classText(optionsRes.data.data[0].costID),
          unitPrice: new classText(optionsRes.data.data[0].unitPrice),
          unitPriceBasis: new classText(optionsRes.data.data[0].unitPriceBasis),
          remark: new classText(optionsRes.data.data[0].remark),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    getIniData();
  }, [id]);
  //List data
  const listDataModal = [
    {
      name: "project",
      title: "項目",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK],
    },
    {
      name: "rate",
      title: "料率 (%)",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK,KEY_RULE_INPUT.IS_RATE],
      ruleInput:[KEY_RULE_INPUT.IS_RATE]
    },
    
    {
      name: "remark",
      title: lang.REMARK,
      required: false,
      type: "textarea",
    },
  ];

  // VALIDATE DATA

  const validate = () => {
    let isFlagErr = false;
    let fistNameErr = "";
    const listDataWithRule = listDataModal.filter((i) => Array.isArray(i.rule));
    if (listDataWithRule.length === 0) {
      return false;
    }
    // check rule
    for (let index = 0; index < listDataWithRule.length; index++) {
      const nameItem = listDataWithRule[index].name;
      const value = data[nameItem].value;
      const listRule = listDataWithRule[index].rule;
      const isErrItem = listRule.some((ruleName) => {
        return !LIST_RULE[ruleName](value);
      });
      if (isErrItem) {
        isFlagErr = true;
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: true,
          },
        }));
        if (!fistNameErr) {
          fistNameErr = nameItem;
        }
      } else {
        fistNameErr = "";
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: false,
          },
        }));
      }
    }

    // Focus Fist Item Error
    if (fistNameErr && inputRefs.current && inputRefs.current[fistNameErr]) {
      inputRefs.current[fistNameErr].focus();
    }
    return !isFlagErr;
  };
  // SAVE DATA
  const postData = async () => {
    try {
      setShowLoading(true);
      const body = {
        id: detail.id,
        project: data.project.value,
        rate: data.rate.value,
        remark: data.remark.value,
      };
      await FeeRateApi.update(body);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      onClose()
    } catch (error) {
    showErrorMessage(error)
    } finally {
      setShowLoading(false);
      onClose();
    }
  };
  // SAVE DATA
  const onSaveData = async () => {
    if (!validate()) {
      return;
    } 
    ModalCustom.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: postData,
    });
  };
  return (
    <div>
      <Modal
        title={
            "レート編集"
        }
        centered
        width={isPC ? "40%" : "90%"}
        bodyStyle={{ height: "auto", overflow: "auto" }}
        closeIcon={<></>}
        className=""
        open={open}
        footer={[
          <Button
            onClick={() => onClose(false)}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CANCEL}
          </Button>,
          <Button
            onClick={() => onSaveData()}
            className="buttonPC button--info wAuto"
          >
            {lang.OK}
          </Button>,
        ]}
      >
        <Form style={{ gap: "100px" }}>
          <Row gutter={[16, 16]}>
            {listDataModal.map((item) => (
              <Col key={item.name} span={24}>
                <div>
                  <label className="bold">
                    {item.title}{" "}
                    {item.required && (
                      <label className="blod mark-required">*</label>
                    )}
                  </label>
                  <FormInput
                    valueObject={data[item.name]}
                    onChange={handleChangeData(item.name)}
                    refer={(ref) => (inputRefs.current[item.name] = ref)}
                    type={item.type}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default AddFeeRate;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { CalculatorFilled, ControlFilled,ProfileFilled  } from "@ant-design/icons";
import { Card, ConfigProvider } from "antd";
import { ExportCurve, Profile2User } from "iconsax-react";
import React from "react";
import { useHistory } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import { LANG } from "../../constants/lang.const";
import "./detail.scss";
import { useSelector } from "react-redux";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import Loading from "../../component/Desktop/loading/loading";

const { Meta } = Card;
function OfficeDetail() {
  const history = useHistory();
  const { isLeader, roleKey } = useSelector((state) => state.auth);
  const style = {
    bodyStyle: {
      backgroundColor: "transparent",
      fontSize: 20,
      fontWeight: "bold !important",
      padding: 16,
    },
    bodyIcon: {
      fontSize: 48,
      color: "rgb(17 33 73)",
    },
  };

  return roleKey ? (
    <ConfigProvider theme={{
      token: {
        colorText: 'rgb(255 255 255 / 88%)'
      },
      components: {
        Card: {
          colorBgContainer: 'rgb(83, 130, 184)',
        }
      }
    }}>
      <div className="des-content home-menu">
        <TitlePage name={LANG.HOME}></TitlePage>
        <Card
          className="w100"
          bodyStyle={style.bodyStyle}
          style={{
            cursor: "pointer",
            boxShadow:
              "0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)",
          }}
          bordered={false}
          onClick={() => history.push("/Estimate/list")}
        >
          <Meta
            title="見積管理"
            avatar={
              <>
                <CalculatorFilled style={style.bodyIcon} />
              </>
            }
          />
        </Card>
       {
        isLeader &&  <Card
        className="w100 mt30"
        bodyStyle={style.bodyStyle}
        style={{
          cursor: "pointer",
          boxShadow:
            "0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)",
        }}
        bordered={false}
        onClick={() => history.push("/master/management")}
      >
        <Meta
          title={"マスター管理"}
          avatar={
            <>
              <ProfileFilled style={style.bodyIcon} />
            </>
          }
        />
      </Card>
       }
        {isLeader && (
          <Card
            className="w100 mt30"
            onClick={() => history.push("/user")}
            bodyStyle={style.bodyStyle}
            style={{
              cursor: "pointer",
              boxShadow:
                "0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)",
            }}
            bordered={false}
          >
            <Meta
              title="ユーザ設定"
              avatar={
                <>
                  <Profile2User style={style.bodyIcon} size={48} variant="Bold" />
                </>
              }
            />
          </Card>
        )}
        
      </div>
    </ConfigProvider>
  ) : (
    <Loading show />
  );
}

export default OfficeDetail;

import { Col, Drawer, Row } from "antd";
import React, { useState } from "react";
import { lang } from "../../../constants/common.const";
import ButtonClone from "./buttonClone";
import ButtonExport from "./buttonExport";
function ButtonMore(props) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className={"actionLeft"} onClick={() => setShowMore(true)}>
        操作パネル
      </div>
      <Drawer
        className="drawEstimate"
        height={400}
        zIndex={1000}
        open={showMore}
        title={<strong>{"操作メニュー"}</strong>}
        placement="bottom"
        onClose={() => setShowMore(false)}
      >
        <Row gutter={[10, 10]}>
          {!props.isDraf && (
            <Col span={24}>
              <ButtonExport machineSelected={props.machineSelected} />
            </Col>
          )}
          <Col span={24}>
            <ButtonClone />
          </Col>
          {props.children}
        </Row>
      </Drawer>
    </>
  );
}

export default ButtonMore;

import Api from "@src/api";
import { isEmpty } from "lodash";
export const EstimateApi = {
  searchEstimate(params) {
    return Api().get("api/office/Estimation", { params });
  },
  getDetailEstimate(id, version, params = {}) {
    return Api().get(`/api/office/Estimation/${id}/${version}`, {
      params,
    });
  },
  getEstimateDetailLastVersion(id) {
    return Api().get(`/api/office/Estimation/${id}`);
  },
  getOptions() {
    return Api().get(`/api/office/Option`);
  },
  getOptionsById(id) {
    return Api().get(`/api/office/Option?id=${id}`);
  },
  updateOptions(body) {
    return Api().put(`/api/office/Option`, body);
  },
  createOptions(body) {
    return Api().post(`/api/office/Option`, body);
  },

  deleteOptions(id) {
    return Api().delete(`/api/office/Option?id=${id}`);
  },
  getWater() {
    return Api().get(`/api/office/Kbn/rawWaterType`);
  },
  getWaterDetail() {
    return Api().get(`/api/office/Kbn/rawWaterDetailType`);
  },

  getFirberType() {
    return Api().get(`/api/office/Kbn/fiberTypeBasedOnRawWaterType`);
  },
  getLv() {
    return Api().get("/api/office/StandardLv");
  },
  getDataStep3(body) {
    return Api().post("/api/office/Estimation/GetMachineData", body);
  },
  createEstimate(body) {
    return Api().post("/api/office/Estimation", body);
  },
  updateEstimate(body) {
    return Api().put("api/office/Estimation/UpVerion", body);
  },
  updateOldEstimate(body) {
    return Api().put("api/office/Estimation/UpdateOldVersion", body);
  },
  updateEstimateNotUpVersion(body) {
    return Api().put("api/office/Estimation", body);
  },

  getConstantDataStep3() {
    return Api().get(`/api/office/Estimation/GetConstantData`);
  },
  viewFile(reportName, id, version) {
    return Api().get(
      `/api/office/Estimation/View/${reportName}/${id}/${version}`
    );
  },
  download3(id, version, type) {
    return new Promise(function (resolve, reject) {
      Api()
        .get(`/api/office/Estimation/${id}/${version}/${type}/download3`, {
          responseType: "blob",
        })
        .then(function (response) {
          let fileName =
            response.headers["content-disposition"].split("filename=")[1];
          fileName = fileName.substring(0, fileName.length - 1);
          resolve([response.data, fileName]);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  download3New(id, version,type ="xlsx") {
    return Api().get(`/api/office/Estimation/${id}/${version}/${type}/download3`, {
      responseType: "blob",
    });
  },

  downloadEF(id, version,type="xlsx") {
    return Api().get(`/api/office/Estimation/${id}/${version}/${type}/downloadEF?id=${id}&version=${version}`, {
      responseType: "blob",
    });
   
  },
  downloadHistoryVersion(id) {
    return new Promise(function (resolve, reject) {
      Api()
        .get(`api/office/Estimation/EsitmateHistory/${id}`, {
          responseType: "blob",
        })
        .then(function (response) {
          let fileName =
            response.headers["content-disposition"].split("filename=")[1];
          fileName = fileName.substring(0, fileName.length - 1);
          resolve([response.data, fileName]);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  download4(id, version,type ="xlsx") {
    return Api().get(`/api/office/Estimation/${id}/${version}/${type}/download4`, {
      responseType: "blob",
    });
  },

  download5(id, version,type="xlsx") {
    return Api().get(`/api/office/Estimation/${id}/${version}/${type}/download5`, {
      responseType: "blob",
    });
  },
  downloadFileAttach(estimationId, version) {
    return Api().get(
      `/api/office/Attachment/Download/${estimationId}/${version}`,
      {
        responseType: "blob",
      }
    );
  },
  downloadAll(
    estimationId,
    version,
    DesignCalculation = false,
    BalanceDiagram = false,
    id
  ) {
    const ids = isEmpty(id) ? "" : "&Id=" + id.join("&Id=");
    const param = `?EstimationId=${estimationId}&Version=${version}&DesignCalculation=${DesignCalculation}&BalanceDiagram=${BalanceDiagram}${ids}`;
    return Api().get(
      `api/office/Attachment/DownloadEstimateAttachment${param}`,
      {
        responseType: "blob",
      }
    );
  },
  downloadFileCheckbox(
    estimationId,
    version,
    DesignCalculation = false,
    BalanceDiagram = false,
    isEf = false,
    outline = false,
    flowsheet = false,
    id
  ) {
    const ids = isEmpty(id) ? "" : "&Id=" + id.join("&Id=");
    const param = `?EstimationId=${estimationId}&Version=${version}&DesignCalculation=${DesignCalculation}
    &BalanceDiagram=${BalanceDiagram}&isEf=${isEf}&outline=${outline}&flowsheet=${flowsheet}${ids}`;
    return Api().get(
      `api/office/Attachment/DownloadEstimateAttachment2${param}`,
      {
        responseType: "blob",
      }
    );
  },

  // Downloaf FlowSheet
  downloadFlowSheet(id,version){
    return Api().get(`api/office/Attachment/DownloadEstimateAttachmentFlowSheet?EstimationId=${id}&Version=${version}`,{
      responseType:"blob"
    })
  },
   // Downloaf FlowSheet
   downLoadOutLine(id,version){
    return Api().get(`api/office/Attachment/DownloadEstimateAttachmentOutLine?EstimationId=${id}&Version=${version}`,{
      responseType:"blob"
    })
  },
  
// DOwnload other file by id
 downloadOtherFileById(idMachine,machineVersion){
return Api().get(`/api/office/Attachment/DownloadByID/${idMachine}/${machineVersion}`,{
  responseType:"blob"
})
 },


  deleteEstimate(id, version) {
    return Api().delete(`/api/office/Estimation/${id}/${version}`);
  },
  getListExportFiels(id, version) {
    return Api().get(`/api/office/Estimation/ListExportFiles/${id}/${version}`);
  },
  getListHistoryVersion(id) {
    return Api().get(`api/office/Estimation/EsitmateHistory/${id}`);
  },
};

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ChemicalTankApi } from "../../../api/backend/chemicalTank";
import Loading from "../../../component/Desktop/loading/loading";
import { HeaderText } from "../../../components/common/headerText";
import { COMMON_CONSTANT, KEY, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";

import { deboundClickModal } from "../../../utils/helpers";
import AddFeeRate from "./AddFeeRate";
import { FeeRateApi } from "../../../api/backend/feeRate";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";
const { Panel } = Collapse;
const FeeRate = ({isLoad,activeMaster}) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [loading, setLoading] = useState(false);
  const [dataFee, setDataFee] = useState([]);
  const [detail,setDetail] = useState(null);
  const [isError, setIsError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [costManagement, setCostManagement] = useState([]);
  const onClose = (reset = true) => {
    setDetail(false);
    if(reset){
      getFeeRate()
    }    
  };


  const columns = [
    {
      title: <div className="text-start">項目</div>,
      dataIndex: "capacity",
      key: "capacity",
      align: "start",
      width: "25%",
      render: (text, record, index) => {
        return <div className="text-start">{record.project}</div>;
      },
    },
    {
      title: (
        <>
          <span>料率 (%)</span>
        </>
      ),
      dataIndex: "rate",
      key: "rate",
      width: "20%",
      render: (text, record) => {
        return <span>{record.rate}</span>;
      },
    },
    {
      title: <div className="text-start">{lang.REMARK}</div>,
      dataIndex: "note",
      key: "note",
      align: "start",
      width: "40%",
      render: (text, record, index) => {
        return <span>{record.remark}</span>;
      },
    },
    {
      title: LANG.ACTION,
      dataIndex: "edit",
      key: "edit",
      align: "center",
      width: "15%",
      render: (id, record, index) => {
        return (
          <Row gutter={!isPC && [4, 4]} justify={"center"}>
            <Col>
              {" "}
              <Tooltip title={lang.EDIT}>
              <Button
                onClick={() => {
                  setOpenModal(true);
                  setDetail(record);
                }}
                className={"iconItem"}
                icon={<EditOutlined />}
              />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  // get Fee Rate
  const getFeeRate = async () => {
    try {
      setLoading(true)
      const res = await FeeRateApi.getListFeeRate();
      setDataFee(res.data.data || []);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    }finally{
      setLoading(false)
    }
  };

  // GET DATA
  useLoadTabDataEffect([isLoad,activeMaster],getFeeRate);

  return (
    <div className="mt20">
      <div>
        <Row align={"middle"} justify="space-between "></Row>
        <Table
          dataSource={dataFee}
          columns={columns}
          pagination={false}
          className="gridSearch"
          scroll={{ x: !isPC && "100%" }}
          bordered
        />
      </div>
      <Loading show={loading} />
      {detail && (
        <AddFeeRate onClose={onClose}  open={detail} detail = {detail} />
      )}
      <Loading show={loading} />
    </div>
  );
};
export default FeeRate;

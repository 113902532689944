/* eslint-disable react-hooks/exhaustive-deps */
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { Radio, Space } from "antd";
import { useMediaQuery } from "react-responsive";
import {
  COMMON_CONSTANT,
  KEY,
  KEY_RULE_INPUT,
  STYLE,
  lang,
} from "../../../constants/common.const";
import { ModalCustom, isUnsignInteger } from "../../../utils/helpers";
import { ChemicalInjectionAPI } from "../../../api/backend/chemicalInjection";
import { MSG_CATCH, showMessage } from "../../../constants/utils";

function EditDrugInjection({ isModalVisible, onClose, detail }) {
  const regex1 = new RegExp(`^${"原水濁度"}>\\d+$`); // variable>20
  const regex2 = new RegExp(`^\\d+<${"原水濁度"}≦\\d+$`); // 10<=variable<20
  const regex3 = new RegExp(`^${"原水濁度"}≦\\d+$`); // variable<=10
  const initLoad = useRef(0);
  const rangeRef = useRef([]);
  const [listRange, setListRange] = useState([]);

  const [listValueRange, setListValueRange] = useState([]);
  const inputRefs = useRef([]);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [remark, setRemark] = useState("");
  // GET CONTENT OPERATORMODAL
  const getContentOperatorModal = (isLower = true) => {
    if (isLower) {
      return (
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={1}>{">"}</Radio>
            <Radio value={2}>{"≧"}</Radio>
          </Space>
        </Radio.Group>
      );
    } else {
      return (
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={1}>{"<"}</Radio>
            <Radio value={2}>≦</Radio>
          </Space>
        </Radio.Group>
      );
    }
  };

  // Open Modal opetator
  const selectOperator = (isLower) => {
    Modal.confirm({
      centered: true,
      title: "選択演算子",
      content: getContentOperatorModal(isLower),
    });
  };

  // VALIDATE DATA
  const validateValueMatchLable = (listValueValidate) => {
    let isFlagErr = false;
    let fistNameErr = "";
    listValueValidate.forEach((item, index) => {
      const label = item.key;
      if (!isValidLabel(label)) {
        return;
      }
      // if (!isValueValid(label, item.value) || !String(item.value).trim()) {
      //   isFlagErr = true;
      //   item.error = true;
      //   if (!fistNameErr) {
      //     fistNameErr = item.key;
      //   }
      // } else {
      //   item.error = false;
      // }
      if(!String(item.value).trim()){
          isFlagErr = true;
        item.error = true;
        if (!fistNameErr) {
          fistNameErr = item.key;
        }
        
      }else{
        item.error = false;
      }
      
    });
    return {
      isFlagErr,
      fistNameErr,
    };
  };

  const validate = () => {
    let isFlagErr = false;
    let fistNameErr = "";

    // Validate List Range
    const listRangeValidate = JSON.parse(JSON.stringify(listRange));
    listRangeValidate.forEach((item, index) => {
      const prevArr = listRangeValidate.slice(0, index);
      const affterArr = listRangeValidate.slice(index + 1);
      const isPrevErr = prevArr.some(({ value }) => {
        return (
          value && String(value).trim() && Number(value) >= Number(item.value)
        );
      });
      const isAffterErr = affterArr.some(({ value }) => {
        return (
          value && String(value).trim() && Number(value) <= Number(item.value)
        );
      });
      const isValidValue = isAffterErr || isPrevErr;
      if (!String(item.value).trim() || isValidValue) {
        isFlagErr = true;
        item.error = true;
        if (!fistNameErr) {
          fistNameErr = item.key;
        }
      } else {
        item.error = false;
      }
    });

    // Validate List Value Raneg
    const listValueValidate = JSON.parse(JSON.stringify(listValueRange));
    const errData = validateValueMatchLable(listValueValidate);
    isFlagErr = errData.isFlagErr;
    if (!fistNameErr) {
      fistNameErr = errData.fistNameErr;
    }
    setListValueRange(listValueValidate);
    setListRange(listRangeValidate);
    // Focus Fist Item Error
    if (fistNameErr && rangeRef.current && rangeRef.current[fistNameErr]) {
      rangeRef.current[fistNameErr].focus();
    }
    return !isFlagErr;
  };

  // CHANGE INPUT RANGE
  const onChangeRange =
    (key) =>
    ({ target: { value } }) => {
      if (value !== "" && !isUnsignInteger(value)) {
        return;
      }
      if(String(value).trim().length > 9){
        return;
      }
      const newListRange = JSON.parse(JSON.stringify(listRange));
      const index = newListRange.findIndex((i) => i.key === key);
      if (index >= 0) {
        newListRange[index].value = value;
        setListRange(newListRange);
      }
    };
  // RENDER LIST RANGE
  const renderListRange = () =>
    listRange.map((item, index) => (
      <>
        <Col className="bold" span={7}>
          分岐点{index + 1} <label className="blod mark-required">*</label>
        </Col>
        <Col span={isPC ? 15 : 14}>
          <Input
            className={item.error ? STYLE.BORDER_RED : ""}
            ref={(ref) => (rangeRef.current[item.key] = ref)}
            value={item.value}
            onChange={onChangeRange(item.key)}
          />
        </Col>
        <Col span={isPC ? 2 : 3}>
          <Button
            disabled={listRange.length === 1}
            onClick={() => handleDeleteRange(item.key)}
            className={"iconItem"}
            icon={<DeleteOutlined />}
          />
        </Col>
      </>
    ));

  const data1 = [
    {
      key: "原水濁度 > 20",
      value: "",
      error: "",
    },
  ];

  function isValueValid(label, value) {
    label = label.replace(/\s+/g, "");
    const numbers = extractNumbersFromLabel(label);
    if (regex1.test(label)) {
      // Case: variable>number
      return value > numbers[0];
    } else if (regex2.test(label)) {
      // Case: number1<=variable<number2

      return value > numbers[0] && value <= numbers[1];
    } else if (regex3.test(label)) {
      // Case: >number
      return value <= numbers[0];
    }

    return false;
  }

  // Check Valid label
  function isValidLabel(label) {
    label = label.replace(/\s+/g, "");
    // Kiểm tra từng định dạng
    return regex1.test(label) || regex2.test(label) || regex3.test(label);
  }

  // Get number from label
  function extractNumbersFromLabel(label) {
    // Loại bỏ khoảng trắng thừa
    const cleanedLabel = label.replace(/\s+/g, "");

    // trích xuất các số
    const numberPattern = /\d+/g;

    // Sử dụng match để lấy tất cả các số trong label
    const numbers = cleanedLabel.match(numberPattern);

    // Chuyển đổi các chuỗi số thành số nguyên
    return numbers ? numbers.map(Number) : [];
  }

  // Get Label
  const getLabel = (listinit = [...listRange]) => {
    const list = listinit.reverse();
    let listLabel = [];
    for (let i = 0; i < list.length; i++) {
      if (i === 0) {
        listLabel.push("原水濁度 > " + list[i].value);
      }
      if (i === list.length - 1) {
        listLabel.push("原水濁度 ≦ " + list[i].value);
      }
      if (list.length > 1 && i < list.length - 1) {
        listLabel.push(list[i + 1].value + " < 原水濁度 ≦ " + list[i].value);
      }
    }
    return listLabel;
  };

  // Find Item
  const findItem = (list = [], value, field = "key") =>
    list.find((item) => item[field] == value);
  const findItemIndex = (list = [], value, field = "key") =>
    list.findIndex((item) => item[field] == value);

  // CHange
  const changeInputValueRange = (key) => (e) => {
    const value = e.target.value;
    if (value !== "" && !isUnsignInteger(value)) {
      return;
    }
    if(String(value).trim().length > 9){
      return;
    }
    const newlist = JSON.parse(JSON.stringify(listValueRange));
    const index = findItemIndex(listValueRange, key);
    if (index >= 0) {
      newlist[index].value = value;
      setListValueRange(newlist);
    }
  };
  const renderInputRange = () => {
    let listLabel = getLabel();
    return listLabel.map((label, index) => {
      const itemByKey = findItem(listValueRange, label);
      const value = itemByKey && itemByKey.value;
      const error = itemByKey && itemByKey.error;
      return (
        <React.Fragment key={label}>
          <Col className="bold" span={7}>
            <span>{label} </span>
          </Col>
          <Col span={17}>
            <Input
              className={error ? STYLE.BORDER_RED : ""}
              ref={(ref) => (rangeRef.current[label] = ref)}
              onChange={changeInputValueRange(label)}
              value={value}
              disabled={!isValidLabel(label)}
            />
          </Col>
        </React.Fragment>
      );
    });
  };
  // RENDER LIST VALUE RANGE
  const renderListRangeValue = () => {
    return (
      <>
        <Col className="mt10" span={7}>
          条件
        </Col>
        <Col
          className="mt10"
          style={{
            textAlign: "start",
          }}
          span={17}
        >
          注入率
        </Col>
        {renderInputRange()}
      </>
    );
  };

  //ADD RANGE
  const handleAddRange = () => {
    setListRange([
      ...listRange,
      {
        value: "",
        key: Date.now(),
        error: false,
      },
    ]);
  };

  // DELETE RANGE
  const handleDeleteRange = (key) => {
    const index = listRange.findIndex((range) => range.key === key);
    if (index >= 0) {
      setListRange(listRange.filter((i) => i.key !== key));
    }
  };

  const sortArray = (arr, key) => arr.sort((i1, i2) => i1[key] - i2[key]);

  // INIT LIST RANGE AFTER OPEN MODAL
  useEffect(() => {
    if (detail) {
      setRemark(detail.remark)
      let { listRange, listRangevalue } = detail;
      listRange = sortArray(listRange, "orderNumber");
      // listRangevalue = sortArray(listRangevalue, "orderNumber");
      const listRangeInit = listRange.map((range, index) => ({
        key: index,
        value: range.value,
        error: false,
      }));
      setListRange(listRangeInit);

      // INIT LIST VALUE RANGE
      const listLabel = getLabel([...listRangeInit]);
      const newListRangeValue = [];
      listLabel.forEach((label, index) => {
        newListRangeValue.push({
          error: false,
          value: listRangevalue[index].value,
          key: label,
        });
      });
      setListValueRange(newListRangeValue);
    }
  }, [detail]);
  // SET LIST DATA BY LISTRANGE
  useEffect(() => {
    initLoad.current++;
    if (initLoad.current > 1) {
      const listLabel = getLabel([...listRange]);
      const newListRangeValue = [];
      listLabel.forEach((label) => {
        const index = findItemIndex(listValueRange, label);
        if (index < 0) {
          newListRangeValue.push({
            error: false,
            value: "",
            key: label,
          });
        } else {
          newListRangeValue.push(listValueRange[index]);
        }
      });
      setListValueRange(newListRangeValue);
    }
  }, [listRange]);

  // SAVE DADA
  const postData = async () => {
    const body = {
      id: detail.id,
      listRange: listRange.map(({ value }, index) => ({
        value,
        orderNumber: index + 1,
      })),
      listRangevalue: listValueRange
       
        .map(({ value }, index) => ({
          value,
          orderNumber: index + 1,
        })),
      remark,
    };
    try {
      await ChemicalInjectionAPI.update(body);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      onClose(true)
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  };

  // SAVE DATA
  const onSaveData = async () => {
    if (!validate()) {
      return;
    }
    ModalCustom.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => postData(),
    });
  };
  return (
    <div>
      <Modal
        centered
        width={isPC ? "50%" : "90%"}
        bodyStyle={{ height: "auto", overflow: "auto", maxHeight: "75vh" }}
        className="modal-cusstom-scrollbar"
        closeIcon={<></>}
        footer={[
          <Button
            onClick={() => onClose(false)}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CANCEL}
          </Button>,
          <Button
            onClick={() => onSaveData()}
            className="buttonPC button--info wAuto"
          >
            {lang.OK}
          </Button>,
        ]}
        title={"薬品注入編集"}
        open={isModalVisible}
      >
        <Form style={{ gap: "100px" }}>
          <Row align={"middle"} gutter={[10, 10]}>
            <Col className="mb10" span={24}>
              <div>
                <label className="bold">
                  {"項目"} <label className="blod mark-required">*</label>
                </label>
                <Input disabled value={detail && detail.name} />
              </div>
            </Col>

            {renderListRange()}
            <Col span={24}>
              <Button
                disabled={listRange.length === 5}
                onClick={() => handleAddRange(true)}
                style={{
                  background: "#5f8de5",
                  color: "#fff",
                  border: "none",
                  width: 60,
                  fontWeight: "bold",
                  opacity: listRange.length === 5 && 0.8,
                }}
                icon={<PlusOutlined />}
              ></Button>
            </Col>
            {renderListRangeValue()}
            <Col className="mt10" span={24}>
              <div>
                <label className="bold">{lang.REMARK}</label>
                <Input.TextArea
                  onChange={(e) => setRemark(e.target.value)}
                  value={remark}
                  className="textNote2"
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* <TitlePage name={"薬品注入編集"} /> */}
    </div>
  );
}

export default EditDrugInjection;

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  Tooltip,
  Popover,
  Checkbox,
  Tabs,
  Typography,
  ConfigProvider,
} from "antd";
import { Wind2, Wind, Layer } from "iconsax-react";
import { Content } from "antd/lib/layout/layout";
import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ApiMachine from "../../api/backend/machineManagement";
import ApiPump from "../../api/backend/pump";
import ApiAirWashing from "../../api/backend/airWashing";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import {
  getQuerySearch,
  MSG_CATCH,
  pushObject2QueryReplace,
  showMessage,
  statusRes,
} from "../../constants/utils";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
import { isEmpty, isObject, isString } from "lodash";
import {
  ClearOutlined,
  DeleteOutlined,
  HistoryOutlined,
  SearchOutlined,
  FormOutlined,
  CloudDownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import { LANG } from "../../constants/lang.const";
import FloatButton from "@src/component/button/floatbutton.jsx";
import { useSelector } from "react-redux";
import ImportMachine from "../ImportMachine/index.jsx";
import { deboundClickModal } from "../../utils/helpers.js";
import useLoadTabDataEffect from "../../hooks/useLoadTabData.jsx";

const PAGE_LIMIT = 100;

export function Machine_list({
  type,
  placeholder,
  apiSearch,
  apiDelete,
  modelName,
  pumpType,
  isLoad,
  isFirstTab,
  activeMaster
}) {
  const history = useHistory();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { tab } = getQuerySearch();
  const [formSearch] = Form.useForm();
  const [grid, setGrid] = useState(new classGrid());
  const [page, setPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [initRender, setInitRender] = useState(true);
  // Input Search
  const [name, setName] = useState(new classText(""));
  const [showLoading, setShowLoading] = useState(false);
  const [checked, setChecked] = useState({});
  const { isLeader } = useSelector((state) => state.auth);

  const clearInputSearch = () => {
    setName({ ...name, value: "" });
    formSearch.resetFields();

    for (const i in searchParams) {
      if (searchParams.hasOwnProperty(i)) {
        searchParams[i] = null;
      }
    }
    search_onClick();
  };
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Search Params
  const searchParams = {
    [modelName]: name.value ? name.value.trim() : null,
  };

  const search_onClick = async (pageNumber = 0) => {
    setPage(pageNumber);
    //search
    try {
      setShowLoading(true);
      const responSearch = await apiSearch({
        ...searchParams,
        limit: PAGE_LIMIT,
        offset: pageNumber * PAGE_LIMIT,
      });
      // const responSearch = await Promise.resolve(mockData);
      if (statusRes(responSearch)) {
        const { data, count } = responSearch.data;
        const checked = {};
        const listGridSearch = data.map((machineItem) => {
          checked[machineItem[modelName]] = false;
          return {
            ...machineItem,
            modelId: machineItem[modelName],
          };
        });
        setGrid({
          ...grid,
          data: listGridSearch,
          count: pumpType ? listGridSearch.length : count,
        });
        setTotalItem(pumpType ? listGridSearch.length : count);
        setChecked(checked);
      }
    } catch (error) {
      showMessageError(MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  const handleDeleteMachine = (code, version) => {
    const deleteFunc = async () => {
      try {
        setShowLoading(true);
        const responseDelete = await apiDelete(code, version);
        if (statusRes(responseDelete)) {
          await search_onClick();
          //success
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
        } else throw new Error(responseDelete);
      } catch (err) {
        let msg = MSG_CATCH();
        if (
          !isEmpty(err.response) &&
          isString(err.response.data) &&
          !isEmpty(err.response.data)
        ) {
          msg = err.response.data;
        }
        if (
          !isEmpty(err.response) &&
          isObject(err.response.data) &&
          isString(err.response.data.msg)
        ) {
          msg = err.response.data.msg;
        }
        showMessage(KEY.ERROR, msg);
      } finally {
        setShowLoading(false);
      }
    };
    Modal.confirm({
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        deleteFunc();
        deboundClickModal.handleCloseModal();
      },
      onCancel: () => {
        deboundClickModal.handleCloseModal();
      },
      centered: true,
    });
  };

  const LINK_BY_TYPE = {
    1: "machine",
    2: "pump",
    3: "air",
  };

  // Search data
  const searchInitData = () => {
    if (initRender) {
      setInitRender(false);
      search_onClick(0);
    } else {
      clearInputSearch();
    }
  }
 
  useLoadTabDataEffect( isFirstTab? [isLoad,activeMaster] :  isLoad,searchInitData)
  const TYPE_DOWNLOAD = {
    1: 0,
    3: 3,
    2: 4,
  };
  const NAME_DOWNLOAD = {
    1: "イーグルファイバー機種表",
    2: "ポンプ機種表",
    3: "空洗ブロワ機種表",
  };
  const downloadFile = async (row) => {
    // console.log(type, row.type);
    try {
      setShowLoading(true);
      row = row || {};
      const id = row.efModel || row.modelNumber || "";
      const thisType =
        type == 2 ? (row.type == 1 ? 2 : 1) : TYPE_DOWNLOAD[type];
      const thisTypeMulti = TYPE_DOWNLOAD[type];
      const listCode = Object.keys(checked).filter((item) => checked[item]);
      const resApi = isEmpty(row)
        ? ApiMachine.downloadMultiFile(listCode, thisTypeMulti)
        : ApiMachine.downloadFile(id, thisType);
      const res = await resApi;
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = `${
        id || (listCode.length == 1 ? listCode[0] : NAME_DOWNLOAD[type])
      }.${isEmpty(row) ? "xlsx" : "zip"}`;
      link.click();
    } catch (e) {
      console.log(e);
      let msg = MSG_CATCH();
      if (e.response && e.response.status == 404) {
        msg = "ファイルが見つかりません";
      }
      showMessageError(msg);
    } finally {
      setShowLoading(false);
    }
  };

  const initColumns = [
    {
      title: placeholder,
      dataIndex: modelName,
     
      width: "40%",
      render: (text, row) => {
        return (
          <Link to={"/" + LINK_BY_TYPE[type] + "/" + text} className="w100 ">
            <a>{text}</a>
          </Link>
        );
      },
    },
    {
      title: lang.ACTION,
      align: "center",
      dataIndex: "modelId",
      className: "pl4 pr4",
      width: "50%",
      render: (value, row) => {
        const versions = [...(row.versions || [])].sort((a, b) => b - a);
        return (
          <>
            {/* Edit */}
            {isLeader && (
              <Tooltip title={lang.EDIT}>
                <Button
                  className="ant-btn btnEdit mr5 pl8 pr8"
                  onClick={() =>
                    history.push(
                      "/" +
                        LINK_BY_TYPE[type] +
                        "/" +
                        (row.efModel || row.modelNumber) +
                        "/update"
                    )
                  }
                >
                  <FormOutlined />
                </Button>
              </Tooltip>
            )}

            {/* <Popover
              placement="bottom"
              title={""}
              content={
                // renderVersion(row[type == '1' ? 'efModel' : 'modelNumber'], row.versions)
                <>
                  {(versions || []).map((item, index) => {
                    return (
                      <div>
                        <Link
                          key={index}
                          to={
                            "/" +
                            LINK_BY_TYPE[type] +
                            "/" +
                            (row.efModel || row.modelNumber) +
                            "?version=" +
                            item
                          }
                        >
                          ver {item}
                        </Link>
                      </div>
                    );
                  })}
                </>
              }
              trigger={isPC ? "hover" : "click"}
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="ant-btn mr5 btn pl8 pr8"
              >
                <HistoryOutlined />
              </Button>
            </Popover> */}

            {/* Download */}
            {/* {
              <Tooltip title="">
                <Button
                  className="ant-btn btnClone mr5 pl8 pr8"
                  onClick={() => downloadFile(row)}
                >
                  <CloudDownloadOutlined />
                </Button>
              </Tooltip>
            } */}

            {/* Delete */}
            {isLeader && (
              <Popover
                placement="bottom"
                title={""}
                content={
                  // renderVersion(row[type == '1' ? 'efModel' : 'modelNumber'], row.versions)
                  <>
                    {(versions || []).map((item, index) => {
                      return (
                        <div>
                          <a
                            key={index}
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              deboundClickModal.handleOpenModal(() =>
                                handleDeleteMachine(value, item)
                              );
                            }}
                          >
                            ver {item}
                          </a>
                        </div>
                      );
                    })}
                  </>
                }
                trigger={isPC ? "hover" : "click"}
              >
             <Tooltip title={lang.DELETE}>
             <Button
                  className="mr5 btnDelete pl8 pr8"
                  // disabled={row.canDelete === false}
                  // onClick={() =>
                  //   handleDeleteMachine(value, versions[0] || row.recordVersion)
                  // }
                >
                  <DeleteOutlined />
                </Button>
             </Tooltip>
              </Popover>
            )}
          </>
        );
      },
    },
  ];

  initColumns.unshift({
    title: (
      <>
        <Checkbox
          checked={
            !isEmpty(Object.values(checked)) &&
            !Object.values(checked).some((item) => item === false)
          }
          onChange={(e) => {
            const newChecked = { ...checked };
            Object.keys(checked).forEach((key) => {
              newChecked[key] = e.target.checked;
            });
            setChecked(newChecked);
          }}
        />
      </>
    ),
    dataIndex: "modelId",
    align: "center",
    render: (value, row) => {
      return (
        <>
          <Checkbox
            checked={checked.all ? true : checked[value]}
            onChange={(e) => {
              setChecked({
                ...checked,
                [value]: e.target.checked,
              });
            }}
          />
        </>
      );
    },
  });

  return (
    <>
      <div>
        {/* <TitlePage name={LANG.LIST_MACHINE} /> */}
        <Content className="site-layout-background">
          {/* Group input */}
          <Form
            layout="vertical"
            form={formSearch}
            onFinish={() => search_onClick()}
          >
            <Row style={{}} gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item name="name">
                  <Input
                    placeholder={placeholder}
                    onChange={(e) =>
                      setName({ ...name, value: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24} className="right">
                <Button
                  type="primary"
                  style={{ minWidth: 103, borderRadius: 4 }}
                  htmlType="submit"
                  className="wAuto mr8"
                >
                  <SearchOutlined />
                  {lang.SEARCH}
                </Button>
                <Button
                  type="link"
                  className="buttonPC button--outline --todo-- wAuto"
                  onClick={() => clearInputSearch()}
                >
                  <ClearOutlined />
                  {lang.CLEAR}
                </Button>
              </Col>
            </Row>
          </Form>
        </Content>

        <Content
          className="site-layout-background"
          style={
            {
              // padding: 15,
            }
          }
        >
          <Row justify="space-between ">
            <Col span={12}>{isLeader && <ImportMachine />}</Col>
            <Col span={12} className="right">
              {/* Count */}
              <Space size={10} align="center" style={{ lineHeight: "35px" }}>
                {lang.ACCESS_NUMBERS}
                <span>
                  {grid.count}
                  {lang.CASE}
                </span>
              </Space>
            </Col>
          </Row>

          {/*grid*/}
          <ConfigProvider renderEmpty={() => <></>}>
            <Table
              rowKey={(record) => record.id}
              className="gridSearch list-machine"
              columns={initColumns}
              dataSource={grid.data}
              pagination={false}
              empty={<></>}
              bordered
            />
          </ConfigProvider>
          {grid.data.length > 0 && (
            <Row justify="center" className="mt20">
              <Pagination
                onChange={(value) => {
                  search_onClick(value - 1);
                }}
                defaultCurrent={1}
                current={page + 1}
                total={totalItem}
                pageSize={PAGE_LIMIT}
              />
            </Row>
          )}
        </Content>
        <Loading show={showLoading}></Loading>
      </div>
      {Object.values(checked).filter((item) => item == true).length >= 1 && (
        <FloatButton style={{}}>
          <Button
            type="primary"
            className="pl10 pr10"
            onClick={() => downloadFile()}
          >
            <CloudDownloadOutlined /> 機種表出力
          </Button>
        </FloatButton>
      )}
    </>
  );
}

const SearchAndTable = () => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { tab } = getQuerySearch();
  const [activeKey, setActiveKey] = useState(tab || "1");
  const onChange = (key) => {
    setActiveKey(key);
    pushObject2QueryReplace({ tab: key });
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <Layer className="first-icon" />
          {(isPC || activeKey == "1") && <span>イーグルファイバー機種表</span>}
        </>
      ),
      children: (
        <Machine_list
          placeholder={"EF型式"}
          apiSearch={ApiMachine.search}
          apiDelete={ApiMachine.delete}
          modelName="efModel"
          type="1"
        />
      ),
    },
    {
      key: "2",
      label: (
        <>
          <Wind2 className="first-icon" />
          {(isPC || activeKey == "2") && <span>ポンプ機種表</span>}
        </>
      ),
      children: (
        <Machine_list
          placeholder={"機種番号"}
          apiSearch={ApiPump.search}
          apiDelete={ApiPump.delete}
          modelName="modelNumber"
          type="2"
        />
      ),
    },
    {
      key: "3",
      label: (
        <>
          <Wind className="first-icon" />
          {(isPC || activeKey == "3") && <span>空洗ブロワ機種表</span>}
        </>
      ),
      children: (
        <Machine_list
          placeholder={"機種番号"}
          apiSearch={ApiAirWashing.search}
          apiDelete={ApiAirWashing.delete}
          modelName="modelNumber"
          type="3"
        />
      ),
    },
  ];

  return (
    <>
      {/* <TitlePage name={LANG.LIST_MACHINE} /> */}
      <Tabs
        hideAdd
        activeKey={activeKey}
        onChange={onChange}
        type="card"
        items={items}
        animated={{ inkBar: true, tabPane: true }}
        size={isPC ? "large" : "small"}
        tabPosition={"top"}
        bordered
      />
    </>
  );
};
export default SearchAndTable;

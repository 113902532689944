import { 
    DownOutlined,
    FullscreenOutlined,
    MoneyCollectOutlined,
    RightOutlined,
    RollbackOutlined,
  } from "@ant-design/icons";
  import { EstimateApi } from "../../api/backend/estimate";
  import { useLocation } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import BoxContent from "@src/component/Desktop/content";
  import FooterPage from "@src/component/footer/footerPage";
  import { IconNumber } from "@src/components/common/iconNumber.jsx";
  import { Button, Col, Collapse, Descriptions, Input, Modal, Row } from "antd";
  import { Filter, InfoCircle } from "iconsax-react";
  import { isArray } from "lodash";
  import React, { useEffect, useState } from "react";
  import { useHistory, useParams } from "react-router-dom";
//   import { HeaderText } from "../../components/common/headerText";
  import { KEY, lang } from "../../constants/common.const";
  import { LANG } from "../../constants/lang.const";
  import {
    caculatorSumMoneyandWeight,
    exactMultiplication,
    formatCurrencyJPY,
    getPathEstimateWithStep,
    roundToDecimalPlaces,
  } from "../../utils/helpers";
  import "./../Estimate/Estimate.scss";
  import "./../Machine_Management/machine.scss";
  import ButtonMore from "./../Estimate/view/buttonMore";
//   import ButtonClone from "./../Estimate/view/buttonClone";
  import { getQuerySearch } from "../../constants/utils";
  import ButtonEdit from "./../Estimate/view/buttonEdit";
  import ButtonDelete from "./../Estimate/view/buttonDelete";
  import {
    MSG_CATCH,
    showMessage,
  } from "../../constants/utils";
  import {
    closeLoading,
    openLoading,
    setDetailData,
  } from "../../redux/estimate";

  const { Panel } = Collapse;
  
  function ModelManagement() {

    const location = useLocation();
    const { id } = useParams();
    const { version } = getQuerySearch();
    const [dataDetail, setDataDetail] = useState(null);
    const basePath = location.pathname + location.search;

    const data = dataDetail || {};
    const history = useHistory();
    const isUpdate = id && history.location.pathname.includes("update");
    const [visible, setVisible] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const dispatch = useDispatch();
    
    const { isLastest } = getQuerySearch();
  
    const fetchDataDetails = async () => {
        try {
          dispatch(openLoading());
          const res = await EstimateApi.getDetailEstimate(40,0);
          setDataDetail(res.data);
          dispatch(setDetailData(res.data));
        } catch (error) {
          showMessage(KEY.ERROR, MSG_CATCH());
        } finally {
          dispatch(closeLoading());
        }
      };
    
      useEffect(() => {
        fetchDataDetails();
      }, []);
    
    const infoProject = [
      {
        title: LANG.ESTIMATE_NUMBER,
        content: data.estimationNumber || "",
      },
      {
        title: LANG.CLIENT_NAME,
        content: data.customerName || "",
      },
      {
        title: LANG.CONSTRUCTION_NAME,
        content: data.constructionName || "",
      },
  
      {
        title: LANG.CREATED_DATE,
        content: data.createDate || "",
        inlineDisplay: true,
      },
    ];
  
    const conditionDesign = [
      {
        title: LANG.WATER_TYPE,
        content: data.rawWaterTypeValue + " - " + data.rawWaterDetailTypeValue,
      },
      {
        title: (
          <>
            {LANG.TREATED_WATER_VOLUMN}
            <br />
            (㎥/h)
          </>
        ),
  
        content: data.estimationConditionInput
          ? Math.round(
              (data.estimationConditionInput.inputRawWaterVolume /
                data.estimationConditionInput.inputOperatingTime) *
                10
            ) / 10
          : "",
      },
      {
        title: data.rawWaterType === "1" ? LANG.IN : LANG.WATER_SS,
        content:
          data.rawWaterType === "1"
            ? data.estimationConditionInput
              ? data.estimationConditionInput.inputRawWaterTurbidity
              : ""
            : data.estimationConditionInput
            ? data.estimationConditionInput.inputRawWaterSsConc
            : "",
      },
      {
        title: data.rawWaterType === "1" ? LANG.OUT : LANG.TREATED_WATER_SS,
        content:
          data.rawWaterType === "1"
            ? data.estimationConditionInput
              ? data.estimationConditionInput.inputTreatedWaterTurbidity
              : ""
            : data.estimationConditionInput
            ? data.estimationConditionInput.inputTreatedWaterSsConc
            : "",
      },
    ];
  
    const openModalInfoTableCost = (item) => {
      setVisible(true);
      setDataModal(item);
    };
  
    const closeModalInfoTableCost = () => {
      setVisible(false);
      setDataModal(null);
    };
  
    const renderItemTableCost = (tableCost, config = {}) => {
      const { isStart, isNotMoney } = config;
      return tableCost.map((info, index) => {
        const showField = info.content.find((i) => i.isShow);
        const text = showField ? showField.content : "-";
        const unit = showField.unit;
        const title = unit ? (
          <>
            {info.title}
            <br />
            {`(${unit})`}
          </>
        ) : (
          info.title
        );
        return (
          <Descriptions.Item
            span={1}
            key={index}
            label={
              <div
                onClick={() => openModalInfoTableCost(info)}
                style={{ cursor: "pointer" }}
              >
                {title}
              </div>
            }
            labelStyle={{
              width: "35%",
              fontWeight: "bold",
              padding: "8px 8px",
            }}
            contentStyle={{
              padding: "8px",
              whiteSpace: "pre-wrap",
            }}
          >
            <div
              className="flex-center"
              style={{ justifyContent: isStart ? "flex-start" : "flex-end" }}
            >
              <span>{`${formatCurrencyJPY(text || 0)} ${
                isNotMoney ? "" : "(千円)"
              }`}</span>
            </div>
          </Descriptions.Item>
        );
      });
    };
  
    const infoProjectArr = [
      {
        title: LANG.PROJECT_INFOMATION,
        icon: <InfoCircle className="first-icon" />,
        content: infoProject,
        defaultExpand: true,
      },
    ];
    const conditionArr = [
      {
        title: LANG.DESIGN_CONDITION,
        icon: <Filter className="first-icon" />,
        content: conditionDesign,
      },
    ];
  
    const renderInfo = (listInfo) =>
      listInfo.map((item, index) => {
        return (
          <Col span={24} className="mt8" key={index}>
            <BoxContent style={{ margin: 0, padding: 0 }}>
              <Collapse
                defaultActiveKey={"1"}
                expandIconPosition="right"
                style={{ padding: 0 }}
              >
                <Panel
                  header={
                    <>
                      <div
                        className="flex-center"
                        style={{ justifyContent: "flex-start" }}
                      >
                        {item.icon}
                        {item.title}
                      </div>
                    </>
                  }
                  key="1"
                >
                  <Descriptions size="small" column={1} bordered>
                    {item.content.map((info, index) => {
                      return (
                        <Descriptions.Item
                          span={1}
                          key={index}
                          label={
                            <span onClick={() => setVisible(true)}>
                              {info.title}
                            </span>
                          }
                          labelStyle={{
                            width: "35%",
                            fontWeight: "bold",
                            padding: "8px 8px",
                          }}
                          contentStyle={{
                            padding: !isArray(info.content) ? "8px" : "0px",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {!isArray(info.content) &&
                            !info.isInput &&
                            info.content}
                          {!isArray(info.content) && info.isInput && (
                            <Input defaultValue={isUpdate ? info.content : ""} />
                          )}
                          {isArray(info.content) && (
                            <Descriptions
                              key={index}
                              size="small"
                              column={1}
                              bordered
                            >
                              {info.content.map((item, index) => {
                                return (
                                  <Descriptions.Item
                                    span={1}
                                    label={item.title}
                                    labelStyle={{
                                      width: "45%",
                                      fontWeight: "semi-bold",
                                    }}
                                  >
                                    {!item.isInput && item.content}
                                    {item.isInput && (
                                      <Input
                                        defaultValue={
                                          isUpdate ? item.content : ""
                                        }
                                      />
                                    )}
                                  </Descriptions.Item>
                                );
                              })}
                            </Descriptions>
                          )}
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                </Panel>
              </Collapse>
            </BoxContent>
          </Col>
        );
      });
    const renderDesContent = (arr, { isOpenModal = true }) => {
      return arr.content.map((info, index) => {
        const { unit } = info;
  
        const title = unit ? (
          <>
            {info.title}
            <br />
            {`(${unit})`}
          </>
        ) : (
          info.title
        );
        return (
          <Descriptions.Item
            span={1}
            key={index}
            label={
              <span onClick={() => (isOpenModal ? setVisible(true) : null)}>
                {title}
              </span>
            }
            labelStyle={{
              width: "35%",
              fontWeight: "bold",
              padding: "8px 8px",
            }}
            contentStyle={{
              padding: !isArray(info.content) ? "8px" : "0px",
              whiteSpace: "pre-wrap",
            }}
          >
            {!isArray(info.content) &&
            !isNaN(info.content) &&
            info.content !== null &&
            info.content !== ""
              ? formatCurrencyJPY(info.content)
              : info.content}
  
            {isArray(info.content) && (
              <Descriptions key={index} size="small" column={1} bordered>
                {info.content.map((item, index) => {
                  return (
                    <Descriptions.Item
                      span={1}
                      label={item.title}
                      labelStyle={{
                        width: "45%",
                        fontWeight: "semi-bold",
                      }}
                    >
                      {item.content}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            )}
          </Descriptions.Item>
        );
      });
    };
  
    // List table Cost
    const tableCost1 = [
      
      //1
      {
        title: "運搬費",
        content: [
          {
            title: "数量(式)",
            content: data.numberOfMachines || "",
          },
  
          {
            title: "単価(千円)",
            content:
              data.estimationConst && data.estimationConst.transportationFee,
            isEdit: true,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              data.estimationConst.transportationFee * data.numberOfMachines,
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.transportationFeeComment,
            key: KEY.REMARK,
          },
        ],
      },
      //2
     
      //3
     
      //4
     
    ];
    const options =
      data && data.options && data.options.length ? data.options : [];
    const itemOptionOftableCost1Array = {
      title: LANG.OPTION,
      content: [],
    };
    /// chia 1000 vi don vị option là yên, còn đơn vị ở đây là nghìn yên
  
    options.forEach((itemOption) => {
      const value = itemOption.value ? Number(itemOption.value) : 0;
      itemOptionOftableCost1Array.content.push({
        title: (
          <>
            {" "}
            {itemOption.optionName} <br /> {"(千円)"}
          </>
        ),
        content: value,
      });
    });
    const moneyOption = itemOptionOftableCost1Array.content.reduce(
      (money, item) => {
        return money + item.content;
      },
      0
    );
  
    itemOptionOftableCost1Array.content.push({
      title: "金額(千円)",
      content: exactMultiplication(moneyOption, data.numberOfMachines),
      isShow: true,
      key: "money",
    });
    itemOptionOftableCost1Array.content.push({
      title: lang.REMARK,
      content: data.estimationConst && data.estimationConst.bodyOptionsComment,
    });
    tableCost1.push(itemOptionOftableCost1Array);

    const tableCost2 = [
      {
      
                title: "試運転調整費・単価",
                content: [
                  {
                    title: "数量(日)",
                    content: data ? 2 + (data.numberOfMachines - 1) * 1 : "",
                  },
          
                  {
                    isEdit: true,
                    title: "単価(千円)",
                    content:
                      data.estimationConst && data.estimationConst.trialRunAdjustmentFee,
                  },
          
                  {
                    title: "金額(千円)",
                    content: data.estimationConst
                      ? (2 + (data.numberOfMachines - 1) * 1) *
                        data.estimationConst.trialRunAdjustmentFee
                      : "",
                    isShow: true,
                    key: "money",
                  },
                  {
                    title: lang.REMARK,
                    content:
                      data.estimationConst &&
                      data.estimationConst.trialRunAdjustmentFeeComment,
                  },
                ],
              
            },
            //2
           
            //3
           
            //4
           
          ];
    const tableCost3 = [
      {
                title: "立会対応費",
                content: [
                  {
                    title: "数量(式)",
                    content: data.numberOfMachines || "",
                  },
          
                  {
                    isEdit: true,
                    title: "単価(千円)",
                    content: data.estimationConst
                      ? data.estimationConst.attendanceFee
                      : "",
                  },
          
                  {
                    title: "金額(千円)",
                    content: data.estimationConst
                      ? data.estimationConst.attendanceFee * data.numberOfMachines
                      : "",
                    isShow: true,
                    key: "money",
                  },
                  {
                    title: lang.REMARK,
                    content:
                      data.estimationConst && data.estimationConst.attendanceFeeComment,
                  },
                ],
              },
       
    ];
    const tableCost4 = [
      {
                title: "設計図書作成費",
                content: [
                  {
                    title: "数量(式)",
                    content: data.numberOfMachines || "",
                  },
          
                  {
                    isEdit: true,
                    title: "単価(千円)",
                    content: data.estimationConst
                      ? data.estimationConst.designDocumentPreparationFee
                      : "",
                  },
          
                  {
                    title: "金額(千円)",
                    content: data.estimationConst
                      ? data.estimationConst.designDocumentPreparationFee *
                        data.numberOfMachines
                      : "",
                    isShow: true,
                    key: "money",
                  },
                  {
                    title: lang.REMARK,
                    content:
                      data.estimationConst &&
                      data.estimationConst.designDocumentPreparationFeeComment,
                  },
                ],
              },
    ];
    const tableCost5 = [
      {
        title: "防音ボックス",
        content: [
          {
            title: "型式",
            content:
              data.estimationAuxMachine &&
              data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxModel,
          },
  
          {
            title: "数量(台)",
            content:
              data.estimationAuxMachine &&
              data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxRegularNumber,
          },
          {
            title: "単位質量(kg)",
            content:
              data.estimationAuxMachine &&
              data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight,
          },
          {
            title: "単価(千円)",
            content:
              data.estimationAuxMachine &&
              data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice,
          },
          {
            title: "質量(kg)",
            content:
              data.estimationAuxMachine &&
              Math.round(
                exactMultiplication(
                  data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight,
                  data.estimationAuxMachine
                    .auxAirWashingBlowerSndprfBoxRegularNumber
                )
              ),
            key: "weight",
          },
          {
            title: "金額(千円)",
            content:
              data.estimationAuxMachine &&
              exactMultiplication(
                data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice,
                data.estimationAuxMachine
                  .auxAirWashingBlowerSndprfBoxRegularNumber
              ),
            isShow: true,
            key: "money",
          },
        ],
      },
    ];
    const tableCost6 = [
      {
        title: "PAC注入装置",
        content: [
          {
            title: "数量(式)",
            content:
              data.estimationConst &&
              data.estimationConst.pacInjectionDeviceNumber,
            isEdit: true,
          },
  
          {
            title: "単価(千円)",
            content:
              data.estimationConst &&
              data.estimationConst.pacInjectionDevicePrice,
            isEdit: true,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              exactMultiplication(
                data.estimationConst.pacInjectionDevicePrice,
                data.estimationConst.pacInjectionDeviceNumber
              ),
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.pacInjectionDeviceComment,
          },
        ],
      },
      {
        title: "次亜注入装置",
        content: [
          {
            title: "数量(式)",
            content:
              data.estimationConst &&
              data.estimationConst.hypotoniaInjectionDeviceNumber,
            isEdit: true,
          },
  
          {
            title: "単価(千円)",
            content:
              data.estimationConst &&
              data.estimationConst.hypotoniaInjectionDevicePrice,
            isEdit: true,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              exactMultiplication(
                data.estimationConst.hypotoniaInjectionDevicePrice,
                data.estimationConst.hypotoniaInjectionDeviceNumber
              ),
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.hypotoniaInjectionDeviceComment,
          },
        ],
      },
      {
        title: "pH調整剤（酸）注入装置",
        content: [
          {
            title: "数量(式)",
            content:
              data.estimationConst &&
              data.estimationConst.phAdjusterInjectionDeviceNumber,
            isEdit: true,
          },
  
          {
            title: "単価(千円)",
            content:
              data.estimationConst &&
              data.estimationConst.phAdjusterInjectionDevicePrice,
            isEdit: true,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              exactMultiplication(
                data.estimationConst.phAdjusterInjectionDevicePrice,
                data.estimationConst.phAdjusterInjectionDeviceNumber
              ),
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.phAdjusterInjectionDeviceComment,
          },
        ],
      },
    ];
    const tableCost7 = [
      {
        title: "制御盤",
        content: [
          {
            title: "数量(式)",
            content:
              data.estimationConst && data.estimationConst.controlPanelNumber,
            isEdit: true,
          },
  
          {
            title: "単価(千円)",
            content:
              data.estimationConst && data.estimationConst.controlPanelPrice,
            isEdit: true,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              exactMultiplication(
                data.estimationConst.controlPanelPrice,
                data.estimationConst.controlPanelNumber
              ),
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.controlPanelPriceComment,
          },
        ],
      },
    ];
    const tableCost8 = [
      {
        title: "試運転SV費",
        content: [
          {
            title: "数量(日)",
            content: data ? 2 + (data.numberOfMachines - 1) * 1 : "",
          },
  
          {
            title: "単価(千円)",
            content:
              data.estimationConst && data.estimationConst.commissioningSvFees,
            isEdit: true,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              exactMultiplication(
                2 + (data.numberOfMachines - 1) * 1,
                data.estimationConst.commissioningSvFees
              ),
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.commissioningSvFeeComment,
          },
        ],
      },
    ];
    const tableCost9 = [
      {
        title: "設計費",
        content: [
          {
            title: "数量(式)",
            content: data.estimationConst && data.estimationConst.designNumber,
          },
  
          {
            title: "金額(千円)",
            content: data.estimationConst && data.estimationConst.designFee,
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst && data.estimationConst.designFeeComment,
          },
        ],
      },
    ];
    const tableCost10 = [
      {
        title: "図書作成費",
        content: [
          {
            title: "数量(式)",
            content:
              data.estimationConst && data.estimationConst.bookCreationNumber,
          },
  
          {
            title: "金額(千円)",
            content: data.estimationConst && data.estimationConst.bookCreationFee,
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst && data.estimationConst.bookCreationFeeComment,
          },
        ],
      },
    ];
  
    ///Equipment cost
    const equipmentCost = caculatorSumMoneyandWeight([
      tableCost1,
      tableCost2,
      tableCost3,
      tableCost4,
      tableCost5,
      tableCost6,
      tableCost7,
    ]);
  
    // Attach cost
    const attachCost = caculatorSumMoneyandWeight([
      tableCost2,
      tableCost3,
      tableCost4,
      tableCost5,
      tableCost6,
      tableCost7,
    ]);
    // Tổng phụ kiện kèm theo
    const attachEquimentCost = [
      {
        title: "付属機器小計",
        content: [
          {
            title: "質量(kg)",
            content: attachCost.weight,
          },
          {
            title: "金額(千円)",
            content: attachCost.money,
            isShow: true,
          },
        ],
      },
    ];
  
    // tổng chi phí thiết bị
    const machineBill = [
      {
        title: "機器費計",
        content: [
          {
            title: "質量(kg)",
            content: equipmentCost.weight,
          },
          {
            title: "金額(千円)",
            content: equipmentCost.money,
            isShow: true,
          },
        ],
      },
    ];
  
    const totalCost = [tableCost8, tableCost9, tableCost10].reduce(
      (result, table) => {
        let money = 0;
        const moneyItem = table[0].content.find((i) => i.key === "money");
        if (moneyItem) {
          money = Number(moneyItem.content);
        }
        return (result += money);
      },
      equipmentCost.money
    );
    const total = [
      {
        title: "計",
        content: [
          {
            title: "金額(千円)",
            content: totalCost,
            isShow: true,
          },
        ],
      },
    ];
    const reverseFund = [
      {
        title: "予備費",
        content: [
          {
            title: "料率 (%)",
            content: data.estimationConst && data.estimationConst.reserveFund,
            isEdit: true,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              Math.round((data.estimationConst.reserveFund * totalCost) / 100),
            isShow: true,
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst && data.estimationConst.reserveFundComment,
          },
        ],
      },
    ];
  
    const dcCost =
      data.estimationConst &&
      Math.round(
        exactMultiplication(
          Number(data.estimationConst.reserveFund) + 100,
          totalCost
        ) / 100
      );
    // DC
    const dc = [
      {
        title: "DC計",
        content: [
          {
            title: "金額(千円)",
            content: dcCost,
            isShow: true,
            key: "money",
          },
        ],
      },
    ];
  
    // Chi phí quản lí
    const manageCost = [
      {
        title: "工事管理費",
        content: [
          {
            title: "料率 (%)",
            content:
              data.estimationConst &&
              data.estimationConst.constructionManagementPercent,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              Math.round(
                (data.estimationConst.constructionManagementPercent * dcCost) /
                  100
              ),
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.constructionManagementPercentComment,
          },
        ],
      },
      {
        title: "販売管理費",
        content: [
          {
            title: "料率 (%)",
            content:
              data.estimationConst &&
              data.estimationConst.generalAdministrativePercent,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              Math.round(
                (data.estimationConst.generalAdministrativePercent * dcCost) / 100
              ),
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.generalAdministrativePercentComment,
          },
        ],
      },
      {
        title: "手数料",
        content: [
          {
            title: "料率 (%)",
            content:
              data.estimationConst && data.estimationConst.commissionPercent,
          },
  
          {
            title: "金額(千円)",
            content:
              data.estimationConst &&
              Math.round((data.estimationConst.commissionPercent * dcCost) / 100),
            isShow: true,
            key: "money",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.commissionPercentComment,
          },
        ],
      },
    ];
  
    // Cost of sale
    let costOfSale = caculatorSumMoneyandWeight([dc, manageCost]);
    costOfSale.money = roundToDecimalPlaces(costOfSale.money, -3);
    const costOfSaleTable = [
      {
        title: "販売原価",
        content: [
          {
            title: "金額(千円)",
            content: costOfSale.money,
            isShow: true,
            key: "money",
          },
        ],
      },
    ];
  
    const profitAndLossper = data.estimationConst
      ? Number(data.estimationConst.profitLossPercent)
      : 0;
    const sellingPrice = roundToDecimalPlaces(
      (costOfSale.money * 100) / (100 - profitAndLossper),
      -3
    );
    const profitAndLoss = [
      {
        title: "損益 (P)",
        content: [
          {
            title: "損益率 (%)",
            content: profitAndLossper,
            isEdit: true,
          },
  
          {
            title: "金額(千円)",
            content: sellingPrice - costOfSale.money,
            isShow: true,
            fullText:
              "損益率 : " +
              profitAndLossper +
              "(%)" +
              "   金額 : " +
              formatCurrencyJPY(sellingPrice - costOfSale.money || 0) +
              "(千円)",
          },
          {
            title: lang.REMARK,
            content:
              data.estimationConst &&
              data.estimationConst.profitLossPercentComment,
          },
        ],
      },
    ];
  
    // Price sell Table
    const priceSellTable = [
      {
        title: "販売価格",
        content: [
          {
            title: "金額(千円)",
            content: sellingPrice,
            isShow: true,
          },
        ],
      },
    ];
  
   
  
    // Render Table Cost Collapse
    const rederCollapsetableCost = (
      arrData,
      num = 1,
      title = "",
      Icon,
      config = {}
    ) => {
      const isSingle = arrData.length === 1;
      const textTitle = title || arrData[0].title;
      const { isOpen, isNotMoney, isStart } = config;
      const sigleEdit = isSingle;
      let fullText;
  
    //   const price = arrData.reduce((result, item) => {
    //     const showEl = item.content.find((i) => i.isShow);
    //     if (showEl.fullText) {
    //       fullText = showEl.fullText;
    //     }
    //     const number = showEl ? showEl.content : 0;
    //     return result + number;
    //   }, 0);
      return (
        <Row gutter={0}>
          <Col span={24} className="mt8">
            <div style={{ margin: 0, padding: 0 }}>
              <Collapse
                className={isNotMoney ? "" : "collapseCost"}
                collapsible={arrData.length === 1 && "icon"}
                expandIconPosition={!isNotMoney ? "left" : "right"}
                defaultActiveKey={["1"]}
                style={{ padding: 0 }}
                expandIcon={
                  !isNotMoney
                    ? ({ isActive }) =>
                        arrData.length === 1 ? null : isActive ? (
                          <DownOutlined
                            style={{ left: 5, marginTop: sigleEdit ? 4 : 0 }}
                          />
                        ) : (
                          <RightOutlined
                            style={{ left: 5, marginTop: sigleEdit ? 4 : 0 }}
                          />
                        )
                    : null
                }
              >
                <Panel
                  header={
                    <>
                      <div
                        onClick={
                          isSingle
                            ? () => openModalInfoTableCost(arrData[0])
                            : null
                        }
                        className="flex-center"
                        style={{
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <div className="flex-start">
                          {Icon ? (
                            <Icon
                              style={{
                                fontSize: 20,
                                color: "#0080cb",
                                marginRight: 6,
                                marginLeft: isSingle ? 8 : 0,
                              }}
                            />
                          ) : (
                            <IconNumber
                              marginLeft={isSingle ? 8 : 0}
                              size={20}
                              number={num}
                            />
                          )}
                          <span>{textTitle}</span>
                        </div>
                        {/* <div
                          className="flex-start"
                          style={{
                            justifyContent: isStart ? "flex-start" : "flex-end",
                          }}
                        >
                          {!isNotMoney &&
                            (fullText ? (
                              <span>{fullText}</span>
                            ) : (
                              <span>{`${
                                !isSingle ? "金額 : " : ""
                              }${formatCurrencyJPY(price || 0)} (千円) `}</span>
                            ))}
                        </div> */}
                      </div>
                    </>
                  }
                  key={isOpen ? "1" : 0}
                >
                  {arrData.length > 1 && (
                    <Descriptions size="small" column={1} bordered>
                      {renderItemTableCost(arrData, { isStart, isNotMoney })}
                    </Descriptions>
                  )}
                </Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      );
    };
  
    return (
      <>
        <div
          style={{ marginTop: -20 }}
          className="des-content machine-content step3"
        >
          {/* <HeaderText text={LANG.PROJECT_INFOMATION} />
          <Row gutter={0}>{renderInfo(infoProjectArr)}</Row>
          <HeaderText text={LANG.CONDITION_INPUT} />
          <Row gutter={0}>{renderInfo(conditionArr)}</Row>
          <HeaderText text={LANG.TABLE_COSST} /> */}
          <div className="wrapTableCost">
            {rederCollapsetableCost(tableCost1, 1, "運搬費")}
            {rederCollapsetableCost(tableCost2, 2)}
            {rederCollapsetableCost(tableCost3, 3)}
            {rederCollapsetableCost(tableCost4, 4)}
            {/* {rederCollapsetableCost(tableCost5, 5)} */}
            {/* {rederCollapsetableCost(tableCost6, 6, "薬品注入装置")} */}
  
            {rederCollapsetableCost(tableCost7,5)}
  
            {/* {rederCollapsetableCost(
              attachEquimentCost,
              1,
              "付属機器小計",
              ApiOutlined
            )} */}
            {/* {rederCollapsetableCost(machineBill, 1, "機器費計", MenuOutlined)} */}
  
            {/* {rederCollapsetableCost(tableCost8, 8)} */}
            {rederCollapsetableCost(tableCost9, 6)}
            {rederCollapsetableCost(tableCost10, 7)}
            {rederCollapsetableCost(total, 8, "計", FullscreenOutlined)}
            {rederCollapsetableCost(
              reverseFund,
              10,
              "予備費",
              MoneyCollectOutlined
            )}
            {/* {rederCollapsetableCost(dc, 10, "DC計", ClockCircleOutlined)} */}
            {rederCollapsetableCost(manageCost, 8, "管理費")}
            {/* {rederCollapsetableCost(
              costOfSaleTable,
              10,
              "販売原価",
              DollarOutlined
            )}
            {rederCollapsetableCost(
              profitAndLoss,
              10,
              "損益 (P)",
              VerticalAlignMiddleOutlined
            )}
            {rederCollapsetableCost(
              priceSellTable,
              10,
              "販売価格",
              EuroCircleOutlined
            )} */}
  
            {/* <HeaderText text={"設計計算書"} />
  
            {rederCollapsetableCost(
              dataStep4,
              6,
              "設計計算書",
              BookOutlined,
  
              {
                isOpen: true,
                isNotMoney: true,
                isStart: true,
              }
            )} */}
          </div>
  
          <div style={{ height: "100px" }}></div>
        </div>
        <Modal
          style={{ padding: 0 }}
          centered
          closeIcon={<></>}
          className=""
          title={dataModal ? dataModal.title : null}
          open={visible && dataModal}
          onCancel={() => closeModalInfoTableCost()}
          footer={
            <Row>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button
                  className="buttonPC --todo-- wAuto  button--outline"
                  onClick={() => closeModalInfoTableCost()}
                >
                  {lang.CLOSE}
                </Button>
              </Col>
            </Row>
          }
        >
          <Descriptions size="small" column={1} bordered>
            {dataModal && renderDesContent(dataModal, { isOpenModal: false })}
          </Descriptions>
        </Modal>
  
        <FooterPage>
          <Row>
            <Col span={12}>
              <div
                className={"actionRight"}
                onClick={() => history.push(getPathEstimateWithStep(basePath, 2))}
              >
                <RollbackOutlined
                  className="mr4"
                  style={{ fontWeight: "bold", fontSize: 16 }}
                />{" "}
                {LANG.BACK}
              </div>
            </Col>
  
            <Col span={12}>
              <ButtonMore isDraf={data && data.status === "0"}>
                <Col span={24}>
                  <ButtonEdit status={data && data.status} />
                </Col>
                {isLastest && (
                  <Col span={24}>
                    <ButtonEdit revup status={data && data.status} />
                  </Col>
                )}
                <Col span={24}>
                  <ButtonDelete />
                </Col>
              </ButtonMore>
            </Col>
          </Row>
        </FooterPage>
      </>
    );
  }
  
  export default ModelManagement;
  
import { lang } from "@src/constants/common.const";

export const ROUTER_PAGE = {
  DASHBOARD: "/home",
  USER_INFO: "/user",
  ESTIMATE: [
    "/Estimate/list",
    "/Estimate/View",
    "/Estimate/Edit",
  ],
  ESTIMATE_ADD:"/Estimate/Add",

  MACHINE_LIST: "/machine/list",
  MACHINE_CREATE: "/machine/create",
};
export const NAME_PAGE = {
  DASHBOARD: lang.HOMEPAGE,
  HOME_BANNER: lang.HOME_BANNER,
  CL_MANAGEMENT: lang.LIST_CHECKLIST,
  CL_HISTORY: lang.HISTORY_CHECKLIST,
  CL_INPUT: lang.TODO_CHECKLIST,
  INPUT_REST_LAST_VALUE: lang.SC_REST_LAST_VALUE,
  CUSTOMER_REPORT: lang.EXPORT_REPORT,
  TEMPLATE_MANAGEMENT: lang.SAMPLE_TEMP_MANAGEMENT,
  WORK_REPORT: lang.WORK_REPORT_MANAGEMENT,
  CL_REGISTRATION: lang.LIST_CHECKLIST,
  MC_REGISTRATION: lang.REGISTRY_MACHINE,
  INVENTORY_REGISTRATION: lang.REGISTRY_INVENTORY,
  OFFICE_INFO: lang.OFFICE_DETAIL,
  OFFICE_MANAGEMENT: lang.OFFICE_MANAGEMENT,
  ROLE_SETTING: lang.ROLE_SETTING,
  WAREHOUSES_MASTER: lang.WAREHOUSES_MASTER,
  CATEGORY_MASTER: lang.CATEGORY_MASTER,
  ITEM_MASTER: lang.ITEM_MASTER,
  INVENTORY_DATA: lang.INVENTORY_DATA,
  STOCK: lang.STOCK,
  MACHINE_MANAGEMENT: lang.MACHINE_MANAGEMENT,
  CALENDAR: lang.CALENDAR,
  DYNAMIC_REPORT_MANAGEMENT: lang.DYNAMIC_REPORT_MANAGEMENT,
};
export default {
  ROUTER_PAGE,
  NAME_PAGE,
};

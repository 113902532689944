/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Modal, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { WaterDeviceApi } from "../../../api/backend/waterDevice";
import FormInput from "../../../component/FormInput";
import {
  COMMON_CONSTANT,
  KEY,
  KEY_RULE_INPUT,
  lang,
} from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import classText from "../../../models/control/text";
import { LIST_RULE, ModalCustom, showErrorMessage } from "../../../utils/helpers";

const { Paragraph } = Typography;

function AddWaterDevice({ open, onClose = () => {}, id }) {
  let [showLoading, setShowLoading] = useState(false);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [data, setData] = useState({
    amountId: new classText(),
    amountTable: new classText(),
    unitPrice: new classText(),
    priceBasis: new classText(),
    remark: new classText(),
  });
  const inputRefs = useRef([]);
  // Change Input
  const handleChangeData = (key) => (e) => {
    const newValue = e.target.value;
    const dataItem = listDataModal.find((i) => i.name === key);
    if (dataItem && Array.isArray(dataItem.ruleInput)) {
      const isNotMatchInput = dataItem.ruleInput.some((ruleName) => {
        return !LIST_RULE[ruleName](newValue);
      });
      if (isNotMatchInput) {
        return;
      }
    }
    setData({ ...data, [key]: { ...data[key], value: newValue } });
  };

  const getIniData = async () => {
    try {
      // edit
      if (id) {
        const optionsRes = await WaterDeviceApi.getWaterDeviceById(
          id
        );
        setData({
          amountId: new classText(optionsRes.data.data[0].amountId),
          amountTable: new classText(optionsRes.data.data[0].amountTable),
          unitPrice: new classText(optionsRes.data.data[0].unitPrice),
          priceBasis: new classText(optionsRes.data.data[0].priceBasis),
          remark: new classText(optionsRes.data.data[0].remark),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    getIniData();
  }, [id]);
  //List data
  const listDataModal = [
    {
      name: "amountTable",
      title: "金額表",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK],
    },
    {
      name: "unitPrice",
      title: "単価(千円/台)",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK,KEY_RULE_INPUT.IS_UNSIGN_INTERGER],
      ruleInput: [KEY_RULE_INPUT.IS_UNSIGN_INTERGER,KEY_RULE_INPUT.MAX_LENGTH_NUMBER],
    },
    {
      name: "priceBasis",
      title: LANG.PRICE_BASIS,
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK],
      type: "textarea",
    },

    {
      name: "remark",
      title: lang.REMARK,
      required: false,
      type: "textarea",
    },
  ];

  // VALIDATE DATA

  const validate = () => {
    let isFlagErr = false;
    let fistNameErr = "";
    const listDataWithRule = listDataModal.filter((i) => Array.isArray(i.rule));
    if (listDataWithRule.length === 0) {
      return false;
    }
    // check rule
    for (let index = 0; index < listDataWithRule.length; index++) {
      const nameItem = listDataWithRule[index].name;
      const value = data[nameItem].value;
      const listRule = listDataWithRule[index].rule;
      const isErrItem = listRule.some((ruleName) => {
        return !LIST_RULE[ruleName](value);
      });
      if (isErrItem) {
        isFlagErr = true;
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: true,
          },
        }));
        if (!fistNameErr) {
          fistNameErr = nameItem;
        }
      } else {
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: false,
          },
        }));
      }
    }

    // Focus Fist Item Error
    if (fistNameErr && inputRefs.current && inputRefs.current[fistNameErr]) {
      inputRefs.current[fistNameErr].focus();
    }
    return !isFlagErr;
  };
  const handleClose = () => {
    setData({
      amountId: new classText(),
      amountTable: new classText(),
      unitPrice: new classText(),
      priceBasis: new classText(),
      remark: new classText(),
    });
    onClose(false);
  };
  // Save Data
  const postData = async () => {
    try {
      setShowLoading(true);
      if (id) {
        const dataPost = {
          amountId: data.amountId.value,
          amountTable: data.amountTable.value,
          unitPrice: data.unitPrice.value,
          priceBasis: data.priceBasis.value,
          remark: data.remark.value,
        };
        await WaterDeviceApi.updateWaterDevice(dataPost);
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      } else {
        const dataPost = {
          amountTable: data.amountTable.value,
          unitPrice: data.unitPrice.value,
          priceBasis: data.priceBasis.value,
          remark: data.remark.value,
        };
        await WaterDeviceApi.createWaterDevice(dataPost);
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      }
    } catch (error) {
      showErrorMessage(error)
    } finally {
      setShowLoading(false);
      onClose();
    }
  };
  // SAVE DATA
  const onSaveData = async () => {
    if (!validate()) {
      return;
    } else {
      ModalCustom.confirm({
        centered: true,
        title: lang.CONFIRM,
        content: COMMON_CONSTANT.BOM_C008,
        okText: lang.OK,
        cancelText: lang.CANCEL,
        onOk: () => postData(),
      });
    }
  }
  

  return (
    <div>
      <Modal
        title={!id ? "水質測定装置価格作成" : "水質測定装置価格編集"}
        centered
        width={isPC ? "40%" : "90%"}
        bodyStyle={{ height: "auto", overflow: "auto" }}
        closeIcon={<></>}
        className=""
        open={open}
        footer={[
          <Button
            onClick={() => handleClose()}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CANCEL}
          </Button>,
          <Button
            onClick={() => onSaveData()}
            className="buttonPC button--info wAuto"
          >
            {lang.OK}
          </Button>,
        ]}
      >
        <Form style={{ gap: "100px" }}>
          <Row gutter={[16, 16]}>
            {listDataModal.map((item) => (
              <Col key={item.name} span={24}>
                <div>
                  <label className="bold">
                    {item.title}{" "}
                    {item.required && (
                      <label className="blod mark-required">*</label>
                    )}
                  </label>
                  <FormInput
                    valueObject={data[item.name]}
                    onChange={handleChangeData(item.name)}
                    refer={(ref) => (inputRefs.current[item.name] = ref)}
                    type={item.type}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default AddWaterDevice;

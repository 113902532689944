/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState } from "react";

import {
  COMMON_CONSTANT,
  KEY,
  KEY_RULE_INPUT,
  STYLE,
  lang,
} from "../../../constants/common.const";
import classText from "../../../models/control/text";
import { useMediaQuery } from "react-responsive";
import { LIST_RULE, ModalCustom } from "../../../utils/helpers";
import { Radio, Space } from "antd";
import FormInput from "../../../component/FormInput";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import { LvAPI } from "../../../api/backend/lv";

function EditLv({ isModalVisible, onClose, itemDetail, callback = () => {} }) {
  const inputRefs = useRef([]);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [data, setData] = useState({
    fiberLength: new classText(itemDetail ? itemDetail.fiberLength : ""),
    rawWaterTypeName: new classText(
      itemDetail ? itemDetail.rawWaterTypeName : ""
    ),
    unit: new classText("m/H"),
    standard: new classText(itemDetail ? itemDetail.standard : ""),
    lowerLimit: new classText(itemDetail ? itemDetail.lowerLimit : ""),
    upperLimit: new classText(itemDetail ? itemDetail.upperLimit : ""),
    remark: new classText(itemDetail ? itemDetail.remark : ""),
  });
  // Change Input
  const handleChangeData = (key) => (e) => {
    const newValue = e.target.value;
    const dataItem = listDataModal.find((i) => i.name === key);
    if (dataItem && Array.isArray(dataItem.ruleInput)) {
      const isNotMatchInput = dataItem.ruleInput.some((ruleName) => {
        return !LIST_RULE[ruleName](newValue);
      });
      if (isNotMatchInput) {
        return;
      }
    }
    setData({ ...data, [key]: { ...data[key], value: newValue } });
  };
  //List data
  const listDataModal = [
    {
      name: "fiberLength",
      title: "シリーズ",
      rule: [KEY_RULE_INPUT.NOT_BLANK],
      disabled: true,
      span: isPC ? 8 : 24,
    },
    {
      name: "rawWaterTypeName",
      title: "種類",
      rule: [KEY_RULE_INPUT.NOT_BLANK],
      span: isPC ? 8 : 24,
      disabled: true,
    },
    {
      name: "unit",
      title: "単位",
      rule: [KEY_RULE_INPUT.NOT_BLANK],
      span: isPC ? 8 : 24,
      disabled: true,
    },
    {
      name: "standard",
      title: "基準",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK, KEY_RULE_INPUT.IS_UNSIGN_INTERGER],
      ruleInput: [KEY_RULE_INPUT.IS_UNSIGN_INTERGER,KEY_RULE_INPUT.MAX_LENGTH_NUMBER],
      span: isPC ? 8 : 24,
      otherError:
        data.standard.value > data.upperLimit.value ||
        data.standard.value < data.lowerLimit.value,
    },
    {
      name: "lowerLimit",
      title: "範囲下限値",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK, KEY_RULE_INPUT.IS_UNSIGN_INTERGER],
      ruleInput: [KEY_RULE_INPUT.IS_UNSIGN_INTERGER,KEY_RULE_INPUT.MAX_LENGTH_NUMBER],
      span: isPC ? 8 : 24,
      otherError: data.lowerLimit.value >= data.upperLimit.value,
    },
    {
      name: "upperLimit",
      title: "範囲上限値",
      required: true,
      rule: [KEY_RULE_INPUT.NOT_BLANK, KEY_RULE_INPUT.IS_UNSIGN_INTERGER],
      ruleInput: [KEY_RULE_INPUT.IS_UNSIGN_INTERGER,KEY_RULE_INPUT.MAX_LENGTH_NUMBER],
      span: isPC ? 8 : 24,
      otherError: data.lowerLimit.value >= data.upperLimit.value,
    },

    {
      name: "remark",
      title: lang.REMARK,
      required: false,
      type: "textarea",
    },
  ];
  // VALIDATE DATA
  const validate = () => {
    let isFlagErr = false;
    let fistNameErr = "";
    
    // check rule
    for (let index = 0; index < listDataModal.length; index++) {
      const nameItem = listDataModal[index].name;
      const value = data[nameItem].value;
      const otherErr = listDataModal[index].otherError;
      const listRule = listDataModal[index].rule;
      let isErrItem = false;
      if (Array.isArray(listRule)) {
        isErrItem = listRule.some((ruleName) => {
          return !LIST_RULE[ruleName](value);
        });
      }

      if (isErrItem || otherErr) {
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: true,
          },
        }));
        if (!fistNameErr) {
          fistNameErr = nameItem;
        }
        isFlagErr = true;
      } else {
        setData((prevData) => ({
          ...prevData,
          [nameItem]: {
            ...prevData[nameItem],
            error: false,
          },
        }));
      }
    }

    // Focus Fist Item Error
   
    if (fistNameErr && inputRefs.current && inputRefs.current[fistNameErr]) {
      inputRefs.current[fistNameErr].focus();
    }
    return !isFlagErr;
  };

  // POST FUNC
  const handleEditLv = async () => {
    try {
      const body = {
        fiberLength: data.fiberLength.value,
        upperLimit: data.upperLimit.value,
        lowerLimit: data.lowerLimit.value,
        standard: data.standard.value,
        remark: data.remark.value,
        unit: data.unit.value,
        rawWaterType: itemDetail.rawWaterType,
      };
      await LvAPI.update(body);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
        callback();
        onClose();
      });
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  };
  // SAVE DATA
  const onSaveData = async () => {
    if (!validate() || !itemDetail) {
      return;
    }
    ModalCustom.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: handleEditLv,
    });
  };
  return (
    <div>
      <Modal
        centered
        width={isPC ? "40%" : "90%"}
        bodyStyle={{ height: "auto", overflow: "auto" }}
        closeIcon={<></>}
        footer={[
          <Button
            onClick={() => onClose()}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CANCEL}
          </Button>,
           <Button
           onClick={() => onSaveData()}
           className="buttonPC button--info wAuto"
         >
           {lang.OK}
         </Button>,
        ]}
        title={"基準LV編集"}
        open={isModalVisible}
      >
        <Form style={{ gap: "100px" }}>
          <Row gutter={[16, 16]}>
            {listDataModal.map((item) => (
              <Col key={item.name} span={item.span || 24}>
                <div>
                  <label className="bold">
                    {item.title}{" "}
                    {item.required && (
                      <label className="blod mark-required">*</label>
                    )}
                  </label>
                  <FormInput
                    prefix={item.prefix && item.prefix}
                    suffix={item.suffix && item.suffix}
                    placeholder={item.placeholer || ""}
                    disabled={item.disabled}
                    valueObject={data[item.name]}
                    onChange={handleChangeData(item.name)}
                    refer={(ref) => (inputRefs.current[item.name] = ref)}
                    className={`${
                      data[item.name].error ? STYLE.BORDER_RED : ""
                    }`}
                    type={item.type}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Form>
      </Modal>
      {/* <TitlePage name={"薬品注入編集"} /> */}
    </div>
  );
}

export default EditLv;

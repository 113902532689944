import React from "react";
export const HeaderText = ({
  text,
  color = "rgba(0, 0, 0, 0.88)",
  size = 18,
  fontSize = 16,
  isLine = true
}) => {
  return (
    <span
      style={{
        color,
        fontSize,
        fontWeight: "bold",
        position: "relative",
        display: "inline-block",
        marginBottom: 10,
        marginTop: 20,
      }}
      className={isLine && "header-text"}
    >
      {text}
    </span>
  );
};

import { Checkbox, Collapse, Row } from "antd";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../../constants/common.const";

import React from "react";
import { useSelector } from "react-redux";
import { cloneObj } from "../../../utils/helpers";
import { RowInput } from "./step1";
const { Panel } = Collapse;

const WaterDevice = ({
  listWaterDevice = [],
  setListWaterDevice = () => {},
}) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const isMobile = !isPC;
  const { isLeader } = useSelector((state) => state.auth);

  const handleChangeChecboxWaterDevice = (key, newVal) => {
    const index = listWaterDevice.findIndex((i) => i.amountId === key);
    if (index >= 0) {
      const newList = cloneObj(listWaterDevice);
      newList[index].checked = newVal;
      setListWaterDevice(newList);
    }
  };

  // Render List option device
  const renderListOptionDevice = listWaterDevice.map((item, index) => {
    return (
    <div style={{
      paddingLeft:50
    }}>
        <RowInput
        labelCol={isMobile ? 24 : 6}
        contentCol={isMobile ? 24 : 12}
        // borderBottom={index < listWaterDevice.length - 1}
        key={index}
        name={
          <div
            style={{
              padding: isPC ? "10px 0" : "5px 0",
              marginTop: !isPC ? 10 : 0,
            }}
          >
            <Checkbox
              checked={item.checked}
              onChange={(e) =>
                handleChangeChecboxWaterDevice(item.amountId, e.target.checked)
              }
            >
              <span
                style={{
                  display: "inline-block",
                  marginTop: 2,
                }}
              >
                {item.amountTable}
              </span>
            </Checkbox>
          </div>
        }
        required={item.required}
      ></RowInput>
    </div>
    );
  });

  return (
    <>
    
      {renderListOptionDevice}
    </>
  );
};
export default React.memo(WaterDevice);

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ChemicalTankApi } from "../../../api/backend/chemicalTank";
import Loading from "../../../component/Desktop/loading/loading";
import { HeaderText } from "../../../components/common/headerText";
import { COMMON_CONSTANT, KEY, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";

import { deboundClickModal, formatCurrencyJPY } from "../../../utils/helpers";
import AddChemicalTank from "./AddChemicalTank";
import useLoadTabDataEffect from "../../../hooks/useLoadTabData";
const { Panel } = Collapse;
const TableChemicalTank = ({isLoad}) => {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [loading, setLoading] = useState(false);
  const [dataChemicalTank, setDataChemicalTank] = useState([]);
  const [idItem, setIdItem] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [costManagement, setCostManagement] = useState([]);
  const onClose = (isReset = true) => {
    setIdItem(null);
    setOpenModal(false);
    if (isReset) {
      getListTank();
    }
  };

  const getListTank = async () => {
    try {
      setLoading(true);
      const optionsRes = await ChemicalTankApi.getChemicalTank();
      const optionData = optionsRes.data.data.map((item, index) => ({
        key: item.chemicalId,
        capacity: item.capacity,
        weight: item.weight,
        price: item.price,
        material: item.material,
        priceBasis: item.priceBasis,
        note: item.note,
        name:item.name
      }));
      setDataChemicalTank(optionData);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  useLoadTabDataEffect(isLoad,getListTank);
  useEffect(() => {
    if (isError) {
      deboundClickModal.handleCloseModal();
    }
  }, [isError]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: "5%",
      render: (text, record, index) => {
        return <span>{++index}</span>;
      },
      align: "center",
    },
    {
      title: "型式",
      dataIndex: "name",
      key: "name",
      width: "10%",
      render: (text, record, index) => {
        return <span>{record.name}</span>;
      },
      align: "start",
    },
    {
      title: (
        <div style={{ textAlign: "start" }}>{LANG.TANK_CAPACITY + "(L)"}</div>
      ),
      dataIndex: "capacity",
      key: "capacity",
      width: "10%",
      render: (text, record, index) => {
        return <div>{formatCurrencyJPY(record.capacity)}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "start" }}>{LANG.WEIGHT}(kg)</div>,
      dataIndex: "weight",
      key: "weight",
      width: "10%",
      render: (text, record) => {
        return <span>{formatCurrencyJPY(record.weight)}</span>;
      },
    },
    {
      title: (
        <div style={{ textAlign: "start" }}>
          {LANG.AMOUT_OF_MONEY + "(千円/基)"}
        </div>
      ),
      dataIndex: "price",
      key: "price",
      width: "10%",
      render: (text, record) => {
        return <span>{formatCurrencyJPY(record.price)}</span>;
      },
    },
    {
      title: <div style={{ textAlign: "start" }}>{LANG.MATERIAL}</div>,
      dataIndex: "material",
      key: "material",
      width: "5%",
      render: (text, record, index) => {
        return <span>{record.material}</span>;
      },
    },
    {
      title: <div style={{ textAlign: "start" }}>{LANG.PRICE_BASIS}</div>,
      dataIndex: "priceBasis",
      key: "priceBasis",
      width: "15%",
      render: (text, record, index) => {
        return <span>{record.priceBasis}</span>;
      },
    },
    {
      title: <div style={{ textAlign: "start" }}>{LANG.REMARK}</div>,
      dataIndex: "note",
      key: "note",
      width: "20%",
      render: (text, record, index) => {
        return <span>{record.note}</span>;
      },
    },
    {
      title: LANG.ACTION,
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: "15%",
      render: (id, record, index) => {
        return (
          <Row gutter={!isPC && [4, 4]} justify={"center"}>
            <Col>
              {" "}
             <Tooltip title={lang.EDIT}>
             <Button
                onClick={() => {
                  setOpenModal(true);
                  setIdItem(record.key);
                }}
                className={"iconItem"}
                icon={<EditOutlined />}
              />
             </Tooltip>
            </Col>
            <Col>
              {" "}
              <Tooltip title={lang.DELETE}>
              <Button
                onClick={() => handleDeletePump(record.key)}
                className={"iconItem"}
                icon={<DeleteOutlined />}
              />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  // DELETE
  const handleDeletePump = (key) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          await ChemicalTankApi.deleteChemicalTank(key);
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
          getListTank();
        } catch (error) {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      },
    });
  };
  return (
    <div>
      <Collapse
        className="mt15"
        defaultActiveKey={["1"]}
        expandIconPosition="right"
      >
        <Panel
          header={
            <div className={"wrapTitle"}>
              <span style={{ fontWeight: "bold" }}>{"薬品タンク"}</span>
            </div>
          }
          key={"1"}
        >
          <div>
            <Row align={"middle"} justify="space-between ">
              <Col  span={12}>
                <Button
                  onClick={() => setOpenModal(true)}
                  style={{
                    background: "#5f8de5",
                    color: "#fff",
                    border: "none",
                  }}
                  icon={<PlusOutlined />}
                >
                  {lang.ADD}
                </Button>
              </Col>
              <Col
                style={{
                  visibility: "hidden",
                }}
                span={12}
              >
                <HeaderText isLine={false} text={"薬品タンク"} />
              </Col>
            </Row>
            <Table
              dataSource={dataChemicalTank}
              columns={columns}
              pagination={false}
              className="gridSearch"
              scroll={{ x: !isPC && "100%" }}
              bordered
            />
          </div>
        </Panel>
      </Collapse>

      <Loading show={loading} />
      {openModal && (
        <AddChemicalTank onClose={onClose} id={idItem} open={openModal} />
      )}
    </div>
  );
};
export default TableChemicalTank;
